
class LocationService {
  private readonly options: PositionOptions

  constructor (enableHighAccuracy = false, timeout = 20000, maximumAge = 0) {
    this.options = {
      enableHighAccuracy,
      timeout,
      maximumAge
    }
  }

  getCurrentLocation = async (): Promise<{ lat: number, long: number }> => {
    const position = await new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error),
        this.options
      )
    })

    return { lat: position.coords.latitude, long: position.coords.longitude }
  }
}

export default LocationService
