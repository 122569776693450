<script setup lang="ts">
import { ref, reactive } from 'vue'
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'

import { useToast } from 'vue-toastification'
import { groupSchema } from '../_formkit/schema/edit_group.schema'

import { useAuthStore } from '../stores/auth'
import { useGroupStore } from '../stores/groups'
import { FormKitNode } from '@formkit/core'
import { FormKitSchema } from '@formkit/vue'
import { router } from '../router'
import { useI18NStore } from '../stores/i18n'
import { PERMISSION_GROUPS_MESSAGES } from '../_constants/toastmessages.constant'

const resetLink = ref<string | undefined>('')

const authStore = useAuthStore()
const groupStore = useGroupStore()
const toast = useToast()

async function submit (data: any, node: FormKitNode | undefined) {
  const response = await groupStore.addGroup(data)
  if (response.success) {
    toast.success(useI18NStore().gettext(PERMISSION_GROUPS_MESSAGES.success))
    router.push({ name: 'groups' })
  } else {
    toast.error(useI18NStore().gettext(PERMISSION_GROUPS_MESSAGES.error))
    node?.setErrors(response.errors)
  }
}

const formKitData = reactive({
  gettext: useI18NStore().gettext
})
</script>

<template>
  <base-layout
    :title="gettext('Add Group')"
  >
    <content-section>
      <div v-if="!resetLink">
        <div>
          <FormKit
            type="form"
            :submit-label="gettext('Submit')"
            novalidate
            message-class="text-red-500 text-sm"
            :disabled="!authStore.can('auth.add_group')"
            @submit="submit"
          >
            <FormKitSchema
              :schema="groupSchema"
              :data="formKitData"
            />
          </FormKit>
        </div>
      </div>
    </content-section>
  </base-layout>
</template>
