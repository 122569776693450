<script setup lang="ts">
import { useSubmissionsStore, SubmissionType } from '../../stores/submissions'
import ContentSection from '../../components/base/ContentSection.vue'
import DataTable from '../../components/commons/DataTable.vue'
import type { Header, Item } from 'vue3-easy-data-table'
import { Router, useRouter } from 'vue-router'
import { isProxy, toRaw, reactive } from 'vue'
import BaseLayout from '../../layouts/BaseLayout.vue'
import { format } from 'date-fns'
import { SUBMISSIONS_STATUS, SUBMISSION_STATUS_ARRAY } from '../../_constants/common.constant'
import { useLocationStore } from '../../stores/locations'
import { useI18NStore } from '../../stores/i18n'
import { useFormsStore } from '../../stores/forms'

const router: Router = useRouter()
const locationStore = useLocationStore()
const i18nstore = useI18NStore()
const formStore = useFormsStore()

const headers: Header[] = reactive([
  { text: i18nstore.gettext('Form name'), value: 'name', sortable: true, width: 270 },
  { text: i18nstore.gettext('Last action'), value: 'last_action', sortable: true },
  { text: i18nstore.gettext('Municipality'), value: 'district', sortable: true },
  { text: i18nstore.gettext('Administrative Post'), value: 'administrative_post', sortable: true },
  { text: i18nstore.gettext('Suco'), value: 'suco' },
  { text: i18nstore.gettext('Status'), value: 'submission_status', class: 'text-center' }
])

const submissionStore = useSubmissionsStore()
let submissions = isProxy(submissionStore.submissions) ? toRaw(submissionStore.submissions) : submissionStore.submissions
submissions = submissions.sort((a: SubmissionType, b: SubmissionType) => {
  const ADate = a.created
  const BDate = b.created
  return new Date(BDate).getTime() - new Date(ADate).getTime()
})

await locationStore.initialize()
const items = () => submissions.map((submission) => {
  const lang = i18nstore.code
  const formName = formStore.getFormByFormType(submission.form_type)?.name_set.find(it => it.lang === lang)
  return {
    redirectParams: { formKey: submission.key },
    name: formName?.label ?? '',
    last_action: format(new Date(submission.created), 'd MMM yyyy, k:mm:ss'),
    last_action_get_time: new Date(submission.created).getTime(),
    submission_status: SUBMISSIONS_STATUS[submission.status ?? 1],
    // These fields are now lookups from locations
    district: locationStore.getName([parseInt(submission.fields.district, 10)]),
    administrative_post: locationStore.getName([parseInt(submission.fields.district, 10), parseInt(submission.fields.administrative_post, 10)]),
    suco: locationStore.getName([parseInt(submission.fields.district, 10), parseInt(submission.fields.administrative_post, 10), parseInt(submission.fields.suco, 10)])
  }
})

const dataTableFilters = {
  field: 'submission_status',
  options: SUBMISSION_STATUS_ARRAY
}

</script>

<template>
  <base-layout
    :title="!router.currentRoute?.value?.meta?.formNavigation ? gettext('Forms Submission') : ''"
    :sub-title="!router.currentRoute?.value?.meta?.formNavigation ? gettext('Access all submitted forms, review and verify submitted data. It streamlines the process of data submission and ensures accuracy and efficiency.') : ''"
  >
    <content-section
      :has-border="false"
      class="!pt-5"
    >
      <DataTable
        :headers="headers"
        :items="items()"
        :filters="dataTableFilters"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl top-[2px] relative !text-zinc-900"
        filter-size="lg"
        redirect-path="formDetail"
        theme-color="dark"
        spacing="narrow"
      />
    </content-section>
  </base-layout>
</template>

<style scoped>

</style>
