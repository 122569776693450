<script setup lang="ts">
import { computed } from 'vue'
import { ColorList } from '../../_types/colours'
import BasicCard from './BasicCard.vue'
import IconBox from '../commons/IconBox.vue'

const props = defineProps<{
  boxClass?: string
  iconclass?: string
  content: {
    title: string
    content: string
    footer: string
  },
  color: keyof typeof ColorList | string
}>()

const styleObject = computed(() => ({
  backgroundColor: (props.color in ColorList) ? ColorList[props.color as keyof typeof ColorList] : props.color
}))

</script>

<template>
  <basic-card :class="boxClass">
    <icon-box
      class="mx-auto mt-4"
      :style="styleObject"
    >
      <i :class="iconclass"></i>
    </icon-box>
    <h4 class="text-xl font-bold mt-3">
      {{ content.title }}
    </h4>
    <h1 class="mt-2 text-4.5xl font-black">
      {{ content.content }}
    </h1>
    <p class="mb-4 mt-2">
      {{ content.footer }}
    </p>
  </basic-card>
</template>
