import { MenuContext, MAIN_MENUS } from '../_constants/navigation.constant'
import { removeSlashFromString } from './common.helpers'
import { useAuthStore } from '../stores/auth'

/**
 * Function to get the selected menu based on the provided path
 * @param path string
 * @returns null | MenuContext
 */
export function getSelectedMenu (path: string): null | MenuContext {
  for (const menu of MAIN_MENUS) {
    // Check if the menu has a 'to' property and it matches the provided path
    if ((menu.to !== undefined) && removeSlashFromString(menu.to) === removeSlashFromString(path)) {
      return menu // Return the menu if found
    }
    // Check if the menu has a 'subMenus' array
    if ((menu.subMenus !== undefined) && menu.subMenus.length > 0) {
      // Iterate through the subMenus array
      for (const subMenu of menu.subMenus) {
        // Check if the subMenu has a 'to' property and it matches the provided path
        if (subMenu.to !== null && removeSlashFromString(subMenu.to) === removeSlashFromString(path)) {
          return subMenu // Return the subMenu if found
        }
      }
    }
  }
  return null
}

/**
 * Function to check if a user has permission to access a specific path
 * @param path path
 * @returns boolean
 */
export function checkNavigationGuard (path: string): boolean {
  const authStore = useAuthStore()
  const permissions = authStore.$state.user?.perms
  const selectedMenu = getSelectedMenu(path)
  if (selectedMenu !== null) {
    const checkPermission = selectedMenu.permissions?.findIndex(perm => permissions?.join(',').includes(perm)) ?? 0
    return checkPermission > -1
  }
  return true
}
