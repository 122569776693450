import { defineStore } from 'pinia'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
import { useToast } from 'vue-toastification'
import { useSukuProfileStore } from './sukuprofile'
import { useI18NStore } from './i18n'
import { FORM_TYPES } from '../_constants/common.constant'
import { useAppStore } from './app'
import { SUKU_TEAMS_MESSAGE } from '../_constants/toastmessages.constant'
import { SUKU_TEAMS_TYPE } from '../_constants/sukumanager.constant'
const toast = useToast()

type Sf_1_3RepeaterplanningSchema = components['schemas']['Sf_1_3RepeaterplanningSchema']
type Sf_1_3RepeaterprojectteamSchema = components['schemas']['Sf_1_3RepeaterprojectteamSchema']
type Sf_1_3RepeatersukusSchema = components['schemas']['Sf_1_3RepeatersukusSchema']
type Sf_1_3Schema = components['schemas']['Sf_1_3Schema']

interface InitialState {
  teams: Array<Sf_1_3RepeaterplanningSchema | Sf_1_3RepeaterprojectteamSchema | Sf_1_3RepeatersukusSchema>
}

export const useSukuTeamsStore = defineStore('sukuteams', {
  state: () => {
    const initialState: InitialState = {
      teams: []
    }
    return initialState
  },
  getters: {
    getTeamById (state) {
      return (id: string) => {
        return state.teams.find(team => team.uuid === id)
      }
    }
  },
  actions: {
    /**
     * fetch SF1_3 repeater data
     * @param activeTeam string
     */
    async initialize (activeTeam: string) {
      const appStore = useAppStore()
      appStore.$state.loading = true
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = sukuProfileStore.$state.activezSukuId
      if (typeof zSucoId !== 'undefined') {
        // fetch the SF1.3 repeater data based on activeTeam
        const path = activeTeam === SUKU_TEAMS_TYPE.repeaterProjectTeam ? '/api/submissions/v2/sf_1_3repeaterprojectteam' : activeTeam === SUKU_TEAMS_TYPE.repeaterPlanning ? '/api/submissions/v2/sf_1_3repeaterplanning' : '/api/submissions/v2/sf_1_3repeatersukus'
        const { data } = await client.GET(path, { params: { query: { suco: zSucoId } } })
        this.teams = data as Array<Sf_1_3RepeaterplanningSchema | Sf_1_3RepeaterprojectteamSchema | Sf_1_3RepeatersukusSchema>
      }
      appStore.$state.loading = false
    },
    /**
     * fetch SF1.3 submission data
     * @param key string
     * @returns Promise<Sf_1_3Schema | undefined>
     */
    async fetchSubmissionData (key: string): Promise<Sf_1_3Schema | undefined> {
      const { data } = await client.GET('/api/submissions/v2/sf_1_3', { params: { query: {} } })
      return data?.find(it => it.submission_id === key)
    },
    /**
     * update SF1.3 submission. this will call `fetchSubmissionData` to get submission data then replace it with `values` which coming from the Form
     * @param values Record<string, never>
     * @param activeTeam string
     * @returns boolean
     */
    async save (values: Record<string, never>, activeTeam: string) {
      const appStore = useAppStore()
      appStore.$state.loading = true
      const submissionKey = values.parent

      if (!submissionKey) return false
      // get submission data
      const submissionData = await this.fetchSubmissionData(submissionKey)

      if (submissionData == null) return false

      const targetArray = activeTeam === SUKU_TEAMS_TYPE.repeaterProjectTeam
        ? submissionData.repeaterProjectTeam
        : activeTeam === SUKU_TEAMS_TYPE.repeaterPlanning
          ? submissionData.repeaterPlanning
          : submissionData.repeaterSukus

      if (targetArray == null) return
      // replace the values based on activeTeam
      submissionData[activeTeam === SUKU_TEAMS_TYPE.repeaterProjectTeam ? 'repeaterProjectTeam' : activeTeam === SUKU_TEAMS_TYPE.repeaterPlanning ? 'repeaterPlanning' : 'repeaterSukus'] = targetArray.map(it => {
        if (it.uuid === values.uuid) {
          return { ...it, ...values }
        }
        return it
      })

      const { data } = await client.PUT('/api/submissions/update', { body: { key: submissionKey, form_type: FORM_TYPES.SF13, fields: submissionData as unknown as Record<string, never> } })
      if (typeof data !== 'undefined') {
        toast.success(useI18NStore().gettext(SUKU_TEAMS_MESSAGE.success))
      } else {
        toast.error(useI18NStore().gettext(SUKU_TEAMS_MESSAGE.error))
      }

      appStore.$state.loading = false

      return typeof data !== 'undefined'
    }

  }
})
