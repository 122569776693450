
export const userSchema = [
  {
    $formkit: 'textFloating',
    id: 'first_name',
    name: 'first_name',
    label: 'First name',
    prefixIcon: 'user',
    innerClass: 'bg-white'
  },
  {
    $formkit: 'textFloating',
    name: 'last_name',
    label: 'Last name',
    prefixIcon: 'user',
    required: true,
    innerClass: 'bg-white'
  },
  {
    $formkit: 'textFloating',
    name: 'email',
    label: 'Email',
    prefixIcon: 'user',
    innerClass: 'bg-white'
  }
]

export const manageUserSchema = [
  {
    $formkit: 'text',
    id: 'first_name',
    name: 'first_name',
    label: '$gettext("First name")'
  },
  {
    $formkit: 'text',
    name: 'last_name',
    label: '$gettext("Last name")',
    required: true
  },
  {
    $formkit: 'email',
    name: 'email',
    label: '$gettext("Email")'
  }
]
