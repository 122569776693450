import { defineStore } from 'pinia'
import { Translatable, interpolationValues, languages } from '../_types/translations'
import { MapTranslations } from '../_translation/idb_translation'
import { client } from '../_api-services/urls'

export type gettextFunc = (a: string, code?: string) => string
type interpolateFunc = (a: string, obj: interpolationValues, named: boolean) => string
type pgettextFunc = (context: string, msgid: string) => string

export type languageCodes = 'tet' | 'en' | 'pt'

interface AppState {
  languages: Array<{ code: string, name: string }>
  code: languageCodes
  allTranslations: MapTranslations // Translations for all languages
  notFound: Array<Partial<Translatable>>
}

const initialState: AppState = {
  languages,
  code: 'tet',
  allTranslations: new MapTranslations(),
  notFound: []
}

export const useI18NStore = defineStore('i18n', {
  state: () => initialState,
  getters: {
    gettext (state): gettextFunc {
      return (msgid: string): string => {
        if (state.code === 'en') return msgid
        const catalog = state.allTranslations.getByLanguage(state.code)
        const msgstr = catalog.gettext(msgid)
        if (typeof msgstr !== 'undefined' && typeof msgstr.msgstr !== 'undefined') return msgstr.msgstr
        state.notFound.push({ msgid, langcode: state.code })
        // console.warn('Translation not found:', msgid, state.code)
        return msgid
      }
    },
    interpolate: interpolateFunc => {
      /**
       * This function is used where we have a string with 'common' parts
       * which we might replace
       * One example is on the Option editor where we translate `Title` and an language name
       * The separate parts of the string; `Title`, and the language name, are translated
       * That means we can re-use "Title" and the 3 language names without having to add "Title: English / Tet / Pt"
       * with a single function with placeholders:
       * >>> i18nstore.interpolate('%(title)s %(label)s', { title: i18nstore.gettext('Title'), label: i18nstore.gettext(label) })
       */
      return (msgid: string, obj: { [key: string]: number | string }): string => {
        return msgid.replace(/%\(\w+\)s/g, function (match) {
          return String(obj[match.slice(2, -2)])
        })
      }
    },
    pgettext (state): pgettextFunc {
      return (context: string, msgid: string): string => {
        if (state.code === 'en') return msgid
        const catalog = state.allTranslations.getByLanguage(state.code)
        const msgstr = catalog.pgettext(context, msgid)
        if (typeof msgstr !== 'undefined' && typeof msgstr.msgstr !== 'undefined') return msgstr.msgstr
        state.notFound.push({ msgid, msgctxt: context, langcode: state.code })
        return msgid
      }
    }
  },
  actions: {
    async fromDb () {
      const t = new MapTranslations()
      await t.fromDb()
      this.$patch((state) => { state.allTranslations = t })
    },
    async get () {
      await this.fromDb()
      const url = '/api/translation/translations'
      try {
        const { data, error, response } = await client.GET(url, { params: { query: { since: this.$state.allTranslations.getLatest() } } })
        if (typeof error !== 'undefined') throw new Error(error)
        if (typeof data !== 'undefined') {
          /* Update the translations map with updated data */
          data.map(it => this.$state.allTranslations.push(it))
          void this.$state.allTranslations.toDb(data)
        }
        return { data, error, response }
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.warn('SyntaxError caught:', e, url)
        } else {
          throw e
        }
      }
    }
  }
})
