<script setup lang="ts">
import { ComputedRef, computed } from 'vue'
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import OptionsDropdownNav from '../../components/options/OptionsDropdownNav.vue'
import DataTable from '../../components/commons/DataTable.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import { useOptionsStore } from '../../stores/options'
import { Header, Item } from 'vue3-easy-data-table'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { useI18NStore } from '../../stores/i18n'
import { components } from '../../_api-services/openapi'
import { format, parseISO } from 'date-fns'
const optionsStore = useOptionsStore()
const i18nstore = useI18NStore()

const props = defineProps<{
  group?: groupName
}>()
/**
 * initialize options list
 */

const groupnames: ComputedRef<string[]> = computed(() => optionsStore.groupNames)

/** Either the passed group option or the first group known */
const groupName_ = computed(() => {
  const f = [...optionsStore.groupNames][0]
  return props.group || f
})

const headers: ComputedRef<Header[]> = computed(() => {
  const h: Header[] = [
    { value: 'value', text: i18nstore.gettext('Value') },
    { value: 'is_active', text: i18nstore.gettext('Is Active') },
    { value: 'label_en', text: i18nstore.gettext('Label (English)') },
    { value: 'label_tet', text: i18nstore.gettext('Label (Tetum)') },
    { value: 'label_pt', text: i18nstore.gettext('Label (Portuguese)') }
  ]
  if (props.group === 'cycle') {
    h.push(
      { value: 'start_date_formatted', text: i18nstore.gettext('Start date') },
      { value: 'end_date_formatted', text: i18nstore.gettext('End date') }
    )
  }
  if (props.group === 'output') h.push({ value: 'subsector', text: i18nstore.gettext('Subsector') })
  if (props.group === 'subsector') h.push({ value: 'sector', text: i18nstore.gettext('Sector') })
  if (props.group === 'teamposition') h.push({ value: 'group', text: i18nstore.gettext('Group') })
  if (props.group === 'programactivity') {
    h.push(
      { value: 'type', text: i18nstore.gettext('Program activity type') },
      { value: 'level', text: i18nstore.gettext('Program activity level') }
    )
  }
  if (props.group === 'suku') h.push({ value: 'postu_adminstrativu', text: i18nstore.gettext('Administrative post') })
  if (props.group === 'postuadministrativu') h.push({ value: 'munisipiu', text: i18nstore.gettext('Municipality') })

  return h
})

type labels = {
  subsector: string,
  sector: string,
  group: string,
  type: string,
  level: string,
  postu_adminstrativu: string,
  munisipiu: string,
  start_date_formatted: string,
  end_date_formatted: string
}
type optionsInTable = components['schemas']['IdaOption'] & { redirectParams: { group: groupName, value: string } } & Partial<labels> & { int_value: number}

const items: ComputedRef<Item[]> = computed(() => {
  const group = optionsStore.filterByGroup(groupName_.value)
  if (typeof group === 'undefined') return []
  const all = Array.from(group.values())
  return all.map((i_) => {
    const i = { } as Partial<optionsInTable>
    i.value = i_.value
    i.label_en = i_.label.get('en')
    i.label_tet = i_.label.get('tet')
    i.label_pt = i_.label.get('pt')
    i.is_active = i_.params.get('is_active') as boolean ?? true
    i.redirectParams = { group: groupName_.value, value: i.value as string }
    // Create parameters to display in the table
    if (props.group === 'output') i.subsector = optionsStore.getLabel('subsector', i_.params.get('subsector_id') as number)
    if (props.group === 'subsector') i.sector = optionsStore.getLabel('sector', i_.params.get('sector_id') as number)
    if (props.group === 'teamposition') i.group = optionsStore.getLabel('teampositiongroup', i_.params.get('group_id') as number)
    if (props.group === 'programactivity') i.type = optionsStore.getLabel('programactivitytype', i_.params.get('type_id') as number)
    if (props.group === 'programactivity') i.level = optionsStore.getLabel('programactivitylevel', i_.params.get('level_id') as number)
    if (props.group === 'cycle') {
      if (i_.params.start_date) {
        i.start_date_formatted = format(i_.params.start_date, 'dd/MM/yyyy')
      }
      if (i_.params.end_date) {
        i.end_date_formatted = format(i_.params.end_date, 'dd/MM/yyyy')
      }
    }

    // Area hierarchy display
    if (props.group === 'suku') i.postu_adminstrativu = optionsStore.getLabel('postuadministrativu', i.postu_administrativu_id)
    if (props.group === 'postuadministrativu') i.munisipiu = optionsStore.getLabel('munisipiu', i.munisipiu_id)

    i.int_value = parseInt(i.value ?? '0')
    return i
  }).sort((a, b) => {
    if ((a.int_value ?? 0) < (b.int_value ?? 0)) return -1
    if ((a.int_value ?? 0) > (b.int_value ?? 0)) return 1
    return 0
  })
})

</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
        </router-link>
        <h1 class="text-2.5xl font-black">
          {{ gettext('Option') }}
        </h1>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <OptionsDropdownNav
          :items="groupnames"
          :group="groupName_"
        />
      </div>
    </template>
    <!-- <content-section>
      <h4 class="text-lg font-medium text-zinc-700 !leading-[26px]">
        {{ gettext('The admin Options page is available for administrators to manage the list of options available in the Ida platform.') }}
      </h4>
    </content-section> -->
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <h1 class="text-2.5xl font-black">
        {{ gettext('Options') }}
      </h1>
      <p class=" text-left text-zinc-700 mb-10">
        {{ gettext('For admin users to manage the list of options available on all the PNDS forms for the data entry and on all the pages on the Ida platform.') }}
      </p>
      <template v-if="groupName_">
        <DataTable
          :headers="headers"
          :items="items"
          theme-color="dark"
          spacing="narrow"
          filter-placeholder="Search"
          filter-suffix-icon="la-search !text-xl text-gray-400"
          redirect-path="option_edit"
        >
          <template #header-action>
            <basic-button
              :to="{name: 'option_add', params: { group: groupName_ }}"
            >
              <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('Add') }}
            </basic-button>
          </template>
        </DataTable>
      </template>
    </content-section>
  </base-layout>
</template>
