<script setup lang="ts">
import { ref, Ref } from 'vue'
import { FormKitNode } from '@formkit/core'

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  context: Object
})

const value: Ref<string> = ref(props.context?.value ?? '')

const ensureUuid = (node: FormKitNode) => {
  if (typeof node.value === 'undefined') node.input(crypto.randomUUID())
}

</script>
<template>
  <FormKit
    :id="context?.id"
    v-bind="context?.attrs"
    :key="context?.key"
    v-model="value"
    type="hidden"
    :name="context?.node?.name"
    @node="ensureUuid"
  />
</template>
