<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import DataTable from '../../components/commons/DataTable.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import { getNode } from '@formkit/core'
import type { Header, Item } from 'vue3-easy-data-table'
import { computed, ComputedRef, reactive, ref, Ref } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useI18NStore } from '../../stores/i18n'
import { useLocationStore } from '../../stores/locations'
import { components } from '../../_api-services/openapi'
import { useOptionsStore } from '../../stores/options'
import { useSukuActivitiesStore } from '../../stores/sukuactivities'
import { useModal } from '../../stores/modal'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { formatCurrencyWithDollar, generateProjectAsTableItem } from '../../_helpers/common.helpers'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../stores/auth'
import { FeatureSchema } from '../../stores/projectmanager'

const i18nstore = useI18NStore()
const locationStore = useLocationStore()
const optionsStore = useOptionsStore()
const sukuActivitiesStore = useSukuActivitiesStore()
const modal = useModal()
const authStore = useAuthStore()
const router = useRouter()

await sukuActivitiesStore.initialize()
await sukuActivitiesStore.fetchIdaProjects()
await optionsStore.initialize()

const showCreateForm = ref(false)
type Pom1RepeaterActivitySchema = components['schemas']['Pom_1RepeateractivitySchema']

const projectByActivities = ref<FeatureSchema[]>([])
const selectedActivity = ref()

const headers: ComputedRef<Header[]> = computed(() => [
  { sortable: true, value: 'year', text: 'Fiscal year' },
  { sortable: true, value: 'activity', text: 'Activity' },
  { sortable: true, value: 'operational_fund', text: 'Operational subsidy' },
  { sortable: true, value: 'infrastructure_fund', text: 'Infrastructure subsidy' },
  { value: 'view', text: 'View', class: 'text-center' }
])

const items: ComputedRef<Item[]> = computed(() => {
  return sukuActivitiesStore.getActivities.map(activity => ({
    ...activity,
    operational_fund: formatCurrencyWithDollar(activity.operational_fund ?? 0),
    infrastructure_fund: formatCurrencyWithDollar(activity.infrastructure_fund ?? 0),
    view: activity.parent,
    activity: optionsStore.getLabel('cycle', activity.activity),
    activityId: activity.activity
  }))
})

const headersProject: ComputedRef<Header[]> = computed(() => {
  const headersData = [
    { sortable: true, value: 'project_name', text: 'Project name' },
    { sortable: true, value: 'total_budget_allocated', text: 'Total budget allocated' },
    { sortable: true, value: 'total_expenditures', text: 'Total expenditures' },
    { sortable: true, value: 'balance', text: 'Balance' },
    { sortable: true, value: 'last_progress', text: 'Last progress' },
    { sortable: true, value: 'project_status', text: 'Project status' }
  ]
  if (authStore.canAny(['ida.view_project', 'ida.change_project'])) {
    headersData.push({ sortable: true, value: 'view', text: 'View' })
  }
  return headersData
})
const itemsProject: ComputedRef<Item[]> = computed(() => projectByActivities.value.length > 0
  ? generateProjectAsTableItem(projectByActivities.value).map(feature => {
    return {
      ...feature,
      project_name: optionsStore.getLabel('output', feature.project_name),
      project_activity: optionsStore.getLabel('cycle', feature.project_activity)
    }
  })
  : [])

const geolocationStore = useGeoLocationStore()
const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)

const values: Ref<{ repeaterActivity: Pom1RepeaterActivitySchema[] }> = ref({
  repeaterActivity: []
})

const viewMore = async (item: Item) => {
  projectByActivities.value = sukuActivitiesStore.getProjectByActivity(item.activityId)
  selectedActivity.value = optionsStore.getLabel('cycle', item.activityId)
  showCreateForm.value = true
}

const viewProject = (item: Item) => {
  router.push({
    name: 'projectsDetail',
    params: {
      projectId: item.project_id
    }
  })
}
</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <h1 class="text-2.5xl font-black mb-10">
        {{ gettext('List of Suku Activities') }}
      </h1>
      <DataTable
        :headers="headers"
        :items="items"
        filter-size="md"
        theme-color="dark"
        spacing="narrow"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl !text-gray-400"
        @view="viewMore"
      />
    </content-section>
    <modal-box
      v-if="showCreateForm"
      class="text-center"
      size="2xl"
      spacing="md"
    >
      <template #header>
        <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
          <i class="text-gray-400 mr-3 text-2xl las la-list-alt"></i>
          {{ selectedActivity }} - {{ gettext('Projects') }}
        </h4>
      </template>
      <button
        class="fixed right-6 top-6 text-lg w-[28px] h-[28px] rounded-full bg-emerald-600 text-white"
        @click="() => showCreateForm = false"
      >
        <i class="las la-times"></i>
      </button>
      <div class="text-left">
        <DataTable
          :headers="headersProject"
          :items="itemsProject"
          filter-size="md"
          theme-color="dark"
          spacing="narrow"
          filter-placeholder="Search"
          filter-prefix-icon="la-search !text-xl !text-gray-400"
          @view="viewProject"
        />
      </div>
    </modal-box>
  </base-layout>
</template>
