import { defineStore } from 'pinia'
import { ButtonTheme } from '../components/commons/button'

interface ModalAction {
  label: string
  icon?: string
  buttonTheme: ButtonTheme
  buttonClass?: string
  callback: (props?: any) => void
}
interface ModalProps {
  label?: string
  modalTitle: string
  modalCaption: string
  actions?: ModalAction[]
  hideModalClose?: boolean
  callback?: (props?: any) => void
  modalIcon?: string
  modalIconWrapper?: string
}

interface Modal {
  isOpen: boolean
  type?: string
  props?: ModalProps
}

const initialState: Modal = {
  isOpen: false,
  type: '', // will use `type` to determine what kind of modal
  props: undefined
}

export const useModal = defineStore('modal', {
  state: () => (initialState),
  actions: {
    open (type: string, props?: ModalProps) {
      this.isOpen = true
      this.props = props
      this.type = type
    },
    close () {
      this.isOpen = false
      this.type = ''
      this.props = undefined
    }
  }
})
