<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { OPTIONS_MENU } from '../../_constants/navigation.constant'

const props = defineProps<{
  items?: string[],
  group?: string
}>()

const router = useRouter()

const options = computed(() => {
  if (props.items !== undefined) {
    return [...props.items].map(i => {
      const label = OPTIONS_MENU.find(it => it.value === i)?.label ?? i
      return {
        label,
        value: i
      }
    })
  }
  return []
})

const input = (val?: string) => {
  router.push({
    name: 'options',
    params: {
      group: val as string
    }
  })
}
</script>

<template>
  <div class="relative h-[55px]">
    <FormKit
      id="optiongroupdropdown"
      :value="props.group"
      name="optiongroups"
      type="dropdown"
      outer-class="!mb-0 relative ml-1 data-[expanded=true]:w-[364px] data-[expanded=true]:absolute data-[expanded=true]:left-0"
      selector-class="!text-2.75xl leading-[27px] !font-black h-[55px] border-2 pr-9 !rounded-2.5xl [&>div>div>span]:hidden !border-zinc-400 aria-expanded:!border-b-transparent aria-expanded:!rounded-bl-none aria-expanded:!rounded-br-none bg-white"
      wrapper-class="w-content"
      dropdown-wrapper-class="!border-zinc-400 !max-h-[390px] overflow-y-scroll !rounded-bl-2.5xl !rounded-br-2.5xl !bg-white !max-h-max !pb-0"
      listitem-class="flex item-center py-2.5 text-lg font-bold border-t border-t-zinc-400 hover:!bg-stone-100 hover:!text-zinc-900"
      selection-wrapper-class="[&>span]:block [&>span]:max-w-[300px] [&>span]:whitespace-nowrap [&>span]:overflow-hidden [&>span]:text-ellipsis"
      :options="options"
      select-icon="angleDown"
      select-icon-class="!relative !-right-4 top-[2px]"
      @input="input"
    >
      <template #option="{ option }">
        <div class="formkit-option flex">
          {{ gettext(option.label) }}
        </div>
      </template>
    </FormKit>
  </div>
</template>
