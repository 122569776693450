<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import NavTabs from '../../components/commons/NavTabs.vue'
import BasicCard from '../../components/commons/BasicCard.vue'
import IconBox from '../../components/commons/IconBox.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import LoadingAnimation from '../../components/commons/LoadingAnimation.vue'
import ProjectChartsWithSuspense from '../../components/sukumanager/ProjectChartsWithSuspense.vue'
import LegacyProjectsDataTable from '../../components/sukumanager/LegacyProjectsDataTable.vue'
import DataTable from '../../components/commons/DataTable.vue'
import ProjectMap from '../../components/commons/ProjectMap.vue'
import SukuBeneficiaries from '../../components/sukumanager/SukuBeneficiaries.vue'

import { Header, Item } from 'vue3-easy-data-table'
import { MenuType } from '../../_types/commons'
import { ref, Ref, computed, onMounted, ComputedRef } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useSukuProfileStore, SSIList } from '../../stores/sukuprofile'
import { useProjectManagerStore } from '../../stores/projectmanager'
import { formatCurrencyWithDollar, generateProjectAsTableItem } from '../../_helpers/common.helpers'
import downloadElement_ from '../../_helpers/downloadElement'

import TooltipBox from '../../components/commons/TooltipBox.vue'
import { useAppStore } from '../../stores/app'
import { useOptionsStore } from '../../stores/options'
import { useLocationStore } from '../../stores/locations'
import { sumOfField } from '../../_helpers/sumfields.helpers'

const geolocationStore = useGeoLocationStore()
const sukuprofileStore = useSukuProfileStore()
const optionsStore = useOptionsStore()
const appStore = useAppStore()
const projectManagerStore = useProjectManagerStore()

await optionsStore.initialize()

const profileData = ref(new SSIList().profileData())
const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)
const overviewText: Ref<string> = ref('')
const isEditOverview: Ref<boolean> = ref(false)
const projectMapRef = ref<{ mapInitialize:() => void } | null>(null)

const headers: ComputedRef<Header[]> = computed(() => [
  { sortable: true, value: 'project_name', text: 'Project name' },
  { sortable: true, value: 'total_budget_allocated', text: 'Total budget allocated' },
  { sortable: true, value: 'total_expenditures', text: 'Total expenditures' },
  { sortable: true, value: 'balance', text: 'Balance' },
  { sortable: true, value: 'last_progress', text: 'Last progress' },
  { sortable: true, value: 'project_status', text: 'Project status' }
])

const idaProjectsBySector = computed(() => projectManagerStore.$state.idaProjects !== undefined
  ? projectManagerStore.$state.idaProjects.filter(it => (sukuprofileStore.sector !== 0) ? it.properties.sector === sukuprofileStore.sector : true).map(it => {
    return { project_progress: it.properties?.project_completion, numberofdaysworked: (it.properties?.no_of_working_days_female ?? 0) + (it.properties?.no_of_working_days_male ?? 0) }
  })
  : [])

// IDA projects list filtered to the current sector

const idaProjectsForSector = computed(() => {
  return projectManagerStore.$state.idaProjects.filter(it => sukuprofileStore.sector === 0 || it.properties.sector === sukuprofileStore.sector)
})

// Values for Materials and Labour spending
const idaBudget = computed(() => {
  const props = idaProjectsForSector.value.map(it => it.properties)
  return {
    materials: sumOfField(props, 'materials_sum'),
    labour: sumOfField(props, 'labour_incentive_sum')
  }
})

const projectItems: ComputedRef<Item[]> = computed(() => {
  if (projectManagerStore.$state.idaProjects === undefined) return []
  const projectBySector = generateProjectAsTableItem(idaProjectsForSector.value, true)

  return projectBySector.map(feature => {
    return {
      ...feature,
      project_name: optionsStore.getLabel('output', feature.project_name),
      project_activity: optionsStore.getLabel('cycle', feature.project_activity)
    }
  })
})

const menus = computed(() => {
  const sectors = optionsStore.options('sector', 'en').map(it => ({
    label: it.label,
    id: it.value
  })) as MenuType[]

  return [
    {
      label: 'Overview',
      id: '0', // Use special id of zero for the main page
      active: true
    },
    ...sectors
  ]
})

const downloadElement = (elementId: string) => {
  const prefix = activeGeoLocation.value?.properties.name
  downloadElement_({ elementId, prefix })
}

/**
 * load suku description
 */

await sukuprofileStore.getSukuDescription().then(() => {
  overviewText.value = sukuprofileStore.$state.activeSukuDescription?.description ?? ''
})

onMounted(async () => {
  appStore.$state.loading = true
  /* Uses 'then' rather than 'async' to continue loading the page while refreshing the idb */
  await sukuprofileStore.initialize().then(async function () {
    if (activeGeoLocation.value) {
      // The data provided in the URL is a "new" location. We need to filter to the "old" location
      // to get the zSuco counts
      // const zSucoId = useLocationStore().convertLocationToZsuco(activeGeoLocation.value.properties.id)
      const pcode = useLocationStore().convertLocationToZsucoPcode(activeGeoLocation.value.properties.id)
      if (typeof pcode === 'number') sukuprofileStore.setPcode(pcode)
      else throw new TypeError(`The zSuco for ${activeGeoLocation.value.properties.id} is not in the database`)
      if (sukuprofileStore.$state.activezSukuId !== undefined) {
        await projectManagerStore.fetchIdaProjects({
          suco: sukuprofileStore.$state.activezSukuId
        })
      }
    }
  }).finally(function () { appStore.$state.loading = false })
  profileData.value = sukuprofileStore.dataForSukuAndSector.profileData()
  if (projectMapRef.value !== null) {
    projectMapRef.value.mapInitialize()
  }
})

const onClickCategories = (value: string) => {
  const sectorId = parseInt(value, 10)
  sukuprofileStore.setSector(sectorId)
  profileData.value = sukuprofileStore.dataForSukuAndSector.profileData()
}

const saveOverview = async () => {
  await sukuprofileStore.saveSukuDescription(overviewText.value)
  isEditOverview.value = false
}

const legacyProjects = computed(() => sukuprofileStore.fetchLegacyProjects({ location_id: activeGeoLocation.value?.properties.id }))

</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <NavTabs
      :menus="menus"
      @click="onClickCategories"
    >
      <template #singleContent>
        <div class="mt-10 mb-8 lg:mb-[60px]">
          <div class="flex items-center mb-6">
            <h1 class="text-2.5xl font-bold">
              {{ gettext('Overview') }}
            </h1>
            <button
              class="ml-4 w-9 h-9 min-w-[36px] rounded-full border border-zinc-400"
              :class="{ 'bg-emerald-600 border-gray-100 text-white': isEditOverview, 'bg-zinc-400/50 hover:border-gray-100 hover:bg-zinc-400/20 hover:text-emerald-600': !isEditOverview }"
              @click="() => isEditOverview = !isEditOverview"
            >
              <i class="las la-pencil-alt"></i>
            </button>
          </div>

          <div
            v-if="isEditOverview"
            class="max-w-[751px]"
          >
            <textarea
              v-model="overviewText"
              name="overview"
              class="pl-4 pb-4.5 pr-6 pt-3 w-full bg-white border border-zinc-400 rounded-lg h-[144px] appearance-none field-normalize focus:border-emerald-600 !outline-0"
            ></textarea>
            <div class="flex justify-end mt-5">
              <basic-button
                theme="text"
                @click="() => isEditOverview = false"
              >
                {{ gettext('Cancel') }}
              </basic-button>
              <basic-button
                class="min-w-[135px]"
                @click="saveOverview"
              >
                {{ gettext('Save') }}
              </basic-button>
            </div>
          </div>
          <div
            v-else
            class="max-w-3xl"
          >
            <p>{{ overviewText === '' ? gettext('No data available') : overviewText }}</p>
          </div>
        </div>
        <Suspense>
          <template #fallback>
            <div class="mb-8 lg:mb-[60px]">
              <div class="flex items-center mb-9">
                <h1 class="text-2.5xl font-bold">
                  {{ gettext('Projects') }}
                </h1>
              </div>
              <loading-animation theme="default" />
            </div>
          </template>
          <project-charts-with-suspense
            v-if="typeof activeGeoLocation?.properties.id !== 'undefined'"
            :location-name="activeGeoLocation?.properties?.name"
            :data-function="legacyProjects"
            :sector-id="sukuprofileStore.sector"
            :ida-projects="idaProjectsBySector"
          />
        </Suspense>
        <div class="mb-8 lg:mb-[60px]">
          <div class="flex items-center mb-9">
            <h1 class="text-2.5xl font-bold">
              {{ gettext('Beneficiaries') }}
            </h1>
            <div class="rounded-full border border-zinc-400 bg-zinc-400/50 h-9 flex text-xl px-2 ml-6 hover:bg-zinc-400/20 hover:border-gray-100">
              <tooltip-box :text="gettext('This sub-section shows the impact of the project in terms of households or number of beneficiaries the project aims to reach.')">
                <button class="px-1 hover:text-emerald-600">
                  <i class="las la-info-circle"></i>
                </button>
              </tooltip-box>
              <tooltip-box :text="gettext('Download data as image')">
                <button
                  class="px-1 hover:text-emerald-600"
                  @click="downloadElement('beneficiaries-graph')"
                >
                  <i class="las la-download"></i>
                </button>
              </tooltip-box>
            </div>
          </div>
          <Suspense>
            <template #fallback>
              <div class="mb-8 lg:mb-[60px]">
                <div class="flex items-center mb-9">
                  <h1 class="text-2.5xl font-bold">
                    {{ gettext('Projects') }}
                  </h1>
                </div>
                <loading-animation theme="default" />
              </div>
            </template>
            <suku-beneficiaries
              v-if="activeGeoLocation?.properties.id"
              :suku-id="activeGeoLocation?.properties.id"
              :sector-id="sukuprofileStore.sector"
            />
          </Suspense>
        </div>

        <div class="mb-8 lg:mb-[60px]">
          <div class="flex items-center mb-9">
            <h1 class="text-2.5xl font-bold">
              {{ gettext('Materials and Labour Budget') }}
            </h1>
            <div class="rounded-full border border-zinc-400 bg-zinc-400/50 h-9 flex text-xl px-2 ml-6 hover:bg-zinc-400/20 hover:border-gray-100">
              <tooltip-box :text="gettext('This section provides the subsidy allocated for the Suku to implement the projects. The information includes the amount allocated for operational and infrastructure costs')">
                <button class="px-1 hover:text-emerald-600">
                  <i class="las la-info-circle"></i>
                </button>
              </tooltip-box>
              <tooltip-box :text="gettext('Download data as image')">
                <button
                  class="px-1 hover:text-emerald-600"
                  @click="downloadElement('budgets-section')"
                >
                  <i class="las la-download"></i>
                </button>
              </tooltip-box>
            </div>
          </div>
          <div
            id="budgets-section"
            class="grid md:grid-cols-3  lg:max-w-5xl gap-[30px]"
          >
            <basic-card class="text-center px-12 xl:px-14 pb-9 h-full w-full">
              <icon-box class="mx-auto bg-[#5E66DE] mt-4">
                <i class="las la-coins"></i>
              </icon-box>
              <h4 class="text-xl font-bold mt-3 leading-tight">
                {{ gettext("Budget for Materials") }}
              </h4>
              <h1 class="mt-2 text-4.5xl font-black">
                {{formatCurrencyWithDollar(sukuprofileStore.dataForSukuAndSector.tags({ tagIds: ['budget', 'mat'] }).sum() + idaBudget.materials) }}
              </h1>
            </basic-card>
            <basic-card class="text-center px-12 xl:px-14 pb-9 h-full w-full">
              <icon-box class="mx-auto bg-[#FF862E] mt-4">
                <i class="las la-file-invoice-dollar"></i>
              </icon-box>
              <h4 class="text-xl font-bold mt-3 leading-tight">
                {{ gettext("Budget for Labour") }}
              </h4>
              <h1 class="mt-2 text-4.5xl font-black">
                {{ formatCurrencyWithDollar(sukuprofileStore.dataForSukuAndSector.tags({ tagIds: ['budget', 'lab'] }).sum() + idaBudget.labour) }}
              </h1>
            </basic-card>
          </div>
        </div>

        <div class="mb-8 lg:mb-[60px]">
          <div class="flex items-center mb-9">
            <h1 class="text-2.5xl font-bold">
              {{ gettext('Projects') }}
            </h1>
            <div class="rounded-full border border-zinc-400 bg-zinc-400/50 h-9 flex text-xl px-2 ml-6 hover:bg-zinc-400/20 hover:border-gray-100">
              <tooltip-box :text="gettext('Project list')">
                <button class="px-1 hover:text-emerald-600">
                  <i class="las la-info-circle"></i>
                </button>
              </tooltip-box>
              <tooltip-box :text="gettext('Download data as image')">
                <button
                  class="px-1 hover:text-emerald-600"
                  @click="downloadElement('datatable')"
                >
                  <i class="las la-download"></i>
                </button>
              </tooltip-box>
            </div>
          </div>
          <div id="projects-table">
            <div>
              <DataTable
                :headers="headers"
                :items="projectItems"
                has-delete-button
                theme-color="dark"
                spacing="narrow"
                view-more-icon="la-pencil-alt"
                redirect-path="projectsDetail"
              />
            </div>
          </div>
        </div>

        <Suspense>
          <template #fallback>
            <div class="mb-8 lg:mb-[60px]">
              <div class="flex items-center mb-9">
                <h1 class="text-2.5xl font-bold">
                  {{ gettext('Projects') }}
                </h1>
              </div>
              <loading-animation theme="default" />
            </div>
          </template>

          <legacy-projects-data-table
            :data-function="legacyProjects"
            :sector-id="sukuprofileStore.sector"
          />
        </Suspense>

        <div>
          <div class="flex items-center mb-9">
            <h1 class="text-2.5xl font-bold">
              {{ gettext('Location of projects in Suco') }}
            </h1>
            <div class="rounded-full border border-zinc-400 bg-zinc-400/50 h-9 flex text-xl px-2 ml-6 hover:bg-zinc-400/20 hover:border-gray-100">
              <tooltip-box :text="gettext('The map shows where the projects are being implemented using GIS location points')">
                <button class="px-1 hover:text-emerald-600">
                  <i class="las la-info-circle"></i>
                </button>
              </tooltip-box>
              <tooltip-box :text="gettext('Download data as image')">
                <button
                  class="px-1 hover:text-emerald-600"
                  @click="downloadElement('project-location')"
                >
                  <i class="las la-download"></i>
                </button>
              </tooltip-box>
            </div>
          </div>
          <ProjectMap
            ref="projectMapRef"
            :active-geo-location="geolocationStore.activeGeolocation"
          />
        </div>
      </template>
    </NavTabs>
  </base-layout>
</template>
