<script setup lang="ts">
import { computed, ref, reactive } from 'vue'
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import BasicButton from '../components/commons/BasicButton.vue'

import { useToast } from 'vue-toastification'
import { userSchema, manageUserSchema } from '../_formkit/schema/edit_user.schema'

import { useAuthStore } from '../stores/auth'
import { useUsersStore, UserReturn } from '../stores/users'
import { useGroupStore } from '../stores/groups'
import { useMembershipStore } from '../stores/membership'
import { FormKitNode } from '@formkit/core'
import { FormKitSchema } from '@formkit/vue'
import { router } from '../router'

import { USER_MESSAGES } from '../_constants/toastmessages.constant'

import QRCode from 'qrcode'
import { useI18NStore } from '../stores/i18n'
import { useModal } from '../stores/modal'

const props = defineProps<{
  username: string
}>()

const modal = useModal()
const authStore = useAuthStore()
const userStore = useUsersStore()
const groupStore = useGroupStore()
const membershipStore = useMembershipStore()
const toast = useToast()

const userReturn = ref<UserReturn>()
const resetLink = ref('')

userReturn.value = await userStore.getUser(props.username).then(async (ur) => {
  if (ur.user) await membershipStore.updateUserMembership(ur.user.id)
  return ur
})

const groupChangeRequestIds = ref<number[]>([])
const groupOptions = computed(() => groupStore.groupOptions?.map(option => {
  return Object.assign(option, {
    requesting: groupChangeRequestIds.value.includes(option.value),
    member: userReturn.value && userReturn.value.user
      ? membershipStore.user_memberships[userReturn.value?.user?.id]?.includes(option.value)
      : false
  })
}
))

const fullName = computed(() => {
  return userReturn.value && userReturn.value.user
    ? `${userReturn.value.user.firstName} ${userReturn.value.user.lastName}`
    : ''
})

async function deleteUser () {
  if (userReturn.value?.user) {
    const response = await userStore.deleteUser(userReturn.value.user)
    if (response) {
      toast.success(useI18NStore().gettext(USER_MESSAGES.delete))
      router.push({ name: 'users' })
    } else {
      toast.error(useI18NStore().gettext(USER_MESSAGES.error))
    }
  }
}
async function toggleActiveUser () {
  if (userReturn.value?.user) {
    userReturn.value.user.isActive = !userReturn.value.user.isActive
    const response = await userStore.toggleUserActive(userReturn.value.user)
    if (response) {
      toast.success(useI18NStore().gettext(userReturn.value.user.isActive ? USER_MESSAGES.activated : USER_MESSAGES.deactivated))
      router.push({ name: 'users' })
    } else {
      toast.error(useI18NStore().gettext(USER_MESSAGES.error))
    }
  }
}

async function resetPassword () {
  if (userReturn.value?.user) {
    const response = await userStore.resetPassword(userReturn.value.user)
    if (response.success) {
      toast.success(useI18NStore().gettext(USER_MESSAGES.resetPassword))
      if (response.resetLink) {
        resetLink.value = response.resetLink
        QRCode.toCanvas(document.getElementById('qr_code_canvas'), resetLink.value, function (error: any) {
          if (error) console.error(error)
        })
      }
    } else {
      toast.warning('Password reset failed')
    }
  }
}

async function submit (data: any, node: FormKitNode | undefined) {
  if (userReturn.value?.user) {
    const response = await userStore.updateUser(userReturn.value.user)
    if (response.success) {
      toast.success(useI18NStore().gettext(USER_MESSAGES.update))
      router.push({ name: 'users' })
    } else {
      toast.error(useI18NStore().gettext(USER_MESSAGES.error))
      node?.setErrors(response.errors)
    }
  }
}
const formKitSubmitAttrs = {
  enabled: false
}

async function groupSelect (groupId: number, value: boolean | undefined) {
  if (userReturn.value?.user) {
    const action = value ? membershipStore.addGroupToUser : membershipStore.removeGroupFromUser
    const feedback = value ? 'added to' : 'removed from'
    groupChangeRequestIds.value.push(groupId)
    const success = await action(groupId, userReturn.value?.user?.id)
    groupChangeRequestIds.value = groupChangeRequestIds.value.filter((gid) => gid !== groupId)
    if (success) {
      toast.success(useI18NStore().gettext(`${feedback} a group`))
    } else {
      toast.warning('failed')
    }
  }
}

const formKitData = reactive({
  gettext: useI18NStore().gettext
})
</script>

<template>
  <base-layout
    :title="gettext('User')"
    :sub-title="fullName"
  >
    <content-section>
      <div v-if="!authStore.can('ida.view_idauser')">
        {{ gettext('You don`t have permission to access this page') }}
      </div>
      <div v-show="resetLink">
        {{ gettext('You have reset this users password, if you added an email they will have been sent this link in an email') }}
        {{ resetLink }}
        {{ gettext('Or they can scan this QRCode to set their password') }}
        <canvas id="qr_code_canvas"></canvas>
      </div>
      <div v-if="userReturn">
        <div v-if="userReturn.error">
          {{ userReturn.error }}
        </div>
        <div
          v-else
          class="max-w-2xl"
        >
          <FormKit
            v-if="userReturn.user"
            v-model="userReturn.user.model.fields"
            type="form"
            :submit-label="gettext('Save')"
            :submit-attrs="formKitSubmitAttrs"
            novalidate
            message-class="text-red-500 text-sm"
            :disabled="!authStore.can('ida.change_idauser')"
            @input="() => {}"
            @submit="submit"
          >
            <FormKitSchema
              :schema="manageUserSchema"
              :data="formKitData"
            />
            <div
              v-if="groupOptions"
              class="mb-8"
            >
              <p class="mb-3">
                {{ gettext('Roles') }}:
              </p>
              <div class="grid grid-cols-3 gap-x-4">
                <FormKit
                  v-for="option in groupOptions"
                  :key="option.value"
                  type="checkbox"
                  :label="gettext(option.label)"
                  :disabled="option.requesting"
                  :value="option.member"
                  @input="(val) => groupSelect(option.value, val)"
                />
              </div>
            </div>
          </FormKit>
          <div class="pb-5 pt-3.5 grid grid-cols-2 gap-5">
            <BasicButton
              v-if="authStore.can('ida.delete_idauser')"
              theme="primaryOutline"
              @click="modal.open('info', {
                label: userReturn.user?.isActive ? 'Deactivate' : 'Activate',
                modalTitle: 'Are you sure?',
                modalCaption: `Do you really want to ${userReturn.user?.isActive ? 'deactivate' : 'activate'} this user ?`,
                callback: async () => {
                  await toggleActiveUser()
                  modal.close()
                }
              })"
            >
              {{ userReturn.user?.isActive ? gettext("Deactivate") : gettext("Activate") }}
            </BasicButton>
            <BasicButton
              theme="light"
              @click="resetPassword"
            >
              {{ gettext('Reset Password') }}
            </BasicButton>
          </div>
        </div>
      </div>
      <div v-else>
        {{ gettext('Requesting') }}
      </div>
    </content-section>
  </base-layout>
</template>
