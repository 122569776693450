<script setup lang="ts">
import { ComputedRef, computed } from 'vue'
import { sumOfField } from '../../_helpers/sumfields.helpers'
import BasicCard from '../../components/commons/BasicCard.vue'
import IconBox from '../../components/commons/IconBox.vue'
import { components } from '../../_api-services/openapi'
import { useI18NStore } from '../../stores/i18n'
import { BeneficiariesColors } from '../../_types/colours'
import { DoughnutOptions, centerTextPlugin } from '../../_helpers/chartLayouts'
import { Doughnut } from 'vue-chartjs'
import ChartLegend from '../../components/ChartLegend.vue'
import { ChartData, ChartOptions, Plugin } from 'chart.js'
import LabelText from '../commons/LabelText.vue'
import { useSukuProfileStore } from '../../stores/sukuprofile'

const i18nStore = useI18NStore()

const props = defineProps({
  sukuId: {
    type: Number,
    required: true
  },
  sectorId: {
    type: Number,
    required: false,
    default: 0
  }
})

const data = await useSukuProfileStore().getBeneficiaries(props.sukuId)
const beneficiaries = computed(() => {
  let value = (data || []) as Array<components['schemas']['BeneficiariesPerSector']>
  if (!(props.sectorId === 0 || typeof props.sectorId === 'undefined')) {
    value = value.filter(it => it.sector === props.sectorId)
  }
  return {
    sumOfMen: sumOfField(value, 'no_men'),
    sumOfWomen: sumOfField(value, 'no_women'),
    sumOfHouseholds: sumOfField(value, 'no_hh')
  }
})

// Data for Beneficiaries chart

const dataForBeneficiaries: ComputedRef<{data: ChartData<'doughnut'>, options: ChartOptions<'doughnut'>, plugins: Plugin<'doughnut'>[]}> = computed(() => {
  return {
    data: {
      labels: [i18nStore.gettext('Number of Beneficiaries women'), i18nStore.gettext('Number of Beneficiaries men')],
      datasets: [{
        data: [
          beneficiaries.value.sumOfWomen,
          beneficiaries.value.sumOfMen
        ],
        backgroundColor: [BeneficiariesColors.get('women'), BeneficiariesColors.get('men')]
      }]
    },
    options: DoughnutOptions,
    plugins: [
      {
        id: 'count',
        afterDatasetsDraw: centerTextPlugin(
          { text: () => (beneficiaries.value.sumOfMen + beneficiaries.value.sumOfWomen).toLocaleString(), textSize: '25px' })
      },
      {
        id: 'total',
        afterDatasetsDraw: centerTextPlugin(
          { text: 'Total', textSize: '18px', yOffset: 30 })
      }
    ]
  }
})

const legendItems = [{
  label: i18nStore.gettext('Number of Beneficiaries women'),
  backgroundColor: BeneficiariesColors.get('women')
}, {
  label: i18nStore.gettext('Number of Beneficiaries men'),
  backgroundColor: BeneficiariesColors.get('men')
}]

</script>

<template>
  <div v-if="beneficiaries.sumOfHouseholds == 0 && (beneficiaries.sumOfMen + beneficiaries.sumOfWomen) == 0">
    <label-text theme="default">
      <p>{{ gettext('No data available') }}</p>
    </label-text>
  </div>
  <div
    id="beneficiaries-graph"
    class="grid md:grid-cols-3 gap-[30px] lg:max-w-5xl"
  >
    <basic-card
      v-if="beneficiaries.sumOfHouseholds > 0"
      class="text-center px-12 xl:px-14 pb-9 h-full w-full flex flex-col justify-center"
    >
      <icon-box class="mx-auto bg-[#ACCE89] mt-4">
        <i class="las la-home"></i>
      </icon-box>
      <h4 class="text-xl font-bold mt-3 leading-tight">
        {{ gettext("Number of households") }}
      </h4>
      <h1 class="mt-2 text-4.5xl font-black">
        {{ beneficiaries.sumOfHouseholds.toLocaleString() }}
      </h1>
    </basic-card>
    <basic-card
      v-if="(beneficiaries.sumOfMen + beneficiaries.sumOfWomen) > 0"
      class="lg:max-w-4xl px-7 py-8"
    >
      <h4 class="text-center text-lg font-bold mb-4">
        {{ gettext('Number of Beneficiaries') }}
      </h4>
      <div class="flex items-center justify-center relative">
        <div class="w-full">
          <Doughnut v-bind="dataForBeneficiaries" />
          <chart-legend :legend-items="legendItems" />
        </div>
      </div>
    </basic-card>
  </div>
</template>
