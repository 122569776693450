<script setup lang="ts">

type Theme = 'success' | 'error' | 'warning'
type Rounded = '2.5xl' | 'md'

withDefaults(defineProps<{
  theme?: Theme
  rounded?: Rounded
}>(), {
  theme: 'success',
  rounded: '2.5xl'
})
const styles: Record<Theme, string> = {
  success: 'bg-emerald-100 border border-green-300',
  error: 'bg-red-400/30 border border-red-400',
  warning: 'bg-amber-400/[.15]'
}
</script>
<template>
  <div
    class="px-6 py-4.5"
    :class="[styles[theme], `rounded-${rounded}`]"
  >
    <slot></slot>
  </div>
</template>
