<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import DropZone from '../forms/DropZone.vue'
import BasicCard from '../commons/BasicCard.vue'
import ModalImage from '../commons/modal/ModalImage.vue'
import { PROJECT_MANAGER_FILE_LABEL_AFTER, PROJECT_MANAGER_FILE_LABEL_BEFORE } from '../../_constants/fileupload.constant'
import { components } from '../../_api-services/openapi'
import { Ref, ref, ComputedRef, computed } from 'vue'
import { useAuthStore } from '../../stores/auth'
import { useProjectManagerStore } from '../../stores/projectmanager'
import { useAppStore } from '../../stores/app'
import { useSubmissionsStore } from '../../stores/submissions'

type SubmissionFile = components['schemas']['SubmissionFileOut']

defineProps<{
  submissionKey: string
}>()

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL
const authStore = useAuthStore()
const projectManagerStore = useProjectManagerStore()
const appStore = useAppStore()
const submissionStore = useSubmissionsStore()

const projectDocumentsAfter: ComputedRef<SubmissionFile[]> = computed(() => projectManagerStore.getDocumentsAfter())
const projectDocumentsBefore: ComputedRef<SubmissionFile[]> = computed(() => projectManagerStore.getDocumentsBefore())
const documentType: Ref<('after' | 'before')> = ref('after')

// load submission files from the submission store
const loadFiles = async () => {
  await projectManagerStore.getProjectDocuments()
}

// delete a file asynchronously
const deleteFile = async (id: number | undefined) => {
  appStore.$state.loading = true
  if (!id) return
  await submissionStore.deleteFile(id)
  setTimeout(async () => {
    await loadFiles() // // After a 2-second delay, load the submission files again to reflect the changes after deletion
    appStore.$state.loading = false
  }, 2000)
}
</script>
<template>
  <h2 class="font-bold text-2.5xl mb-8 mt-14">
    {{ gettext('Documents & Images') }}
  </h2>
  <basic-card
    class="!rounded-2.5xl max-w-[672px] !p-5"
  >
    <FormKit
      id="document_type"
      key="document_type"
      v-model="documentType"
      type="select"
      name="document_type"
      outer-class="!mb-0"
      value="after"
      :options="[
        {
          value: 'after',
          label: gettext('After')
        },
        {
          value: 'before',
          label: gettext('Before')
        }
      ]"
    />
  </basic-card>
  <div class="mt-8">
    <div
      v-if="documentType === 'after'"
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5"
    >
      <DropZone
        v-if="authStore.can('ida.change_project')"
        :file-label="PROJECT_MANAGER_FILE_LABEL_AFTER"
        :form-key="submissionKey"
        @upload="() => loadFiles()"
      />
      <template
        v-for="(file, index) in projectDocumentsAfter"
        :key="index"
      >
        <modal-image
          :id="file.id"
          :enable-delete="true"
          @delete="deleteFile(file.id)"
        >
          <div
            class="min-h-[204px] h-full relative"
          >
            <img
              loading="lazy"
              height="204"
              width="260"
              class="absolute w-full h-full object-cover rounded-[5px]"
              :src="file.thumbnails[1]"
              :alt="file.file"
            />
          </div>
          <template #modalContent>
            <embed
              v-if="file.file.includes('.pdf')"
              :src="VITE_BASE_URL + file.file"
              height="800"
              width="800"
            />
            <img
              v-else
              loading="lazy"
              height="800"
              width="800"
              class=" w-full h-full object-cover"
              :src="VITE_BASE_URL + file.file"
              :alt="file.file"
            />
          </template>
        </modal-image>
      </template>
    </div>
    <div
      v-else-if="documentType === 'before'"
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5"
    >
      <DropZone
        v-if="authStore.can('ida.change_project')"
        :file-label="PROJECT_MANAGER_FILE_LABEL_BEFORE"
        :form-key="submissionKey"
        @upload="() => loadFiles()"
      />
      <template
        v-for="(file, index) in projectDocumentsBefore"
        :key="index"
      >
        <modal-image
          :id="file.id"
          :enable-delete="authStore.can('ida.change_project')"
          @delete="deleteFile(file.id)"
        >
          <div
            class="min-h-[204px] h-full relative"
          >
            <img
              loading="lazy"
              height="204"
              width="260"
              class="absolute w-full h-full object-cover rounded-[5px]"
              :src="file.thumbnails[1]"
              :alt="file.file"
            />
          </div>
          <template #modalContent>
            <embed
              v-if="file.file.includes('.pdf')"
              :src="VITE_BASE_URL + file.file"
              height="800"
              width="800"
            />
            <img
              v-else
              loading="lazy"
              height="800"
              width="800"
              class=" w-full h-full object-cover"
              :src="VITE_BASE_URL + file.file"
              :alt="file.file"
            />
          </template>
        </modal-image>
      </template>
    </div>
  </div>
</template>
