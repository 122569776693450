<script setup lang="ts">
import ModalBox from '../commons/modal/ModalBox.vue'
import { ref } from 'vue'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import BasicButton from '../commons/BasicButton.vue'

defineProps<{
    reportingPeriod: string
    operationalSubsidy1000: number
    lastYearBalance1001: number
    interest1002: number
    other1003: number
    totalOperationalReceipts: number
    communityMeetings2000: number
    communityTraining2001: number
    incentiveForSuku2002: number
    projectAdminCost2003: number
    totalOperationalExpenditures: number
    balanceCash: number
    balanceBank: number
    totalBalance: number
}>()

const showModal = ref(false)

const toggleModal = (value: boolean) => {
  showModal.value = value
}

defineExpose({ toggleModal })

</script>
<template>
  <modal-box
    v-if="showModal"
    size="xl"
    spacing="md"
  >
    <template #header>
      <div class="flex justify-start items-center">
        <div class="w-[46px] h-[46px] rounded-[10px] inline-flex mr-5 items-center justify-center text-white text-2xl bg-[#92BA69]">
          <i class="las la-coins"></i>
        </div>
        <div>
          <h2 class="text-3.5xl font-black leading-10 mb-2 first-letter:uppercase">
            {{ gettext('Operational budget report') }}
          </h2>
          <span class="inline-block px-3 py-1 text-sm border bg-zinc-400/50 border-zinc-400 rounded-full">
            <i class="lar la-calendar"></i> <span class="font-semibold">{{ gettext('Reporting period') }}</span> {{ reportingPeriod }}
          </span>
        </div>
      </div>
      <h2 class="text-1.5xl font-bold mt-6">
        <i class="las la-file-invoice-dollar text-gray-400 mr-2"></i>
        {{ gettext('Operational receipts') }}
      </h2>
      <ul class="mt-2.5 mb-3.5">
        <li class="flex items-center">
          <p class="w-1/2">
            {{ gettext('1000 - Subsidy Operational PNDS') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(operationalSubsidy1000) }}
            </div>
          </div>
        </li>
        <li class="flex items-center mt-2.5">
          <p class="w-1/2">
            {{ gettext('1001 - Last Year Balance') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(lastYearBalance1001) }}
            </div>
          </div>
        </li>
        <li class="flex items-center mt-2.5">
          <p class="w-1/2">
            {{ gettext('1002 - Interest') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(interest1002) }}
            </div>
          </div>
        </li>
        <li class="flex items-center mt-2.5">
          <p class="w-1/2">
            {{ gettext('1003 - Others') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(other1003) }}
            </div>
          </div>
        </li>
        <li class="flex items-center rounded-full mt-5 bg-zinc-400 px-5">
          <p class="w-1/2 font-bold">
            {{ gettext('Total receipts (USD)') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="font-semibold border-l border-l-zinc-450 w-full border-zinc-400 px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(totalOperationalReceipts) }}
            </div>
          </div>
        </li>
      </ul>
    </template>
    <button
      class="w-7.5 h-7.5 rounded-full bg-emerald-600 text-white inline-flex items-center justify-center text-xl fixed top-6 right-6"
      @click="() => showModal = false"
    >
      <i class="las la-times"></i>
    </button>
    <h2 class="text-1.5xl font-bold">
      <i class="las la-money-check-alt text-gray-400 mr-2"></i>
      {{ gettext('Operational expenditures') }}
    </h2>
    <ul class="mt-2.5 mb-3.5">
      <li class="flex items-center">
        <p class="w-1/2">
          {{ gettext('2000 - Community Meeting') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(communityMeetings2000) }}
          </div>
        </div>
      </li>
      <li class="flex items-center mt-2.5">
        <p class="w-1/2">
          {{ gettext('2001 - Community Training') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(communityTraining2001) }}
          </div>
        </div>
      </li>
      <li class="flex items-center mt-2.5">
        <p class="w-1/2">
          {{ gettext('2002 - Incentive for Suku team') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(incentiveForSuku2002) }}
          </div>
        </div>
      </li>
      <li class="flex items-center mt-2.5">
        <p class="w-1/2">
          {{ gettext('2003 - Project Administration Cost') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(projectAdminCost2003) }}
          </div>
        </div>
      </li>
      <li class="flex items-center rounded-full mt-5 bg-zinc-400 px-5">
        <p class="w-1/2 font-bold">
          {{ gettext('Total expenditures (USD)') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="font-semibold border-l border-l-zinc-450 w-full border-zinc-400 px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(totalOperationalExpenditures) }}
          </div>
        </div>
      </li>
    </ul>

    <hr class="mt-10 mb-7 -mx-5 lg:-mx-9" />

    <h2 class="text-1.5xl font-bold">
      <i class="las la-balance-scale text-gray-400 mr-2"></i>
      <span class="first-letter:uppercase inline-block">
        {{ gettext('Operational balance') }}
      </span>
    </h2>
    <ul class="mt-2.5 mb-3.5">
      <li class="flex items-center">
        <p class="w-1/2">
          {{ gettext('Cash in hand') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(balanceCash) }}
          </div>
        </div>
      </li>
      <li class="flex items-center mt-2.5">
        <p class="w-1/2">
          {{ gettext('Bank balance') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(balanceBank) }}
          </div>
        </div>
      </li>
      <li class="flex items-center rounded-full mt-5 bg-zinc-400 px-5">
        <p class="w-1/2 font-bold">
          {{ gettext('Total balance (USD)') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="font-semibold border-l border-l-zinc-450 w-full border-zinc-400 px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(totalBalance) }}
          </div>
        </div>
      </li>
    </ul>

    <div class="flex items-center justify-center flex-wrap mt-8 mb-4">
      <basic-button
        class="!w-[254px] mt-4 md:mt-0 md:ml-3"
        @click="() => showModal = false"
      >
        <i class="las la-times text-2xl mr-4"></i>
        {{ gettext('Close') }}
      </basic-button>
    </div>
  </modal-box>
</template>
