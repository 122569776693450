<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useAuthStore } from '../stores/auth'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { FormKitSchema, FormKitMessages } from '@formkit/vue'
import AuthLayout from '../layouts/AuthLayout.vue'
import { loginSchema } from '../_formkit/schema/auth.schema'
import { FormKitNode } from '@formkit/core'
import { useI18NStore } from '../stores/i18n'
import { LoginData } from '../_types/auth'
import { API_RESPONSE_MESSAGE, LOGIN_MESSAGE, OFFLINE_ERROR_MESSAGE } from '../_constants/toastmessages.constant'
import AlertBox from '../components/commons/AlertBox.vue'
const i18Store = useI18NStore()
const router = useRouter()
const authStore = useAuthStore()
const showError = ref(false)
async function change () {
  showError.value = false
}
async function submit (data: LoginData, node: FormKitNode | undefined) {
  showError.value = false
  const formData = new FormData()
  if (typeof data.username === 'string') formData.append('username', data.username)
  if (typeof data.password === 'string') formData.append('password', data.password)

  const response = await authStore.login(formData)
  if (response.success) {
    const { redirect } = router.currentRoute.value.query
    // Once the login is successfully, if query has redirect params it will redirect there, otherwise it will redirect to home page
    if (redirect !== undefined) {
      router.push(redirect as RouteLocationRaw)
    } else {
      router.push({ name: 'home' })
    }
  } else {
    showError.value = true
    if (node) {
      switch (response.reason) {
        case 'badrequest':
          node.setErrors(
            i18Store.gettext(LOGIN_MESSAGE.error)
          )
          break
        case 'network':
          node.setErrors(
            i18Store.gettext(OFFLINE_ERROR_MESSAGE.error)
          )
          break
        case 'server':
          node.setErrors(
            i18Store.gettext(API_RESPONSE_MESSAGE.error)
          )
          break
      }
    }
  }
}

const onSuffixIconClick = () => {
  data.passwordFieldType = data.passwordFieldType === 'textFloating' ? 'password' : 'textFloating'
  data.passwordFieldIcon = data.passwordFieldType === 'textFloating' ? 'eye' : 'eyeClosed'
}

const data = reactive({
  passwordFieldType: 'password',
  passwordFieldIcon: 'eyeClosed',
  passwordFieldAttributes: {
    onSuffixIconClick
  },
  gettext: i18Store.gettext
})

const formKitSubmitAttrs = {
  suffixIcon: 'arrowRight'
}

</script>

<template>
  <auth-layout title="Login">
    <FormKit
      type="form"
      submit-label="Login"
      :submit-attrs="formKitSubmitAttrs"
      novalidate
      message-class="text-red-500 text-sm"
      @submit="submit"
      @input="change"
    >
      <FormKitSchema
        :schema="loginSchema"
        :data="data"
      />
      <AlertBox
        v-if="showError"
        theme="error"
        class="mt-9 md:max-w-[440px]"
      >
        <div class="flex items-center md:pr-10">
          <div class="mr-5">
            <i class="text-2xl las la-exclamation-triangle"></i>
          </div>
          <div class="text-[15px]">
            <p class="font-bold mb-1">
              <FormKitMessages />
            </p>
            <div>
              <router-link
                to="/forgot-password/"
                class="text-base text-emerald-600 underline"
              >
                {{ gettext('Forgot password') }}
              </router-link>
              {{ gettext('or') }}
              <router-link
                to="/help/"
                class="text-base text-emerald-600 underline"
              >
                {{ gettext('Need help?') }}
              </router-link>
            </div>
          </div>
        </div>
      </AlertBox>
    </FormKit>

    <hr class="border-b border-zinc-400 border-t-0 mt-9 mb-6 -mx-6 md:-mx-10" />
    <div class="flex items-center justify-center">
      <router-link
        to="/forgot-password/"
        class="text-base text-emerald-600 underline"
      >
        {{ gettext('Forgot password') }}
      </router-link>
      <div class="w-[6px] h-[6px] bg-gray-400 rounded-full mx-3"></div>
      <router-link
        to="/help/"
        class="text-base text-emerald-600 underline"
      >
        {{ gettext('Need help?') }}
      </router-link>
    </div>
    <hr class="border-b border-zinc-400 border-t-0 mt-6 mb-8 -mx-6 md:-mx-10" />
    <p class="text-lg font-bold text-zinc-700">
      {{ gettext('Ida is a web platform, currently in the early stages of development, to assist the Ministry of State and Administration in the implementation of the Programa Nacional de Desenvolvimento do Suku (PNDS) in Timor-Leste. It will assist the Government of Timor-Leste in keeping track of PNDS progress, while offering high-standard tools for accountability, transparency and strategic planning.') }}
    </p>
    <hr class="border-b border-zinc-400 border-t-0 mt-8 mb-10 -mx-6 md:-mx-10" />
    <div class="flex">
      <img
        class="w-[69px] h-69px mr-7.5"
        width="69"
        height="69"
        src="../assets/logos/RDTL.webp"
        alt="RDTL"
      />
      <img
        class="w-[69px] h-69px mr-7.5"
        width="69"
        height="69"
        src="../assets/logos/MAE.webp"
        alt="MAE"
      />
    </div>
    <p class="text-sm max-w-[600px] w-full lg:w-auto mt-4">
      {{ gettext("Managed by the Government of Timor Leste. Proudly supported by the Australian Government. Designed and developed by Catalpa International") }}
    </p>
  </auth-layout>
</template>

<style scoped>

</style>
