import { defineStore } from 'pinia'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
import { useGeoLocationStore } from './geolocation'
import { useLocationStore } from './locations'
import { useAppStore } from './app'
import { useOptionsStore } from './options'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
import { SUKU_COMPLAINT_MESSAGE, COMMENT_MESSAGE } from '../_constants/toastmessages.constant'
import { format, parseISO } from 'date-fns'
import { formatDate, extractDateDiff, DateDiff } from '../_helpers/common.helpers'

const toast = useToast()

type ComplaintSchemaOut = components['schemas']['ComplaintSchemaOut']
type ComplaintSchemaIn = components['schemas']['ComplaintSchemaIn']
type ComplaintVerify = components['schemas']['ComplaintVerify']

type ComplaintCommentSchemaIn = components['schemas']['ComplaintCommentSchemaIn']
type ComplaintCommentSchemaOut = components['schemas']['ComplaintCommentSchemaOut']
interface InitialState {
  complaints: ComplaintSchemaOut[]
  comments: ComplaintCommentSchemaOut[]
}

export const useSukuComplaintStore = defineStore('sukucomplaint', {
  state: () => {
    const initialState: InitialState = {
      complaints: [],
      comments: []
    }
    return initialState
  },
  getters: {
    getComplaintsData (state) {
      return state.complaints
    },
    getComplaintsTableData (state) {
      const optionsStore = useOptionsStore()
      const complaints = state.complaints.sort((a: ComplaintSchemaOut, b: ComplaintSchemaOut) => {
        const dateA: Date | undefined = parseISO(a.received ?? '')
        const dateB: Date | undefined = parseISO(b.received ?? '')

        return dateB !== undefined && dateA !== undefined ? dateB.getTime() - dateA.getTime() : 0
      })
      const i18nStore = useI18NStore()
      const { code } = i18nStore.$state
      /* Wrapper around `pgettext` for date types where we use `time` as the context */
      const transDate = (inDate: DateDiff['unit'] | 'day') => i18nStore.pgettext('time', inDate)
      return complaints.map((complaint: ComplaintSchemaOut) => {
        const datediff = complaint.received !== undefined
          ? extractDateDiff(complaint.received, (complaint.status === 0 // if `status` = `0` which is `verified`, it will calculated the duration based on `verified_date`, otherwise it will based on `current date`
            ? complaint.verified_date
            : undefined)) // extractDateDiff(startDate, endDate), if endDate is undefined it will use `current date`
          : []
        const formatDuration = datediff.map(({ unit, value }) => {
          return `${value} ${value === 1 && code === 'en'
           ? unit.slice(0, -1) // if language is `EN` it will remove the plural text
           : transDate(unit)}`
        })
        const duration = (formatDuration.length > 0 ? formatDuration.join(', ') : `0 ${transDate('day')}`)
        return {
          date: complaint.received !== undefined ? format(new Date(complaint.received), 'dd/MM/yyyy') : '-',
          organization: complaint.organization,
          suco: complaint.suku_id !== undefined ? optionsStore.getLabel('suku', complaint.suku_id) : '-',
          duration,
          last_update: format(parseISO(complaint.updated_at), 'dd/MM/yyyy'),
          complaint_status: complaint.status,
          complaint_name: complaint.complainant_name ?? '-',
          view: complaint.id,
          redirectParams: {
            id: complaint.id
          }
        }
      })
    },
    getComplaintById (state) {
      return (id: number) => {
        return state.complaints.find(complaint => complaint.id === id)
      }
    },
    getComments (state) {
      const i18nstore = useI18NStore()
      const comments = state.comments.sort((a: ComplaintCommentSchemaOut, b: ComplaintCommentSchemaOut) => {
        const dateA: Date | undefined = parseISO(a.created_at ?? '')
        const dateB: Date | undefined = parseISO(b.created_at ?? '')

        return dateB !== undefined && dateA !== undefined ? dateB.getTime() - dateA.getTime() : 0
      })
      return comments.map((comment: ComplaintCommentSchemaOut) => {
        const dateCreated = parseISO(comment.created_at)
        const title = formatDate(comment.created_at, {
          month: 'long',
          year: 'numeric'
        })
        const username: string = comment.user_name
        const info = `${i18nstore.gettext('Posted by')} ${username} - ${format(dateCreated, 'dd/MM/yy, h:mm aaaa')}`
        return {
          comment: comment.text_content,
          title,
          info,
          status: comment.status
        }
      })
    }
  },
  actions: {
    async initialize (fetchAll = true) {
      useAppStore().$state.loading = true
      let query = {}
      if (!fetchAll) {
        const activeGeoLocation = useGeoLocationStore().activeGeolocation?.properties
        const sukuId = activeGeoLocation?.id
        const zSucoId = typeof sukuId !== 'undefined' ? useLocationStore().convertLocationToZsuco(sukuId) : undefined
        query = {
          suku_id: zSucoId
        }
      }
      const url = '/api/complaint/all'
      try {
        const { data } = await client.GET(url, {
          params: {
            query
          }
        })
        if (data !== undefined) {
          this.complaints = data
        }
      } catch (e) {
        toast.error(useI18NStore().gettext('Something wrong when fetching complaints data'))
        if (e instanceof SyntaxError) {
          console.warn('SyntaxError caught:', e, url)
        } else {
          throw e // re-throw the error unchanged
        }
      } finally {
        useAppStore().$state.loading = false
      }
    },

    async fetchComments (id: number) {
      useAppStore().$state.loading = true
      const { data } = await client.GET('/api/complaint/get_complaint_comments', {
        params: {
          query: {
            complaint: id
          }
        }
      })

      if (data !== undefined) {
        this.comments = data
      } else {
        toast.error(useI18NStore().gettext('Something wrong when fetching complaint comments'))
      }

      useAppStore().$state.loading = false
    },

    async createComplaint (body: ComplaintSchemaIn, isNew = true) {
      useAppStore().$state.loading = true
      const activeGeoLocation = useGeoLocationStore().activeGeolocation?.properties
      const sukuId = activeGeoLocation?.id
      if (typeof sukuId === 'number' && body.suku_id === undefined) body.suku_id = sukuId
      const { data } = await client.POST('/api/complaint/create', { body })
      if (typeof data !== 'undefined') {
        toast.success(useI18NStore().gettext(isNew ? SUKU_COMPLAINT_MESSAGE.success : SUKU_COMPLAINT_MESSAGE.update))
        if (!isNew) {
          const findSelectedComplaintIndex = this.complaints.findIndex(complaint => complaint.id === body.id)
          if (findSelectedComplaintIndex > -1) {
            this.complaints[findSelectedComplaintIndex] = data
          }
        } else {
          this.complaints.push(data)
        }
      }
      useAppStore().$state.loading = false
      return data
    },

    async verifyComplaint (body: ComplaintVerify) {
      useAppStore().$state.loading = true
      const { data } = await client.POST('/api/complaint/verify', { body })
      if (data !== undefined) {
        toast.success(useI18NStore().gettext(SUKU_COMPLAINT_MESSAGE.verified))
        const findSelectedComplaintIndex = this.complaints.findIndex(complaint => complaint.id === body.id)
        if (findSelectedComplaintIndex > -1) {
          this.complaints[findSelectedComplaintIndex] = data
        }
      } else {
        toast.success(useI18NStore().gettext(SUKU_COMPLAINT_MESSAGE.error))
      }
      useAppStore().$state.loading = false
      return data
    },

    async createComplaintComment (body: ComplaintCommentSchemaIn, status?: number) {
      useAppStore().$state.loading = true
      const { data } = await client.POST('/api/complaint/create_complaint_comment', { body })
      if (data !== undefined) {
        if (status !== undefined) {
          data.status = status
        }
        toast.success(useI18NStore().gettext(COMMENT_MESSAGE.success))
        this.comments.push(data)
      } else {
        toast.success(useI18NStore().gettext(COMMENT_MESSAGE.error))
      }
      useAppStore().$state.loading = false
      return data !== undefined
    }
  }
})
