<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import DataTable, { ReOrderContext } from '../../components/commons/DataTable.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import type { Header, Item } from 'vue3-easy-data-table'
import { computed, ComputedRef, ref, nextTick, reactive } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useI18NStore } from '../../stores/i18n'
import { useAppStore } from '../../stores/app'
import { useAuthStore } from '../../stores/auth'
import { useSukuPrioritiesStore } from '../../stores/sukupriorities'
import { useLocationStore } from '../../stores/locations'
import { useOptionsStore } from '../../stores/options'
import { FormKit, FormKitSchema } from '@formkit/vue'
import { FormKitSchemaFormKit, FormKitSchemaNode } from '@formkit/core'
import { useSukuProfileStore } from '../../stores/sukuprofile'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import { date } from '../../_formkit/schema/forms/common.schema'
import { components } from '../../_api-services/openapi'
import { priorities } from '../../_formkit/schema/forms/sf23.schema'

type SF23PriorityContext = components['schemas']['Sf_2_3PrioritiesSchema']
interface SF23PriorityFormContext extends SF23PriorityContext {
  date?: string
}

const i18nstore = useI18NStore()
const appStore = useAppStore()
const sukuPrioritiesStore = useSukuPrioritiesStore()
const locationStore = useLocationStore()
const optionsStore = useOptionsStore()
const sukuProfileStore = useSukuProfileStore()
const authStore = useAuthStore()

await sukuProfileStore.setActiveSukuLocationNode()
/**
 * initial value of priority
 */
const values = ref<SF23PriorityFormContext>({
  uuid: '',
  aldeia: undefined,
  project_sector: undefined,
  project_sub_sector: undefined,
  project_name: undefined,
  place: '',
  unit: undefined,
  project_type: undefined,
  beneficiaries_female: undefined,
  beneficiaries_male: undefined,
  households: undefined,
  women_priority: undefined
})

const showCreateForm = ref(false)
const action = ref('create')

const geolocationStore = useGeoLocationStore()
const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)
/**
 * initialize options list and Priorities list
 */
appStore.$state.loading = true
await optionsStore.initialize()
await sukuPrioritiesStore.initialize()
appStore.$state.loading = false
/**
 * datatable header
 */
const headers: ComputedRef<Header[]> = computed(() => [
  { sortable: true, value: 'order', text: 'Priority order', width: 129 },
  { sortable: true, value: 'project_sector', text: 'Sector' },
  { sortable: true, value: 'project_name', text: 'Project name' },
  { sortable: true, value: 'unit', text: 'Volume/Unit' },
  { sortable: true, value: 'project_type', text: 'Project type', width: 165 },
  { sortable: true, value: 'women_priority', text: 'Women priority', width: 115 },
  { sortable: true, value: 'cost_estimation', text: 'Cost estimation' },
  { sortable: true, value: 'project_code', text: 'Project code' },
  { sortable: true, value: 'view', text: 'View' }
])
/**
 * data table items
 */
const items = computed(() => {
  return sukuPrioritiesStore.getPriorities.map(priority => ({
    order: `${priority.order}`,
    project_sector: optionsStore.getLabel('sector', priority.sf23_priority.project_sector),
    project_name: optionsStore.getLabel('output', priority.sf23_priority.project_name),
    unit: optionsStore.getLabel('unit', priority.sf23_priority.unit),
    project_type: optionsStore.getLabel('activity', priority.sf23_priority.project_type),
    cost_estimation: formatCurrencyWithDollar(priority.sf23_priority.cost_estimation ?? 0),
    women_priority: priority.sf23_priority.women_priority,
    project_code: priority.sf23_priority.project,
    view: priority.id,
    date: priority.submission_date
  }))
})
/**
 * when order is changed
 * @param items Item
 */
const onReOrder = async (params: ReOrderContext) => {
  appStore.$state.loading = true
  const zSucoId = sukuProfileStore.$state.activezSukuId
  if (zSucoId) {
    await sukuPrioritiesStore.updatePriorityOrder(params.item.view, {
      suco_id: zSucoId,
      order: params.newOrder
    })
  }
  nextTick(() => {
    appStore.$state.loading = false
  })
}
/**
 * add a new priority
 */
const create = () => {
  showCreateForm.value = true
  action.value = 'create'
  values.value = {
    aldeia: undefined,
    project_sector: undefined,
    project_sub_sector: undefined,
    project_name: undefined,
    place: '',
    unit: undefined,
    project_type: undefined,
    beneficiaries_female: undefined,
    beneficiaries_male: undefined,
    households: undefined,
    women_priority: undefined,
    cost_estimation: undefined,
    date: ''
  }
}
/**
 * show the popup and edit existing priority
 * @param item Item
 */
const viewMore = (item: Item) => {
  showCreateForm.value = true
  const findPriority = sukuPrioritiesStore.getPriority(item.view)
  if (typeof findPriority !== 'undefined') {
    values.value = {
      date: findPriority.submission_date,
      ...findPriority.sf23_priority
    }
  }
  action.value = 'update'
}

const formSchemaData = reactive({
  /* Allows using `$gettext` and `$pgettext` in the forms */
  gettext: i18nstore.gettext,
  pgettext: i18nstore.pgettext,
  getLocations: () => {
    /* Provided parent values, return the options available */
    const zSucoId = sukuProfileStore.$state.activezSukuId
    const emptyOption = { label: '----' }
    if (typeof zSucoId === 'undefined') return []
    const location = locationStore.getByLevel(3).find(it => it.path.includes(zSucoId))

    const locations = locationStore.getLocations(...(location?.path ?? [])).map((opt) => { return { label: opt.name, value: opt.path.at(-1) } })
    return [emptyOption, ...locations]
  },
  ida: (groupName: groupName, ...filterParams: string[]) => optionsStore.options(groupName, i18nstore.code, filterParams)
})
const formSchema: Array<FormKitSchemaFormKit | FormKitSchemaNode> = [
  date,
  ...priorities
]

const submit = async () => {
  const req = await sukuPrioritiesStore.submit(values.value)
  if (req) {
    showCreateForm.value = false
    await sukuPrioritiesStore.initialize()
  }
}
</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <h1 class="text-2.5xl font-black mb-10">
        {{ gettext('Suku Priorities') }}
      </h1>
      <DataTable
        v-if="appStore.$state.loading === false"
        :headers="headers"
        :items="items"
        has-delete-button
        theme-color="dark"
        spacing="narrow"
        :row-order="authStore.canAny([
          'pnds_data.change_sucopriorities',
          'pnds_data.add_sucopriorities',
        ])"
        filter-placeholder="Search"
        filter-suffix-icon="la-search !text-xl text-gray-400"
        :enable-filter-by-submission-date="true"
        @reorder="onReOrder"
        @view="viewMore"
      >
        <template #header-filter-action>
          <button
            type="button"
            class="inline-flex items-center text-base font-semibold"
          >
            <i class="las la-filter text-xl mr-2"></i>
            {{ gettext('Filter') }}
          </button>
        </template>
        <template #header-action>
          <basic-button
            v-if="authStore.canAny([
              'pnds_data.add_sucopriorities',
              'pnds_data.change_sucopriorities'
            ])"
            @click="create"
          >
            <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('Add') }}
          </basic-button>
        </template>
      </DataTable>
    </content-section>
    <modal-box
      v-if="showCreateForm"
      class="text-center"
      size="lg"
      spacing="md"
    >
      <template #header>
        <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
          <i class="text-gray-400 mr-3 text-2xl las la-edit"></i>
          {{ gettext('Priority details') }}
        </h4>
      </template>
      <button
        class="fixed right-6 top-6 text-lg w-[28px] h-[28px] rounded-full bg-emerald-600 text-white"
        @click="() => showCreateForm = false"
      >
        <i class="las la-times"></i>
      </button>
      <FormKit
        ref="form"
        v-model="values"
        type="form"
        :actions="false"
        :disabled="!authStore.canAny([
          'pnds_data.add_sucopriorities',
          'pnds_data.change_sucopriorities'
        ])"
        @submit="submit"
      >
        <div class="text-left">
          <FormKitSchema
            :schema="formSchema"
            :data="formSchemaData"
          />
        </div>
        <FormKit
          v-if="authStore.canAny([
            'pnds_data.add_sucopriorities',
            'pnds_data.change_sucopriorities'
          ])"
          type="submit"
          outer-class="max-w-[254px] mx-auto mt-8"
          prefix-icon="search"
        >
          <i class="las la-check text-2xl mr-3"></i>
          {{ gettext('Save priority') }}
        </FormKit>
        <basic-button
          theme="text"
          class="mt-2"
          @click="() => showCreateForm = false"
        >
          {{ gettext('Cancel') }}
        </basic-button>
      </FormKit>
    </modal-box>
  </base-layout>
</template>
