<script setup lang="ts">
import ModalBox from './ModalBox.vue'
import BasicButton from '../BasicButton.vue'
import { reactive } from 'vue'
import { storeToRefs } from 'pinia'
import { useModal } from '../../../stores/modal'

const modal = useModal()

const model = reactive({})

const { isOpen, type, props } = storeToRefs(modal)

interface ModalThemeContext {
  icon: string
  iconWrapper: string
}
interface ModalThemesContext {
  confirm: ModalThemeContext
  info: ModalThemeContext
}

const modalThemes: ModalThemesContext = {
  confirm: {
    icon: 'la-times',
    iconWrapper: 'bg-white border-2 border-red-400 text-dark'
  },
  info: {
    icon: 'la-exclamation-triangle',
    iconWrapper: 'bg-white border-2 border-emerald-600 text-dark'
  }
}

</script>
<template>
  <teleport to="body">
    <modal-box
      v-if="isOpen"
      class="text-center !z-[100001]"
      size="lg"
    >
      <span
        :class="props?.modalIconWrapper ? props.modalIconWrapper : modalThemes[type as keyof ModalThemesContext].iconWrapper"
        class="w-[56px] h-[56px] md:w-[60px] md:h-[60px] flex items-center justify-center mx-auto rounded-full text-dark"
      >
        <i
          :class="props?.modalIcon ? props.modalIcon : modalThemes[type as keyof ModalThemesContext].icon"
          class="las text-2xl"
        ></i>
      </span>
      <h2 class="text-1.5xl md:text-3.5xl font-bold mt-6 leading-tight">
        {{ gettext(props?.modalTitle ?? '') }}
      </h2>
      <p class="mt-4">
        {{ gettext(props?.modalCaption ?? '') }}
      </p>
      <basic-button
        v-for="(action, index) in props?.actions"
        :key="index"
        size="lg"
        :theme="action.buttonTheme"
        class="mt-7 md:w-full md:max-w-[324px] !h-[50px] md:!h-[60px] !text-base md:!text-lg"
        :class="action.buttonClass"
        @click="action.callback(model)"
      >
        <i
          v-if="action.icon"
          class="las text-3xl mr-4"
          :class="[action?.icon]"
        ></i>
        {{ gettext(action?.label) }}
      </basic-button>
      <basic-button
        v-if="(typeof props !== 'undefined' && typeof props?.callback !== 'undefined')"
        size="lg"
        :theme="type === 'confirm' ? 'red': 'primary'"
        class="mt-7 md:w-full md:max-w-[324px] !h-[50px] md:!h-[60px] !text-base md:!text-lg"
        @click="props.callback(model)"
      >
        <i class="las la-check text-3xl mr-4"></i>
        {{ gettext(props?.label ?? 'Delete') }}
      </basic-button>
      <basic-button
        v-if="props?.hideModalClose !== true"
        size="lg"
        class="mt-6 mb-5 md:w-full md:max-w-[324px] !h-[50px] md:!h-[60px] !text-base md:!text-lg"
        theme="text"
        @click="modal.close()"
      >
        {{ gettext('Cancel') }}
      </basic-button>
    </modal-box>
  </teleport>
</template>
