<script setup lang="ts">
import MapLegend from './MapLegend.vue'
import L, { layerGroup, LatLng, Map, Marker } from 'leaflet'
import { mapMarkers, activePoint, osmLayer, mapTilerLayer } from '../../_constants/map.constant'
import { SectorColors } from '../../_types/colours'
import { useProjectManagerStore, FeatureSchema as FeatureSchemaWithProjectProps } from '../../stores/projectmanager'
import { components } from '../../_api-services/openapi'
import { useI18NStore } from '../../stores/i18n'
import { useOptionsStore } from '../../stores/options'
import { onMounted, ref, Ref, watch, computed, ComputedRef, nextTick, toRaw } from 'vue'

type FeatureSchema = components['schemas']['Feature']

const props = withDefaults(defineProps<{
  activeGeoLocation?: FeatureSchema | undefined
  editable?: boolean
}>(), {
  activeGeoLocation: undefined,
  editable: false
})

const i18nstore = useI18NStore()
const projectManagerStore = useProjectManagerStore()
const optionsStore = useOptionsStore()

const lat: Ref<number> = ref(0)
const lng: Ref<number> = ref(0)
const map: Ref<Map | null> = ref(null)
const isHaveCoordinate: ComputedRef<boolean> = computed(() => lat.value !== 0 && lng.value !== 0)

const markerMapLegendColors = ref<number[]>([])

onMounted(() => {
  addIconToMousePointer()
})

// Watch for changes in the project manager store
watch(projectManagerStore, (newValue) => {
  const location = newValue.projectLocation
  // Update latitude and longitude if the project location changes
  if (typeof location !== 'undefined' && (lat.value !== location.lat || lng.value !== location.lng)) {
    lat.value = location.lat
    lng.value = location.lng
  }
})

/**
 * remove custom cursor pointer
 */
function removeCustomCursor () {
  const mapContainerEl: HTMLElement | null = document.getElementById('mapContainer')
  if (mapContainerEl) { // remove custom cursor pointer
    mapContainerEl.style.cursor = 'default'
  }
}

/**
 * add custom pointer if coordinates aren't set
 */
async function addIconToMousePointer () {
  await nextTick()
  if (!isHaveCoordinate.value) {
    const selectedProject: FeatureSchemaWithProjectProps = toRaw(projectManagerStore.$state.idaProjects[0])
    if (selectedProject) {
      const sectorColor = SectorColors.get(selectedProject.properties.sector ?? 0) // get sectorColor
      const mapContainerEl: HTMLElement | null = document.getElementById('mapContainer')
      const icon = mapMarkers({
        fill: sectorColor
      })

      const img = new Image()

      // Convert the SVG string to a data URI
      const svgDataUrl = `data:image/svg+xml;base64,${btoa(icon.options.html as string)}`

      // Set the src attribute of the img element to the data URI
      img.src = svgDataUrl

      // Set the cursor property for the body
      img.onload = () => {
        if (mapContainerEl) {
          mapContainerEl.style.cursor = `url('${svgDataUrl}') 14 40, auto`
        }
      }
    }
  }
}

// Initialize the legend for marker colors
function initializeMarkerMapLegend () {
  const markerColors: number[] = Array.from(new Set(projectManagerStore.$state.idaProjects.map((it) => (it.properties.sector ?? 1)))) // if project doesnt have sector it will assigned to `Health` sector, just for marker color purpose
  markerMapLegendColors.value = markerColors
}

/**
 * create new marker with some props
 * @param feature FeatureSchema
 * @param latlng LatLng
 * @returns Marker
 */
function initializeMarker (feature: FeatureSchema, latlng: LatLng): Marker {
  lat.value = latlng.lat
  lng.value = latlng.lng
  const label = `<strong>${i18nstore.gettext('Project ID')} :</strong> ${feature.properties.project_id ? `<a target="_blank" class="font-bold underline" href="/projects/${feature.properties.project_id}">IDA-${feature.properties.project_id}</a>` : '-'}<br/>
        <strong>${i18nstore.gettext('Suco')} :</strong> ${feature.properties.suco ? optionsStore.getLabel('suku', feature.properties.suco) : '-'}<br/>
        <strong>${i18nstore.gettext('Project name')} :</strong> ${feature.properties.output ? optionsStore.getLabel('output', feature.properties.output) : '-'}<br/>
        <strong>${i18nstore.gettext('Project status')} :</strong> ${feature.properties.project_status ? optionsStore.getLabel('subprojectstatus1', feature.properties.project_status) : '-'}
        `
  const sectorColor = SectorColors.get(feature.properties.sector ?? 0) // get sectorColor

  const marker = L.marker(latlng, {
    icon: mapMarkers({
      editable: props.editable,
      fill: sectorColor
    }),
    draggable: props.editable,
    autoPan: true,
    autoPanOnFocus: true
  }).bindPopup(label).openPopup()

  if (props.editable) {
    marker.on('drag', function () {
      const position = marker.getLatLng()
      lat.value = position.lat
      lng.value = position.lng
      projectManagerStore.setLocation(position, 'map')
    })
    watch(lng, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        const newLatLng = new L.LatLng(marker.getLatLng().lat % 90, newValue % 180)
        marker.setLatLng(newLatLng)
        if (map.value) map.value.panTo(newLatLng)
      }
    })

    watch(lat, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        const newLatLng = new L.LatLng(newValue % 90, marker.getLatLng().lng % 180)
        marker.setLatLng(newLatLng)
        if (map.value) map.value.panTo(newLatLng)
      }
    })
  }

  return marker
}

/**
 * when `clicking` the map, set a marker if coordinates aren't set .
 * it will only work once
 * @param feature FeatureSchema
 */
function initializeAddMarkerOnClickingMap (feature: FeatureSchema) {
  if (map.value !== null) {
    map.value.on('click', function (e) {
      const newLatLong: LatLng = e.latlng
      if (isHaveCoordinate.value) return // if coordinates are set, we can't add new marker

      if (map.value) {
        lat.value = newLatLong.lat
        lng.value = newLatLong.lng
        projectManagerStore.setLocation(newLatLong, 'map')
        initializeMarker(feature, newLatLong).addTo(map.value)
        removeCustomCursor()
      }
    })
  }
}

/**
 * initialize project map
 * @param zoomArea - if this value exist it will force zoom the map as per `zoomArea` param
 */
function mapInitialize (zoomArea?: FeatureSchema | undefined) {
  removeCustomCursor()
  const osm = osmLayer()
  const satellite = mapTilerLayer()
  map.value = L.map('mapContainer', {
    zoomControl: false,
    center: [-9.12, 125.623],
    zoom: 8,
    layers: [osm, satellite]
  })
  L.control.layers({ osm, satellite }, undefined, { position: 'bottomright' }).addTo(map.value)

  initializeMarkerMapLegend()

  L.control.zoom({ position: 'topright' }).addTo(map.value)

  const projectLayer = L.geoJSON(projectManagerStore.$state.idaProjects, {
    pointToLayer: function (feature, latlng) {
      const isProjectLocationSet = zoomArea !== undefined && latlng.lat === 0 && latlng.lng === 0 // If longitude and latitude = 0 which mean the project location is not yet set
      const geoJsonLayerGroup = layerGroup()

      if (isProjectLocationSet) {
        initializeAddMarkerOnClickingMap(feature as FeatureSchema)
        return geoJsonLayerGroup
      }

      return initializeMarker(feature as FeatureSchema, latlng)
    }
  }).addTo(map.value)

  if (zoomArea !== undefined) { // force zoom based on `zoomArea` params
    const selectedZoomArea = L.geoJSON(zoomArea)
    map.value.fitBounds(selectedZoomArea.getBounds())
  } else {
    if (props.activeGeoLocation !== undefined) {
      const layer = L.geoJSON(props.activeGeoLocation).addTo(map.value).setStyle(activePoint)
      map.value.fitBounds(layer.getBounds())
    } else {
      map.value.fitBounds(projectLayer.getBounds())
    }
  }
}

defineExpose({ mapInitialize })

</script>
<template>
  <div
    id="project-location"
    class="rounded-[30px] lg:rounded-5xl overflow-hidden relative"
  >
    <MapLegend
      :marker-colors="markerMapLegendColors"
    />
    <div
      id="mapContainer"
      class="w-full h-[555px]"
    ></div>
  </div>
</template>
