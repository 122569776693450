<script setup lang="ts">
import LanguageToggle from '../components/forms/LanguageToggle.vue'
import { IS_MOBILE_MAX_SCREEN_WIDTH } from '../_constants/common.constant'
import { onMounted, ref } from 'vue'

withDefaults(defineProps<{
  title?: string
  hasBorder?: boolean
  contentSize?: string
}>(), {
  title: undefined,
  hasBorder: true,
  contentSize: 'md'
})

const isMobile = ref<boolean>(false)

const masonryImages: Record<string, string[]> = {
  first: ['Image11@2x.webp', 'Image17@2x.webp', 'Image6@2x.webp', 'Image10@2x.webp', 'Image11@2x.webp', 'Image17@2x.webp'],
  second: ['Image2@2x.webp', 'Image8@2x.webp', 'Image14@2x.webp', 'Image4@2x.webp', 'Image2@2x.webp', 'Image8@2x.webp'],
  third: ['Image1@2x.webp', 'Image7@2x.webp', 'Image12@2x.webp', 'Image13@2x.webp', 'Image1@2x.webp', 'Image7@2x.webp'],
  fourth: ['Image3@2x.webp', 'Image9@2x.webp', 'Image16@2x.webp', 'Image5@2x.webp', 'Image3@2x.webp', 'Image9@2x.webp']
}

const _isMobileChecker = () => {
  isMobile.value = document.body?.clientWidth ? document.body.clientWidth < IS_MOBILE_MAX_SCREEN_WIDTH : false
}

_isMobileChecker()

onMounted(() => {
  window.addEventListener('resize', () => {
    _isMobileChecker()
  }, { passive: true })
})
</script>
<template>
  <div class="flex bg-zinc-400">
    <div
      class="w-full bg-stone-100 md:rounded-tr-5xl py-9 px-6 md:px-10 h-full min-h-screen overflow-auto scrollbar-hide md:max-h-screen shadow-xl"
      :class="{ 'md:w-[514px]': contentSize === 'md', 'md:w-[65%]': contentSize === 'lg' }"
    >
      <div class="flex items-center justify-between">
        <router-link
          class="text-zinc-900 text-2xl lg:text-4xl font-black"
          :to="{ name: 'home' }"
        >
          <img
            src="/assets/IDA-logo-dark-440.webp"
            width="110"
            height="40"
            alt="logo"
          />
        </router-link>
        <LanguageToggle />
      </div>
      <h1
        v-if="title"
        class="text-4.5xl font-black mt-9"
      >
        {{ title }}
      </h1>
      <hr
        v-if="hasBorder"
        class="border-b border-zinc-400 border-t-0 mt-2.5 mb-10 -mx-6 md:-mx-10"
      />
      <slot></slot>
    </div>
    <div
      v-if="!isMobile"
      class="pl-10 lg:pl-[59px] max-h-screen overflow-hidden"
      :class="{ 'md:w-[calc(100%-514px)]': contentSize === 'md', 'md:w-[45%]': contentSize === 'lg' }"
    >
      <div
        class="grid grid-cols-2 gap-6 lg:gap-7.5 w-[110%]"
        :class="{'xl:grid-cols-4': contentSize === 'md', 'xl:grid-cols-3': contentSize === 'lg'}"
      >
        <div
          class="gap-6 lg:gap-7.5 columns-1 space-y-6 lg:space-y-7.5 -mt-[120%]"
        >
          <img
            v-for="(image, index) in masonryImages.first"
            :key="index"
            v-lazy="{ src: `/masonry-images/${image}`}"
            width="282"
            height="360"
            class="aspect-[3/4] w-full object-cover shadow-2xl rounded-2.5xl"
          />
        </div>
        <div
          class="gap-6 lg:gap-7.5 columns-1 space-y-6 lg:space-y-7.5 -mt-[60%]"
        >
          <img
            v-for="(image, index) in masonryImages.second"
            :key="index"
            v-lazy="{ src: `/masonry-images/${image}`}"
            width="282"
            height="360"
            class="aspect-[3/4] w-full object-cover shadow-2xl rounded-2.5xl"
          />
        </div>
        <div
          class="gap-6 lg:gap-7.5 columns-1 space-y-6 lg:space-y-7.5 -mt-[105%]"
        >
          <img
            v-for="(image, index) in masonryImages.third"
            :key="index"
            v-lazy="{ src: `/masonry-images/${image}`}"
            width="282"
            height="360"
            class="aspect-[3/4] w-full object-cover shadow-2xl rounded-2.5xl"
          />
        </div>
        <div
          class="gap-6 lg:gap-7.5 columns-1 space-y-6 lg:space-y-7.5 -mt-[90%]"
        >
          <img
            v-for="(image, index) in masonryImages.fourth"
            :key="index"
            v-lazy="{ src: `/masonry-images/${image}`}"
            width="282"
            height="360"
            class="aspect-[3/4] w-full object-cover shadow-2xl rounded-2.5xl"
          />
        </div>
      </div>
    </div>
  </div>
</template>
