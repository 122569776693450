import { getCsrf } from '../../_helpers/common.helpers'
import ApiService from '../api.service'
class AuthService extends ApiService {
  login = async (formData: FormData) => {
    return await fetch(
      '/accounts_api/login',
      {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
          'X-CSRFToken': getCsrf()
        }
      }
    )
  }

  passwordReset = async (data: object) => {
    return await this.post('/accounts_api/password_reset', data, {
      headers: {
        Accept: 'application/json'
      }
    })
  }
}

export default new AuthService()
