<script setup lang="ts">
import { ref, reactive } from 'vue'
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import BasicButton from '../components/commons/BasicButton.vue'

import { useToast } from 'vue-toastification'
import { groupSchema } from '../_formkit/schema/edit_group.schema'

import { useAuthStore } from '../stores/auth'
import { useGroupStore, GroupReturn } from '../stores/groups'
import { FormKitNode } from '@formkit/core'
import { FormKitSchema } from '@formkit/vue'
import { router } from '../router'
import { useI18NStore } from '../stores/i18n'
import { useModal } from '../stores/modal'

const props = defineProps<{
  id: string
}>()

const authStore = useAuthStore()
const groupStore = useGroupStore()
const toast = useToast()
const modal = useModal()

const groupReturn = ref<GroupReturn>()

groupReturn.value = await groupStore.getGroup(props.id)

async function deleteGroup () {
  if (groupReturn.value?.group) {
    const response = await groupStore.deleteGroup(groupReturn.value.group)
    if (response) {
      toast.success('Deleted')
      router.push({ name: 'groups' })
    } else {
      toast.success('Delete failed')
    }
  }
}

async function submit (data: any, node: FormKitNode | undefined) {
  if (groupReturn.value?.group) {
    const response = await groupStore.updateGroup(groupReturn.value.group)
    if (response.success) {
      toast.success('Udpated')
      router.push({ name: 'groups' })
    } else {
      toast.success('failed')
      node?.setErrors(response.errors)
    }
  }
}
const formKitSubmitAttrs = {
  suffixIcon: 'arrowRight',
  enabled: false
}

const formKitData = reactive({
  gettext: useI18NStore().gettext
})
</script>

<template>
  <base-layout
    :title="gettext('Group')"
    :sub-title="groupReturn?.group?.name ? gettext(groupReturn?.group?.name) : ''"
  >
    <content-section>
      <div v-if="groupReturn">
        <div v-if="groupReturn.error">
          {{ groupReturn.error }}
        </div>
        <div
          v-else
          class="max-w-xl"
        >
          <FormKit
            v-if="groupReturn.group"
            v-model="groupReturn.group.model.fields"
            type="form"
            :submit-label="gettext('Update')"
            :submit-attrs="formKitSubmitAttrs"
            novalidate
            message-class="text-red-500 text-sm"
            :disabled="!authStore.can('auth.change_group')"
            :actions="false"
            @submit="submit"
            @input="() => {}"
          >
            <FormKitSchema
              :schema="groupSchema"
              :data="formKitData"
            />
            <div class="flex mt-4">
              <basic-button
                type="submit"
                class="min-w-[220px] mr-4"
              >
                {{ gettext('Save') }}
              </basic-button>
            </div>
          </FormKit>
        </div>
      </div>
      <div v-else>
        Requesting
      </div>
    </content-section>
  </base-layout>
</template>
