<script setup lang="ts">
import TableDiv from '../commons/table/TableDiv.vue'
import TableDivRow from '../commons/table/TableDivRow.vue'
import TableDivTd from '../commons/table/TableDivTd.vue'
import TableDivTh from '../commons/table/TableDivTh.vue'
import { formatCurrencyWithDollar, getMonthName } from '../../_helpers/common.helpers'
import { ExpendituresData, sumMaterials3000Expenditures, sumLabourIncentive3001Expenditures } from '../../stores/projectmanager'
import { computed } from 'vue'

const props = defineProps<{
  materials3000Subsidy: number
  labourIncentive3001Subsidy: number
  communityContribution: number
  expendituresData: ExpendituresData[]
}>()

const totalInfrastructureSubsidy = computed(() => props.materials3000Subsidy + props.labourIncentive3001Subsidy)
const totalInfrastructureAndCommunityContribution = computed(() => totalInfrastructureSubsidy.value + props.communityContribution)
const materials3000Expenditures = computed(() => sumMaterials3000Expenditures(props.expendituresData))
const labourIncentive3001Expenditures = computed(() => sumLabourIncentive3001Expenditures(props.expendituresData))

</script>
<template>
  <h2 class="font-bold text-2.5xl mb-5">
    {{ gettext('Infrastructure subsidy') }}
  </h2>
  <div class="mb-2">
    <label class="mb-4 text-base block font-bold">{{ gettext('3000 - Materials (USD)') }} </label>
    <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
      {{ formatCurrencyWithDollar(materials3000Subsidy) }}
    </div>
  </div>
  <div class="mb-2">
    <label class="mb-4 text-base block font-bold">{{ gettext('3001 - Labour incentive (USD)') }}</label>
    <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
      {{ formatCurrencyWithDollar(labourIncentive3001Subsidy) }}
    </div>
  </div>
  <div class="w-full flex items-center justify-between bg-zinc-400 rounded-[5px] px-4.5 py-[15px] mt-4 mb-6 text-base">
    <strong class="text-2sm">{{ gettext('Total Infrastructure Budget') }}</strong>
    {{ formatCurrencyWithDollar((totalInfrastructureSubsidy)) }}
  </div>

  <h2 class="font-bold text-2.5xl mb-5">
    {{ gettext('Community contribution') }}
  </h2>
  <div class="mb-2">
    <label class="mb-4 text-base block font-bold">{{ gettext('Amount') }} (USD)</label>
    <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
      {{ formatCurrencyWithDollar(communityContribution) }}
    </div>
  </div>
  <div class="w-full flex items-center justify-between bg-zinc-400 rounded-[5px] px-4.5 py-[15px] text-2base mt-4">
    <strong>{{ gettext('Total') }}</strong>
    {{ formatCurrencyWithDollar(totalInfrastructureAndCommunityContribution) }}
  </div>
  <h2 class="font-bold text-2.5xl mb-5 mt-6">
    {{ gettext('Expenditures') }}
  </h2>
  <p v-if="materials3000Expenditures === 0">
    {{ gettext('No data available') }}
  </p>
  <table-div
    v-else
    class="relative text-center table-expenditures pl-14 xl:pl-0"
  >
    <table-div-row>
      <table-div-th>
        {{ gettext('Month') }}
      </table-div-th>
      <table-div-th>
        3000 - {{ gettext('Materials') }} (USD)
      </table-div-th>
      <table-div-th>
        3001 - {{ gettext('Labour Incentive') }} (USD)
      </table-div-th>
    </table-div-row>
    <template
      v-for="(value, index) in expendituresData"
      :key="index"
    >
      <div class="relative">
        <p class="absolute text-lg font-black top-[15px] -left-14">
          {{ value.year }}
        </p>
      </div>
      <table-div-row
        v-for="(data, month) in value.data"
        :key="month"
      >
        <table-div-td class="text-3xs">
          {{ getMonthName(Number(month)) }}
        </table-div-td>
        <table-div-td class="text-xl">
          {{ formatCurrencyWithDollar(data.materials_3000) }}
        </table-div-td>
        <table-div-td class="text-xl">
          {{ formatCurrencyWithDollar(data.labour_incentive_3001) }}
        </table-div-td>
      </table-div-row>
    </template>
    <div class="h-8"></div>
    <table-div-row>
      <table-div-td class="font-black !bg-zinc-400 !border-r-zinc-450 rounded-tl-2.5xl">
        {{ gettext('Total') }}
      </table-div-td>
      <table-div-td class="text-xl bg-zinc-400 border-r-zinc-450">
        {{ formatCurrencyWithDollar(materials3000Expenditures) }}
      </table-div-td>
      <table-div-td class="text-xl bg-zinc-400 rounded-tr-2.5xl">
        {{ formatCurrencyWithDollar(labourIncentive3001Expenditures) }}
      </table-div-td>
    </table-div-row>
  </table-div>
</template>
