import { defineStore } from 'pinia'
export interface Embed {
  id: string
  name: string
  path: string
  subtitle?: string
  description: string
  title: string
}

export interface EmbedsState {
  host?: string
  embeds?: Embed[]
  error?: string
}

const initialState: EmbedsState = {}

export const useEmbedsStore = defineStore('embeds', {
  state: () => (initialState),
  getters: {
    getDashboard (state) {
      return (id: string) => {
        return state.embeds?.find(e => e.id === id)
      }
    }
  },
  actions: {
    async initialize () {
      this.error = undefined
      try {
        const response = await fetch('/embeds/')
        if (response.ok) {
          const responseJson = await response.json()
          this.host = responseJson.host
          this.embeds = responseJson.dashboards
        } else if (response.status === 500) {
          this.error = response.statusText
        }
      } catch (error) {
        this.error = 'fetch error'
      }
    }
  }
})
