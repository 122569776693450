import { defineStore } from 'pinia'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
import { useSukuProfileStore } from './sukuprofile'
import { useSukuPrioritiesStore } from './sukupriorities'
import { useAppStore } from './app'
import { useSubmissionsStore } from './submissions'
import { FORM_TYPES } from '../_constants/common.constant'
import { FeatureSchema as FeatureWithProjectInformationSchema } from './projectmanager'

const toast = useToast()

type Pom_1Schema = components['schemas']['Pom_1Schema']
type Pom1RepeateractivitySchema = components['schemas']['Pom_1RepeateractivitySchema']
type FF4Schema = components['schemas']['Cfm_2_ff_4Schema']
type SubmissionFileOut = components['schemas']['SubmissionFileOut']
type ProjectSchema = components['schemas']['Tf_6_1_1Schema']
type FeatureCollectionSchema = components['schemas']['FeatureCollection']
type FeatureSchema = components['schemas']['Feature']

interface Pom1RepeateractivitySchemaWithYear extends Pom1RepeateractivitySchema {
  year: number | undefined
}

interface ActivitySchema { activity: number, project_output: number[] }

interface InitialState {
  pom1submissions: Pom_1Schema[]
  pom1Files: SubmissionFileOut[]
  ff4submissions: FF4Schema[]
  activities: ActivitySchema[]
  projectBySuco: FeatureWithProjectInformationSchema[]
}

export const useSukuActivitiesStore = defineStore('sukuactivities', {
  state: () => {
    const initialState: InitialState = {
      pom1submissions: [],
      pom1Files: [],
      ff4submissions: [],
      activities: [],
      projectBySuco: []
    }
    return initialState
  },
  getters: {
    getActivities (state): Pom1RepeateractivitySchemaWithYear[] {
      const data: Pom1RepeateractivitySchemaWithYear[] = []
      state.pom1submissions.forEach(submission => {
        submission.repeaterActivity?.forEach((activity, index) => {
          data.push({
            year: index === 0 ? submission.year : undefined,
            ...activity
          })
        })
      })
      return data
    },
    getPom1Submission (state) {
      return (submissionId: string): Pom_1Schema | undefined => {
        return state.pom1submissions.find(it => it.submission_id === submissionId)
      }
    },

    getProjectByActivity (state) {
      return (activity: number): FeatureWithProjectInformationSchema[] => {
        return state.projectBySuco.filter(project => project.properties.cycle === activity)
      }
    }
  },
  actions: {
    async initialize () {
      useAppStore().$state.loading = true
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = sukuProfileStore.$state.activezSukuId
      const { data, error } = await client.GET('/api/submissions/v2/pom_1', { params: { query: { suco: zSucoId } } })
      if (typeof data !== 'undefined') {
        this.pom1submissions = data.sort((a, b) => (b.year ?? 0) - (a.year ?? 0)) // sort the data by year DESC
      }
      useAppStore().$state.loading = false
    },
    /**
     * fetch FF4 submission based on suco id to generate activity that we will use in `fetchProjectByFF4Activity`
     */
    async fetchFF4Submissions () {
      useAppStore().$state.loading = true
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = sukuProfileStore.$state.activezSukuId
      const { data, error } = await client.GET('/api/submissions/v2/cfm_2_ff_4', {
        params: {
          query: {
            suco: zSucoId
          }
        }
      })
      if (data !== undefined) {
        const activities: ActivitySchema[] = []

        data.forEach(item => {
          if ((item.repeaterInfrastructureFund != null) && Array.isArray(item.repeaterInfrastructureFund)) {
            item.repeaterInfrastructureFund.forEach(fund => {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const { activity, project_name } = fund

              if (activity !== undefined) {
                const existingActivityIndex = activities.findIndex(it => it.activity === activity)

                if (existingActivityIndex > -1 && project_name !== undefined) {
                  activities[existingActivityIndex].project_output.push(project_name)
                } else {
                  activities.push({
                    activity,
                    project_output: project_name !== undefined ? [project_name] : []
                  })
                }
              }
            })
          }
        })

        this.activities = activities
      }
      useAppStore().$state.loading = false
    },

    async getActivityFiles (formKey: string) {
      useAppStore().$state.loading = true
      const submissionStore = useSubmissionsStore()
      const data = await submissionStore.getSubmissionFiles(formKey)
      this.pom1Files = data
      useAppStore().$state.loading = false
    },

    async save (values: Pom1RepeateractivitySchema[]): Promise<boolean> {
      useAppStore().$state.loading = true
      const submissionStore = useSubmissionsStore()
      const formKey = values.length > 0 ? values[0].parent : undefined
      if (formKey === undefined) return false
      const selectedSubmission = this.getPom1Submission(formKey)
      if (selectedSubmission !== undefined) {
        selectedSubmission.repeaterActivity = values
        const req = await submissionStore.update(formKey, FORM_TYPES.POM1, selectedSubmission as unknown as Record<string, never>)
        if (!req) {
          toast.error(useI18NStore().gettext('The activity was not saved successfully'))
          return false
        }
      }

      this.pom1submissions.map(submission => {
        if (submission.submission_id === formKey) {
          submission.repeaterActivity = values
        }
        return submission
      })

      toast.success(useI18NStore().gettext('The activities has been successfully updated'))
      useAppStore().$state.loading = false
      return true
    },
    /**
     * fetch project (TF611) submission
     * @param activity ActivitySchema
     */
    async fetchIdaProjects () {
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = sukuProfileStore.$state.activezSukuId
      useAppStore().$state.loading = true

      const url = '/api/ida/projects'
      try {
        const { data } = await client.GET(url, {
          params: {
            query: {
              // suku: zSucoId // currently its not yet working
            }
          }
        })

        if (data !== undefined) {
          this.projectBySuco = data.features.filter(feature => feature.properties.suco === zSucoId) as unknown as FeatureWithProjectInformationSchema[]
        } else {
          useToast().error(useI18NStore().gettext('An unexpected error occurred during fetching projects data'))
        }
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.warn('SyntaxError caught:', e, url)
        } else if (e instanceof TypeError) {
          console.warn('Failed to Fetch caught:', e, url)
        } else {
          throw e // re-throw the error unchanged
        }
      } finally {
        useAppStore().$state.loading = false
      }
    }
  }
})
