import { defineStore } from 'pinia'
import { getCsrf, objectToFormData } from '../_helpers/common.helpers'
import { useAppStore } from './app'
export enum StoreState {
  INITIAL,
  QUERYING,
  ERRORED,
  IDLE
}

interface DjangoModel {
  model: string
  pk: string | number
  fields: Record<string, number | string | boolean | null>
}

interface IUser {
  username: string
  firstName: string
  lastName: string
  email: string
  isActive?: boolean
}

export class User implements IUser {
  model: DjangoModel
  constructor (model: DjangoModel) {
    this.model = model
  }

  get id (): number {
    return this.model.pk as number
  }

  get username (): string {
    return this.model.fields.username as string
  }

  get firstName (): string {
    return this.model.fields.first_name as string
  }

  get lastName (): string {
    return this.model.fields.last_name as string
  }

  get email (): string {
    return this.model.fields.email as string
  }

  get isActive (): boolean {
    return this.model.fields.is_active as boolean
  }

  set isActive (active: boolean) {
    this.model.fields.is_active = active
  }
}

const userListPath = '/accounts_api/users/'
const userAddPath = '/accounts_api/user/create'
function getUserUpdatePath (id: string | number): string {
  return `/accounts_api/user/${id}/update`
}
function getUserDeletePath (id: string | number): string {
  return `/accounts_api/user/${id}/delete`
}
function getUserRessetPath (id: string | number): string {
  return `/accounts_api/user/${id}/reset`
}

export interface UsersState {
  users?: User[]
  state: StoreState
}
const initialState: UsersState = {
  state: StoreState.INITIAL
}

export interface UserReturn {
  user?: User
  error?: 'notfound' | 'network'
}
export interface AddUserReturn {
  success: boolean
  errors?: any
  resetLink?: string
}
export interface ResetUserReturn {
  success: boolean
  resetLink?: string
}

export const useUsersStore = defineStore('users', {
  state: () => (initialState),
  getters: {
  },
  actions: {
    async initialize () {
      if (this.state !== StoreState.QUERYING) {
        this.state = StoreState.QUERYING
        useAppStore().$state.loading = true
        try {
          const response = await fetch(userListPath)
          if (response.ok) {
            const models = await response.json() as DjangoModel[]
            this.users = models.map(m => new User(m))
            this.state = StoreState.IDLE
          } else {
            this.state = StoreState.ERRORED
          }
        } catch {
          this.state = StoreState.ERRORED
        }

        useAppStore().$state.loading = false
      }
    },

    localUser (username: string): User | undefined {
      return this.users !== undefined
        ? this.users.find((u) => u.username === username)
        : undefined
    },

    async getUser (username: string): Promise<UserReturn> {
      let user = this.localUser(username)
      if (user !== undefined) return { user }
      await this.initialize()
      if (this.state === StoreState.ERRORED) return { error: 'network' }
      user = this.localUser(username)
      if (user !== undefined) return { user }
      return { error: 'notfound' }
    },

    async updateUser (user: User): Promise<{
      success: boolean
      errors?: any
    }> {
      const response = await fetch(
        getUserUpdatePath(user.model.pk),
        {
          method: 'POST',
          body: objectToFormData({
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            is_active: user.isActive
          }),
          headers: {
            Accept: 'application/json',
            'X-CSRFToken': getCsrf()
          }

        }
      )
      if (response.ok) {
        return {
          success: true
        }
      } else {
        const errorResponse = await response.json()
        return {
          success: false,
          errors: errorResponse
        }
      }
    },

    async deleteUser (user: User): Promise<boolean> {
      const response = await fetch(
        getUserDeletePath(user.model.pk),
        {
          method: 'POST',
          headers: {
            'X-CSRFToken': getCsrf()
          }
        }
      )
      return response.ok
    },

    async toggleUserActive (user: User): Promise<boolean> {
      const response = await fetch(
        getUserUpdatePath(user.model.pk),
        {
          method: 'POST',
          body: objectToFormData({
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName,
            is_active: user.isActive

          }),
          headers: {
            'X-CSRFToken': getCsrf()
          }
        }
      )
      return response.ok
    },

    async resetPassword (user: User): Promise<ResetUserReturn> {
      const response = await fetch(
        getUserRessetPath(user.model.pk),
        {
          method: 'POST',
          headers: {
            'X-CSRFToken': getCsrf()
          }
        }
      )
      if (response.ok) {
        return {
          success: true,
          resetLink: await response.text()
        }
      } else {
        return {
          success: false
        }
      }
    },

    async addUser (user: Record<string, string | undefined>): Promise<AddUserReturn> {
      const payload: Record<string, string | undefined> = {
        username: user.username
      }
      if (user.first_name !== undefined) payload.first_name = user.first_name
      if (user.last_name !== undefined) payload.last_name = user.last_name
      if (user.email !== undefined) payload.email = user.email
      const response = await fetch(
        userAddPath,
        {
          method: 'POST',
          body: objectToFormData(payload),
          headers: {
            Accept: 'application/json',
            'X-CSRFToken': getCsrf()
          }
        }
      )
      const returnData = {
        success: response.ok,
        errors: undefined,
        resetLink: ''
      }
      if (response.ok) {
        returnData.resetLink = await response.text()
      } else {
        returnData.errors = await response.json()
      }
      return returnData
    }
  }
})
