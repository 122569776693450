/**
 * IDB helper to store location data
 */
import { openDB, IDBPDatabase, DBSchema } from 'idb'
import fetchTopoJson from '../_helpers/topojson.helper'
import { components } from '../_api-services/openapi'
type FeatureCollection = components['schemas']['FeatureCollection']

interface MyDBSchema extends DBSchema {
  geodata: {
    key: string
    value: FeatureCollection
  }
}

const dbName = 'location'
const objectStoreName = 'geodata'

export async function initializeDB (): Promise<IDBPDatabase<MyDBSchema>> {
  return await openDB<MyDBSchema>(dbName, 1, {
    upgrade (db) {
      if (!db.objectStoreNames.contains(objectStoreName)) {
        db.createObjectStore(objectStoreName)
      }
    }
  })
}

export async function addData (db: IDBPDatabase<MyDBSchema>, key: string, value: FeatureCollection): Promise<void> {
  await db.put(objectStoreName, value, key)
}

export async function getData (db: IDBPDatabase<MyDBSchema>, key: string): Promise<FeatureCollection | undefined> {
  return await db.get(objectStoreName, key)
}

/**
 * Will fetch the topojson file and store it to IDB
 * if the data already exist on IDB, it will not fetch the data.
 */
export async function fetchLocationAndStore (): Promise<void> {
  const db = await initializeDB()
  // Check if data is already available in IndexedDB
  const existingData = await getData(db, objectStoreName)
  if (existingData !== undefined) {
    return
  }
  try {
    // Fetch data from the geodata.json file
    const data = await fetchTopoJson('mid-detail')
    // Store the fetched data in IndexedDB
    if (typeof data !== 'undefined') await addData(db, objectStoreName, data)
    else throw new Error('No location data received')
  } catch (error) {
    console.error('Error:', error)
  } finally {
    db.close()
  }
}
