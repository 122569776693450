import { App, Plugin, DirectiveBinding } from 'vue'

// The Install function used by Vue to register the plugin
type ClickOutsideFunction = (event: Event) => void
interface ExtendedHTMLElement extends HTMLElement {
  clickOutsideEvent: ClickOutsideFunction
}

export const clickOutsideDirective: Plugin = {
  install (app: App) {
    app.directive('click-outside', {
      mounted (el: ExtendedHTMLElement, binding: DirectiveBinding) {
        el.clickOutsideEvent = function (event: Event) {
          if (!(el === event.target || Boolean(el.contains(event.target as Node) || (event.target as HTMLElement).classList.contains('stop-click-outside')))) {
            binding.value(event, el)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    })
  }
}
