import { defineStore } from 'pinia'
import { client } from '../_api-services/urls'
import { FORM_TYPES, SUBMISSIONS_STATUS_VALUE } from '../_constants/common.constant'
import { PROJECT_MANAGER_FILE_LABEL_AFTER, PROJECT_MANAGER_FILE_LABEL_BEFORE } from '../_constants/fileupload.constant'
import { getMonthName, isDateInRange } from '../_helpers/common.helpers'
import { components } from '../_api-services/openapi'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
import { useAppStore } from './app'
import { useSubmissionsStore } from './submissions'
import { DELETE_PROJECT_MESSAGES, UPDATE_PROJECT_MESSAGES } from '../_constants/toastmessages.constant'
import { LatLng } from 'leaflet'
import { filterNonNumericTimes } from '../_helpers/timedata.helpers'
import { useOptionsStore } from './options'

type SetSubmissionStatus = components['schemas']['SetSubmissionStatus']
type TF611Schema = components['schemas']['Tf_6_1_1Schema']
type ProjectBReakdownSchema = components['schemas']['ProjectBreakdown']
type SubmissionFile = components['schemas']['SubmissionFileOut']
type ProjectInfoTF611Schema = components['schemas']['ProjectInfo_Tf_6_1_1']
type ProjectInfoSf4Schema = components['schemas']['ProjectInfo_Sf_4_2Repeaterproject']
type ProjectInfoFF4Schema = components['schemas']['ProjectInfo_Cfm_2_ff_4Repeaterinfrastructurefund']
type ProjectOutputSchema = components['schemas']['ProjectInfo_Tf_6_1_1_Repeaterprojectoutput']
type ProjectInfoFF12Infrastructure = components['schemas']['ProjectInfo_Cfm_12_ff_12Repeaterinfrastructurefund']
type ProjectInfoProjectProgressSchema = components['schemas']['ProjectInfo_Tf_13_2_1Repeaterprojectprogress']
type ProjectInfoWorkersSchema = components['schemas']['ProjectInfo_Sf_6_2']

type ProjectProperties = components['schemas']['ProjectInformationSchema'] & { project_id: number }
export interface FeatureSchema {
  type: 'Feature'
  geometry: components['schemas']['Point']
  properties: ProjectProperties // Add new type to accept project properties type
  id?: number | string
  bbox?: [number, number, number, number] | [number, number, number, number, number, number]
}
interface ProjectInfoWorkersTotalSchema extends ProjectInfoWorkersSchema {
  total_working_days: number
  total_new_workers: number
  total_workers_with_disability: number
  total_new_workers_with_disablity: number
  total_working_days_with_disability: number
  total_workers: number
}
interface ProjectInfoTF611WithOutputSchema extends ProjectInfoTF611Schema {
  repeaterProjectOutput?: ProjectOutputSchema[]
}

const toast = useToast()

export interface ProjectDetailType {
  district: number | undefined
  administrativePost: number | undefined
  suco?: number | undefined
  aldeia?: number | undefined
  projectOutput: number | undefined
  dateStart: string | undefined
  dateFinish: string | undefined
  key: string
  project_id?: string | undefined
  activity: number[]
}

interface WorkersDataType {
  no_of_new_workers_male: number
  no_of_new_workers_female: number
  total_new_workers: number
  no_of_working_days_male: number
  no_of_working_days_female: number
  total_working_days: number
  workers_with_disability_male: number
  workers_with_disability_female: number
  total_workers_with_disability: number
  new_workers_with_disability_male: number
  new_workers_with_disability_female: number
  total_new_workers_with_disablity: number
  workers_male: number
  workers_female: number
  total_workers: number
}

type WorkersDataKey = keyof WorkersDataType

interface WorkersEachMonth {
  year: number
  data: {
    [month: string]: WorkersDataType
  }

}

interface WorkersDatas {
  total: WorkersDataType | null
  data: WorkersEachMonth[]
}

export interface ExpendituresData {
  year: string
  data: {
    [month: number]: {
      materials_3000: number
      labour_incentive_3001: number
    }
  }
}

export const sumMaterials3000Expenditures = (expendituresData: ExpendituresData[]) => {
  let sum = 0
  for (const data of expendituresData) {
    for (const month in data.data) {
      if (typeof data.data[month].materials_3000 === 'number') {
        sum += data.data[month].materials_3000
      }
    }
  }
  return sum
}

export const sumLabourIncentive3001Expenditures = (expendituresData: ExpendituresData[]) => {
  let sum = 0
  for (const data of expendituresData) {
    for (const month in data.data) {
      if (typeof data.data[month].labour_incentive_3001 === 'number') {
        sum += data.data[month].labour_incentive_3001
      }
    }
  }
  return sum
}

export interface PhysicalProgressData {
  year: string
  data: {
    [month: string]: {
      'total': number
    }
  }
}

interface BudgetsData {
  materials: number
  labour_incentive: number
  operational_budget?: number
  community_contribution: number
}

interface Expenditures {
  data: ExpendituresData[]
  total: {
    materials_3000: number
    labour_incentive_3001: number
  }
}

interface InfrastructureSubsidySchema {
  materials: number
  labour_incentive_3001: number
  total_infrastructure_subsidy: number
}

interface ProjectBudgetAndExpendituresSchema {
  infrastructure_subsidy: InfrastructureSubsidySchema
  community_contribution: number
  total_infrastructure_and_community_contribution: number
  expenditures: Expenditures
}

interface PhysicalProgressSchema {
  data: PhysicalProgressData[]
  current: number
}

interface ReopenedSubmissionSchema {
  form: string
  key: string
}

interface initialState {
  projectDetail: ProjectDetailType
  budget: BudgetsData
  projectDocuments: SubmissionFile[]
  idaProjects: FeatureSchema[]
  tf611projects: TF611Schema[]
  projectBreakdownDetail: ProjectBReakdownSchema[]
  projectLocation: L.LatLng | undefined
  locationSource: 'map' | 'form' | undefined
}

export const sf62ToWorkers = (data: Array<components['schemas']['ProjectInfo_Sf_6_2']>): WorkersDatas => {
  const workersInit: WorkersDataType = {
    no_of_new_workers_male: 0,
    no_of_new_workers_female: 0,
    total_new_workers: 0,
    no_of_working_days_male: 0,
    no_of_working_days_female: 0,
    total_working_days: 0,
    workers_with_disability_male: 0,
    workers_with_disability_female: 0,
    total_workers_with_disability: 0,
    new_workers_with_disability_male: 0,
    new_workers_with_disability_female: 0,
    total_new_workers_with_disablity: 0,
    workers_female: 0,
    workers_male: 0,
    total_workers: 0
  }
  const totalData: WorkersDataType = { ...workersInit }
  const formattedData: WorkersEachMonth[] = filterNonNumericTimes(data).reduce((acc: WorkersEachMonth[], item) => {
    const { year } = item
    const month = getMonthName(item.month) as string

    // Check if the year exists in the accumulator
    const existingYear = acc.find((entry) => entry.year.toString() === year?.toString())

    if (typeof existingYear !== 'undefined') {
      // Year already exists, update the month data
      if (typeof existingYear.data[month] === 'undefined') {
        existingYear.data[month] = { ...workersInit }
      }

      // Update the fields for the month
      for (const field in item) {
        if (field.includes('work')) {
          existingYear.data[month][field as WorkersDataKey] += Number((item as ProjectInfoWorkersTotalSchema)[field as WorkersDataKey])
        }
      }

      // Calculate the totals for the month
      existingYear.data[month].total_new_workers =
        existingYear.data[month].no_of_new_workers_female + existingYear.data[month].no_of_new_workers_male
      existingYear.data[month].total_new_workers_with_disablity =
        existingYear.data[month].new_workers_with_disability_male +
        existingYear.data[month].new_workers_with_disability_female
      existingYear.data[month].total_workers_with_disability =
        existingYear.data[month].workers_with_disability_female + existingYear.data[month].workers_with_disability_male
      existingYear.data[month].total_working_days =
        existingYear.data[month].no_of_working_days_male + existingYear.data[month].no_of_working_days_female
      existingYear.data[month].total_workers =
        existingYear.data[month].workers_female + existingYear.data[month].workers_male
    } else {
      // Year doesn't exist, add a new entry
      const newYearData: WorkersDataType = { ...workersInit }

      // Update the fields for the month
      for (const field in item) {
        if (field.includes('work')) {
          newYearData[field as WorkersDataKey] = Number((item as ProjectInfoWorkersTotalSchema)[field as WorkersDataKey])
        }
      }

      // Calculate the totals for the month
      newYearData.total_new_workers = newYearData.no_of_new_workers_female + newYearData.no_of_new_workers_male
      newYearData.total_new_workers_with_disablity =
        newYearData.new_workers_with_disability_male + newYearData.new_workers_with_disability_female
      newYearData.total_workers_with_disability =
        newYearData.workers_with_disability_female + newYearData.workers_with_disability_male
      newYearData.total_working_days = newYearData.no_of_working_days_male + newYearData.no_of_working_days_female
      newYearData.total_workers = newYearData.workers_female + newYearData.workers_male

      const newYear = {
        year,
        data: {
          [month]: newYearData
        }
      }

      acc.push(newYear)
    }

    return acc
  }, [])

  // Calculate the totals for the totalData
  for (const entry of formattedData) {
    for (const monthData of Object.values(entry.data)) {
      for (const field in workersInit) {
        totalData[field as WorkersDataKey] += monthData[field as WorkersDataKey]
      }
    }
  }

  return {
    data: formattedData,
    total: totalData
  }
}

export const useProjectManagerStore = defineStore('projectmanager', {
  state: () => {
    const initialState: initialState = {
      projectDetail: {
        district: undefined,
        administrativePost: undefined,
        suco: undefined,
        aldeia: undefined,
        projectOutput: undefined,
        dateFinish: undefined,
        dateStart: undefined,
        key: '',
        project_id: '',
        activity: []
      },
      projectLocation: undefined,
      locationSource: undefined,
      budget: {
        community_contribution: 0,
        materials: 0,
        labour_incentive: 0
      },
      projectDocuments: [],
      idaProjects: [],
      tf611projects: [],
      projectBreakdownDetail: []
    }
    return initialState
  },

  getters: {
    getDocumentsAfter (state) {
      return () => state.projectDocuments.filter(document => document.comment === PROJECT_MANAGER_FILE_LABEL_AFTER)
    },
    getDocumentsBefore (state) {
      return () => state.projectDocuments.filter(document => document.comment === PROJECT_MANAGER_FILE_LABEL_BEFORE)
    },

    getProjectId (state) {
      return (): number | undefined => state.projectBreakdownDetail[0]?.project_id ?? undefined
    },

    getProjectActivity (state) {
      return (): number | undefined => state.projectBreakdownDetail[0]?.ff_4_infrastructure[state.projectBreakdownDetail[0]?.ff_4_infrastructure.length - 1]?.activity ?? undefined
    },

    getProjectOutput (state) {
      return (): ProjectOutputSchema[] | undefined => state.projectBreakdownDetail[0]?.project_output
    },

    getProjectInformation (state) {
      return (): ProjectInfoTF611WithOutputSchema | undefined => {
        const projectInfo: ProjectInfoTF611WithOutputSchema | undefined = state.projectBreakdownDetail[0]?.tf_6_1_1 ?? undefined
        if (projectInfo !== undefined) {
          projectInfo.repeaterProjectOutput = this.getProjectOutput()
        }
        return projectInfo
      }
    },

    getCommunityContribution (state) {
      return (): ProjectInfoSf4Schema[] => state.projectBreakdownDetail[0]?.community_contribution.filter(it => it.submission_status === SUBMISSIONS_STATUS_VALUE.verified) ?? []
    },

    getInfrastructureSubsidy (state) {
      return (): ProjectInfoFF4Schema[] => {
        const filterDataByProjectOutput = state.projectBreakdownDetail[0]?.ff_4_infrastructure.filter(it => it.submission_status === SUBMISSIONS_STATUS_VALUE.verified)
        return filterDataByProjectOutput
      }
    },

    getTotalCommunityContribution () {
      return (): number => {
        const data: ProjectInfoSf4Schema[] = this.getCommunityContribution()
        const totalCommunityContribution = data.reduce((total, item) => {
          return total + (item.community_contribution_amount !== undefined ? Number(item.community_contribution_amount) : 0)
        }, 0)
        return totalCommunityContribution
      }
    },

    getFF12Infrastructure (state) {
      return (): ProjectInfoFF12Infrastructure[] => state.projectBreakdownDetail[0]?.ff12_infrastructure.filter(it => it.submission_status === SUBMISSIONS_STATUS_VALUE.verified) ?? []
    },

    getTotalInfrastructureSubsidy () {
      return (): InfrastructureSubsidySchema => {
        const data: ProjectInfoFF4Schema[] = this.getInfrastructureSubsidy()
        // eslint-disable-next-line @typescript-eslint/naming-convention
        if (data === undefined) return { materials: 0, labour_incentive_3001: 0, total_infrastructure_subsidy: 0 }
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const total = data.reduce(({ materials = 0, labour_incentive_3001 = 0, total_infrastructure_subsidy = 0 }, item) => {
          return {
            materials: materials + Number(item.materials),
            labour_incentive_3001: labour_incentive_3001 + Number(item.labour_incentive_3001),
            total_infrastructure_subsidy: total_infrastructure_subsidy + materials + Number(item.materials) + labour_incentive_3001 + Number(item.labour_incentive_3001)
          }
        }, { materials: 0, labour_incentive_3001: 0, total_infrastructure_subsidy: 0 })
        return total
      }
    },

    getExpenditures () {
      return (): Expenditures => {
        const ff12Infrastructure = this.getFF12Infrastructure()
        const filteredData: ProjectInfoFF12Infrastructure[] = ff12Infrastructure.sort((a, b) => {
          const yearComparison = Number(b.year) - Number(a.year)
          if (yearComparison !== 0) {
            return yearComparison
          }
          return Number(b.month) - Number(a.month)
        })

        const totalExpenditures = filteredData.reduce((result, item) => {
          result.materials_3000 += Number(item.materials_3000) ?? 0
          result.labour_incentive_3001 += Number(item.labour_incentive_3001) ?? 0
          return result
        },
          { materials_3000: 0, labour_incentive_3001: 0 })

        // group array by year and month
        const groupedData = filteredData.reduce<Record<string, { year: string, data: Record<string, ProjectInfoFF12Infrastructure[]> }>>((result, item) => {
          if (item.year !== undefined && item.month !== undefined) {
            const yearKey = item?.year.toString()
            const monthKey = item.month

            result[yearKey] = result[yearKey] ?? { year: yearKey, data: {} }
            result[yearKey].data[monthKey] = [...(result[yearKey].data[monthKey] ?? []), item]
          }

          return result
        }, {})

        // Sort the keys (years) in descending order
        const sortedYears = Object.keys(groupedData).sort((a, b) => parseInt(b) - parseInt(a))

        // Create an array of objects with 'year' and 'data' properties
        const resultArray = sortedYears.map((year) => ({
          year,
          data: Object.keys(groupedData[year].data).reduce<Record<string, { materials_3000: number, labour_incentive_3001: number }>>((months, month) => {
            months[month] = groupedData[year].data[month].reduce((result, item) => {
              result.materials_3000 += Number(item.materials_3000) ?? 0
              result.labour_incentive_3001 += Number(item.labour_incentive_3001) ?? 0
              return result
            },
              { materials_3000: 0, labour_incentive_3001: 0 })
            return months
          }, {})
        }))

        return {
          data: resultArray,
          total: totalExpenditures
        }
      }
    },

    getProjectBudgetAndExpenditures () {
      return (): ProjectBudgetAndExpendituresSchema => {
        const infrastructureSubsidy = this.getTotalInfrastructureSubsidy()
        const communityContribution = this.getTotalCommunityContribution()
        return {
          infrastructure_subsidy: infrastructureSubsidy,
          community_contribution: communityContribution,
          total_infrastructure_and_community_contribution: communityContribution + infrastructureSubsidy.total_infrastructure_subsidy,
          expenditures: this.getExpenditures()
        }
      }
    },

    getPhysicalProgress (state) {
      return (): PhysicalProgressSchema => {
        const physicalProgress: ProjectInfoProjectProgressSchema[] = state.projectBreakdownDetail[0]?.project_progress.filter(it => it.submission_status === SUBMISSIONS_STATUS_VALUE.verified) ?? []
        let current = 0
        const filteredData: ProjectInfoProjectProgressSchema[] = physicalProgress.filter(it => it.project === this.getProjectInformation()?.project).sort((a, b) => {
          const yearComparison = Number(b.year) - Number(a.year)
          if (yearComparison !== 0) {
            return yearComparison
          }
          return Number(b.month) - Number(a.month)
        })

        if (filteredData.length > 0) {
          current = filteredData[0]?.total ?? 0
        }

        const groupedData = filteredData.reduce<Record<string, { year: string, data: Record<string, ProjectInfoProjectProgressSchema[]> }>>((result, item) => {
          if (item.year !== undefined && item.month !== undefined) {
            const yearKey = item?.year.toString()
            const monthKey = getMonthName(item.month) as string

            result[yearKey] = result[yearKey] ?? { year: yearKey, data: {} }
            result[yearKey].data[monthKey] = [...(result[yearKey].data[monthKey] ?? []), item]
          }

          return result
        }, {})

        // Sort the keys (years) in descending order
        const sortedYears = Object.keys(groupedData).sort((a, b) => parseInt(b) - parseInt(a))

        // Create an array of objects with 'year' and 'data' properties
        const resultArray = sortedYears.map((year) => ({
          year,
          data: Object.keys(groupedData[year].data).reduce<Record<string, { total: number }>>((months, month) => {
            months[month] = groupedData[year].data[month].reduce((result, item) => {
              result.total += Number(item.total) ?? 0
              return result
            },
              { total: 0 })
            return months
          }, {})
        }))

        return {
          data: resultArray,
          current
        }
      }
    },

    getWorkerSubmissions (state) {
      return () => {
        const data: ProjectInfoWorkersSchema[] = state.projectBreakdownDetail[0]?.sf_6_2.filter(it => it.submission_status === SUBMISSIONS_STATUS_VALUE.verified) ?? []
        const projectInfo = this.getProjectInformation()
        // const dataInProjectDateRange = (typeof this.projectDetail.dateStart !== 'undefined') && (typeof this.projectDetail.dateFinish !== 'undefined') && (typeof item.fields.date !== 'undefined') ? isDateInRange(item.fields.date, this.projectDetail.dateStart, this.projectDetail.dateFinish) : false
        return data?.filter((it) => {
          // filter data by date and project name
          const dataInProjectDateRange = (typeof projectInfo?.date_start !== 'undefined') && (typeof projectInfo.date_finish !== 'undefined') && (typeof it.date !== 'undefined') ? isDateInRange(it.date, projectInfo?.date_start, projectInfo.date_finish) : false
          return dataInProjectDateRange && it.aldeia === projectInfo?.aldeia
        }).sort((a, b) => {
          const yearComparison = Number(b.year) - Number(a.year)
          if (yearComparison !== 0) {
            return yearComparison
          }
          return Number(b.month) - Number(a.month)
        })
      }
    },
    getWorkers (_state) {
      return (): WorkersDatas => {
        return sf62ToWorkers(this.getWorkerSubmissions())
      }
    },

    getProjectBreakdownFeature (state) {
      return (): FeatureSchema => {
        const coordinates = state.projectBreakdownDetail[0]?.coordinates ?? [0, 0]
        state.projectLocation = new LatLng(coordinates[1], coordinates[0])
        const projectInfo = this.getProjectInformation()
        const properties: ProjectProperties = {
          project_id: this.getProjectId() ?? 1,
          submission_id: projectInfo?.submission ?? '',
          suco: projectInfo?.suco ?? 0,
          timor_locations_suco: 0,
          output: projectInfo?.project ?? 0,
          subsector: projectInfo?.project_sub_sector ?? 0,
          sector: projectInfo?.project_sector ?? 0,
          project_status: projectInfo?.project_status ?? 0,
          cycle: 0,
          labour_incentive_sum: 0,
          materials_sum: 0,
          balance_cash: 0,
          balance_bank: 0,
          labour_exp_sum: 0,
          materials_exp_sum: 0,
          operational_fund_sum: 0,
          infrastructure_sum: 0,
          project_completion: 0
        }
        const tttt: FeatureSchema = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates
          },
          properties,
          id: this.getProjectId()
        }
        return tttt
      }
    },

    getReopenedSubmissions (state: initialState) {
      return (formType: string, submissions: Array<ProjectInfoWorkersSchema | ProjectInfoSf4Schema | ProjectInfoFF4Schema | ProjectOutputSchema | ProjectInfoFF12Infrastructure | ProjectInfoProjectProgressSchema>, path = 'parent_id'): ReopenedSubmissionSchema[] => {
        const result: ReopenedSubmissionSchema[] = []

        submissions.forEach((item) => {
          const potentialValue = item[path as keyof typeof item]
          if (potentialValue !== undefined) {
            const findSubmission = result.findIndex(it => it.key === potentialValue?.toString())
            if (item?.submission_status === SUBMISSIONS_STATUS_VALUE.changesRequested && findSubmission < 0) {
              result.push({
                form: formType,
                key: potentialValue.toString()
              })
            }
          }
        })

        return result
      }
    },

    getReopenedSubmissionFromProject (state): ReopenedSubmissionSchema[] {
      const result: ReopenedSubmissionSchema[] = []
      const tf611Submission = this.getProjectInformation()
      const isTF611reopened = tf611Submission?.submission_status === SUBMISSIONS_STATUS_VALUE.changesRequested
      const getReopenedSf62submissions = this.getReopenedSubmissions(FORM_TYPES.SF62, state.projectBreakdownDetail[0]?.sf_6_2 ?? [], 'submission')
      const getReopenedFT1321submissions = this.getReopenedSubmissions(FORM_TYPES.TF1321, state.projectBreakdownDetail[0]?.project_progress ?? [])
      const getReopenedFs42submissions = this.getReopenedSubmissions(FORM_TYPES.SF42, this.getCommunityContribution())
      const getReopenedFf4submissions = this.getReopenedSubmissions(FORM_TYPES.CFM2FF4, state.projectBreakdownDetail[0]?.ff_4_infrastructure ?? [])
      const getReopenedFf12submissions = this.getReopenedSubmissions(FORM_TYPES.CFM12FF12, [...state.projectBreakdownDetail[0]?.ff12_balance ?? [], ...state.projectBreakdownDetail[0]?.ff12_infrastructure ?? []])

      if (isTF611reopened) {
        result.push({
          form: FORM_TYPES.TF611,
          key: tf611Submission.submission
        })
      }
      return [
        ...result,
        ...getReopenedSf62submissions,
        ...getReopenedFT1321submissions,
        ...getReopenedFf4submissions,
        ...getReopenedFf12submissions,
        ...getReopenedFs42submissions
      ]
    }
  },

  actions: {
    setLocation (projectLocation: LatLng, locationSource: 'map' | 'form') {
      if (projectLocation.lat !== this.projectLocation?.lat || projectLocation.lng !== this.projectLocation?.lng) {
        this.$patch(
          { projectLocation, locationSource }
        )
      }
    },

    /**
     * get projects documents
     */

    async getProjectDocuments () {
      useAppStore().$state.loading = true
      const key = this.getProjectInformation()?.submission
      if (key !== undefined) {
        const { data, error } = await client.GET('/api/submissions/{submission_key}/file-list', { params: { path: { submission_key: key } } })
        this.projectDocuments = typeof data !== 'undefined' ? data.filter(dt => dt.deleted !== true && (dt.comment === 'project-manager-after' || dt.comment === 'project-manager-before')) : [] // return only file that have `data-collection` label
      }
      useAppStore().$state.loading = false
    },

    async deleteProject (formKey: string) {
      const body: SetSubmissionStatus = {
        form_key: formKey,
        status: SUBMISSIONS_STATUS_VALUE.rejected
      }
      const { data, error } = await client.POST('/api/submissions/set-status', { body })
      if (typeof data !== 'undefined') {
        const submissionStore = useSubmissionsStore()
        const submissions = submissionStore.$state.submissions
        submissionStore.$state.submissions = submissions.filter(sub => sub.key !== formKey)
        toast.success(useI18NStore().gettext(DELETE_PROJECT_MESSAGES.success))
      } else {
        toast.warning(useI18NStore().gettext(DELETE_PROJECT_MESSAGES.error))
      }
      return { data, error }
    },

    /**
     * update project manager
     * @param key string
     * @param fields fields
     * @returns boolean
     */
    async updateProject (key: string, fields: Record<string, never>) {
      const { error } = await client.PUT('/api/submissions/update', { body: { key, form_type: FORM_TYPES.TF611, fields } })
      if (typeof error !== 'undefined') {
        toast.error(useI18NStore().gettext(UPDATE_PROJECT_MESSAGES.error))
      } else {
        toast.success(useI18NStore().gettext(UPDATE_PROJECT_MESSAGES.success))
      }
    },

    async fetchProjects (): Promise<TF611Schema[]> {
      const { data, error } = await client.GET('/api/submissions/v2/tf_6_1_1', {
        params: {
          query: {}
        }
      })

      if (data === undefined) {
        toast.error(useI18NStore().gettext('An unexpected error occurred during fetching projects'))
      }

      this.tf611projects = data ?? []

      return data ?? []
    },

    async fetchIdaProjects (filterParams?: {
      suco?: number
      project_id?: number
    }) {
      useAppStore().$state.loading = true
      let projects: FeatureSchema[] = []
      const url = '/api/ida/projects'
      try {
        const { data } = await client.GET(url, {
          params: {
            query: {
              // zsuco: filterParams?.suco
            }
          }
        })

        if (typeof data === undefined) {
          useToast().error(useI18NStore().gettext('An unexpected error occurred during fetching projects data'))
          return []
        }
        if (data?.features !== undefined) {
          // Defines that 'features' are only point features
          const features = data.features as unknown as FeatureSchema[]
          projects = (filterParams?.suco === undefined && filterParams?.project_id === undefined)
            ? features
            : features.filter(feature => {
              return (
                (filterParams.suco === undefined ||
                  feature.properties.suco === filterParams.suco) &&
                (filterParams.project_id === undefined ||
                  feature.properties.project_id === filterParams.project_id)
              )
            })
        }
        this.idaProjects = projects.sort((a, b) => {
          return b.properties.project_id - a.properties.project_id
        })
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.warn('SyntaxError caught:', e, url)
        } else if (e instanceof TypeError) {
          console.warn('Failed to Fetch caught:', e, url)
        } else {
          throw e
        }
      } finally {
        useAppStore().$state.loading = false
      }
    },

    async fetchActivityCycle () {
      useAppStore().$state.loading = true
      const { data, error } = await client.GET('/api/submissions/v2/cfm_2_ff_4', {
        params: {
          query: {
            suco: this.projectDetail.suco
          }
        }
      })

      if (typeof data !== undefined) {
        const activity: number[] = []

        data?.forEach(dt => {
          // filter ff4 based on project `dateStart` and `dateFinish`
          const dataInProjectDateRange = (typeof this.projectDetail.dateStart !== 'undefined') && (typeof this.projectDetail.dateFinish !== 'undefined') && (typeof dt.date !== 'undefined') ? isDateInRange(dt.date, this.projectDetail.dateStart, this.projectDetail.dateFinish) : false
          dt.repeaterInfrastructureFund?.forEach(fund => {
            const fundProjectName = useOptionsStore().getProjectOutput(fund.project)
            if (Number(fundProjectName) === Number(this.projectDetail.projectOutput) && dataInProjectDateRange && fund.activity !== undefined) {
              activity.push(fund.activity)
            }
          })
        })
        this.projectDetail.activity = activity
      } else {
        toast.error(useI18NStore().gettext('Something wrong when fetching FF4 data'))
      }
      useAppStore().$state.loading = false
    },

    async fetchProjectBreakdown (projectId: number) {
      if (projectId === undefined) {
        throw new Error('Project ID is not provided')
      }
      useAppStore().$state.loading = true
      const { data } = await client.GET('/api/ida/project_breakdown', {
        params: {
          query: {
            project_id: projectId
          }
        }
      })

      if (data !== undefined) {
        this.projectBreakdownDetail = data
      } else {
        useToast().error(useI18NStore().gettext('An unexpected error occurred during fetching projects detail'))
      }
      useAppStore().$state.loading = false
    }
  }
})
