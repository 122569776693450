import { FormKitSchemaFormKit } from '@formkit/core'
import { generateDatepicker } from './forms/common.schema'

export const complaintSchemaIn = (isEdit = false): FormKitSchemaFormKit[] => {
  const schema: FormKitSchemaFormKit[] = [
    generateDatepicker({
      name: 'received',
      id: 'received',
      key: 'received',
      validation: 'required',
      valueFormat: 'YYYY-MM-DD'
    }),
    {
      $formkit: 'autocomplete',
      label: '$gettext("Suco")',
      placeholder: '$gettext("Select a Suco")',
      name: 'suku_id',
      key: 'suku_id',
      id: 'suku_id',
      options: '$getLocationByLevel(3)',
      inputClass: '!pl-3 text-sm',
      dropdownWrapperClass: 'max-h-[180px]',
      listitemClass: 'stop-click-outside',
      optionClass: 'stop-click-outside',
      emptyMessage: '$gettext("No suku found")',
      validation: 'required'
    },
    {
      $formkit: 'text',
      name: 'complainant_name',
      id: 'complainant_name',
      key: 'complainant_name',
      validation: 'required',
      label: '$gettext("Complainant\'s name")'
    },
    {
      $formkit: 'text',
      name: 'organization',
      id: 'organization',
      key: 'organization',
      validation: 'required',
      label: '$gettext("Complainant\'s  organization / institution")'
    },
    {
      $formkit: 'select',
      name: 'method',
      id: 'method',
      key: 'method',
      label: '$gettext("Complaint submission method")',
      placeholder: '$gettext("Select complaint submission method")',
      options: '$complaintsMethod',
      validation: 'required'
    },
    {
      $formkit: 'textarea',
      name: 'description',
      id: 'description',
      key: 'description',
      placeholder: '$gettext("Short description")'
    },
    {
      $formkit: 'select',
      name: 'category',
      id: 'category',
      key: 'category',
      label: '$gettext("Complaint category")',
      placeholder: '$gettext("Select complaint category")',
      options: '$complaintsCategories',
      validation: 'required'
    },
    {
      $formkit: 'text',
      name: 'complaint_object',
      id: 'complaint_object',
      key: 'complaint_object',
      validation: 'required',
      label: '$gettext("Complaint object")'
    }
  ]
  if (isEdit) {
    schema[1] = {
      $formkit: 'hidden',
      name: 'suku_id',
      key: 'suku_id',
      id: 'suku_id'
    }
  }
  return schema
}

export const complaintVerificationSchemaIn: FormKitSchemaFormKit[] = [
  generateDatepicker({
    name: 'verified_date',
    id: 'verified_date',
    key: 'verified_date',
    validation: 'required',
    valueFormat: 'YYYY-MM-DD',
    label: '$gettext("Verification date")'
  }),
  {
    $formkit: 'radio',
    id: 'case_opened',
    key: 'case_opened',
    name: 'case_opened',
    label: '$gettext("Complaint result")',
    optionsClass: 'flex',
    optionClass: 'mr-5',
    options: '$caseOpenedOptions'
  },
  {
    $formkit: 'textarea',
    name: 'process_details',
    id: 'process_details',
    key: 'process_details',
    label: '$gettext("Process details")',
    help: '$gettext("Description of each process")'
  }
]
