<script setup lang="ts">
import BasicCard from '../../components/commons/BasicCard.vue'
import IconBox from '../../components/commons/IconBox.vue'
import TooltipBox from '../../components/commons/TooltipBox.vue'
import { computed } from 'vue'
import downloadElement_ from '../../_helpers/downloadElement'
import { sumOfField } from '../../_helpers/sumfields.helpers'
import LoadingIcon from '../../components/commons/LoadingIcon.vue'

const props = defineProps<{
    idaProjects: {project_progress?: number, numberofdaysworked: number}[]
    locationName: string
    /** Projects */
    projects: {project_progress?: number}[]
    /** Workers */
    workers: {numberofdaysworked?: number }[]
}>()

const workingDays = computed(() => sumOfField(props.workers, 'numberofdaysworked') + sumOfField(props.idaProjects, 'numberofdaysworked'))
const totalProjects = computed(() => props.projects.length + props.idaProjects.length)
const totalProjectProgress = computed(() => sumOfField(props.projects, 'project_progress') + sumOfField(props.idaProjects, 'project_progress'))

// The number of projects which have a 'project_progress' value
const totalProjectsWithCompletion = computed(() => {
  const func = (it: {project_progress?: number}) => typeof it.project_progress === 'number' && it.project_progress > 0
  return props.projects.filter(func).length + props.idaProjects.filter(func).length
})
const averageProgress = computed(() => {
  const averageProgress = totalProjectsWithCompletion.value ? totalProjectProgress.value / totalProjectsWithCompletion.value : 0
  const progress = parseFloat(averageProgress.toFixed(2)) === averageProgress ? averageProgress : averageProgress.toFixed(2)
  return Number(progress)
})

const downloadElement = (elementId: string) => {
  const prefix = props.locationName
  downloadElement_({ elementId, prefix })
}

</script>

<template>
  <div class="mb-8 lg:mb-[60px]">
    <div class="flex items-center mb-9">
      <h1 class="text-2.5xl font-bold">
        {{ gettext('Projects') }}
      </h1>
      <div class="rounded-full border border-zinc-400 bg-zinc-400/50 h-9 flex text-xl px-2 ml-6 hover:bg-zinc-400/20 hover:border-gray-100">
        <tooltip-box :text="gettext('This sub-section shows specific information about the ongoing projects in the Suku')">
          <button class="px-1 hover:text-emerald-600">
            <i class="las la-info-circle"></i>
          </button>
        </tooltip-box>
        <tooltip-box :text="gettext('Download data as image')">
          <button
            class="px-1 hover:text-emerald-600"
            @click="downloadElement('projects-graph')"
          >
            <i class="las la-download"></i>
          </button>
        </tooltip-box>
      </div>
    </div>
    <div id="projects-graph">
      <div
        class="grid md:grid-cols-3 lg:max-w-5xl gap-[30px]"
      >
        <basic-card class="text-center px-12 xl:px-14 pb-9 h-full w-full ">
          <icon-box class="mx-auto bg-[#FF6ECB] mt-4">
            <i class="las la-hard-hat"></i>
          </icon-box>
          <h4 class="text-xl font-bold mt-3 leading-tight">
            {{ gettext("Number of projects") }}
          </h4>
          <h1 class="mt-2 text-4.5xl font-black">
            {{ totalProjects }}
          </h1>
        </basic-card>
        <basic-card class="text-center px-12 xl:px-14 pb-9 h-full w-full ">
          <icon-box class="mx-auto bg-[#5E66DE] mt-4">
            <i class="las la-sun"></i>
          </icon-box>
          <h4 class="text-xl font-bold mt-3 leading-tight">
            {{ gettext("Number of working days") }}
          </h4>
          <h1 class="mt-2 text-4.5xl font-black">
            {{ workingDays }}
          </h1>
        </basic-card>
        <basic-card class="text-center px-4 pb-9 h-full w-full">
          <LoadingIcon
            :progress="averageProgress"
          />
          <h4 class="text-xl font-bold mt-3 leading-tight">
            {{ gettext("Physical project progress") }}
          </h4>
          <h1 class="mt-2 text-4.5xl font-black">
            {{ averageProgress }}%
          </h1>
        </basic-card>
      </div>
    </div>
  </div>
</template>
