<script lang="ts" setup>
import { ref, onMounted } from 'vue'

withDefaults(defineProps<{
  device?: string
}>(), {
  device: 'all'
})

const wrapper = ref<HTMLDivElement | null>(null)
const parent = ref<HTMLDivElement | null>(null)
const wrapperWidth = ref('100%')
const isFixed = ref(false)
const fixedStyle: Record<string, string> = {
  all: 'fixed',
  desktop: 'lg:fixed',
  tablet: 'md:fixed lg:relative',
  mobile: 'fixed md:relative'
}

const handleScroll = () => {
  if (!wrapper.value) {
    return
  }

  if (window.pageYOffset > wrapper.value.offsetTop) {
    isFixed.value = true
    wrapperWidth.value = parent.value?.offsetWidth + 'px'
  } else {
    isFixed.value = false
    wrapperWidth.value = '100%'
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll, { passive: true })
  window.addEventListener('resize', handleScroll, { passive: true })
})
</script>

<template>
  <div ref="parent">
    <div
      ref="wrapper"
      :class="isFixed ? `${fixedStyle[device]} top-4` : 'relative'"
      :style="{ width: wrapperWidth }"
    >
      <slot></slot>
    </div>
  </div>
</template>
