<script setup lang="ts">
import { computed, reactive } from 'vue'
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import BasicButton from '../components/commons/BasicButton.vue'
import DataTable from '../components/commons/DataTable.vue'

import type { Header } from 'vue3-easy-data-table'

import { useAuthStore } from '../stores/auth'
import { useGroupStore, StoreState } from '../stores/groups'
import { useI18NStore } from '../stores/i18n'

const authStore = useAuthStore()
const groupStore = useGroupStore()

groupStore.initialize()

const headers: Header[] = reactive([
  { text: 'Name', value: 'name', sortable: true }
])

const groupItems = computed(() => {
  return groupStore.groups
    ? groupStore.groups.map((group) => {
      return {
        name: useI18NStore().gettext(group.name),
        redirectParams: { id: group.id }
      }
    })
    : []
})
</script>

<template>
  <base-layout
    :title="gettext('Groups')"
    :sub-title="gettext('For admin users, allows managing and assigning users into groups with different permissions to access features in Ida for easier collaboration and access control.')"
  >
    <content-section>
      <DataTable
        v-if="groupStore.state === StoreState.IDLE"
        :headers="headers"
        :items="groupItems"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl top-[2px] relative !text-zinc-900"
        filter-size="lg"
        redirect-path="group"
        theme-color="dark"
      >
        <template #header-action>
          <BasicButton
            v-if="authStore.can('auth.add_group')"
            :to="{ name:'add_group'}"
          >
            <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('Add') }}
          </BasicButton>
        </template>
      </DataTable>
    </content-section>
  </base-layout>
</template>
