<script setup lang="ts">
import { computed } from 'vue'
import { components } from '../../_api-services/openapi'
import ProjectCharts from './ProjectCharts.vue'
import { filterBySector } from './helpers'
const props = defineProps<{dataFunction: Promise<components['schemas']['LegacyProfileData'] | undefined>, locationName?: string, sectorId: number | undefined, idaProjects: {project_progress?: number, numberofdaysworked: number}[] }>()
const data = await props.dataFunction

const projects = computed(
  () => {
    if (props.sectorId === 0) return data?.projects ?? []
    return filterBySector((data?.projects ?? []), props.sectorId)
  }
)

const projectIds = computed(() => new Set(projects.value.map((p) => p.suco_subproject_id)))
const workers = computed(() => { return (data?.workers ?? []).filter(it => projectIds.value.has(it.subproject_id)) })

</script>
<template>
  <Transition appear>
    <project-charts
      v-if="data"
      :location-name="locationName ?? 'unknown'"
      :projects="projects"
      :ida-projects="idaProjects"
      :workers="workers"
    />
  </Transition>
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
