type componentTheme = 'default' | 'success' | 'warning' | 'error'

const LABELTEXT_THEMES: Record<componentTheme, string> = {
  default: 'bg-zinc-400/[.45] border-zinc-400',
  success: 'bg-emerald-200/[.85] border-green-300',
  warning: 'bg-amber-400/[.45] border-amber-400',
  error: 'bg-red-300/[.25] border-red-300'
}

export type { componentTheme }
export { LABELTEXT_THEMES }
