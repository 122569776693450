<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import AngleSolid from '../../components/icons/AngleSolid.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import PreparationBudget from '../../components/sukumanager/PreparationBudget.vue'
import MonthlyReport from '../../components/sukumanager/MonthlyReport.vue'
import NavTabs from '../../components/commons/NavTabs.vue'
import { ref, Ref, computed, ComputedRef, toRaw, onUpdated, nextTick, onMounted } from 'vue'
import { components } from '../../_api-services/openapi'
import { useGeoLocationStore } from '../../stores/geolocation'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import { useI18NStore } from '../../stores/i18n'
import { useSukuBudgetStore } from '../../stores/sukubudget'
import { useAppStore } from '../../stores/app'
import { useAuthStore } from '../../stores/auth'
import { SUKU_BUDGET_STATUS, SUKU_BUDGET_TYPE, SUKU_BUDGET_TYPES } from '../../_constants/sukumanager.constant'
import { useRouter } from 'vue-router'
import { useModal } from '../../stores/modal'
import { FORM_TYPES } from '../../_constants/common.constant'
import { useOptionsStore } from '../../stores/options'
import { useToast } from 'vue-toastification'

const i18nstore = useI18NStore()
const toast = useToast()
const modal = useModal()
const geolocationStore = useGeoLocationStore()
const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)
const sukuBudgetStore = useSukuBudgetStore()
const appStore = useAppStore()
const authStore = useAuthStore()
const router = useRouter()
const optionsStore = useOptionsStore()

const activeBudgetName: ComputedRef<SUKU_BUDGET_TYPES> = computed(() => router.currentRoute?.value.params?.section as SUKU_BUDGET_TYPES) // get a params from url to use as budget name

const showAllocationDetailModal: Ref<boolean> = ref(false)

await optionsStore.initialize()

const preparationData = computed(() => {
  return sukuBudgetStore.getPreparationData
})

const allocationDetailValues = ref()
const activePreparationData: Ref<components['schemas']['Pom_1Schema'] | undefined > = ref()

const operationalBudgetReviewValues: Ref<{ last_year_balance: number, annual_subsidy_allocated: number}> = ref({
  last_year_balance: 0,
  annual_subsidy_allocated: 0
})

const totalAllocationDifference = computed(() => {
  const totalBudget = operationalBudgetReviewValues.value.annual_subsidy_allocated + operationalBudgetReviewValues.value.last_year_balance
  if (activeBudgetName.value === SUKU_BUDGET_TYPE.infrastructure) {
    return totalBudget - Number(allocationDetailValues.value?.total_infrastructure_fund ?? 0)
  } else {
    return totalBudget - Number(allocationDetailValues.value.total_amount ?? 0)
  }
})

type SukuBudgetStatusValue = (typeof SUKU_BUDGET_STATUS)[keyof typeof SUKU_BUDGET_STATUS] // '1' | '2' | '3'

/**
 * get active budget status
 */
const activeBudgetStatus: ComputedRef<SukuBudgetStatusValue | ''> = computed(() => {
  if (!activePreparationData.value) return ''
  return activePreparationData.value[activeBudgetName.value === SUKU_BUDGET_TYPE.infrastructure ? 'pom1_infrastructure_status' : 'pom1_operational_status'] as SukuBudgetStatusValue
})

await sukuBudgetStore.initialize()

// listen if the page was re-routed
onUpdated(() => {
  appStore.$state.loading = true
  sukuBudgetStore.$state.activeBudget = activeBudgetName.value // set the active budget
  nextTick(() => {
    appStore.$state.loading = false
  })
})

onMounted(async () => {
  sukuBudgetStore.$state.activeBudget = activeBudgetName.value // set the active budget
  // if there is no data for POM1 and FF4 it will show a modal
  await nextTick()
  if (preparationData.value.length < 1) {
    const currentSukuId = router?.currentRoute.value.params.id as string
    modal.open('info', {
      modalTitle: 'No data available',
      modalCaption: 'You must have a verified POM1 and FF12 form before using this feature',
      hideModalClose: true,
      actions: [
        {
          label: 'Back',
          buttonTheme: 'primary',
          icon: 'la-angle-left',
          buttonClass: 'mb-5',
          callback: () => {
            modal.close()
            router.push(`/suku-manager/${currentSukuId}/`)
          }
        }
      ]
    })
  }
})
const onTabChange = async (val: string) => {
  if (val === 'allocation' || val === 'monthlyreporttab') {
    // If 'val' is 'allocation', await the 'fetchAllocationData' function from 'sukuBudgetStore' to get allocation data.
    await sukuBudgetStore.fetchAllocationData()
  } else if (val === 'preparation') {
    await sukuBudgetStore.initialize()
  }
  document.body.style.paddingBottom = val === 'monthlyreporttab' ? '90px' : '0px'
}

/**
 * show allocation budget detail
 * @param index number
 */
const showAllocationDataDetail = async (index: number) => {
  // Get the 'selectedData' from the 'allocationData' array at the specified 'index'.
  const selectedData = preparationData.value[index]
  activePreparationData.value = sukuBudgetStore.getPom1SubmissionByYear(selectedData.year)
  // Get allocation data from FF4 submission
  await sukuBudgetStore.fetchAllocationData()
  // filter allocation data by `year`
  const allocationDetail = sukuBudgetStore.getAllocationDataByYear(selectedData.year)

  if (allocationDetail === undefined) { // if there is no FF4
    toast.warning(i18nstore.gettext('There is no FF4 submission that being submitted on this period'))
  } else {
    // Set the 'showAllocationDetailModal' value to true. so modal will displayed
    showAllocationDetailModal.value = true

    // Set values of allocation budget on the allocation modal
    allocationDetailValues.value = toRaw(allocationDetail)

    // Set values of last year balance and annual subsidy allocated on the allocation modal
    operationalBudgetReviewValues.value = {
      last_year_balance: ((selectedData.brought_forward_bank ?? 0) + (selectedData.brought_forward_cash ?? 0)),
      annual_subsidy_allocated: (selectedData.subsidy_allocated ?? 0)
    }
  }
}

/**
 * update suku budget status, will update pom1 status
 * @param status string
 */
const updateBudgetStatus = async (status: string) => {
  if (!activePreparationData.value) return
  await sukuBudgetStore.updateBudgetStatus(FORM_TYPES.POM1, status, activePreparationData.value)
  showAllocationDetailModal.value = false
}

const saveAndNext = () => {
  document.getElementById('nav-tab-toggle-allocation')?.click()
}
</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 xl:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <nav-tabs
      :menus="[
        {
          label: 'Preparation',
          id: 'preparation',
          active: true
        },
        {
          label: 'Allocation',
          id: 'allocation'
        },
        {
          label: 'Monthly report',
          id: 'monthlyreporttab'
        }
      ]"
      @click="onTabChange"
    >
      <template #preparation>
        <preparation-budget
          :preparation-data="preparationData"
          :active-budget-name="activeBudgetName"
          @save-and-next="saveAndNext"
        />
      </template>
      <template #allocation>
        <div class="mb-10 mt-[59px] flex items-center">
          <h1 class="text-2.5xl font-black first-letter:uppercase">
            {{ interpolate(gettext('%(section)s management'), {
              section: gettext(activeBudgetName.replace(/-/, ' '))
            }, true) }}
          </h1>
        </div>
        <table
          v-if="preparationData.length > 0"
          class="table w-full text-left table-primary rounded-2.5xl overflow-hidden"
        >
          <thead class="text-3xs">
            <tr>
              <th>
                {{ gettext('Year') }}
              </th>
              <th>
                {{ interpolate(gettext('Total %(section)s (USD)'), {
                  section: gettext(activeBudgetName.replace(/-/, ' '))
                }, true) }}
              </th>
              <th>
                {{ gettext('Status') }}
              </th>
              <th class="w-[118px] text-center">
                {{ gettext('Visualize and activate') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in preparationData"
              :key="index"
            >
              <td class="text-xl">
                {{ data.year }}
              </td>
              <td class="text-3xs font-medium">
                {{ formatCurrencyWithDollar(data.total_budget_allocated ?? 0) }}
              </td>
              <td class="text-2sm">
                <div
                  v-if="data.status === SUKU_BUDGET_STATUS.active"
                  class="flex items-center text-2sm"
                >
                  <span class="w-5 h-5 mr-2.5 rounded-[4px] bg-emerald-400/10 inline-flex items-center justify-center text-emerald-400 text-sm">
                    <i class="las la-check"></i>
                  </span>
                  {{ gettext('Active') }}
                </div>
                <div
                  v-else-if="data.status === SUKU_BUDGET_STATUS.closed"
                  class="flex items-center text-2sm"
                >
                  <span class="w-5 h-5 mr-2.5 rounded-[4px] bg-pink-700/10 inline-flex items-center justify-center text-pink-700 text-sm">
                    <i class="las la-times"></i>
                  </span>
                  {{ gettext('Closed') }}
                </div>
                <div
                  v-else
                  class="flex items-center text-2sm"
                >
                  <span class="w-5 h-5 mr-2.5 rounded-[4px] bg-blue-300/10 inline-flex items-center justify-center text-blue-300 text-sm">
                    <i class="las la-ellipsis-h"></i>
                  </span>
                  {{ gettext('Inactive') }}
                </div>
              </td>
              <td class="text-center">
                <button @click="showAllocationDataDetail(index)">
                  <AngleSolid />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-else
          class="text-sm font-light"
        >
          {{ gettext('No data available') }}
        </p>
      </template>
      <template #monthlyreporttab>
        <MonthlyReport />
      </template>
    </nav-tabs>
    <modal-box
      v-if="showAllocationDetailModal"
      size="xl"
      spacing="md"
    >
      <template #header>
        <h2 class="text-1.5xl font-black">
          <i class="las la-coins text-gray-400 mr-1"></i>
          <span class="first-letter:uppercase inline-block">
            {{ interpolate(gettext('%(section)s allocation review'), {
              section: gettext(activeBudgetName.replace(/-/, ' '))
            }, true) }}
          </span>
        </h2>

        <div class="flex items-center justify-between mt-5">
          <p class="font-medium flex items-center w-[60%]">
            {{ gettext('Last year`s balance') }}
          </p><div class="w-[40%] border border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(operationalBudgetReviewValues.last_year_balance) }}
          </div>
        </div>
        <div class="flex items-center justify-between mt-[11px]">
          <p class="font-medium flex items-center w-[60%]">
            {{ gettext('Annual subsidy allocated') }}
          </p><div class="w-[40%] border border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(operationalBudgetReviewValues.annual_subsidy_allocated) }}
          </div>
        </div>
        <div class="flex items-center justify-between mt-5 rounded-full bg-zinc-400 h-10">
          <p class="font-black inline-flex items-center w-[60%] pl-4">
            {{ interpolate(gettext('Total %(section)s'), {
              section: gettext(activeBudgetName.replace(/-/, ' '))
            }, true) }}
          </p><h5 class="text-xl font-medium inline-flex items-center h-10 border-l border-l-zinc-450 w-[40%] pl-2">
            {{ formatCurrencyWithDollar(operationalBudgetReviewValues.annual_subsidy_allocated + operationalBudgetReviewValues.last_year_balance) }}
          </h5>
        </div>
      </template>
      <button
        class="w-7.5 h-7.5 rounded-full bg-emerald-600 text-white inline-flex items-center justify-center text-xl fixed top-6 right-6"
        @click="() => showAllocationDetailModal = false"
      >
        <i class="las la-times"></i>
      </button>
      <h2 class="text-1.5xl font-black mt-5">
        <i class="las la-coins text-gray-400 mr-1"></i>
        <span class="first-letter:uppercase inline-block">
          {{ interpolate(gettext('%(section)s fund'), {
            section: gettext(activeBudgetName.replace(/-/, ' '))
          }, true) }}
        </span>
      </h2>

      <div
        v-if="activeBudgetName === SUKU_BUDGET_TYPE.operational"
      >
        <div class="flex items-center justify-between mt-5">
          <p class="font-medium flex items-center w-[60%]">
            {{ gettext('2000 - Community Meeting') }}
          </p>
          <div class="w-[40%] border border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(allocationDetailValues.community_meetings ?? 0) }}
          </div>
        </div>
        <div class="flex items-center justify-between mt-5">
          <p class="font-medium flex items-center w-[60%]">
            {{ gettext('2001 - Community Training') }}
          </p>
          <div class="w-[40%] border border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(allocationDetailValues.community_training ?? 0) }}
          </div>
        </div>
        <div class="flex items-center justify-between mt-5">
          <p class="font-medium flex items-center w-[60%]">
            {{ gettext('2002 - Suco Incentive') }}
          </p>
          <div class="w-[40%] border border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(allocationDetailValues.labour_incentive ?? 0) }}
          </div>
        </div>
        <div class="flex items-center justify-between mt-5">
          <p class="font-medium flex items-center w-[60%]">
            {{ gettext('2003 - Project Administrative Cost') }}
          </p>
          <div class="w-[40%] border border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(allocationDetailValues.project_admin_cost ?? 0) }}
          </div>
        </div>
        <div class="flex items-center justify-between mt-5 rounded-full bg-zinc-400 h-10">
          <p class="font-black inline-flex items-center w-[60%] pl-4">
            {{ gettext('Total operational budget') }}
          </p><h5 class="text-xl font-medium inline-flex items-center h-10 border-l border-l-zinc-450 w-[40%] pl-2">
            {{ formatCurrencyWithDollar(allocationDetailValues.total_amount) }}
          </h5>
        </div>
      </div>

      <div
        v-else-if="activeBudgetName === SUKU_BUDGET_TYPE.infrastructure"
      >
        <table class="table-primary table-primary-narrow w-full mt-5 ">
          <thead>
            <tr>
              <th>
                {{ gettext('Project name') }}
              </th>
              <th>
                {{ gettext('3000 - Materials') }}
              </th>
              <th class="max-w-[120px]">
                {{ gettext('3001 - Labour Incentive') }}
              </th>
              <th>
                {{ gettext('Total') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(allocation, index) in allocationDetailValues?.allocationFund"
              :key="index"
            >
              <tr>
                <td
                  colspan="4"
                  class="!py-2 font-semibold"
                >
                  {{ optionsStore.getLabel('cycle', allocation.activity_id.toString()) }}
                </td>
              </tr>
              <tr
                v-for="(fund, i) in allocation.funds"
                :key="i"
              >
                <td>{{ optionsStore.getLabel('project', fund.project, '-') }}</td>
                <td class="text-center">
                  {{ formatCurrencyWithDollar(fund.materials) }}
                </td>
                <td class="text-center">
                  {{ formatCurrencyWithDollar(fund.labour_incentive_3001) }}
                </td>
                <td class="text-center">
                  {{ formatCurrencyWithDollar(fund.total) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="flex items-center justify-between mt-5 rounded-full bg-zinc-400 h-10">
          <p class="font-black inline-flex items-center w-[60%] pl-4">
            {{ gettext("Total infrastructure budget") }}
          </p><h5 class="text-xl font-medium inline-flex items-center h-10 border-l border-l-zinc-450 w-[40%] pl-2">
            {{ formatCurrencyWithDollar(allocationDetailValues?.total_infrastructure_fund ?? 0) }}
          </h5>
        </div>
      </div>

      <div class="flex items-center justify-between mt-8 rounded-2.5xl bg-red-300/25 ">
        <p class="font-black inline-flex items-center w-[60%] pl-4">
          {{ gettext("Total allocation difference") }}
        </p>
        <div class="border-l border-l-red-300/25 w-[40%] px-4 py-4">
          <p class="text-xs font-bold uppercase text-red-300">
            {{ gettext('ALLOCATION OVER') }}
          </p>
          <h5 class="text-xl font-medium">
            {{ formatCurrencyWithDollar(totalAllocationDifference) }}
          </h5>
        </div>
      </div>

      <div
        v-if="authStore.canAny(['ida_forms.add_pom_1', 'ida_forms.change_pom_1'])"
        class="flex items-center justify-center flex-wrap mt-8 mb-4"
      >
        <basic-button
          class="rounded-full"
          :disabled="activeBudgetStatus === SUKU_BUDGET_STATUS.active"
          @click="modal.open('info', {
            label: 'Activate budget',
            modalTitle: 'Are you sure?',
            modalCaption: 'Do you really want to activate this budget? This process cannot be undone',
            callback: async () => {
              updateBudgetStatus(SUKU_BUDGET_STATUS.active)
              modal.close()
            }
          })"
        >
          <i class="las la-check text-2xl mr-4"></i>
          {{ gettext('Activate budget') }}
        </basic-button>
        <basic-button
          theme="red"
          class="rounded-full mt-4 md:mt-0 md:ml-3"
          :disabled="activeBudgetStatus === SUKU_BUDGET_STATUS.closed"
          @click="modal.open('confirm', {
            label: 'Close fiscal year',
            modalTitle: 'Are you sure?',
            modalCaption: 'Do you really want to close this fiscal year? This process cannot be undone',
            callback: async () => {
              updateBudgetStatus(SUKU_BUDGET_STATUS.closed)
              modal.close()
            }
          })"
        >
          <i class="las la-times text-2xl mr-4"></i>
          {{ gettext('Close fiscal year') }}
        </basic-button>
      </div>
    </modal-box>
  </base-layout>
</template>
