<template>
  <span class="inline-block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
    <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
      <slot name="prefixLabel"></slot>
    </span>
    <span class="px-3 py-3.5 inline-block">
      <slot name="suffixLabel"></slot>
    </span>
  </span>
</template>
