<script setup lang="ts">
import TableDiv from '../../components/commons/table/TableDiv.vue'
import TableDivRow from '../../components/commons/table/TableDivRow.vue'
import TableDivTh from '../../components/commons/table/TableDivTh.vue'
import TableDivTd from '../../components/commons/table/TableDivTd.vue'
import { mapByYearMonth, getMonthName } from '../../_helpers/timedata.helpers'
import { components } from '../../_api-services/openapi'
import { computed } from 'vue'

const props = defineProps<{
  workerSubmissions: Array<components['schemas']['ProjectInfo_Sf_6_2']>
}>()

const workers = computed(() => mapByYearMonth(props.workerSubmissions))
const noData = computed(() => Array.from(workers.value.keys()).length === 0)

</script>

<template>
  <h2 class="font-bold text-2.5xl mb-5 mt-14">
    {{ gettext('Workers') }}
  </h2>
  <p v-if="noData">
    {{ gettext('No data available') }}
  </p>
  <table-div
    v-else
    class="relative text-center table-expenditures"
  >
    <table-div-row>
      <table-div-th class="text-3xs">
        {{ gettext('Month') }}
      </table-div-th>
      <table-div-th class="!px-0 !py-0">
        <table-div-row>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of female workers') }}
          </table-div-th>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of male workers') }}
          </table-div-th>
          <table-div-th class="text-3xs !px-2 2xl:!px-4 w-[20%]">
            {{ gettext('Total') }}
          </table-div-th>
        </table-div-row>
      </table-div-th>
      <table-div-th class="!px-0 !py-0">
        <div class="text-3xs relative top-3 uppercase text-zinc-400">
          {{ gettext('People with disabilities') }}
        </div>
        <table-div-row>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of female workers') }}
          </table-div-th>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of male workers') }}
          </table-div-th>
          <table-div-th class="text-3xs !px-2 2xl:!px-4 w-[20%]">
            {{ gettext('Total') }}
          </table-div-th>
        </table-div-row>
      </table-div-th>
      <table-div-th class="!px-0 !py-0">
        <div class="text-3xs relative top-3 uppercase text-zinc-400">
          {{ gettext('New workers') }}
        </div>
        <table-div-row>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of female workers') }}
          </table-div-th>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of male workers') }}
          </table-div-th>
          <table-div-th class="text-3xs !px-2 2xl:!px-4 w-[20%]">
            {{ gettext('Total') }}
          </table-div-th>
        </table-div-row>
      </table-div-th>
      <table-div-th class="!px-0 !py-0">
        <div class="text-3xs relative top-3 uppercase text-zinc-400">
          {{ gettext('No. of working days') }}
        </div>
        <table-div-row>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of female workers') }}
          </table-div-th>
          <table-div-th class="!border-r-0 text-3xs !px-2 2xl:!px-4 w-[40%]">
            {{ gettext('No. of male workers') }}
          </table-div-th>
          <table-div-th class="text-3xs !px-2 2xl:!px-4 w-[20%]">
            {{ gettext('Total') }}
          </table-div-th>
        </table-div-row>
      </table-div-th>
    </table-div-row>
    <template
      v-for="[year, yearData] in workers"
      :key="year"
    >
      <div class="relative">
        <p class="absolute text-lg font-black top-[32px] -left-14">
          {{ year }}
        </p>
      </div>
      <table-div-row
        v-for="[month, monthData] in yearData"
        :key="month"
        class="text-xl"
      >
        <table-div-td class="text-3xs">
          <p>{{ getMonthName(month) }}</p>
        </table-div-td>
        <table-div-td class="!px-0">
          <table-div class="w-full">
            <table-div-td class="!bg-white !px-2 2xl:!px-4 !border-0 w-[40%]">
              {{ monthData.get_sum('workers_female') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[40%]">
              {{ monthData.get_sum('workers_male') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[20%] font-bold">
              {{ monthData.get_sum('workers_female') + monthData.get_sum('workers_male') }}
            </table-div-td>
          </table-div>
        </table-div-td>
        <table-div-td class="!px-0">
          <table-div>
            <table-div-td class="!bg-white !border-0 !px-2 2xl:!px-4 w-[40%]">
              {{ monthData.get_sum('workers_with_disability_female') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[40%]">
              {{ monthData.get_sum('workers_with_disability_male') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[20%] font-bold">
              {{ monthData.get_sum('workers_with_disability_female') + monthData.get_sum('workers_with_disability_male') }}
            </table-div-td>
          </table-div>
        </table-div-td>
        <table-div-td class="!px-0">
          <table-div>
            <table-div-td class="!bg-white !border-0 !px-2 2xl:!px-4 w-[40%]">
              {{ monthData.get_sum('no_of_new_workers_female') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[40%]">
              {{ monthData.get_sum('no_of_new_workers_male') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[20%] font-bold">
              {{ monthData.get_sum('no_of_new_workers_female') + monthData.get_sum('no_of_new_workers_male') }}
            </table-div-td>
          </table-div>
        </table-div-td>
        <table-div-td class="!px-0">
          <table-div>
            <table-div-td class="!bg-white !border-0 !px-2 2xl:!px-4 w-[40%]">
              {{ monthData.get_sum('no_of_working_days_female') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[40%]">
              {{ monthData.get_sum('no_of_working_days_male') }}
            </table-div-td>
            <table-div-td class="!border-0 !px-2 2xl:!px-4 w-[20%] font-bold">
              {{ monthData.get_sum('no_of_working_days_female') + monthData.get_sum('no_of_working_days_male') }}
            </table-div-td>
          </table-div>
        </table-div-td>
      </table-div-row>
    </template>
    <div class="h-7.5"></div>
    <table-div-row
      class="text-xl"
    >
      <table-div-td class="text-3xs font-black !bg-zinc-400 !border-r-zinc-450 rounded-tl-2.5xl">
        {{ gettext('Total') }}
      </table-div-td>
      <table-div-td class="!px-0 !border-r-zinc-450 !bg-zinc-400">
        <table-div class="w-full !bg-zinc-400">
          <table-div-td class="!bg-transparent !px-2 2xl:!px-4 !border-0 w-[40%] border-none">
            {{ workers.get_sum('workers_female') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[40%] border-none">
            {{ workers.get_sum('workers_male') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[20%] font-bold border-none">
            {{ workers.get_sum('workers_female') + workers.get_sum('workers_male') }}
          </table-div-td>
        </table-div>
      </table-div-td>
      <table-div-td class="!px-0 !bg-zinc-400 !border-r-zinc-450">
        <table-div>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[40%] border-none">
            {{ workers.get_sum('workers_with_disability_female') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[40%] border-none">
            {{ workers.get_sum('workers_with_disability_male') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[20%] font-bold border-none">
            {{ workers.get_sum('workers_with_disability_female') + workers.get_sum('workers_with_disability_male') }}
          </table-div-td>
        </table-div>
      </table-div-td>
      <table-div-td class="!px-0 !bg-zinc-400 !border-r-zinc-450">
        <table-div>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[40%] border-none">
            {{ workers.get_sum('no_of_new_workers_female') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !px-2 2xl:!px-4 w-[40%] border-none">
            {{ workers.get_sum('no_of_new_workers_male') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !px-2 2xl:!px-4 w-[20%] font-bold border-none">
            {{ workers.get_sum('no_of_new_workers_female') + workers.get_sum('no_of_new_workers_male') }}
          </table-div-td>
        </table-div>
      </table-div-td>
      <table-div-td class="!px-0 !bg-zinc-400 !rounded-tr-2.5xl">
        <table-div>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[40%] border-none">
            {{ workers.get_sum('no_of_working_days_female') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !px-2 2xl:!px-4 w-[40%] border-none">
            {{ workers.get_sum('no_of_working_days_male') }}
          </table-div-td>
          <table-div-td class="!bg-transparent !border-0 !px-2 2xl:!px-4 w-[20%] font-bold border-none">
            {{ workers.get_sum('no_of_working_days_female') + workers.get_sum('no_of_working_days_male') }}
          </table-div-td>
        </table-div>
      </table-div-td>
    </table-div-row>
  </table-div>
</template>
