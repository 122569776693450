<script setup lang="ts">
import ModalBox from '../commons/modal/ModalBox.vue'
import { ref } from 'vue'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import BasicButton from '../commons/BasicButton.vue'
import { components } from '../../_api-services/openapi'
import { useOptionsStore } from '../../stores/options'
import { InfrastructureBalanceSchema } from '../../stores/sukubudget'

const optionsStore = useOptionsStore()

type FF12RepeaterInfrastructureFundContext = components['schemas']['Cfm_12_ff_12RepeaterinfrastructurefundSchema']

defineProps<{
  reportingPeriod: string
  totalInfrastructureReceipts: number
  totalInfrastructureExpenditures: number
  infrastructureFund: FF12RepeaterInfrastructureFundContext[]
  infrastructureBalance: InfrastructureBalanceSchema
  totalBalance: number
}>()

const showModal = ref(false)

const toggleModal = (value: boolean) => {
  showModal.value = value
}

defineExpose({ toggleModal })
</script>
<template>
  <modal-box
    v-if="showModal"
    size="xl"
    spacing="md"
  >
    <template #header>
      <div class="flex justify-start items-center">
        <div class="w-[46px] h-[46px] rounded-[10px] inline-flex mr-5 items-center justify-center text-white text-2xl bg-[#92BA69]">
          <i class="las la-coins"></i>
        </div>
        <div>
          <h2 class="text-3.5xl font-black leading-10 mb-2 first-letter:uppercase">
            {{ gettext('Infrastructure budget report') }}
          </h2>
          <span class="inline-block px-3 py-1 text-sm border bg-zinc-400/50 border-zinc-400 rounded-full">
            <i class="lar la-calendar"></i> <span class="font-semibold">{{ gettext('Reporting period') }}</span> {{ reportingPeriod }}
          </span>
        </div>
      </div>

      <h2 class="text-1.5xl font-bold mt-6">
        <i class="las la-file-invoice-dollar text-gray-400 mr-2"></i>
        {{ gettext('Infrastructure receipts') }}
      </h2>
      <ul class="mt-2.5 mb-3.5">
        <li class="mt-4">
          <p class="font-bold">
            {{ gettext('1003 - PNDS Infrastructure subsidy') }}
          </p>
        </li>
        <li class="flex items-center rounded-full mt-5 bg-zinc-400 px-5">
          <p class="w-1/2 font-bold">
            {{ gettext('Total receipts (USD)') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="font-semibold border-l border-l-zinc-450 w-full border-zinc-400 px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(totalInfrastructureReceipts) }}
            </div>
          </div>
        </li>
      </ul>
    </template>
    <button
      class="w-7.5 h-7.5 rounded-full bg-emerald-600 text-white inline-flex items-center justify-center text-xl fixed top-6 right-6"
      @click="() => showModal = false"
    >
      <i class="las la-times"></i>
    </button>

    <h2 class="text-1.5xl font-bold">
      <i class="las la-money-check-alt text-gray-400 mr-2"></i>
      {{ gettext('Infrastructure expenditures') }}
    </h2>
    <table class="table table-primary table-primary-narrow mt-5 w-full text-left">
      <thead>
        <tr>
          <th class="w-1/4">
            {{ gettext('Project name') }}
          </th>
          <th class="w-1/4">
            {{ gettext('3000 - Materials') }}
          </th>
          <th class="w-1/4">
            {{ gettext('3001 - Labour Incentive') }}
          </th>
          <th class="w-1/4">
            {{ gettext('Total') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in infrastructureFund"
          :key="index"
        >
          <td>
            {{ optionsStore.getLabel('project', item.project, '-') }}
          </td>
          <td>
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(item.materials_3000 ?? 0) }}
            </div>
          </td>
          <td>
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(item.labour_incentive_3001 ?? 0) }}
            </div>
          </td>
          <td>
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(Number(item?.labour_incentive_3001 ?? 0) + Number(item?.materials_3000 ?? 0)) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ul class="mt-2.5 mb-3.5">
      <li class="flex items-center rounded-full mt-5 bg-zinc-400 px-5">
        <p class="w-3/4 font-bold">
          {{ gettext('Total infrastructure expenditures (USD)') }}
        </p>
        <div class="w-1/4 pl-2.5">
          <div class="font-semibold border-l border-l-zinc-450 w-full border-zinc-400 px-2.5 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(totalInfrastructureExpenditures) }}
          </div>
        </div>
      </li>
    </ul>

    <hr class="mt-10 mb-7 -mx-5 lg:-mx-9" />

    <h2 class="text-1.5xl font-bold">
      <i class="las la-balance-scale text-gray-400 mr-2"></i>
      <span class="first-letter:uppercase inline-block">
        {{ gettext('Infrastructure balance') }}
      </span>
    </h2>
    <ul class="mt-2.5 mb-3.5">
      <template
        v-for="(item, index) in infrastructureBalance"
        :key="index"
      >
        <li class="mt-2.5">
          <p class="font-bold">
            {{ optionsStore.getLabel('cycle', index) }}
          </p>
        </li>
        <li class="flex items-center mt-2.5">
          <p class="w-1/2">
            {{ gettext('Cash in hand') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(item.cash) }}
            </div>
          </div>
        </li>
        <li class="flex items-center mt-2.5">
          <p class="w-1/2">
            {{ gettext('Bank balance') }}
          </p>
          <div class="w-1/2 pl-5">
            <div class="border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center">
              {{ formatCurrencyWithDollar(item.bank) }}
            </div>
          </div>
        </li>
      </template>

      <li class="flex items-center rounded-full mt-5 bg-zinc-400 px-5">
        <p class="w-1/2 font-bold">
          {{ gettext('Total balance (USD)') }}
        </p>
        <div class="w-1/2 pl-5">
          <div class="font-semibold border-l border-l-zinc-450 w-full border-zinc-400 px-4 h-[40px] flex items-center">
            {{ formatCurrencyWithDollar(totalBalance) }}
          </div>
        </div>
      </li>
    </ul>

    <div class="flex items-center justify-center flex-wrap mt-8 mb-4">
      <basic-button
        class="!w-[254px] mt-4 md:mt-0 md:ml-3"
        @click="() => showModal = false"
      >
        <i class="las la-times text-2xl mr-4"></i>
        {{ gettext('Close') }}
      </basic-button>
    </div>
  </modal-box>
</template>
