import { defineStore } from 'pinia'
import { getCsrf } from '../_helpers/common.helpers'

export enum StoreState {
  INITIAL,
  QUERYING,
  ERRORED,
  IDLE
}

function getGroupMembershipPath (id: number): string {
  return `/accounts_api/membership/group/${id}`
}
function getUserMembershipPath (id: number): string {
  return `/accounts_api/membership/user/${id}`
}
function getUserJoinGroupPath (userId: number, groupId: number): string {
  return `/accounts_api/membership/user/${userId}/${groupId}`
}

interface MembershipState {
  state: StoreState
  user_memberships: Record<number, number[]>
  group_memberships: Record<number, number[]>
}

const initialState: MembershipState = {
  state: StoreState.INITIAL,
  user_memberships: {},
  group_memberships: {}
}

export const useMembershipStore = defineStore('membership', {
  state: () => (initialState),
  actions: {
    async updateGroupMembership (groupId: number) {
      const response = await fetch(
        getGroupMembershipPath(groupId)
      )
      if (response.ok) {
        const memberships = await response.json()
        Object.assign(
          this.group_memberships,
          memberships
        )
      }
    },

    async updateUserMembership (userId: number) {
      const response = await fetch(
        getUserMembershipPath(userId)
      )
      if (response.ok) {
        const memberships = await response.json()
        Object.assign(
          this.user_memberships,
          memberships
        )
      }
    },

    async addGroupToUser (groupId: number, userId: number): Promise<boolean> {
      const response = await fetch(
        getUserJoinGroupPath(userId, groupId),
        {
          method: 'POST',
          headers: {
            'X-CSRFToken': getCsrf()
          }
        }
      )
      if (response.ok) {
        this.user_memberships[userId].push(groupId)
      }
      return response.ok
    },

    async removeGroupFromUser (groupId: number, userId: number): Promise<boolean> {
      const response = await fetch(
        getUserJoinGroupPath(userId, groupId),
        {
          method: 'DELETE',
          headers: {
            'X-CSRFToken': getCsrf()
          }
        }
      )
      if (response.ok) {
        this.user_memberships[userId] = this.user_memberships[userId].filter((gid) => gid !== groupId)
      }
      return response.ok
    }
  }
})
