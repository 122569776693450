<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue'

interface DropdownGroupOptionsContext {
  label: string
  value: string
  icon?: string
}

withDefaults(defineProps<{
  label?: string
  options: DropdownGroupOptionsContext[]
}>(), {
  label: 'Label'
})

const dropdownWrapper = ref<HTMLDivElement | null>(null)
const dropdownToggle = ref<HTMLButtonElement | null>(null)
const dropdownWrapperPosition: {top: string, left: string} = reactive({
  top: '0px',
  left: '0px'
})
const isOpen = ref(false)
const emit = defineEmits <{(e: 'click', value: string): void }>()

const click = (item: DropdownGroupOptionsContext) => {
  isOpen.value = false
  // Emit an event to notify the parent component about the selected item
  emit('click', item.value)
}

const setDropdownPosition = () => {
  if (dropdownWrapper.value && dropdownToggle.value) {
    const toggleHeight = dropdownToggle.value.clientHeight
    dropdownWrapperPosition.left = dropdownWrapper.value.getBoundingClientRect().left + 'px'
    dropdownWrapperPosition.top = (dropdownWrapper.value.getBoundingClientRect().top + toggleHeight) + 'px'
  }
}

const open = () => {
  isOpen.value = !isOpen.value
  setDropdownPosition()
}

onMounted(() => {
  window.addEventListener('resize', () => {
    setDropdownPosition()
  }, { passive: true })

  window.addEventListener('scroll', () => {
    setDropdownPosition()
  }, { passive: true })
})
</script>

<template>
  <div
    ref="dropdownWrapper"
    v-click-outside="() => isOpen = false"
    class="min-h-[50px]"
  >
    <div
      class="relative"
      @click="open"
    >
      <button
        class="h-[50px] rounded-2.5xl border border-zinc-400 bg-[#FBFBF8] px-6 whitespace-nowrap text-base font-bold"
        :class="{'!rounded-bl-none !rounded-br-none absolute top-0 w-[182px] !text-left !bg-white left-0': isOpen}"
        ref="dropdownToggle"
      >
        {{ label }}
        <i
          class="las text-lg inline-block ml-2 relative"
          :class="isOpen ? 'la-angle-up top-[2px]' : 'la-angle-down'"
        ></i>
        <!-- {{ isOpen ? 'Close Dropdown' : 'Open Dropdown' }} -->
      </button>
      <ul
        v-if="isOpen"
        class="fixed bg-white border border-zinc-400 shadow-lg z-60 w-[182px] rounded-bl-2.5xl rounded-br-2.5xl"
        :style="dropdownWrapperPosition"
      >
        <li
          v-for="(option, index) in options"
          :key="index"
          class="px-3.5 py-2 hover:bg-stone-100 cursor-pointer border-b border-b-zin-400 last:border-b-0 text-sm font-bold"
          @click="click(option)"
        >
          <span
            v-if="option.icon"
            class="w-7.5 h-7.5 rounded-md bg-emerald-600 text-white inline-flex items-center justify-center mr-2.5"
          >
            <i
              class="las"
              :class="option.icon"
            ></i>
          </span>

          {{ gettext(option.label) }}
        </li>
      </ul>
    </div>
  </div>
</template>
