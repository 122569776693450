<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import DataTable from '../../components/commons/DataTable.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import { FormKitSchema, FormKit } from '@formkit/vue'
import type { Header, Item } from 'vue3-easy-data-table'
import { computed, ComputedRef, reactive, ref, Ref, toRaw } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useI18NStore } from '../../stores/i18n'
import { useLocationStore } from '../../stores/locations'
import { useAuthStore } from '../../stores/auth'
import { components } from '../../_api-services/openapi'
import { useOptionsStore } from '../../stores/options'
import { complaintsMethod, complaintsCategories, complaintStatus } from '../../_constants/options.constant'
import { useSukuComplaintStore } from '../../stores/sukucomplaint'
import { COMPLAINT_METHOD_CHOICE } from './complaints_enums'
import { complaintSchemaIn } from '../../_formkit/schema/complaint.schema'
import { OptionsType } from '../../_types/components/forms/select-dropdown'
import { useRouter } from 'vue-router'

const i18nstore = useI18NStore()
const locationStore = useLocationStore()
const sukuComplaintStore = useSukuComplaintStore()
const authStore = useAuthStore()
const optionsStore = useOptionsStore()
const router = useRouter()

await locationStore.initialize()
optionsStore.initialize()

const showCreateForm = ref(false)
type ComplaintSchemaIn = components['schemas']['ComplaintSchemaIn']
type ComplaintVerify = components['schemas']['ComplaintVerify']
type ComplaintSchemaOut = components['schemas']['ComplaintSchemaOut']

const headers: Header[] = reactive([
  { text: 'Date received', value: 'date', sortable: true },
  { text: 'Complainant\'s name', value: 'complaint_name', sortable: true },
  { text: 'Organization / institution', value: 'organization', sortable: true },
  { text: 'Suku', value: 'suco', sortable: true },
  { text: 'Duration', value: 'duration', sortable: true },
  { text: 'Last update', value: 'last_update', sortable: true },
  { text: 'Status', value: 'complaint_status' },
  { text: 'View', value: 'view' }
])

const items: ComputedRef<Item[]> = computed(() => {
  return sukuComplaintStore.getComplaintsTableData.map(complaint => ({
    ...complaint,
    redirectParams: {
      complaintId: complaint.redirectParams.id
    }
  }))
})

const isEdit = ref(false)

const geolocationStore = useGeoLocationStore()
const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)

const sukuId = computed(() => {
  const activeGeoLocation = geolocationStore.activeGeolocation?.properties
  const sukuId = activeGeoLocation?.id
  const zSucoId = typeof sukuId !== 'undefined' ? useLocationStore().convertLocationToZsuco(sukuId) : undefined
  return zSucoId
})

const getInitialValue = (): ComplaintSchemaIn => {
  return {
    suku_id: sukuId.value ?? 0,
    received: '', /* Date */
    organization: '',
    /** Method */
    method: COMPLAINT_METHOD_CHOICE.LETTER,
    /** Description */
    description: '',
    /** Category */
    category: 0,
    /** Complaint Object */
    complaint_object: '',
    /** Status */
    status: 1,
    /** Id */
    id: undefined,
    /** Complainant's name */
    complainant_name: ''
  }
}

const showVerifyForm = ref(false)

const values: Ref<ComplaintSchemaIn> = ref(getInitialValue())

const schemaData = reactive({
  gettext: i18nstore.gettext,
  getLocationByLevel: (level: number): OptionsType => {
    const locations = locationStore.getByLevel(level).map((opt) => { return { label: opt.name, value: opt.path.at(-1) } })
    return locations as OptionsType
  },
  getCurrentDate: () => {
    const currDate = new Date()
    return {
      day: currDate.getDate(),
      month: currDate.getMonth(),
      year: currDate.getFullYear()
    }
  },
  complaintsCategories: computed(() => complaintsCategories.map(it => ({ value: it.value, label: i18nstore.gettext(it.label) }))),
  complaintsMethod: computed(() => complaintsMethod.map(it => ({ value: it.value, label: i18nstore.gettext(it.label) })))
})

const submit = async (dataIn: ComplaintSchemaIn) => {
  if (sukuId.value === undefined) return
  dataIn.suku_id = sukuId.value
  if (!isEdit.value) {
    dataIn.status = 1
  }
  const submitComplaint = await sukuComplaintStore.createComplaint(dataIn, isEdit.value !== true)
  if (submitComplaint) {
    showCreateForm.value = false
  }
}

const create = () => {
  values.value = getInitialValue()
  showCreateForm.value = true
  isEdit.value = false
}

/**
 * fetch complaints data
 */
sukuComplaintStore.initialize(false)

</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <h1 class="text-2.5xl font-black mb-10">
        {{ gettext('List of Suku Complaints') }}
      </h1>
      <DataTable
        :headers="headers"
        :items="items"
        filter-size="md"
        theme-color="dark"
        spacing="narrow"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl !text-gray-400"
        :filters="{
          field: 'complaint_status',
          options: complaintStatus
        }"
        redirect-path="sukuComplaintDetails"
        @view="(item: Item) => router.push({
          name: 'sukuComplaintDetails',
          params: {
            complaintId: item.view
          }
        })"
      >
        <template
          v-if="authStore.can('ida.add_complaint')"
          #header-action
        >
          <basic-button @click="create">
            <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('Add') }}
          </basic-button>
        </template>
      </DataTable>
    </content-section>
    <modal-box
      v-if="showCreateForm"
      class="text-center"
      spacing="md"
    >
      <template #header>
        <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
          <i class="text-gray-400 mr-3 text-2xl las la-user"></i>
          {{ gettext('Add complaint details') }}
        </h4>
      </template>
      <button
        class="fixed right-6 top-6 text-lg w-[28px] h-[28px] rounded-full bg-emerald-600 text-white"
        @click="() => showCreateForm = false"
      >
        <i class="las la-times"></i>
      </button>
      <FormKit
        ref="form"
        v-model="values"
        type="form"
        :actions="false"
        :disabled="values.status === 0"
        @submit="submit"
      >
        <div class="text-left">
          <FormKitSchema
            :schema="complaintSchemaIn(true)"
            :data="schemaData"
          />
        </div>
        <FormKit
          type="submit"
          outer-class="max-w-[254px] mx-auto mt-8"
          prefix-icon="search"
        >
          <i class="las la-check text-2xl mr-3"></i>
          {{ gettext('Save complaint') }}
        </FormKit>
        <basic-button
          theme="text"
          class="mt-2"
          @click="() => showCreateForm = false"
        >
          {{ gettext('Cancel') }}
        </basic-button>
      </FormKit>
    </modal-box>
  </base-layout>
</template>
