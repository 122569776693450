<script setup lang="ts">
import { MenuType } from '../../_types/commons'
import { Ref, ref, computed, onMounted } from 'vue'
import ContentSection from '../base/ContentSection.vue'
const props = defineProps<{
  menus: MenuType[]
}>()
const emit = defineEmits <{(e: 'click', value: string): void}>()
const showDropdown = ref(false)
const navWrapper: Ref<HTMLUListElement | null> = ref(null)
const navContainer: Ref<HTMLDivElement | null> = ref(null)

const mainMenus: Ref<MenuType[]> = ref(props.menus)
const dropdownMenus: Ref<MenuType[]> = ref([])

const isDropdownActive = computed(() => dropdownMenus.value.findIndex(menu => menu.id === activeTabs.value) ?? -1)

const selectedMenu = mainMenus.value.filter(menu => menu.active)

const activeTabs: Ref<string> = ref(selectedMenu.length > 0 ? selectedMenu[0].id : '')

const click = (id: string, isDropdown = false) => {
  const selectedMenus = (isDropdown && dropdownMenus.value ? dropdownMenus.value : mainMenus.value).filter(menu => menu.id === id)
  if (selectedMenus.length > 0) {
    activeTabs.value = selectedMenus[0].id
  } else {
    activeTabs.value = ''
  }
  showDropdown.value = false
  emit('click', activeTabs.value)
}

const onResizeHandler = () => {
  truncateMenus()
}

const truncateMenus = () => {
  const MENU_WIDTH = 130
  const containerWidth = (navContainer?.value?.clientWidth ?? 0) - 150
  let navMenuWidth = 0
  let findTruncatedMenuIndex: number | undefined
  for (let index = 0; index < props.menus.length; index++) {
    navMenuWidth += MENU_WIDTH
    if (navMenuWidth > containerWidth && findTruncatedMenuIndex === undefined) {
      findTruncatedMenuIndex = index
      break
    }
  }
  if (findTruncatedMenuIndex !== undefined) {
    mainMenus.value = props.menus.slice(0, findTruncatedMenuIndex)
    dropdownMenus.value = props.menus.slice(findTruncatedMenuIndex, props.menus.length)
  } else {
    mainMenus.value = props.menus
    dropdownMenus.value = []
  }
}

onMounted(() => {
  onResizeHandler()
  window.addEventListener('resize', onResizeHandler, { passive: true })
})
</script>

<template>
  <content-section class="!py-0">
    <div
      ref="navContainer"
      class="flex items-center justify-between"
    >
      <ul
        ref="navWrapper"
        class="flex w-auto flex-nowrap"
      >
        <li
          v-for="(menu, index) in mainMenus"
          :key="index"
          class="mr-[8px] last:mr-0"
        >
          <button
            :id="`nav-tab-toggle-${menu.id}`"
            class="min-w-[123px] text-sm leading-tight px-3 h-[70px] border-b-4 border-b-transparent text-zinc-700 hover:text-zinc-900 [&.active]:text-zinc-900 font-medium hover:border-b-emerald-600 hover:bg-zinc-400/25 [&.active]:border-b-emerald-600 [&.active]:bg-zinc-400/25"
            :class="{ 'active' : activeTabs === menu.id, '!text-gray-100 pointer-events-none': menu.disabled}"
            type="button"
            @click="click(menu.id)"
          >
            {{ gettext(menu.label as string) }}
          </button>
        </li>
      </ul>
      <div
        v-if="dropdownMenus && dropdownMenus.length > 0"
        v-click-outside="() => showDropdown = false"
        class="relative block min-w-[90px] max-w-[90px] h-[70px] border-l border-l-zinc-400/50 border-r border-r-zinc-400/50 "
      >
        <button
          class="flex peer h-full w-full items-center justify-center border-b-4 border-b-transparent hover:border-b-emerald-600 hover:bg-zinc-400/50 [&.active]:border-b-emerald-600 [&.active]:bg-zinc-400/50"
          :class="{ 'active': showDropdown || isDropdownActive > -1, '[&>i]:transform [&>i]:rotate-180': showDropdown }"
          type="button"
          @click="() => showDropdown = !showDropdown"
        >
          <i class="las text-2xl la-angle-down"></i>
        </button>
        <ul
          :class="{ 'block': showDropdown, 'hidden': !showDropdown }"
          class="top-[70px] absolute z-[1000] text-center w-[214px] shadow-lg right-0 bg-stone-100 border border-zinc-400/50 rounded-br-2.5xl"
        >
          <li
            v-for="(menu, index) in dropdownMenus"
            :key="index"
            class="border-b border-b-zinc-400/50 last:rounded-br-2.5xl"
          >
            <button
              :class="{ 'active' : activeTabs === menu.id, '!text-gray-100 pointer-events-none': menu.disabled}"
              class="py-3.5 text-sm w-full block border-l-4 border-l-transparent hover:border-l-emerald-600 hover: hover:bg-zinc-400/25 [&.active]:border-l-emerald-600 [&.active]:bg-zinc-400/25"
              type="button"
              @click="click(menu.id, true)"
            >
              {{ gettext(menu.label) }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </content-section>
  <content-section
    :has-border="false"
    class="!pt-0"
  >
    <div
      v-if="typeof $slots.sidebar !== 'undefined'"
      class="mt-14 flex flex-wrap flex-col-reverse lg:flex-row relative"
    >
      <div class="w-full lg:w-2/3 lg:pr-10 2xl:pr-[163px]">
        <slot name="globalContent"></slot>
        <slot :name="activeTabs"></slot>
        <slot name="singleContent"></slot>
      </div>
      <div class="w-full lg:w-1/3 mb-8 lg:mb-0">
        <slot name="sidebar"></slot>
      </div>
    </div>
    <div v-else>
      <div
        v-if="$slots.globalContent"
        class="mt-14"
      >
        <slot name="globalContent"></slot>
      </div>
      <slot
        :name="activeTabs"
      ></slot>
      <slot
        name="singleContent"
      ></slot>
    </div>
  </content-section>
</template>
