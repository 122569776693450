<script setup lang="ts">
import { format } from 'date-fns'
import { useProjectManagerStore } from '../../stores/projectmanager'
import { useSubmissionsStore } from '../../stores/submissions'
import { useOptionsStore } from '../../stores/options'
import { useLocationStore } from '../../stores/locations'
import { components } from '../../_api-services/openapi'
import { computed } from 'vue'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import { useRouter } from 'vue-router'

type Tf_6_1_RepeaterProjectSchema = components['schemas']['Tf_6_1_1RepeaterprojectoutputSchema']

const optionsStore = useOptionsStore()
const locationStore = useLocationStore()
const projectManagerStore = useProjectManagerStore()
const router = useRouter()

const submission = computed(() => {
  return projectManagerStore.getProjectInformation() ?? null
})

const currentDate = format(new Date(), 'dd/MM/yyyy')

const stringToDate = (str: string) => {
  return format(new Date(str), 'dd/MM/yyyy')
}

</script>
<template>
  <div class="hidden print:block max-w-[1440px] mx-auto text-sm py-4">
    <div class="flex items-center justify-between">
      <img
        src="../../assets/IDA-logo-dark-440.webp"
        width="88"
        alt="32"
      />
      <p class="font-bold text-base">
        {{ gettext('Date') }}: {{ currentDate }}
      </p>
    </div>
    <h5 class="font-bold mt-4">
      {{ gettext('Project Factsheet') }}
    </h5>
    <h5 class="text-xl mt-2">
      <span class="font-bold">{{ gettext('Project Name') }}:</span> {{ optionsStore.getLabel('project', submission?.project, '-') }}
    </h5>
    <p class="text-2sm mt-1">
      <span class="font-bold">{{ gettext('Objective') }}:</span> {{ optionsStore.getLabel('objective', submission?.objective, '-') }}
    </p>

    <div class="grid grid-cols-2 gap-5 mt-5">
      <div>
        <p class="font-bold text-base mb-2">
          {{ gettext('LOCATION & TIME FRAME') }}
        </p>
        <table class="table-auto w-full text-left">
          <tbody>
            <tr>
              <td class="font-semibold">
                {{ gettext('Municipality') }}:
              </td>
              <td>
                {{ submission?.district ? locationStore.getName([submission?.district]) : '-' }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Administrative Post') }}:
              </td>
              <td>
                {{ submission?.district && submission?.administrative_post ? locationStore.getName([submission?.district, submission?.administrative_post]) : '-' }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Suco') }}:
              </td>
              <td>
                {{ submission?.district && submission?.administrative_post && submission?.suco ? locationStore.getName([submission?.district, submission?.administrative_post, submission?.suco]) : '-' }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Aldeia') }}:
              </td>
              <td>
                {{ submission?.district && submission?.administrative_post && submission?.suco && submission?.aldeia ? locationStore.getName([submission?.district, submission?.administrative_post, submission?.suco, submission?.aldeia]) : '-' }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Start Date') }}:
              </td>
              <td>
                {{ submission?.date_start ? stringToDate(submission?.date_start) : '-' }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('End Date') }}:
              </td>
              <td>
                {{ submission?.date_finish ? stringToDate(submission?.date_finish) : '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <p class="font-bold text-base mb-2">
          {{ gettext('PROJECT DETAILS') }}
        </p>
        <table class="table-auto w-full text-left">
          <tbody>
            <tr>
              <td class="font-semibold">
                {{ gettext('Sector') }}:
              </td>
              <td>
                {{ optionsStore.getLabel('sector', submission?.project_sector, '-') }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Sub Sector') }}:
              </td>
              <td>
                {{ optionsStore.getLabel('subsector', submission?.project_sub_sector, '-') }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Project name') }}:
              </td>
              <td>
                {{ optionsStore.getLabel('project', submission?.project, '-') }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Is women priority') }}:
              </td>
              <td>
                {{ optionsStore.getLabel('yesno', submission?.women_priority, '-') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <p class="font-bold text-base mb-2">
          {{ gettext('BENEFICIARIES') }}
        </p>
        <table class="table-auto w-full text-left">
          <tbody>
            <tr>
              <td class="font-semibold">
                {{ gettext('Household') }}:
              </td>
              <td class="text-right">
                {{ submission?.number_of_households ?? 0 }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Female') }}:
              </td>
              <td class="text-right">
                {{ submission?.no_of_women ?? 0 }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Male') }}:
              </td>
              <td class="text-right">
                {{ submission?.no_of_men ?? 0 }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('PwD') }}:
              </td>
              <td class="text-right">
                {{ submission?.no_of_pwd_male ?? 0 }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('PwD-Female') }}:
              </td>
              <td class="text-right">
                {{ submission?.no_of_pwd_female ?? 0 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <p class="font-bold text-base mb-2">
          {{ gettext('BUDGET & EXPENDITURES') }}
        </p>
        <table class="table-auto w-full text-left">
          <tbody>
            <tr>
              <td class="font-semibold">
                {{ gettext('Subsidy') }}:
              </td>
              <td class="text-right">
                {{ formatCurrencyWithDollar(projectManagerStore.getProjectBudgetAndExpenditures().infrastructure_subsidy.total_infrastructure_subsidy) }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Material') }}:
              </td>
              <td class="text-right">
                {{ formatCurrencyWithDollar(projectManagerStore.getProjectBudgetAndExpenditures().infrastructure_subsidy.materials) }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Incentives') }}:
              </td>
              <td class="text-right">
                {{ formatCurrencyWithDollar(projectManagerStore.getProjectBudgetAndExpenditures().infrastructure_subsidy.labour_incentive_3001) }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Community contribution') }}:
              </td>
              <td class="text-right">
                {{ formatCurrencyWithDollar(projectManagerStore.getProjectBudgetAndExpenditures().community_contribution) }}
              </td>
            </tr>
            <tr>
              <td class="font-semibold">
                {{ gettext('Expenditures') }}:
              </td>
              <td class="text-right">
                {{ formatCurrencyWithDollar(Number(projectManagerStore.getProjectBudgetAndExpenditures().expenditures.total.materials_3000) + Number(projectManagerStore.getProjectBudgetAndExpenditures().expenditures.total.labour_incentive_3001)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p class="font-bold text-base mb-2 mt-5">
      {{ gettext('PROJECT OUTPUT') }}
    </p>
    <table class="table-auto w-full text-center">
      <thead>
        <tr>
          <th class="text-left">
            {{ gettext('Output name') }}
          </th>
          <th>
            {{ gettext('Activity') }}
          </th>
          <th>
            {{ gettext('Value') }}
          </th>
          <th>
            {{ gettext('Unit') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(projectOutput, index) in (submission?.repeaterProjectOutput as unknown as Tf_6_1_RepeaterProjectSchema[])"
          :key="index"
        >
          <td class="text-left">
            {{ optionsStore.getLabel('output', projectOutput?.output, '-') }}
          </td>
          <td>
            {{ optionsStore.getLabel('activity', projectOutput?.activity, '-') }}
          </td>
          <td>
            {{ projectOutput?.quantity }}
          </td>
          <td>
            {{ optionsStore.getLabel('unit', projectOutput?.unit, '-') }}
          </td>
        </tr>
      </tbody>
    </table>

    <p class="font-bold text-base mb-2 mt-5">
      {{ gettext('PHYSICAL PROGRESS & WORKERS') }}
    </p>
    <table class="table-auto w-full text-center">
      <thead></thead>
      <tbody>
        <tr>
          <td
            colspan="6"
            class="text-left"
          >
            <span class="font-semibold">{{ gettext('Physical progress') }}:</span> {{ projectManagerStore.getPhysicalProgress()?.current ?? 0 }}%
          </td>
          <td
            colspan="3"
            class="text-left"
          >
            <span class="font-semibold">{{ gettext('Status') }}:</span> {{ optionsStore.getLabel('subprojectstatus1', projectManagerStore.getProjectInformation()?.project_status) }}
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan="6">
            {{ gettext('No of workers') }}
          </th>
          <th colspan="3">
            {{ gettext('No of working days') }}
          </th>
        </tr>
        <tr>
          <th>
            {{ gettext('Female') }}
          </th>
          <th>
            {{ gettext('Male') }}
          </th>
          <th>
            {{ gettext('Total') }}
          </th>
          <th>
            {{ gettext('PwD Male') }}
          </th>
          <th>
            {{ gettext('PwD Female') }}
          </th>
          <th>
            {{ gettext('PwD Total') }}
          </th>
          <th>
            {{ gettext('Female') }}
          </th>
          <th>
            {{ gettext('Male') }}
          </th>
          <th>
            {{ gettext('Total') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ projectManagerStore.getWorkers().total?.workers_female }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.workers_male }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.total_workers }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.workers_with_disability_male }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.workers_with_disability_female }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.total_workers_with_disability }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.no_of_working_days_female }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.no_of_working_days_male }}
          </td>
          <td>
            {{ projectManagerStore.getWorkers().total?.total_working_days }}
          </td>
        </tr>
      </tbody>
    </table>
    <footer class="flex items-center w-full print:absolute bottom-0 mt-5">
      <img
        class="w-[60px] h-60px mr-4"
        width="60"
        height="60"
        src="../../assets/logos/RDTL.webp"
        alt="RDTL"
      />
      <img
        class="w-[60px] h-60px mr-4"
        width="60"
        height="60"
        src="../../assets/logos/MAE.webp"
        alt="MAE"
      />
      <p class="text-xs max-w-[600px] w-full lg:w-auto">
        {{ gettext("Managed by the Government of Timor Leste. Proudly supported by the Australian Government. Designed and developed by Catalpa International") }}
      </p>
    </footer>
  </div>
</template>
<style scoped lang="postcss">
  table {
    th, td {
      @apply border border-gray-400 px-2;
    }
    th {
      @apply font-bold py-2 bg-gray-75;
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
  }
</style>
