<script setup lang="ts">
import { type PropType } from 'vue'
import { OptionsType } from '../../_types/components/forms/select-dropdown'

defineProps({
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: 'Select'
  },
  options: {
    type: Array as PropType<OptionsType>,
    required: true
  },
  modelValue: {
    type: String,
    required: true
  }
})
const emit = defineEmits <{(e: 'update:modelValue', value: string): void}>()

</script>
<template>
  <select
    class="cursor-pointer h-[40px] py-0 appearance-none rounded-[10px] leading-[24px] bg-white border-zinc-400 border text-[15px]"
    :value="modelValue"
    @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
  >
    <option disabled>
      {{ placeholder }}
    </option>
    <option
      v-for="(option,index) in options"
      :key="index"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<style scoped>
select.light {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  @apply bg-transparent;
}
</style>
