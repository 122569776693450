<script setup lang="ts">
import { HistoryListData } from '../../_types/components/commons/form'
import { computed, reactive, watch } from 'vue'
import { FormKitSchema } from '@formkit/vue'
import { useI18NStore } from '../../stores/i18n'
const props = defineProps<{
  datas: HistoryListData[]
  filter: {
    keyword: string
    status: string[]
  }
}>()

const icons: Record<string, string> = {
  verified: 'la-check',
  revised: 'la-pencil-alt',
  requested: 'la-comment-dots',
  created: 'la-mouse-pointer',
  reopened: 'la-undo-alt'
}

const filteredData = computed(() => { // filter the data by message and title
  let source = props.datas
  if (typeof props.filter.status !== 'undefined' && props.filter.status.length > 0) {
    source = source.filter(data => props.filter.status.includes(data.status))
  }
  return source
})

const formkitSchemaData = reactive({
  gettext: useI18NStore().gettext,
  pgettext: useI18NStore().pgettext
})

/**
 * filter the history based on DOM
 * because we're using formKitSchema for the message
 */
function filterHistoryDOM () {
  const historyItems = document.querySelectorAll('.history-item')
  historyItems.forEach(item => {
    const message = item.innerHTML
    const parent = item.closest('li')
    console.log('m', message)
    if (parent === null) return
    if (!message.toLocaleLowerCase().includes(props.filter.keyword)) {
      parent.classList.add('hidden')
    } else {
      parent.classList.remove('hidden')
    }
  })
}

watch(() => props.filter.keyword, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    filterHistoryDOM()
  }
})

</script>
<template>
  <ul class="text-left">
    <li
      v-for="(data, index) in filteredData"
      :key="index"
      class="relative"
      :class="data.status"
    >
      <span class="prefix z-20 w-10 h-10 md:w-[50px] md:h-[50px] absolute flex items-center justify-center text-lg md:text-xl rounded-full left-0 top-16 md:top-12">
        <i
          class="las"
          :class="icons[data.status]"
        ></i>
      </span>
      <span class="md:flex justify-between items-end">
        <h4 class="font-black text-2sm md:text-xl leading-loose">{{ gettext(data.title) }}</h4>
        <p class="text-[13px] text-gray-400">{{ data.date }}</p>
      </span>
      <span class="block relative bg-white border border-zinc-400 py-2.5 md:py-4 px-4.5 md:px-6 rounded-[4px] mt-2 bubble-text">
        <FormKitSchema
          :schema="[
            {
              $el: 'p',
              attrs: {
                class: 'history-item'
              },
              children: [
                ...data.message,
                '&nbsp;',
                '$gettext(by)',
                '&nbsp;',
                data.user ?? ''
              ]
            }
          ]"
          :data="formkitSchemaData"
        />
      </span>
    </li>
  </ul>
</template>
<style lang="postcss" scoped>
ul {
  li {
    padding-left: 72px;
    &:not(:last-child) {
      margin-bottom: 25px;
      &:before {
        content: '';
        @apply block w-[1px] h-[calc(100%-14px)] md:h-[calc(100%-24px)] bg-zinc-400 absolute left-5 md:left-[25px] -bottom-[89px] md:-bottom-[74px] z-10;
      }
    }
    &.verified {
      .prefix {
        @apply bg-emerald-200/[.85] border border-green-300;
      }
    }
    &.revised {
      .prefix {
        @apply bg-blue-300/[.55] border border-blue-300;
      }
    }
    &.requested {
      .prefix {
        @apply bg-amber-400/[.45] border border-amber-400;
      }
    }

    &.reopened {
      .prefix {
        @apply bg-amber-400/[.45] border border-amber-400;
      }
    }

    &.created {
      .prefix {
        @apply bg-emerald-600/[.35] border border-emerald-600;
      }
    }
  }
}
</style>
