<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import L, { LatLngExpression, CircleMarker, Path, Layer } from 'leaflet'
import { components } from '../../_api-services/openapi'
import { fetchLocationAndStore } from '../../_locations/idb_locations'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useLocationStore } from '../../stores/locations'
import { useProjectManagerStore } from '../../stores/projectmanager'
import { useAppStore } from '../../stores/app'
import { useAuthStore } from '../../stores/auth'
import { useRouter } from 'vue-router'
import { useSukuProfileStore } from '../../stores/sukuprofile'
import { useI18NStore } from '../../stores/i18n'
import { activeLayer, normalLayer, osmLayer } from '../../_constants/map.constant'

type FeatureCollectionSchema = components['schemas']['FeatureCollection']

const geoLocationStore = useGeoLocationStore()
const locationStore = useLocationStore()
const router = useRouter()
const sukuProfileStore = useSukuProfileStore()
const projectManagerStore = useProjectManagerStore()
const authStore = useAuthStore()

const map = ref()
const mapLayers = ref()
const activeSuku = ref<number | undefined>(undefined)

/**
 * initialize leaflet polygon and event handler using geoJSON data
 * @param feature geoJSON feature
 * @param layer geoJSON layer
 */
function onEachFeature (feature: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>, layer: Layer) {
  layer.on('mouseover', () => {
    const pathLayer = layer as Path
    pathLayer.setStyle(activeLayer)
  })
  layer.on('mouseout', () => {
    if (feature.properties && (feature.properties.id !== activeSuku.value)) {
      const pathLayer = layer as Path
      pathLayer.setStyle(normalLayer)
    }
  })
  layer.on('click', async () => {
    if (feature.properties) {
      // get zSuco id
      const zSucoId = locationStore.convertLocationToZsuco(feature.properties.id)
      if (zSucoId === undefined) return

      // fetch ida projects by zSuco id
      await projectManagerStore.fetchIdaProjects({
        suco: zSucoId
      })
      // fetch legacy project
      const legacyProject = await sukuProfileStore.fetchLegacyProjects({ location_id: feature.properties.id })
      const totalLegacyProject = legacyProject?.projects ? legacyProject?.projects.length : 0
      // total projects
      const totalProjects = projectManagerStore.$state.idaProjects.length + totalLegacyProject

      map.value.setStyle(normalLayer)
      activeSuku.value = feature.properties.id
      const pathLayer = layer as Path
      pathLayer.setStyle(activeLayer)

      let label = `
        <strong>${useI18NStore().gettext('Municipality')}</strong>: ${feature?.properties?.municipality_name}<br/>
        <strong>${useI18NStore().gettext('Adminstrative Post')}</strong>: ${feature?.properties?.adminpost_name}<br/>
        <strong>${useI18NStore().gettext('Suco')}</strong>: ${feature?.properties?.name}<br/>
        <strong>${useI18NStore().gettext('Total project')}</strong>: ${totalProjects}<br/>
      `
      if (authStore.canAny(['pnds_data.view_zsuco'])) {
        label += `
          <a href="/suku-manager/${feature.properties.id}/profile/" class="block rounded-2.5xl bg-emerald-600 text-center !text-white text-3xs font-bold px-3 py-2 w-full mt-3">${useI18NStore().gettext('View suku profile')}</a>
        `
      }

      layer.bindPopup(label).openPopup()
    }
  })
}

const goToDetailProfile = async (id: number) => {
  await geoLocationStore.setActiveGeoLocation(id)
  await sukuProfileStore.setActiveZsucoId()
  router.push(`/suku-manager/${id}/`)
}

function mapInitialize () {
  map.value = L.map('mapContainer', { zoomControl: false }).setView([-9.12, 125.623], 8)
  osmLayer().addTo(map.value)
  L.control.zoom({ position: 'topright' }).addTo(map.value)

  map.value = L.geoJSON(geoLocationStore.getGeoLocationData, {
    onEachFeature
  }).addTo(map.value).setStyle(normalLayer)
}

onMounted(async () => {
  useAppStore().$state.loading = true
  await fetchLocationAndStore()
  await geoLocationStore.getGeoLocationList()
  await locationStore.fetchZsucoToLocation()

  useAppStore().$state.loading = false

  mapInitialize()
})

onBeforeUnmount(() => {
  if (map.value) {
    map.value.remove()
  }
})

</script>

<template>
  <div class="relative">
    <!-- <div class="absolute z-[1000] bottom-6 left-6 rounded-xl bg-white w-[223px] overflow-hidden text-left">
      <div class="bg-stone-100 py-3 px-4">
        <p class="font-bold text-sm text-emerald-900 flex items-center">
          <i class="las la-info-circle text-xl mr-2"></i> {{ gettext('Map legend') }}
        </p>
      </div>
      <div class="pt-3 px-4 pb-4">
        <p class="text-xs font-bold">
          {{ gettext('Number of projects') }}
        </p>
        <div class="w-[34px] h-[34px] inline-flex items-center justify-center text-xs text-white font-bold bg-purple-600 rounded-full mt-3">
          200
        </div>
      </div>
    </div> -->
    <div
      id="mapContainer"
      class="w-full h-[555px]"
    ></div>
  </div>
</template>

<style scoped>
@import url('leaflet/dist/leaflet.css');
.leaflet-tooltip.l-label {
  border: transparent;
  background: transparent;
  box-shadow: none;
  @apply rounded-full font-bold text-white;
}
.leaflet-interactive {
  @apply !drop-shadow-lg;
}
</style>
