<script setup lang="ts">
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import BasicButton from '../components/commons/BasicButton.vue'
import DataTable from '../components/commons/DataTable.vue'
import ModalBox from '../components/commons/modal/ModalBox.vue'

import { useIdaFormsStore } from '../stores/idaforms'
import { useAuthStore } from '../stores/auth'
import { ComputedRef, computed, ref } from 'vue'
import { Item } from 'vue3-easy-data-table'
import { FormKitNode } from '@formkit/core'
import { components } from '../_api-services/openapi'
import { addOrUpdateFormSchema } from './schemas'
import { useI18NStore } from '../stores/i18n'
import { useOptionsStore } from '../stores/options'
import { groupName } from '../_pndsdata/idb_pndsdata'
import { useToast } from 'vue-toastification'
import { useModal } from '../stores/modal'
import { FORM_SCHEMA_STATUS_DRAFT, FORM_SCHEMA_STATUS_PUBLISHED } from '../_constants/common.constant'

const authStore = useAuthStore()
const modal = useModal()
const store = useIdaFormsStore()
const i18nStore = useI18NStore()
const optionsStore = useOptionsStore()
store.initialize()

const toast = useToast()
// const relations = computed(() => store.relations)

const items: ComputedRef<Item[]> = computed(() => {
  return store.forms.map((i: Item) => {
    i.redirectParams = { group: i.key }
    const nameEn = i.name_set.find((i: {lang: string}) => i.lang === 'en')
    i.name_en = nameEn ? nameEn.label : ''
    const nameTet = i.name_set.find((i: {lang: string}) => i.lang === 'tet')
    i.name_tet = nameTet ? nameTet.label : ''
    const namePt = i.name_set.find((i: {lang: string}) => i.lang === 'pt')
    i.name_pt = namePt ? namePt.label : ''
    i.publish = i.schema_id
    i.action = i.schema_id
    return i
  })
})

const headers = [
  { value: 'key', text: 'Form name', sortable: true },
  { value: 'name_en', text: 'Name (en)', sortable: true },
  { value: 'name_tet', text: 'Name (tet)', sortable: true },
  { value: 'name_pt', text: 'Name (pt)', sortable: true },
  { value: 'publish', text: 'Published', sortable: false },
  { value: 'action', text: 'Delete', sortable: false }
]

const showModal = ref(false)

function uniqueKey (node: FormKitNode) {
  return items.value.findIndex((i) => i.key === node.value) === -1
}

async function createOrUpdateForm (dataIn: components['schemas']['FormSchemaIn'], node?: FormKitNode | undefined) {
  const { response, error } = await store.createOrUpdateForm(dataIn)
  if (error && node !== undefined) node.setErrors(error.errors as string[], error.field_errors as Record<string, string>)
  if (node !== undefined) toast.success(i18nStore.gettext('Form created'))
  showModal.value = !response.ok
  return response.ok
}

const schema = computed(() => addOrUpdateFormSchema({ uniqueKey, isEdit: false }))

const formkitSchemaData = computed(() => ({
  gettext: i18nStore.gettext,
  ida: (name: groupName, ...filterParams: string[]) => optionsStore.options(name, i18nStore.code, filterParams),
  statusOptions: () => {
    return [
      { label: i18nStore.gettext('Draft'), value: 'draft' },
      { label: i18nStore.gettext('Published'), value: 'published' }
    ]
  }
}))

const updateFormStatus = (item: Item) => {
  modal.open('info', {
    label: item.status === FORM_SCHEMA_STATUS_PUBLISHED ? 'Publish' : 'Unpublish',
    modalTitle: item.status === FORM_SCHEMA_STATUS_PUBLISHED ? 'Would you like to publish?' : 'Would you like to unpublish?',
    modalIcon: 'la-stamp',
    modalCaption: item.status === FORM_SCHEMA_STATUS_PUBLISHED ? 'By publishing this form it will be displayed on the Data collection page' : 'By unpublishing this form it will be removed from the Data collection page',
    callback: async () => {
      if (item.status !== undefined) {
        const req = await store.updateFormStatus({
          key: item.key,
          status: item.status
        })
        if (req) {
          modal.close()
        }
      }
    }
  })
}

const deleteForm = (item: Item) => {
  modal.open('confirm', {
    label: 'Delete',
    modalTitle: 'Are you sure?',
    modalCaption: 'Do you really want to delete this form? This process cannot be undone',
    callback: async () => {
      const req = await store.deleteForm(item.key)
      if (req) {
        toast.success(useI18NStore().gettext('Form has been successfully deleted'))
        modal.close()
      }
    }
  })
}

</script>

<template>
  <base-layout
    :title="gettext('Manage & Edit Forms')"
    :sub-title="gettext('Admins can manage forms through this tool, enabling them to create, edit, and manage various forms used within the Ida platform.')"
  >
    <content-section
      :has-border="false"
    >
      <DataTable
        :headers="headers"
        :items="items"
        theme-color="dark"
        spacing="narrow"
        filter-placeholder="Search"
        filter-suffix-icon="la-search !text-xl text-gray-400"
        redirect-path="idaformsedit"
        sort-by="change_id"
        sort-type="desc"
        :has-delete-button="true"
        @publish="updateFormStatus"
        @delete="deleteForm"
      >
        <template
          v-if="authStore.can('ida.view_idauser')"
          #header-action
        >
          <basic-button
            @click="showModal = true"
          >
            <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('New form') }}
          </basic-button>
        </template>
      </DataTable>
      <modal-box
        v-if="showModal"
        size="xl"
        spacing="md"
      >
        <template #header>
          <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
            <i class="text-gray-400 mr-3 text-2xl las la-edit"></i>
            {{ gettext('Add a new form') }}
          </h4>
        </template>
        <button
          class="w-7.5 h-7.5 rounded-full bg-emerald-600 text-white inline-flex items-center justify-center text-xl fixed top-6 right-6"
          @click="() => showModal = false"
        >
          <i class="las la-times"></i>
        </button>
        <div>
          <FormKit
            type="form"
            :submit-label="gettext('Add form')"
            :submit-attrs="{
              wrapperClass: 'max-w-[220px] mx-auto mt-8'
            }"
            @submit="createOrUpdateForm"
          >
            <FormKitSchema
              :schema="schema"
              :data="formkitSchemaData"
            />
          </FormKit>
        </div>

        <div class="flex items-center justify-center flex-wrap mt-4 mb-4">
          <basic-button
            theme="text"
            class="!w-[254px]"
            @click="() => showModal = false"
          >
            {{ gettext('Cancel') }}
          </basic-button>
        </div>
      </modal-box>
    </content-section>
  </base-layout>
</template>
