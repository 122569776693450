<script setup lang="ts">
import { ref } from 'vue'

defineProps<{
  text: string
}>()

const showTooltip = ref(false)
</script>

<template>
  <div
    class="relative inline-flex items-center"
    @mouseover="showTooltip = true"
    @mouseout="showTooltip = false"
  >
    <span>
      <slot></slot>
    </span>
    <div
      v-if="showTooltip"
      class="md:min-w-[200px] z-[1000] absolute -left-2 top-full text-sm bg-white shadow-xl pl-5 pt-3.5 pr-3.5 pb-3.5 rounded-2xl after:content-[' '] after:inline-block after:w-5 after:h-5 after:bg-white after:absolute after:rounded-md after:left-3 after:-top-1 after:transform after:rotate-45"
      @mouseover="showTooltip = true"
      @mouseout="showTooltip = false"
    >
      {{ text }}
    </div>
  </div>
</template>

<style scoped>
</style>
