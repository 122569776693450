import { createPinia } from 'pinia'
import { markRaw, App, Plugin } from 'vue'
import { router } from '../router'
import { Router } from 'vue-router'

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router
  }
}

export const PiniaPlugin: Plugin = {
  install (app: App) {
    const pinia = createPinia()
    pinia.use(({ store }) => {
      store.router = markRaw(router)
    })
    app.use(pinia)
  }
}
