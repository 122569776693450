
type ButtonTheme = 'primary' | 'light' | 'primaryOutline' | 'text' | 'red' | 'secondary'
type ButtonSize = 'default' | 'lg' | 'md'

const BUTTON_THEMES: Record<ButtonTheme, string> = {
  primary: 'bg-emerald-600 !hover:bg-emerald-600/90 !focus:bg-emerald-600/90 text-white font-bold',
  light: 'bg-white hover:bg-emerald-600 text-black hover:text-white shadow-lg',
  primaryOutline: 'bg-white border-2 border-emerald-600 hover:bg-emerald-600 focus:bg-emerald-600 hover:text-white focus:text-white text-zinc-900 font-bold',
  text: 'bg-transparent border-none text-zinc-900 font-bold',
  red: 'bg-red-300 text-white font-bold !hover:bg-red-300/90 !focus:bg-red-300/90',
  secondary: 'bg-gray-75 text-white hover:bg-emerald-600'
}

const BUTTON_SIZES: Record<ButtonSize, string> = {
  default: 'h-[50px] text-base',
  lg: 'h-[60px] text-lg',
  md: 'h-[40px] !text-2sm'
}

export type { ButtonTheme, ButtonSize }
export { BUTTON_THEMES, BUTTON_SIZES }
