<script setup lang="ts">
import ContentSection from '../../components/base/ContentSection.vue'
import DataTable from '../../components/commons/DataTable.vue'
import type { Header, Item, ServerOptions } from 'vue3-easy-data-table'
import { reactive, computed, ref, ComputedRef, watch } from 'vue'
import BaseLayout from '../../layouts/BaseLayout.vue'
import { useI18NStore } from '../../stores/i18n'
import { useHistoryStore } from '../../stores/history'
import { useAppStore } from '../../stores/app'

const historyStore = useHistoryStore()
const appStore = useAppStore()

const i18nstore = useI18NStore()

const headers: Header[] = reactive([
  { text: i18nstore.gettext('No.'), value: 'no', sortable: true },
  { text: i18nstore.gettext('Username'), value: 'username', sortable: true },
  { text: i18nstore.gettext('Date/time'), value: 'date_time', sortable: true },
  { text: i18nstore.gettext('Description'), value: 'description', sortable: true },
  { text: i18nstore.gettext('Operation'), value: 'operation', sortable: true }
])

const items: ComputedRef<Item[]> = computed(() => historyStore.getHistories.map(history => {
  return {
    ...history,
    ...{
      no: history.no + ((serverOptions.value.page - 1) * serverOptions.value.rowsPerPage)
    }
  }
}))

const serverItemsLength = ref(0)
const serverOptions = ref<ServerOptions>({
  page: 1,
  rowsPerPage: 10
})

const loadFromServer = async () => {
  appStore.$state.loading = true
  const data = await historyStore.initialize({
    page: serverOptions.value.page,
    page_size: serverOptions.value.rowsPerPage
  })
  serverItemsLength.value = data?.total_count ?? 0
  appStore.$state.loading = false
}

loadFromServer()

watch(serverOptions, (value: ServerOptions) => { loadFromServer() }, { deep: true })

</script>

<template>
  <base-layout
    :title="gettext('Submission History')"
    :sub-title="gettext('Allows admins users to track and review submission history and any changes made to the submission, ensuring transparency and accountability.')"
  >
    <content-section
      :has-border="false"
      class="!pt-5"
    >
      <DataTable
        v-model:server-options="serverOptions"
        :server-items-length="serverItemsLength"
        :headers="headers"
        :items="items"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl top-[2px] relative !text-zinc-900"
        filter-size="lg"
        redirect-path="formDetail"
        theme-color="dark"
        spacing="narrow"
      />
    </content-section>
  </base-layout>
</template>
