import { openDB, IDBPDatabase, DBSchema } from 'idb'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'

type SSI = components['schemas']['SukuSectorInformation']
type dim = components['schemas']['SukuInformationDimension']

interface SSIdb extends DBSchema {
  sucoInformation: {
    key: string
    value: SSI
    indexes: {
      'by-suku_pcode': number
      'by-sector_id': number
      'by-tag': string
    }
  }
}

const dbName = 'suku-profiles'

/** access ( and update ) the db, to be called by all db access methods */
export async function getDb (): Promise<IDBPDatabase<SSIdb>> {
  const openPromise = openDB<SSIdb>(dbName, 1, {
    upgrade (db) {
      const sucoInformation = db.createObjectStore('sucoInformation', {
        autoIncrement: true
      })
      sucoInformation.createIndex('by-suku_pcode', 'suku_pcode')
      sucoInformation.createIndex('by-sector_id', 'sector_id')
      sucoInformation.createIndex('by-tag', 'tags')
    }
  })
  const openDb = await openPromise
  return openDb
}

export const initializeDB = async () => {
  /*
  Check if there is already content in the database
  TODO: Make an efficient out-of-band update
  */
  const ds = await client.GET('/api/suku_profile/data', {})
  if (ds.response.status === 204) {
    // This is a 204 NO CONTENT, carry on
    // A 304 is more 'correct' but leads to an invisible replacement
    // with a 200 and a complete teardown/nuke of the IDB database
    // This might be a good place to put a "Checked for new content, unchanged" toast
    return
  }
  if (Array.isArray(ds.data)) {
    const db = await getDb()
    const tx = db.transaction('sucoInformation', 'readwrite')
    await tx.store.clear()
    const adds = ds.data.map(async (it) => await tx.store.add(it))
    await Promise.all(adds)
    await tx.done
  }
}

async function dataForSuku (sukuPcode: number): Promise<SSI[]> {
  /**
   * Return all values which match a given suco
   * pcode
   */
  const db = await getDb()
  return await db.getAllFromIndex(
    'sucoInformation',
    'by-suku_pcode',
    sukuPcode
  )
}

export class SSIList extends Array<SSI> {
  static async forSuku ({ sukuId }: { sukuId: number }) {
    return new SSIList(...await dataForSuku(sukuId))
  }

  public sector ({ sectorid }: { sectorid: number }): SSIList {
    return new SSIList(...this.filter((it) => it.sector_id === sectorid))
  }

  public tag ({ tagId }: { tagId: dim }): SSIList {
    /* Return one matching tag */
    return this.tags({ tagIds: [tagId] })
  }

  public tags ({ tagIds }: { tagIds: dim[] }): SSIList {
    /* Check multiple tags match */
    const data = this.filter((it) => tagIds.every((tagId) => it.tags.includes(tagId)))
    return new SSIList(...data)
  }

  public setsOfTags (...tags: dim[][]) {
    return new SSIList(...tags.map((tagIds) => this.tags({ tagIds })[0]))
  }

  public number (): number {
    // Return the first value, if it is numeric
    const val = this[0]?.value
    if (typeof val === 'string') return parseFloat(val)
    if (typeof val === 'number') return val
    return 0
  }

  public sum (): number {
    /* The numeric sum of all item values in this array */
    let total = 0
    for (const it of this) {
      if (typeof it.value === 'number') {
        total += it.value
      }
    }
    return total
  }

  public getValues (def: number): number[] {
    /* Return the values for all elements, with a 'default' value replacing non numeric values */
    const values = []
    for (const it of this) {
      if (typeof it.value === 'number') {
        values.push(it.value)
      } else {
        values.push(def)
      }
    }
    return values
  }

  public weightedSumOfPhysicalProgress () {
    const avgProgressTags = this.tags({ tagIds: ['pro', 'prg', 'avg'] })
    const data = {
      summation: 0,
      projects: 0
    }

    for (const t of avgProgressTags) {
      if (typeof t.value !== 'number') continue
      if (typeof t.sector_id === 'undefined') continue
      // Count of projects in the same sector
      const projectCount = this.tags({ tagIds: ['pro', 'count'] }).sector({ sectorid: t.sector_id }).sum()
      // Sum of physical progress in the same sector
      data.summation += t.value * projectCount
      data.projects += projectCount
    }
    if (data.projects === 0) return 0
    return (data.summation / data.projects)
  }

  public profileData (): Record<string, number> {
    return {
      number_of_project: this.tags({ tagIds: ['pro', 'count'] }).sum(),
      sum_of_physical_progress: this.weightedSumOfPhysicalProgress(),
      people_with_disabilities: this.tags({ tagIds: ['sum', 'dsa', 'wrk'] }).number(),
      sum_of_no_of_men: this.tags({ tagIds: ['sum', 'men', 'wrk'] }).sum(),
      sum_of_no_of_women: this.tags({ tagIds: ['sum', 'wom', 'wrk'] }).sum(),
      beneficiaries_sum_of_no_of_household: this.tags({ tagIds: ['ben', 'hsh'] }).sum(),
      beneficiaries_sum_of_no_of_men: this.tags({ tagIds: ['ben', 'men'] }).sum(),
      beneficiaries_sum_of_no_of_women: this.tags({ tagIds: ['ben', 'wom'] }).sum(),
      working_days: this.tags({ tagIds: ['sum', 'days', 'wrk'] }).sum(),
      // Not implemented yet
      subsidy_execution: 0,
      subsidy_expenditures: 0,
      subsidy_project_fund: 0,
      community_participants: 0,
      community_participants_training: 0
    }
  }
}

export type { SSI, dim }
