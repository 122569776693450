import { useI18NStore } from '../stores/i18n'

export const SUPPLIER_CREATE_MESSAGES = {
  success: 'Supplier has been successfully created',
  add: 'Supplier has been successfully added',
  delete: 'Supplier has been successfully deleted',
  update: 'Supplier has been successfully updated',
  error: 'Supplier was not created successfully'
}

export const DELETE_PROJECT_MESSAGES = {
  success: 'Project has been successfully deleted',
  error: 'The project was not deleted successfully'
}

export const UPDATE_PROJECT_MESSAGES = {
  success: 'The project has been successfully updated',
  error: 'The project was not updated successfully'
}

export const CREATE_SUKU_DESCRIPTION_MESSAGE = {
  success: 'Suku description has been successfully updated',
  error: 'Suku description was not updated successfully'
}

export const API_RESPONSE_MESSAGE = {
  error: 'Something wrong with the api '
}

export const OFFLINE_ERROR_MESSAGE = {
  error: 'The network failed'
}

export const LOGIN_MESSAGE = {
  error: 'Invalid login credentials'
}

export const SUKU_COMPLAINT_MESSAGE = {
  success: 'Complaint has been successfully created',
  update: 'Complaint has been successfully updated',
  verified: 'Complaint has been successfully verified',
  error: 'An unexpected error occurred during complaint update'
}

export const COMMENT_MESSAGE = {
  success: 'Comment has been successfully created',
  update: 'Comment has been successfully updated',
  error: 'Something wrong when trying to submit a comment'
}

export const FILE_UPLOAD_MESSAGE = {
  offline: 'Due to connection issue, the files has been stored locally and will automatically uploaded once the connection is back',
  success: 'File uploaded successfully',
  failure: 'File did not upload successfully',
  error: 'An unexpected error occurred during file upload'
}

export const SUKU_PRIORITY_MESSAGE = {
  success: 'Priority has been successfully created',
  update: 'Priority has been successfully updated',
  error: 'The priority was not saved successfully',
  reorder: 'Priority order has been successfully updated'
}

export const SUKU_TEAMS_MESSAGE = {
  success: 'Suku teams has been successfully updated',
  error: 'Suku teams was not updated successfully'
}

export const USER_MESSAGES = {
  success: 'The user has been successfully created',
  delete: 'The user has been successfully deleted',
  deactivated: 'The user has been successfully deactivated',
  activated: 'The user has been successfully activated',
  resetPassword: 'The password has been successfully reset',
  update: 'The user has been successfully updated',
  error: 'The user was not updated successfully'
}

export const PERMISSION_GROUPS_MESSAGES = {
  success: 'The group has been successfully created',
  error: 'The group was not created successfully'
}
