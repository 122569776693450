/**
 * currently this is just for demoing the UI and UX, bcs still not sure about the data format
 */
import { defineStore } from 'pinia'
import { getDb, initializeDB, SSIList } from '../_suku_profile/idb'
import { useI18NStore } from './i18n'
import { useGeoLocationStore } from './geolocation'
import { useLocationStore } from './locations'
import { client } from '../_api-services/urls'
import { components } from '../_api-services/openapi'
import { useAppStore } from './app'
import { useToast } from 'vue-toastification'
import { CREATE_SUKU_DESCRIPTION_MESSAGE } from '../_constants/toastmessages.constant'

type SukuDescriptionContext = components['schemas']['SukuProfileDescriptionSchema']
type SukuDescriptionInContext = components['schemas']['SukuProfileDescriptionIn']

interface initialState {
  pcode: number | undefined
  sector: number | undefined
  dataForSuku: SSIList
  dataForSukuAndSector: SSIList
  activezSukuId: number | undefined
  activeSukuDescription: SukuDescriptionContext | undefined
  sukuLocationNode: {
    district: number | undefined
    administrative_post: number | undefined
    suco: number | undefined
  }
}

const toast = useToast()

export const useSukuProfileStore = defineStore('sukuprofile', {
  state: () => {
    const initialState: initialState = {
      pcode: undefined,
      sector: undefined,
      dataForSuku: new SSIList(),
      dataForSukuAndSector: new SSIList(),
      activezSukuId: undefined,
      activeSukuDescription: undefined,
      sukuLocationNode: {
        district: undefined,
        administrative_post: undefined,
        suco: undefined
      }
    }
    return initialState
  },
  getters: {
    getOverviewData (state) {
      return () => {
        return state.dataForSukuAndSector.profileData()
      }
    },
    getDataByCategory (state) {
      return () => {
        return state.dataForSukuAndSector.profileData()
      }
    },

    numberOfWorkersData (state) {
      return () => {
        const i18nStore = useI18NStore()
        return {
          labels: [i18nStore.gettext('Sum of No Of Men Working'), i18nStore.gettext('Sum of No Of Women Working'), i18nStore.gettext('PwD - people with disabilities')],
          datasets: [{
            data: [
              state.dataForSukuAndSector.tags({ tagIds: ['sum', 'men', 'wrk'] }).sum(),
              state.dataForSukuAndSector.tags({ tagIds: ['sum', 'wom', 'wrk'] }).sum(),
              state.dataForSukuAndSector.tags({ tagIds: ['sum', 'dsa', 'wrk'] }).sum()
            ],
            backgroundColor: ['#69c8c8', '#3A43CD', '#ACCE89'],
            borderRadius: 10
          }]
        }
      }
    },
    getBeneficiaries: () => {
      return async (sukuId: number) => {
        const { data } = await client.GET('/api/suku_profile/beneficiaries_per_sector/{suco_id}', { params: { path: { suco_id: sukuId } } })
        return data ?? []
      }
    }
  },
  actions: {
    async setPcode (pcode: number) {
      /*
      This sets the data available to the store to the 'Suku ID' provided.
      This is async because it calls to idb
      NOTE: THIS IS THE OLD PCODE
      */
      this.pcode = pcode
      this.dataForSuku = await SSIList.forSuku({ sukuId: this.pcode })
      this.setSector(typeof this.sector === 'number' ? this.sector : 0)
    },
    setSector (sector: number) {
      /*
      Set the data available on `dataForSukuAndSector`
      */
      this.sector = sector
      /* If sector is 0 use data for all sectors */
      if (sector === 0) this.dataForSukuAndSector = this.dataForSuku
      else this.dataForSukuAndSector = this.dataForSuku.sector({ sectorid: this.sector })
    },
    async initialize () {
      /* Make a call to refresh data in idb */
      /* This is super inefficient + we should replace with better cache detection */
      const db = await getDb()
      const counter = await db.count('sucoInformation')
      if (counter > 0) {
        void initializeDB()
        return
      }
      return await initializeDB()
    },
    setActiveZsucoId () {
      const geoLocationStore = useGeoLocationStore()
      const locationStore = useLocationStore()
      const sucoId = geoLocationStore.activeGeolocation?.properties?.id
      const zSucoId = typeof sucoId !== 'undefined' ? locationStore.convertLocationToZsuco(sucoId) : undefined
      this.activezSukuId = zSucoId
    },

    setActiveSukuLocationNode () {
      const locationStore = useLocationStore()
      const zSucoId = this.activezSukuId
      const location = typeof zSucoId !== 'undefined' ? locationStore.getByLevel(3).find(it => it.path.includes(zSucoId))?.path : undefined
      const district = typeof location !== 'undefined' ? location[0] : undefined
      const administrativePost = typeof location !== 'undefined' ? location[1] : undefined
      if (zSucoId !== undefined && district !== undefined && administrativePost !== undefined) {
        this.sukuLocationNode = {
          suco: zSucoId,
          administrative_post: administrativePost,
          district
        }
      }
    },

    async getSukuDescription () {
      const { data, error } = await client.GET('/api/suku_profile/description/all', {})

      if (typeof data !== 'undefined') {
        const findDescription = data.find(dt => dt.suco === this.activezSukuId)
        this.activeSukuDescription = findDescription
      }
    },

    async saveSukuDescription (description: string) {
      if (typeof this.activezSukuId === 'undefined') return
      useAppStore().$state.loading = true
      const payload: SukuDescriptionInContext = {
        suco: this.activezSukuId,
        description
      }
      const { data, error } = await client.POST('/api/suku_profile/add_description', {
        body: payload
      })

      if (typeof data !== 'undefined') {
        toast.success(useI18NStore().gettext(CREATE_SUKU_DESCRIPTION_MESSAGE.success))
      } else {
        toast.error(useI18NStore().gettext(CREATE_SUKU_DESCRIPTION_MESSAGE.error))
      }

      useAppStore().$state.loading = false
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    async fetchLegacyProjects ({ location_id }: { location_id: number | undefined }) {
      try {
        const { data } = await client.GET('/api/suku_profile/legacy-projects', { params: { query: { location_id } } })
        if (typeof data === 'undefined') throw new Error('No data')
        return data
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.warn('SyntaxError caught:', e)
        } else if (e instanceof TypeError) {
          console.warn('Failed to Fetch caught:', e)
        } else {
          throw e
        }
      }
    }
  }
})

export { SSIList }
