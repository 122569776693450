<script setup lang="ts">
import { ObjectKeyWithString } from '../../../_types/commons'
import { computed, useSlots } from 'vue'
const props = withDefaults(defineProps<{
  size?: string
  spacing?: string
}>(), {
  size: 'default',
  spacing: 'default'
})

const slots = useSlots()

const sizes:ObjectKeyWithString = {
  default: 'md:max-w-[562px]',
  lg: 'md:max-w-[690px]',
  xl: 'md:max-w-[752px]',
  '2xl': 'md:max-w-[900px]'
}

const spacings: Record<string, string> = {
  default: 'px-8 md:px-22',
  md: 'px-5 lg:px-9'
}

const hasHeader = computed(() => Boolean(slots.header))
const hasFooter = computed(() => Boolean(slots.footer))
</script>
<template>
  <div class="fixed h-full max-h-full overflow-y-auto w-full top-0 left-0 z-[100000] bg-stone-100/80 px-5 py-22 backdrop-blur-[20px]">
    <div
      class="bg-light2 shadow-lg rounded-5xl mx-auto"
      :class="[props?.size ? sizes[props?.size] : 'default']"
    >
      <div
        v-if="hasHeader"
        class="pt-8 lg:pt-11 pb-4.5 md:pb-9 border-b border-zinc-400 text-left"
        :class="[spacings[spacing]]"
      >
        <slot name="header"></slot>
      </div>
      <div
        class="pt-14 lg:pt-[77px] mx-auto"
        :class="[!hasHeader ? 'md:pt-10': '!pt-6', hasFooter ? 'pb-0' : 'pb-10 lg:pb-16']"
      >
        <div
          :class="[spacings[spacing]]"
        >
          <slot></slot>
        </div>
      </div>
      <div
        v-if="hasFooter"
        class="pb-10 pt-8 border-t border-zinc-400 text-left"
        :class="[spacings[spacing]]"
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
