<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import NavTabs from '../../components/commons/NavTabs.vue'
import AlertBox from '../../components/commons/AlertBox.vue'
import ProjectsFactsheet from './ProjectsFactsheet.vue'
import ProjectMap from '../../components/commons/ProjectMap.vue'
import ProjectInfoBox from '../../components/project/ProjectInfoBox.vue'
import ProjectSideBar from '../../components/project/ProjectSideBar.vue'
import ProjectDetailWorkers from '../../components/project/ProjectDetailWorkers.vue'
import ProjectDetailBudget from '../../components/project/ProjectDetailBudget.vue'
import ProjectDetailPhysicalProgress from '../../components/project/ProjectDetailPhysicalProgress.vue'
import ProjectDetailSupplier from '../../components/project/ProjectDetailSupplier.vue'
import ProjectDocuments from '../../components/project/ProjectDocuments.vue'
import { MenuType } from '../../_types/commons'
import { ref, reactive, computed, Ref, toRaw, ComputedRef, onMounted, watch, nextTick, onBeforeMount } from 'vue'
import { FormKitSchemaFormKit, FormKitSchemaDOMNode, FormKitSchemaNode, FormKitNode } from '@formkit/core'
import { FormKitSchema } from '@formkit/vue'
import { useI18NStore } from '../../stores/i18n'
import { useAuthStore } from '../../stores/auth'
import { useLocationStore } from '../../stores/locations'
import { useProjectManagerStore } from '../../stores/projectmanager'
import { useSuppliersStore } from '../../stores/suppliers'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useOptionsStore } from '../../stores/options'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import { useAppStore } from '../../stores/app'
import { components } from '../../_api-services/openapi'
import { TF611repeaterProjectOutput } from '../../_types/formkit-repeater'
import { useModal } from '../../stores/modal'
import { useRouter, useRoute } from 'vue-router'
import { OptionGroupMap, groupName } from '../../_pndsdata/idb_pndsdata'
import { FormValue } from '../../forms/form-types'
import { useIdaFormsStore } from '../../stores/idaforms'
import { FORM_TYPES, SUBMISSIONS_STATUS_VALUE } from '../../_constants/common.constant'
import { FormkitInput } from '../../_types/components/commons/form'
import { LatLng } from 'leaflet'
import { useToast } from 'vue-toastification'

type ProjectInfoTF611Schema = components['schemas']['ProjectInfo_Tf_6_1_1']
type Feature = components['schemas']['Feature']

const router = useRouter()
const toast = useToast()
const route = useRoute()
const i18nstore = useI18NStore()
const locationStore = useLocationStore()
const optionsStore = useOptionsStore()
const projectManagerStore = useProjectManagerStore()
const appStore = useAppStore()
const supplierStore = useSuppliersStore()
const authStore = useAuthStore()
const modal = useModal()
const formStore = useIdaFormsStore()
const geolocationStore = useGeoLocationStore()
const formSchemas = ref({} as Record<string, Array<FormKitSchemaFormKit | FormKitSchemaDOMNode | FormKitSchemaNode>>)

const props = defineProps<{
  projectId: string
}>()

/**
 * fetch project detail
 */
await projectManagerStore.fetchProjectBreakdown(Number(props.projectId))

const submission: ComputedRef<ProjectInfoTF611Schema | null> = computed(() => {
  return projectManagerStore.getProjectInformation() ?? null
})
const idaProjectId = computed(() => {
  return projectManagerStore.getProjectId() ? `IDA-${projectManagerStore.getProjectId()}` : '-'
})

const isReopenedProject = computed(() => {
  return projectManagerStore.getReopenedSubmissionFromProject.length > 0
})

const qrCodeUrl: ComputedRef<string> = computed(() => `/api/project/qrcode/${projectManagerStore.getProjectInformation()?.submission}`)
const deleteProject = async () => {
  const sub = submission?.value?.submission
  if (typeof sub !== 'undefined') {
    await projectManagerStore.deleteProject(sub)
  }
}

await optionsStore.initialize()

const activeMenu: Ref<string> = ref('projectOverview')
const formData: Ref<Record<string, any>> = ref(submission.value ?? {})
const projectOutputTableHeading = ref<FormKitSchemaDOMNode[]>([])
const projectMapRef = ref<{ mapInitialize:(zoomArea?: Feature) => void } | null>(null)

if (props.projectId === undefined || projectManagerStore.projectBreakdownDetail.length < 1) { // if project not exist redirect to
  router.push({
    name: 'projectsIndex'
  })
}

projectManagerStore.$subscribe((mutation, state) => {
  /* A change to `ProjectManagerStore` has occurred.
  If this changes the `projectLocation` also set the values in the
  `Submission` form data.
  */
  /* This change is only made if the projectLocation was altered by the map to avoid circular update */
  if (projectManagerStore.locationSource === 'form') return
  const desiredLat = state.projectLocation?.lat.toFixed(5)
  const currentLat = formData.value.latitude
  if (typeof desiredLat !== 'undefined' && desiredLat !== currentLat) {
    formData.value.latitude = desiredLat
  }

  const desiredLng = state.projectLocation?.lng.toFixed(5)
  const currentLng = formData.value.longitude
  if (typeof desiredLng !== 'undefined' && desiredLng !== currentLng) {
    formData.value.longitude = desiredLng
  }
})
watch(formData, (newData) => {
  const newLat = Number(newData.latitude)
  const newLng = Number(newData.longitude)
  if (
    (projectManagerStore.projectLocation?.lat !== newLat ||
    projectManagerStore.projectLocation?.lng !== newLng) && (newLat !== undefined && newLng !== undefined)
  ) {
    const projectLocation = new LatLng(newLat, newLng)
    projectManagerStore.setLocation(projectLocation, 'form')
  }
})

// get TF.6.1.1 schema
const getSchema = async () => {
  const schema = formStore.forms.find((it) => it.key === FORM_TYPES.TF611)
  if (typeof schema === 'undefined') return
  if (typeof schema.schema_id === 'undefined') throw new TypeError('Schema Key is invalid')
  const node = formStore.getNodeByKey(schema.schema_id)
  if (typeof node === 'undefined') throw new TypeError('Schema Node is invalid')
  return formStore.nodeAsFormkitSchema(node, false)
}

/**
 * generate FormKit schema and set initial values
 */
const schemaGenerator = async () => {
  const schema = await getSchema()
  if (typeof schema === 'undefined' || typeof schema === 'string' || !Array.isArray(schema.children)) return
  const schemas: Record<string, {schema: Array<FormKitSchemaDOMNode | FormKitSchemaFormKit | FormKitSchemaNode>}> = {}
  for (const input of (schema.children ?? []) as FormkitInput[]) {
    schemas[input.id] = {
      schema: Array.isArray(input.children) ? [...input.children] : []
    }
    if (Array.isArray(input.children) && input.id === 'projectoutput') {
      const projectOutputSchema = input.children[0].children as FormKitSchemaFormKit[]
      projectOutputSchema?.forEach(node => {
        if (typeof node.$formkit !== 'undefined') {
          if (node.readonly !== true) {
            projectOutputTableHeading.value.push({
              $el: 'div',
              attrs: {
                class: 'flex items-center'
              },
              children: [node.label]
            })
          } else {
            node.outerClass = 'hidden'
          }
        }
      })
    }
  }

  if (schemas.projectoutput.schema !== undefined) {
    const projectOutputSchemaChildren = schemas.projectoutput.schema[0] as FormKitSchemaFormKit
    if (Array.isArray(projectOutputSchemaChildren.children)) {
      projectOutputSchemaChildren.children.push({
        $el: 'div',
        attrs: {
          class: 'formkit-outer'
        }
      });
      (schemas.projectoutput.schema[0] as FormKitSchemaFormKit).children = projectOutputSchemaChildren.children
    }
  }

  const projectDetailsSchema: FormKitSchemaFormKit[] = (schemas.projectdetails.schema as FormKitSchemaFormKit[]).map(schm => {
    if (schm.key === 'project_status') {
      schm.outerClass = ''
    }
    return schm
  })

  projectOutputTableHeading.value.push({
    $el: 'div',
    attrs: {
      class: 'flex items-center'
    },
    children: ['$gettext("Delete")']
  })

  formSchemas.value = {
    projectOverview: [
      {
        $el: 'h2',
        attrs: {
          class: 'font-bold text-2.5xl mb-5'
        },
        children: ['$gettext("Location")']
      },
      {
        $el: 'div',
        attrs: {
          class: '[&>div>div>label]:font-bold'
        },
        children: schemas.meetinginformation.schema
      },
      {
        $el: 'h2',
        attrs: {
          class: 'font-bold text-2.5xl mb-5 mt-8'
        },
        children: ['$gettext("Project time frame")']
      },
      ...schemas.projecttimeframe.schema,
      {
        $el: 'h2',
        attrs: {
          class: 'font-bold text-2.5xl mb-5 mt-8'
        },
        children: ['$gettext("Project details")']
      },
      {
        $el: 'div',
        attrs: {
          class: 'mb-5'
        },
        children: [
          {
            $el: 'p',
            attrs: {
              class: 'mb-2.5 text-2sm block font-semibold'
            },
            children: ['$gettext("Project activity")']
          },
          {
            $el: 'div',
            attrs: {
              class: 'border w-full bg-white border-zinc-400 rounded-lg px-3 h-[40px] flex items-center'
            },
            children: ['$projectActivity']
          }
        ]
      },
      ...projectDetailsSchema
    ],
    beneficiaries: [
      {
        $formkit: 'hidden', // add hidden field for project sub sector, so the output will have options
        name: 'project_sub_sector',
        id: 'project_sub_sector'
      },
      {
        $el: 'h2',
        attrs: {
          class: 'font-bold text-2.5xl mb-5'
        },
        children: ['$gettext("Beneficiaries")']
      },
      ...schemas.projectbeneficiaries.schema,
      {
        $el: 'h2',
        if: '$activityOptions().length > 0',
        attrs: {
          class: 'font-bold text-2.5xl mb-5 mt-8'
        },
        children: ['$gettext("Project outputs")']
      },
      {
        $el: 'div',
        attrs: {
          class: 'w-full lg:w-auto overflow-x-scroll lg:overflow-x-visible'
        },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'formkit-table text-2sm w-full min-w-[873px] lg:min-w-[auto] lg:w-[calc(100vw-356px)] xl:w-[calc(100vw-446px)]'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'formkit-table-head grid grid-cols-[1fr,1fr,1fr,1fr,1fr,75px] !text-3xs text-white font-bold'
                },
                children: projectOutputTableHeading.value
              },
              {
                $el: 'div',
                attrs: {
                  class: 'formkit-table-body'
                },
                children: schemas.projectoutput.schema
              }
            ]
          }
        ]
      }
    ]
  }
}

const menus: MenuType[] = [
  {
    label: 'Overview',
    id: 'projectOverview',
    active: true
  },
  {
    label: 'Description & beneficiaries',
    id: 'beneficiaries'
  },
  {
    label: 'Budget & expenditures',
    id: 'budgetandexpenditures'
  },
  {
    label: 'Physical Progress',
    id: 'physicalProgress'
  },
  {
    label: 'Workers',
    id: 'workers'
  },
  {
    label: 'Documents & images',
    id: 'documentsAndImages'
  },
  {
    label: 'Suppliers',
    id: 'suppliers'
  }
]

const data = reactive({
  gettext: i18nstore.gettext,
  getLocations: (...parents: number[]) => locationStore.getLocationsAsOptions(...parents),
  getAldeia: (...parents: number[]) => {
    /* Provided parent values, return the options available */
    /* Aldeias are a dropdown field. This does not render the label/value type correctly so a list of names is returned. */
    const locations = locationStore.getLocations(...parents).map((opt) => { return opt.name })
    return locations
  },
  parseToFloat: (value: string): number => {
    if (!value) {
      return 0
    }
    return parseFloat(parseFloat(value.replace(/[^0-9.]/g, '')).toFixed(2))
  },
  parseToCurrency: formatCurrencyWithDollar,
  ida: (groupName: groupName, ...filterParams: string[]) => optionsStore.options(groupName, i18nstore.code, filterParams).map(it => ({ value: Number(it.value), label: it.label })),
  activityOptions: () => {
    const outputs: TF611repeaterProjectOutput[] = toRaw(formData.value?.repeaterProjectOutput) ?? []

    const activities = outputs.length === 0 || Object.keys(outputs[0]).length === 0
      ? []
      : outputs.map((output: TF611repeaterProjectOutput) => {
        return {
          label: i18nstore.pgettext('activity', output.activity),
          value: output.activity
        }
      })

    return activities
  },
  repeaterRemoveAction: (event: MouseEvent) => {
    event.preventDefault()
    const target = event.target as HTMLButtonElement
    const index = target.dataset.index
    const repeaterName = target.dataset.repeaterid
    const values: Record<string, FormValue | FormValue[]> = formData.value
    if (typeof index !== 'undefined' && typeof repeaterName !== 'undefined') {
      const repeaterData = values[repeaterName] as FormValue[] // Assuming FormValue is the type of your repeater data
      const indexToRemove = parseInt(index, 10)
      if (repeaterData !== undefined) {
        if (!isNaN(indexToRemove) && indexToRemove >= 0 && indexToRemove < repeaterData.length) {
          modal.open('confirm', {
            label: 'Delete',
            modalTitle: 'Are you sure?',
            modalCaption: 'Do you really want to delete this data? This process cannot be undone',
            callback: async () => {
              repeaterData.splice(indexToRemove, 1)
              modal.close()
            }
          })
        }
      }
    }
  },
  /**
   * to disable days on the datepicker
   * need to add `_maxDateSource` or `_minDateSource` on the formKit schema. The value should be the existing field name. It will looking the value on `formData` and will use that as min date or max date
   * @param node FormkitNode
   * @param date selected Date
   */
  setDisabledDays: (node: FormKitNode, date: Date) => {
    const formValues = toRaw(formData.value)
    const maxDateSource = node.props.attrs._maxDateSource
    const minDateSource = node.props.attrs._minDateSource
    if (maxDateSource) {
      const target: string = formValues[maxDateSource] as string
      if (target) {
        return date > new Date(target)
      }
    }
    if (minDateSource) {
      const target: string = formValues[minDateSource] as string
      if (target) {
        return date < new Date(target)
      }
    }
    return false
  },
  getCurrentDate: () => {
    const currDate = new Date()
    return {
      day: currDate.getDate(),
      month: currDate.getMonth(),
      year: currDate.getFullYear()
    }
  },
  projectActivity: computed(() => {
    const cycle = projectManagerStore.getProjectActivity()
    return cycle !== undefined ? optionsStore.getLabel('cycle', cycle) : '-'
  })
})
// load submission files from the submission store
const loadFiles = async () => {
  await projectManagerStore.getProjectDocuments()
}

// handle clicking on a menu item with a given 'value'
const clickMenu = async (value: string) => {
  appStore.$state.loading = true
  activeMenu.value = value
  if (value === 'documentsAndImages') {
    await loadFiles()
  } else if (value === 'suppliers') {
    await supplierStore.initialize()
  } else if (value === 'projectOverview') {
    nextTick(() => {
      if (projectMapRef.value !== null) {
        projectMapRef.value.mapInitialize(isHaveCoordinate.value === false ? sucoGeoLocation.value : undefined)
      }
    })
  }

  appStore.$state.loading = false
}

// convert zsuco to location id that we use on the suku manager
const sucoId: ComputedRef<undefined | number> = computed(() => submission.value?.suco !== undefined ? locationStore.convertZsucoToLocation(submission.value?.suco) : undefined)

// get suco geolocation
const sucoGeoLocation: ComputedRef<Feature | undefined> = computed(() => sucoId.value !== undefined ? geolocationStore.getGeoLocationBySucoId(sucoId.value) : undefined)

// is have long and lat
const isHaveCoordinate: ComputedRef<boolean> = computed(() => Number(formData.value?.latitude) !== 0 && Number(formData.value?.longitude) !== 0)

// show project location alert
const showProjectLocationAlert = ref<boolean>(false)

// download fact sheet action
const downLoadFactSheet = async () => {
  appStore.$state.loading = true
  // fetch all the data needed
  projectManagerStore.getPhysicalProgress()
  projectManagerStore.getWorkers()
  appStore.$state.loading = false

  window.print()
}

onBeforeMount(() => {
  /**
 * redirect to projects index page if the project ID doesn't exist
  */
  if (projectManagerStore.$state.projectBreakdownDetail.length < 1) {
    toast.error(i18nstore.gettext('The project doesn\'t exist'))
    router.push({
      name: 'projectsIndex'
    })
  }
})

onMounted(() => {
  document.title = `PNDS-IDA - Project Factsheet - ${idaProjectId.value}`

  formStore.$subscribe(() => { schemaGenerator() })
  formStore.initialize()
  projectManagerStore.$state.idaProjects = [projectManagerStore.getProjectBreakdownFeature()]
  if (projectMapRef.value) {
    projectMapRef.value.mapInitialize(isHaveCoordinate.value === false ? sucoGeoLocation.value : undefined)
  }
})

/**
 * update project manager data and record it on the audit trails
 */
const save = async () => {
  // if coordinates aren't set yet, scroll to project location and show the warning message
  // once the warning message show up, if we click save button, it will show a confirmation popup if we want update the project data
  if (!showProjectLocationAlert.value && !isHaveCoordinate.value) {
    showProjectLocationAlert.value = true
    const projectLocationElement = document.getElementById('project-location-section')
    projectLocationElement?.scrollIntoView()
    return
  }

  modal.open('info', {
    label: 'Save',
    modalTitle: 'Are you sure?',
    modalCaption: 'Do you really want to update this project? This process cannot be undone',
    callback: async () => {
      appStore.$state.loading = true
      if (typeof submission.value?.submission !== 'undefined') {
        await projectManagerStore.updateProject(submission.value.submission, formData.value as Record<string, never>)
      }
      appStore.$state.loading = false
      modal.close()
    }
  })
}

const projectName = computed(() => {
  const projectName = formData.value?.project_name
  if (typeof projectName === 'undefined') return i18nstore.gettext('Project name')
  return optionsStore.getLabel('output', projectName)
})

</script>

<template>
  <base-layout
    class="!pb-32 print:hidden"
  >
    <template #title>
      <router-link
        :to="{
          name: 'projectsIndex',
          query: route.query
        }"
        class="text-[28px] font-black inline-flex items-center 2xl:max-w-[360px]"
      >
        <icon-circle-box class="border-emerald-600 mr-4">
          <i class="las la-angle-left text-xl"></i>
        </icon-circle-box>
        <span class="max-w-xl">{{ projectName }}</span>
      </router-link>
    </template>
    <template #info>
      <project-info-box
        v-if="submission"
        :submission="submission.submission"
        :ida-project-id="idaProjectId"
        :qr-code-url="qrCodeUrl"
        :show-delete="authStore.can('ida.change_project')"
        @delete-project="deleteProject"
        @down-load-fact-sheet="downLoadFactSheet"
      />
    </template>
    <FormKit
      type="form"
      :actions="false"
      :disabled="!authStore.can('ida.change_project')"
      @submit="save"
    >
      <NavTabs
        :menus="menus"
        @click="clickMenu"
      >
        <template
          v-if="isReopenedProject"
          #globalContent
        >
          <alert-box
            class="mb-5"
            theme="warning"
          >
            <div class="flex items-center md:pr-10">
              <div class="mr-5">
                <i class="text-2xl las la-exclamation-triangle"></i>
              </div>
              <div class="text-[15px]">
                <p class="font-bold mb-1">
                  {{ gettext('Project is under review.') }}
                </p>
                <p>
                  {{ gettext(projectManagerStore.getReopenedSubmissionFromProject.length > 1 ? 'These submissions are reopened' : 'This submission is reopened') }}:
                  <router-link
                    v-for="(item, index) in projectManagerStore.getReopenedSubmissionFromProject"
                    :key="index"
                    :to="{
                      name: 'formDetail',
                      params: {
                        formKey: item.key
                      }
                    }"
                    target="_blank"
                  >
                    <strong class="underline">{{ item.form.replace(/_/g, '.') }}</strong><span v-if="projectManagerStore.getReopenedSubmissionFromProject.length > 0 && index < projectManagerStore.getReopenedSubmissionFromProject.length - 1">,&nbsp;</span>
                  </router-link>
                </p>
              </div>
            </div>
          </alert-box>
        </template>
        <template
          v-if="activeMenu !== 'workers' && activeMenu !== 'documentsAndImages'"
          #sidebar
        >
          <project-side-bar
            v-if="submission"
            :submission="submission"
            :completion="projectManagerStore.getPhysicalProgress().current"
            :project-activity="projectManagerStore.getProjectActivity()"
            :select-options="(optionsStore.selectOptionsIDb as OptionGroupMap)"
            :z-suco-id="sucoId"
          />
        </template>
        <template #projectOverview>
          <FormKit
            v-if="formSchemas.projectOverview"
            v-model="formData"
            type="group"
            :disabled="!authStore.can('ida.change_project')"
          >
            <FormKitSchema
              :schema="formSchemas.projectOverview"
              :data="data"
            />
          </FormKit>
          <hr class="mt-10 mb-8" />
          <p
            id="project-location-section"
            class="mb-2.5 text-2sm block font-semibold"
          >
            {{ gettext('Project location') }}
          </p>
          <p class="text-3xs mb-6">
            {{ gettext('Click on the map and drag the map marker to the exact project location. Alternatively you can enter the latitutde and longitude co-ordindaes above.') }}
          </p>
          <alert-box
            v-if="showProjectLocationAlert && !isHaveCoordinate"
            theme="warning"
            class="mb-6"
          >
            <div class="flex items-center">
              <i class="las la-exclamation-triangle text-2xl"></i>
              <div class="pl-5">
                <p class="text-2sm font-bold">
                  {{ gettext("No project location added") }}
                </p>
                <p
                  class="text-2sm mt-1"
                >
                  {{ gettext("It’s recommended to add a project location") }}
                </p>
              </div>
            </div>
          </alert-box>
          <ProjectMap
            ref="projectMapRef"
            class="!rounded-2.5xl"
            :editable="true"
          />
        </template>
        <template #beneficiaries>
          <FormKit
            v-if="formSchemas.beneficiaries"
            v-model="formData"
            :disabled="!authStore.can('ida.change_project')"
            type="group"
          >
            <FormKitSchema
              :schema="formSchemas.beneficiaries"
              :data="data"
            />
          </FormKit>
        </template>
        <template #budgetandexpenditures>
          <ProjectDetailBudget
            :materials3000-subsidy="projectManagerStore.getProjectBudgetAndExpenditures().infrastructure_subsidy.materials"
            :labour-incentive3001-subsidy="projectManagerStore.getProjectBudgetAndExpenditures().infrastructure_subsidy.labour_incentive_3001"
            :community-contribution="projectManagerStore.getProjectBudgetAndExpenditures().community_contribution"
            :labour-incentive3001-expenditures="projectManagerStore.getProjectBudgetAndExpenditures().expenditures?.total.labour_incentive_3001"
            :expenditures-data="projectManagerStore.getProjectBudgetAndExpenditures().expenditures?.data"
          />
        </template>
        <template #physicalProgress>
          <ProjectDetailPhysicalProgress
            :current-progress="projectManagerStore.getPhysicalProgress()?.current"
            :physical-progress-data="projectManagerStore.getPhysicalProgress()?.data"
          />
        </template>
        <template #workers>
          <project-detail-workers
            :worker-submissions="projectManagerStore.getWorkerSubmissions()"
            :class="{'!-mt-9': isReopenedProject}"
          />
        </template>
        <template #suppliers>
          <ProjectDetailSupplier
            :suco-id="sucoId"
          />
        </template>
        <template #documentsAndImages>
          <ProjectDocuments
            v-if="submission?.submission"
            :submission-key="submission.submission"
            :class="{'!-mt-9': isReopenedProject}"
          />
        </template>
      </NavTabs>
      <content-section
        v-if="authStore.can('ida.change_project') && !isReopenedProject"
        class="fixed lg:w-[calc(100%-260px)] right-0 bottom-0 bg-stone-100 border-t border-t-zinc-400 z-[1001]"
      >
        <basic-button
          v-if="authStore.can('ida.change_project')"
          class="min-w-[220px]"
          type="submit"
        >
          <i class="las la-check text-2xl mr-4"></i> {{ gettext('Save') }}
        </basic-button>
      </content-section>
    </FormKit>
  </base-layout>
  <ProjectsFactsheet />
</template>
<style lang="postcss">
.table-expenditures .table-row:nth-last-child(3) {
  .table-cell {
    @apply first:rounded-bl-2.5xl last:rounded-br-2.5xl;
  }
}
.formkit-table-head {
  > div {
    @apply py-5 px-4 bg-gray-800 h-20 border-r last:border-r-0 border-r-zinc-700 first:rounded-tl-2.5xl last:rounded-tr-2.5xl;
  }
}
.formkit-table {
  @apply pb-[76px];
  .formkit-add-button {
    @apply w-max absolute left-0;
  }
}
.formkit-table-body {
  .formkit-content {
    @apply grid grid-cols-[1fr,1fr,1fr,1fr,1fr,75px];
    > div:not(.formkit-outer) {
      @apply hidden;
    }
    .formkit-label {
      @apply hidden;
    }
    .formkit-outer {
      @apply !mb-0 border-r first:bg-gray-50 bg-white px-4 border-light border-b border-b-light py-5;
    }
  }
  .formkit-controls {
    @apply absolute right-[20px] top-1/2 transform -translate-y-1/2 !mt-0;
  }
  .formkit-item {
    @apply after:hidden !py-0;
    &:last-child {
      .formkit-outer {
        @apply last:rounded-br-2.5xl;
        &:nth-child(3) {
          @apply rounded-bl-2.5xl;
        }
      }
    }
  }
  .formkit-remove button {
    @apply rounded-full inline-flex items-center justify-center w-10 min-w-[40px] h-10 border-2 border-red-300 hover:bg-red-300 hover:text-white text-[0px];
    &:after {
      font-family: 'Line Awesome Free';
      font-weight: 900;
      content: "\f1f8";
      font-size: 1.25rem;
    }
  }
}
</style>
