<script setup lang="ts">
/**
 * This component aggregates FF12, POM1 submissions into a table view
 * 'Brought Forward Data' is from FF12 submissions in December
 * 'Subsidy Allocated' is from POM1 submissions
 * 'Expenditures' are from FF12 submissions
 */
import { SUKU_BUDGET_STATUS } from '../../_constants/sukumanager.constant'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import { useAppStore } from '../../stores/app'
import { sumOfFields } from '../../_helpers/sumfields.helpers'
import { PreparationDataContext } from '../../_types/sukubudget'
import { computed } from 'vue'
import BasicButton from '../commons/BasicButton.vue'

const appStore = useAppStore()
const emit = defineEmits(['saveAndNext'])
const props = defineProps<{
    activeBudgetName: 'infrastructure-budget' | 'operational-budget'
    preparationData: Omit<PreparationDataContext, 'total_budget_allocated'>[]
}>()

const totalBudgetAllocated = (data: Pick<PreparationDataContext, 'subsidy_allocated' | 'brought_forward_bank' | 'brought_forward_cash'>) => data.subsidy_allocated + data.brought_forward_bank + data.brought_forward_cash
const sumOfPreparationData = computed(() => sumOfFields(props.preparationData, { subsidy_allocated: 0, brought_forward_bank: 0, brought_forward_cash: 0 }))
</script>

<template>
  <div class="mb-[34px] mt-[54px] flex items-center">
    <h1 class="text-2.5xl font-black first-letter:uppercase">
      {{ interpolate(gettext('%(section)s management'), {section: gettext(activeBudgetName.replace(/-/, ' '))}, true) }}
    </h1>
    <basic-button
      class="ml-5 !rounded-full !px-6"
      @click="emit('saveAndNext')"
    >
      {{ gettext('Next') }}
      <i class="las text-xl la-arrow-right ml-2 font-black"></i>
    </basic-button>
  </div>

  <table
    v-if="preparationData.length > 0"
    class="table w-full text-left table-primary"
  >
    <thead class="text-3xs">
      <tr>
        <th>
          {{ gettext('Grant year') }}
        </th>
        <th>
          {{ gettext('Subsidy allocated') }}
        </th>
        <th>
          {{ gettext('Expenditure') }}
        </th>
        <th>
          {{ gettext('Brought forward (bank)') }}
        </th>
        <th>
          {{ gettext('Brought forward (cash)') }}
        </th>
        <th>
          {{ gettext('Total budget allocated') }}
        </th>
        <th class="w-[130px]">
          {{ gettext('Status') }}
        </th>
      </tr>
    </thead>
    <tbody v-if="appStore.$state.loading">
      <tr>
        <td
          colspan="7"
          class="text-center"
        >
          {{ gettext('Loading') }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(data, index) in preparationData"
        :key="index"
        class="text-2sm"
      >
        <td class="text-xl">
          {{ data.year }}
        </td>
        <td>
          {{ formatCurrencyWithDollar(data.subsidy_allocated) }}
        </td>
        <td>
          {{ formatCurrencyWithDollar(data.expenditure) }}
        </td>
        <td>
          {{ formatCurrencyWithDollar(data.brought_forward_bank) }}
        </td>
        <td>
          {{ formatCurrencyWithDollar(data.brought_forward_cash) }}
        </td>
        <td>
          {{ formatCurrencyWithDollar(totalBudgetAllocated(data)) }}
        </td>
        <td>
          <div
            v-if="data.status === SUKU_BUDGET_STATUS.active"
            class="flex items-center text-2sm"
          >
            <span
              class="w-5 h-5 mr-2.5 rounded-[4px] bg-emerald-400/10 inline-flex items-center justify-center text-emerald-400 text-sm"
            >
              <i class="las la-check"></i>
            </span>
            {{ gettext('Active') }}
          </div>
          <div
            v-else-if="data.status === SUKU_BUDGET_STATUS.closed"
            class="flex items-center text-2sm"
          >
            <span
              class="w-5 h-5 mr-2.5 rounded-[4px] bg-pink-700/10 inline-flex items-center justify-center text-pink-700 text-sm"
            >
              <i class="las la-times"></i>
            </span>
            {{ gettext('Closed') }}
          </div>
          <div
            v-else
            class="flex items-center text-2sm"
          >
            <span
              class="w-5 h-5 mr-2.5 rounded-[4px] bg-blue-300/10 inline-flex items-center justify-center text-blue-300 text-sm"
            >
              <i class="las la-ellipsis-h"></i>
            </span>
            {{ gettext('Inactive') }}
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot v-if="!appStore.$state.loading">
      <tr>
        <td class="text-zinc-700 font-black">
          {{ gettext('Total') }}
        </td>
        <td colspan="4">
          <p class="text-xs text-zinc-700 font-bold uppercase">
            {{ gettext('Subsidy allocated') }}
          </p>
          <h4 class="text-xl font-medium mt-[2px]">
            {{ formatCurrencyWithDollar(sumOfPreparationData.subsidy_allocated) }}
          </h4>
        </td>
        <td colspan="2">
          <p class="text-xs text-zinc-700 font-bold uppercase">
            {{ gettext('Total budget allocated') }}
          </p>
          <h4 class="text-xl font-medium mt-[2px]">
            {{ formatCurrencyWithDollar(totalBudgetAllocated(sumOfPreparationData)) }}
          </h4>
        </td>
      </tr>
    </tfoot>
  </table>
  <p
    v-else
    class="text-sm font-light"
  >
    {{ gettext('No data available') }}
  </p>
</template>
