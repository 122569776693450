<script setup lang="ts">
import { ref, computed } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

const props = withDefaults(defineProps<{
  to?: RouteLocationRaw
  hasSubmenu?: boolean
  menuName?: string,
  parentMenu?: string[]
  disabled?: boolean
}>(), {
  menuName: 'header',
  to: undefined,
  parentMenu: undefined
})
const router = useRouter()

const inputRef = ref<HTMLInputElement | null>(null)

const onInput = (el: Event) => {
  const target = el?.target as HTMLInputElement
  const ulParent = inputRef.value?.parentElement?.closest('ul')
  const allCheckbox = ulParent?.querySelectorAll('input')
  allCheckbox?.forEach(checkbox => {
    if (checkbox.id !== target?.id) {
      checkbox.checked = false
    }
  })
}

const subMenuActive = computed(() => {
  if (!props.hasSubmenu || props.parentMenu === undefined) return false
  const checkPath = props.parentMenu.find(it => router?.currentRoute.value.path.split('/').includes(it.replaceAll('/', '')))
  return checkPath !== undefined
})
</script>

<template>
  <li
    v-if="to"
    class="mb-2.5 lg:mb-0 last:mb-0"
  >
    <component
      :is="disabled ? 'span' : 'router-link'"
      class="font-semibold px-5 text-2base py-[13px] relative after:content[` `] after:inline-block after:absolute after:left-0 after:top-0 after:w-[4px] after:h-full hover:bg-black hover:after:bg-emerald-600"
      :to="disabled? '.' : to"
      :class="{
        'transition-all ease-in-out duration-300 block': !hasSubmenu && !disabled,
        'text-white/50 hover:after:bg-gray-500 transition-all ease-in-out duration-300 hover:bg-gray block': disabled
      }"
    >
      <slot></slot>
    </component>
  </li>
  <li
    v-else
    class="py-[13px] mb-2.5 lg:mb-0 last:mb-0 relative hover:bg-black group"
  >
    <div v-if="!hasSubmenu">
      <slot></slot>
    </div>
    <div v-else>
      <input
        :id="`menu-item-${menuName}`"
        ref="inputRef"
        class="hidden peer"
        type="checkbox"
        name="menu"
        value="true"
        :checked="subMenuActive"
        @input="onInput"
      />
      <div class="transition-all ease-in-out duration-100 absolute w-full h-full top-0 left-0 group-hover:opacity-100 peer-checked:opacity-100 bg-black z-0 opacity-0 border-l-4 border-l-black group-hover:border-l-teal-500 peer-checked:!border-l-black"></div>
      <label
        class="font-semibold px-5 text-2base relative inline-block cursor-pointer z-10"
        :for="`menu-item-${menuName}`"
      >
        <slot name="toggle"></slot> <i
          class="las la-angle-right absolute -right-1 top-1/2 -translate-y-[calc(50%)] inline-block peer-checked:rotate-90 transition-all ease duration-100"
        ></i>
      </label>
      <div class="max-h-0 peer-checked:pt-3 overflow-hidden transition-all ease-in-out duration-[1000] peer-checked:max-h-[1000px] relative z-10">
        <slot name="submenu"></slot>
      </div>
    </div>
  </li>
</template>
<style scoped>
input:checked~label i {
  @apply rotate-90;
}
.active {
  @apply bg-black;
}
.router-link-active {
  @apply bg-emerald-600 hover:bg-emerald-600;
}
</style>
