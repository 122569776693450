
export const loginSchema = [
  {
    $formkit: 'textFloating',
    name: 'username',
    label: '$gettext("Username")',
    validation: 'required',
    prefixIcon: 'user',
    required: true,
    innerClass: 'bg-white'

  },
  {
    $formkit: '$passwordFieldType',
    name: 'password',
    label: '$gettext("Password")',
    validation: 'required|length:8',
    prefixIcon: 'lock',
    required: true,
    suffixIcon: '$passwordFieldIcon',
    bind: '$passwordFieldAttributes',
    innerClass: 'bg-white'
  }
]

export const forgotPasswordSchema = [
  {
    $formkit: 'textFloating',
    name: 'email',
    label: '$gettext("email address")',
    validation: 'required|email',
    prefixIcon: 'user',
    required: true,
    innerClass: 'bg-white'

  }
]

export const resetPasswordSchema = [
  {
    $formkit: '$passwordFieldType',
    name: 'password',
    label: '$gettext("Password")',
    validation: 'required|length:8|matches:/^(?=.*[a-zA-Z])(?=.*\\d)(?=.*\\W).+/',
    prefixIcon: 'lock',
    required: true,
    validationMessages: {
      required: '$gettext("This field is required.")'
    },
    suffixIcon: '$passwordFieldIcon',
    bind: '$passwordFieldAttributes',
    innerClass: 'bg-white'
  },
  {
    $formkit: '$confirmPasswordFieldType',
    name: 'password_confirm',
    label: '$gettext("Confirm password")',
    validation: 'required|confirm',
    prefixIcon: 'lock',
    required: true,
    validationMessages: {
      required: '$gettext("This field is required.")'
    },
    suffixIcon: '$confirmPasswordFieldIcon',
    bind: '$confirmPasswordFieldAttributes',
    innerClass: 'bg-white'
  }
]
