<script setup lang="ts">
import ContentSection from '../base/ContentSection.vue'
import BasicCard from '../commons/BasicCard.vue'
import InteractiveMap from '../commons/InteractiveMap.vue'
import CardGroup from './CardGroup.vue'
import { computed } from 'vue'
import { useAppStore } from '../../stores/app'
import { SectorColors } from '../../_types/colours'
import { centerTextPlugin, DoughnutOptions, BarOptions } from '../../_helpers/chartLayouts'
import { useI18NStore } from '../../stores/i18n'
import ChartLegend from '../ChartLegend.vue'
import { useOptionsStore } from '../../stores/options'
import { Bar, Doughnut } from 'vue-chartjs'
import { Plugin } from 'chart.js'

const i18nStore = useI18NStore()
const optionsStore = useOptionsStore()
optionsStore.initialize()
const appStore = useAppStore()

await appStore.fetchLandingPageData()

const projectByDistrictData = computed(() => appStore.getProjectByDistrictChart)
const projectBySectorData = computed(() => appStore.getProjectBySectorChart)

const projectBySectorLegend = computed(() => {
  return appStore.getProjectBySectorLabels.map(it => {
    return {
      // Translates the label if the options store is ready
      label: optionsStore.getLabel('sector', it.id) ?? it.label,
      backgroundColor: SectorColors.get(it.id ?? 1),
      percentage: it.percentage
    }
  })
})

const TotalProjects: Plugin<'doughnut'> = {
    id: 'count',
    afterDatasetsDraw: centerTextPlugin(
      { text: appStore.getTotalProjects, textSize: '25px' })
  }

const LabelTotal: Plugin<'doughnut'> = {
    id: 'total',
    afterDatasetsDraw: centerTextPlugin(
      { text: i18nStore.gettext('Total'), textSize: '18px', yOffset: 30 })
  }

const SectorChartOptions = structuredClone(DoughnutOptions)
if (SectorChartOptions.plugins?.datalabels) {
  SectorChartOptions.plugins.datalabels.display = false
}

</script>
<template>
  <Transition appear>
    <content-section :has-border="false">
      <h5 class="text-xl lg:text-2.5xl font-black mt-3.5">
        {{ gettext("Key national indicators") }}
      </h5>
      <CardGroup class="mt-8 lg:mt-10" />
    </content-section>
  </Transition>
  <Transition appear>
    <content-section :has-border="false">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-7.5">
        <basic-card class="min-h-[288px]">
          <h4 class="text-xl font-extrabold">
            {{ gettext('Projects by Municipalities') }}
          </h4>
          <h5 class="text-zinc-700 text-base mt-2">
            {{ gettext('Number of total PNDS projects by municipality') }}
          </h5>
          <div class="flex h-full items-center lg:pb-10">
            <Bar
              :data="projectByDistrictData"
              :options="BarOptions"
            />
          </div>
        </basic-card>
        <basic-card class="min-h-[288px]">
          <h4 class="text-xl font-extrabold mb-2">
            {{ gettext('Projects per Sector') }}
          </h4>
          <h5 class="text-zinc-700 text-base mt-2 mb-4">
            {{ gettext('Total national percentage distribution of projects by sectors') }}
          </h5>
          <div class="block 3xl:flex items-center">
            <div style="max-height:300px; display: inline-table;">
              <Doughnut
                :data="projectBySectorData"
                :options="SectorChartOptions"
                :plugins="[TotalProjects, LabelTotal]"
              />
            </div>
            <chart-legend :legend-items="projectBySectorLegend" />
          </div>
        </basic-card>
      </div>
    </content-section>
  </Transition>
  <content-section :has-border="false">
    <h5 class="text-xl lg:text-2.5xl font-black">
      {{ gettext("Explore national projects") }}
    </h5>
    <basic-card class="!p-0 mt-8 lg:mt-10">
      <InteractiveMap />
    </basic-card>
  </content-section>
</template>
