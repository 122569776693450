<script setup lang="ts">
import TooltipBox from '../../components/commons/TooltipBox.vue'
import BasicCard from '../../components/commons/BasicCard.vue'

import { components } from '../../_api-services/openapi'
import { OptionGroupMap } from '../../_pndsdata/idb_pndsdata'
import { PROJECT_STATUS_ICON } from '../../_constants/submission.constant'

import { formatDateRange } from '../../_helpers/timedata.helpers'
import { ComputedRef, computed } from 'vue'

const projectStatus: ComputedRef<number> = computed(() => {
  // When the project is "approved", status 1, consistently show
  // the value depending on the project completion.
  if (
    props.submission.project_status &&
    props.submission.project_status === 1 &&
    props.completion
  ) {
    if (props.completion && props.completion < 100) {
      return 2
    } else if (props.completion && props.completion >= 100) {
      return 3
    }
  }
  return props.submission.project_status ?? 1
}
)

type Props = {
  submission: Pick<components['schemas']['ProjectInfo_Tf_6_1_1'], 'date_start' | 'date_finish' | 'district' | 'administrative_post' | 'suco' | 'aldeia' | 'project_status'>,
  projectActivity: number | undefined
  selectOptions: OptionGroupMap
  zSucoId: number | undefined
  completion: number | undefined
}

const props = defineProps<Props>()

</script>
<template>
  <basic-card class="!shadow-none !rounded-2.5xl !text-left overflow-visible">
    <div class="px-7 pt-2">
      <h2 class="text-xl font-bold flex">
        <i class="las la-expand mr-3 text-2xl relative top-[2px] text-emerald-600"></i> {{ gettext('Project snapshot') }}
      </h2>
      <div class="flex items-center mt-5">
        <i class="las la-calendar mr-4"></i>
        <div>
          <p class="text-zinc-700 text-sm leading-tight">
            {{ gettext('Project dates') }}
          </p>
          <p class="text-asd-700 font-bold">
            {{ formatDateRange(submission.date_start, submission.date_finish) }}
          </p>
        </div>
      </div>
      <div class="flex items-center mt-5">
        <i class="las la-tasks mr-4"></i>
        <div>
          <p class="text-zinc-700 text-sm leading-tight">
            {{ gettext('Project activity') }}
          </p>
          <p class="text-zinc-700 font-bold">
            {{ selectOptions.getLabel('cycle', projectActivity) ?? '-' }}
          </p>
        </div>
      </div>

      <div
        v-if="projectStatus"
        class="flex items-center mt-5"
      >
        <span
          class="w-4 h-4 inline-flex items-center justify-center rounded-[.25rem] font-bold mr-4 text-xs"
          :class="PROJECT_STATUS_ICON[projectStatus].boxClass"
        >
          <i
            class="las"
            :class="PROJECT_STATUS_ICON[projectStatus].icon"
          ></i>
        </span>
        <div>
          <p class="text-zinc-700 text-sm leading-tight">
            {{ gettext('Project status') }}
          </p>
          <p class="text-asd-700 font-bold">
            {{ selectOptions.getLabel('subprojectstatus1', projectStatus) }}
          </p>
        </div>
      </div>
    </div>
    <hr class="my-8 w-[calc(100%+2rem)] relative -left-4" />
    <div class="px-7 pb-2">
      <div class="flex items-start mt-5">
        <i class="las la-map-marker mr-4 relative top-3"></i>
        <div class="grid grid-cols-2 gap-x-6 gap-y-4">
          <div>
            <p class="text-zinc-700 text-sm leading-tight">
              {{ gettext('Municipality') }}
            </p>
            <tooltip-box
              v-if="submission.district"
              :text="gettext('Filter projects by Municipality')"
            >
              <router-link
                :to="{
                  name: 'projectsIndex',
                  query: {
                    district: submission.district
                  }
                }"
                class="text-blue-400 font-bold underline"
              >
                {{ selectOptions.getLabel('munisipiu', submission.district) }}
              </router-link>
            </tooltip-box>
          </div>
          <div v-if="submission.district && submission.administrative_post">
            <p class="text-zinc-700 text-sm leading-tight">
              {{ gettext('Admin post') }}
            </p>
            <tooltip-box :text="gettext('Filter projects by Administrative Post')">
              <router-link
                :to="{
                  name: 'projectsIndex',
                  query: {
                    district: submission.district,
                    administrative_post: submission.administrative_post
                  }
                }"
                class="text-blue-400 font-bold underline"
              >
                {{ selectOptions.getLabel('postuadministrativu', submission.administrative_post) }}
              </router-link>
            </tooltip-box>
          </div>
          <div v-if="submission.district && submission.administrative_post && submission.suco">
            <p class="text-zinc-700 text-sm leading-tight">
              {{ gettext('Suku') }}
            </p>
            <tooltip-box :text="gettext('Link to the Suku profile.')">
              <router-link
                :to="{
                  name: 'sukuProfile',
                  params: {
                    id: zSucoId,
                    section: 'profile'
                  }
                }"
                class="text-blue-400 font-bold underline"
              >
                {{ selectOptions.getLabel('suku', submission.suco) }}
              </router-link>
            </tooltip-box>
          </div>
          <div v-if="submission.district && submission.administrative_post && submission.suco && submission.aldeia">
            <p class="text-zinc-700 text-sm leading-tight">
              {{ gettext('Aldeia') }}
            </p>
            <p class="text-asd-700 font-bold">
              {{ selectOptions.getLabel('aldeia', submission.aldeia) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </basic-card>
</template>
