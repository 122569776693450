<script setup lang="ts">
import { reactive, ref, onMounted, onUpdated } from 'vue'
/**
 * Custom Input component
 * for icons please refer to https://icons8.com/line-awesome
 */
const props = withDefaults(defineProps<{
  name: string
  type: string
  autofocus?: boolean
  autocapitalize?: string
  autocomplete?: string
  maxlength?: string
  required?: boolean
  id?: string
  icon?: string
  label?: string
  modelValue: string
  suffixIcon?: string
  placeholder?: string
  value?: string
  readonly?: boolean
}>(), {
  name: 'fieldName',
  autocapitalize: 'off',
  autocomplete: 'off',
  maxlength: undefined,
  id: undefined,
  icon: undefined,
  label: '',
  suffixIcon: undefined,
  placeholder: '',
  value: ''
})

const state = reactive({
  onFocus: false,
  showPassword: false,
  label: undefined
})

const inputValue = ref('')
const inputRef = ref(null)

const emit = defineEmits<{(event: 'update:modelValue', payload: string): void;}>()

const input = ($event: Event) => {
  inputValue.value = ($event.target as HTMLInputElement).value
  emit('update:modelValue', ($event.target as HTMLInputElement).value)
}

onMounted(() => {
  inputValue.value = props.modelValue
})

onUpdated(() => {
  inputValue.value = props.modelValue
})

</script>
<template>
  <div
    class="input-wrapper"
    :class="{ 'shadow-md': state.onFocus && !readonly }"
  >
    <i
      v-if="suffixIcon"
      class="las"
      :class="suffixIcon"
    ></i>
    <input
      :id="id"
      ref="inputRef"
      :value="inputValue"
      :name="name"
      :type="state.showPassword ? 'text' : type"
      :autofocus="autofocus"
      :autocapitalize="autocapitalize"
      :maxlength="maxlength"
      :required="required"
      class="!bg-transparent appearance-none !border-none !outline-none w-full h-full pl-0 pr-0 pb-0 relative z-10"
      :class="{ 'pt-2': label, 'pt-0': !label }"
      :placeholder="placeholder"
      :readonly="readonly"
      @focus="state.onFocus = true"
      @blur="state.onFocus = inputValue.length > 0"
      @input="input"
    />
    <i
      v-if="icon"
      class="las"
      :class="icon"
    ></i>
    <label
      v-if="label"
      class="absolute left-11"
    >{{ label }}</label>
    <button
      v-if="type === 'password'"
      class="absolute right-3 top-1/2 transform -translate-y-1/2 z-20 text-emerald-600 text-2xl"
      type="button"
      @click="state.showPassword = !state.showPassword"
    >
      <i
        class="las"
        :class="state.showPassword ? 'la-eye-slash': 'la-eye'"
      ></i>
    </button>
  </div>
</template>

<style scoped lang="postcss">
input {
  box-shadow: none !important;
}
.input-wrapper {
  height: 3.125rem;
  box-shadow: 0px 10px 25px rgba(89,105,114,0.07);
  @apply rounded-xl border border-light flex items-center pl-4.5 flex-row-reverse relative text-zinc-700 text-base;
  > i {
    @apply text-2xl mr-3 inline-block;
  }
  > label {
    @apply transition-all ease-in-out duration-200 top-3 left-12;
  }

  input {
    &:focus, &:valid {

      ~ label {
        @apply text-2xs transform -translate-y-3;
      }
    }

    &:valid {
      ~ i {
        @apply text-emerald-600;
      }
    }
  }
}
</style>
