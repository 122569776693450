// Refresh the data store for pnds db data

import { client } from '../_api-services/urls'
import { getPndsDataDb, Output, SlimSector, SlimSubsector } from './idb_pndsdata'
import { isEqual } from 'lodash-es'
import { isNumeric } from '../_types/commons'
import { del, get, set } from '../_helpers/idb_keyval'
import { useI18NStore } from '../stores/i18n'
import { useToast } from 'vue-toastification'

const API_URL = '/api/pnds_data/sectors'
const toast = useToast()

async function findSectorChanges (fromRequest: SlimSector[]) {
  const fromDb = await (await getPndsDataDb()).getAll('sector')
  const keysInDbArray = fromDb.map((it) => it.sector_id)
  const keysInDb = new Set(keysInDbArray)
  const keysInRequest = new Set(fromRequest.map((it) => it.sector_id))
  const adds = fromRequest.filter((it) => !keysInDb.has(it.sector_id))
  const deletes = keysInDbArray.filter((it) => !keysInRequest.has(it)).filter(isNumeric)
  const updates = fromRequest.filter((it) => keysInDb.has(it.sector_id) && !isEqual(it, fromDb.find((itIndb) => it.sector_id === itIndb.sector_id)))
  return { adds, deletes, updates }
}

async function applySectorChanges ({ adds, deletes, updates }: { adds: SlimSector[], deletes: number[], updates: SlimSector[] }) {
  const storeName = 'sector'
  const db = await getPndsDataDb()
  const tx = db.transaction(storeName, 'readwrite')
  await Promise.all([
    Promise.all(adds.map(async (it) => (await tx.store.add(it)))),
    Promise.all(deletes.map(async (id) => await tx.store.delete(id))),
    Promise.all(updates.map(async (it) => await tx.store.put(it))),
    tx.done]
  )
}

async function findSubsectorChanges (fromRequest: SlimSubsector[]) {
  const fromDb = await (await getPndsDataDb()).getAll('subsector')
  const keysInDbArray = fromDb.map((it) => it.sub_sectorid)
  const keysInDb = new Set(keysInDbArray)
  const keysInRequest = new Set(fromRequest.map((it) => it.sub_sectorid))
  const adds = fromRequest.filter((it) => !keysInDb.has(it.sub_sectorid))
  const deletes = keysInDbArray.filter((it) => !keysInRequest.has(it)).filter(isNumeric)
  const updates = fromRequest.filter((it) => keysInDb.has(it.sub_sectorid) && !isEqual(it, fromDb.find((itIndb) => it.sub_sectorid === itIndb.sub_sectorid)))
  return { adds, deletes, updates }
}

async function applySubsectorChanges ({ adds, deletes, updates }: { adds: SlimSubsector[], deletes: number[], updates: SlimSubsector[] }) {
  const storeName = 'subsector'
  const db = await getPndsDataDb()
  const tx = db.transaction(storeName, 'readwrite')
  await Promise.all([
    Promise.all(adds.map(async (it) => (await tx.store.add(it)))),
    Promise.all(deletes.map(async (id) => await tx.store.delete(id))),
    Promise.all(updates.map(async (it) => await tx.store.put(it))),
    tx.done]
  )
}

async function findOutputChanges (fromRequest: Output[]) {
  const fromDb = await (await getPndsDataDb()).getAll('outputs')
  const keysInDbArray = fromDb.map((it) => it.outputid)
  const keysInDb = new Set(keysInDbArray)
  const keysInRequest = new Set(fromRequest.map((it) => it.outputid))
  const adds = fromRequest.filter((it) => !keysInDb.has(it.outputid))
  const deletes = keysInDbArray.filter((it) => !keysInRequest.has(it)).filter(isNumeric)
  const updates = fromRequest.filter((it) => keysInDb.has(it.outputid) && !isEqual(it, fromDb.find((itIndb) => it.outputid === itIndb.outputid)))
  return { adds, deletes, updates }
}

async function applyOutputChanges ({ adds, deletes, updates }: { adds: Output[], deletes: number[], updates: Output[] }) {
  const db = await getPndsDataDb()
  const tx = db.transaction('outputs', 'readwrite')
  await Promise.all([
    Promise.all(adds.map(async (it) => (await tx.store.add(it)))),
    // Promise.all(deletes.map(async (id) => await tx.store.delete(id))),
    Promise.all(updates.map(async (it) => await tx.store.put(it))),
    tx.done]
  )
}

export async function fetchPndsData () {
  try {
    const { data } = await client.GET(API_URL, {})
    /** Fetch and flatten the request tree */
    // Here we use object destructuring to get the object
    // expect for the nested elements

    // Underscores are because we need to use
    // the names 'subsectors' and 'outputs'
    // in destructuring
    const sectors_: SlimSector[] = []
    const subsectors_: SlimSubsector[] = []
    const outputs_: Output[] = []
    if (typeof data === 'undefined') return
    data.forEach((s) => {
      const { subsectors, ...sector } = s
      if (typeof subsectors === 'undefined') return
      subsectors.forEach((sub) => {
        const { outputs, ...slimsubsector } = sub
        subsectors_.push(slimsubsector)
        if (typeof outputs === 'undefined') return
        outputs_.push(...outputs)
      })
      sectors_.push(sector)
    })
    const sectorMutations = await findSectorChanges(sectors_)
    const subsectorMutations = await findSubsectorChanges(subsectors_)
    const outputMutations = await findOutputChanges(outputs_)
    await applySectorChanges(sectorMutations)
    await applySubsectorChanges(subsectorMutations)
    await applyOutputChanges(outputMutations)
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.warn('SyntaxError caught:', e, API_URL)
    } else {
      throw e
    }
  }
}

const optionKeys = {
  old: [
    '/api/ida_options/options.lastChangeId.v2',
    '/api/ida_options/options.lastChangeId.v3'
  ],
  current: '/api/ida_options/options.lastChangeId.v4'
}

async function nukeOptions () {
  /* If an 'old' key is present in the keyval database,
  remove all the options
  **/
  let requiresReset = false
  for (const k of optionKeys.old) {
    if (typeof (await get(k)) !== 'undefined') {
      await del(k)
      requiresReset = true
    }
  }
  /** clear the 'options store */
  if (requiresReset) {
    const db = await getPndsDataDb()
    const tx = db.transaction('options', 'readwrite')
    await tx.store.clear()
    await tx.done
    db.close()
  }
}

export async function updateOptions () {
  // Loads options into the "options" store from different application endpoints
  // fromDB is a set of strings to detect "collisions"

  const updateLastChangeId = async (db: Awaited<ReturnType<typeof getPndsDataDb>>) => {
    /**
     * This updates the keyval for "last change id"
     * which is read by "updateOptions"
     * It finds the maximum value of the "change ID" property
     * in the options table
     */
    const { store } = db.transaction('options', 'readwrite')
    const maxChangeIdCursor = await store.index('by-change_id').openCursor(null, 'prev')
    const lastChangeId = maxChangeIdCursor?.value.change_id
    if (typeof lastChangeId === 'number') await set(optionKeys.current, lastChangeId)
    return lastChangeId
  }

  const lastChangeId: number | undefined = await get(optionKeys.current) ?? -1

  // Fetch options from `ida_options`
  const url = '/api/ida_options/options'
  try {
    const { data } = await client.GET(url, {
      params: {
        query: {
          last_change_id: lastChangeId
        }
      }
    })
    if (typeof data === 'undefined') return
    await nukeOptions()
    const db = await getPndsDataDb()
    const tx = db.transaction('options', 'readwrite')
    data.map(async (it) => {
      await tx.store.put(it)
    })

    // Let all the transactions finish before reading the last change ID
    await tx.done
    const newLastChanged = await updateLastChangeId(db)
    db.close()
    // Return true if the last change ID has changed
    return lastChangeId !== newLastChanged
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.warn('SyntaxError caught:', e, url)
    } else if (e instanceof TypeError) {
      console.warn('Failed to Fetch caught:', e, url)
    } else {
      throw e
    }
  }
}
