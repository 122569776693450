/**
 * This is intended to interact with the "pnds_data" IDB database
 */

import { SlimSector, SlimSubsector, Output, getPndsDataDb, groupName, OptionGroupMap, OptionMap, idaOptionToClass } from '../_pndsdata/idb_pndsdata'
import { fetchPndsData, updateOptions } from '../_pndsdata/refresh'

import { defineStore } from 'pinia'
import { languageCodes, useI18NStore } from './i18n'
import { OptionsType } from '../_types/components/forms/select-dropdown'
import { useSubmissionsStore, SubmissionType } from './submissions'

import { client } from '../_api-services/urls'

import { units, zActivities, subActivity1, subActivity2 } from '../_constants/options.constant'
import { components } from '../_api-services/openapi'

type idaOption = components['schemas']['IdaOptionIn']

interface OptionsState {
  allSectors: SlimSector[]
  allSubsectors: SlimSubsector[]
  allOutputs: Output[]
  state: 'not loaded' | 'loading' | 'loaded'
  selectOptions: Record<string, OptionsType>
  // Data fetched from the IDB database
  // is held here
  selectOptionsIDb: OptionGroupMap
}

const initialState: OptionsState = {
  allSectors: [],
  allSubsectors: [],
  allOutputs: [],
  state: 'not loaded',
  selectOptions: { // global select options will be placed here
    unit: units,
    zActivities,
    subActivity1,
    subActivity2
  },
  // Initially this will return empty options
  // until refreshed from IDB
  selectOptionsIDb: new OptionGroupMap()
}

export const useOptionsStore = defineStore('options', {
  state: () => (initialState),
  getters: {
    groupNames: (state) => {
      // CMT positions were removed, this also removes them from the user interface in `options`
      // as they may already be part of the user's data in IDB
      return state.selectOptionsIDb.groupNames().filter((it) => it !== 'cmtposition')
    },
    filterByGroup: (state) => {
      return (group: groupName): OptionMap | undefined => state.selectOptionsIDb.get(group)
    },
    get () {
      return (group: groupName, value: string) => this.selectOptionsIDb.get(group)?.get(value)
    },
    getRelated: (state) => {
      return (option: ReturnType<typeof idaOptionToClass> | undefined, group: groupName) => {
        if (typeof option !== 'undefined') {
          return state.selectOptionsIDb.getRelated(option, group)
        }
      }
    },
    set: (state) => {
      return (opt: idaOption) => {
        const g = state.selectOptionsIDb.get(opt.group_name as groupName)
        if (typeof g === 'undefined') return
        g.set(opt.value, idaOptionToClass(opt))
      }
    },
    getLabel: (state) => {
      // Returns the translated value of an IDA option
      return (group: groupName, value: string | number | undefined, default_ = '') => {
        // Get the option which corresponds to the "option" id and the "label"
        if (typeof value === 'undefined') return default_
        // We use strings for the option value
        if (typeof value === 'number') value = value.toString()
        return state.selectOptionsIDb.getLabel(group, value)
      }
    },
    options: (state) => {
      return (groupName: groupName | 'GROUPS', lang_?: languageCodes | undefined, filterParams?: string[]): OptionsType => {
        const lang = lang_ ?? useI18NStore().code
        if (groupName === 'GROUPS') {
          return state.selectOptionsIDb.groupNames().map((it) => { return { value: it, label: it } })
        }
        const opts = state.selectOptionsIDb.getOpts({ groupName, lang, filterParams })
        if (['year', 'month'].includes(groupName) || (typeof filterParams !== 'undefined' && filterParams.includes('sortByValue'))) {
          opts.sort((a, b) => {
            if (a.value === b.value) return 0
            return (typeof a.value === 'number' ? a : parseInt(a.value)) > (typeof b.value === 'number' ? b : parseInt(b.value)) ? 1 : -1
          })
        }
        if (typeof filterParams !== 'undefined' && filterParams.includes('sortByLabel')) {
          opts.sort((a, b) => {
            if (a.label === b.label) return 0
            return (a.label > b.label ? 1 : -1)
          })
        }
        return opts
      }
    },
    sectors: (state) => {
      return (): OptionsType => {
        console.warn('Deprecated: use `idaOptions` instead')
        const lang = useI18NStore().code
        const groupName: groupName = 'sector'
        return state.selectOptionsIDb.getOpts({ groupName, lang }) ?? []
      }
    },
    subsectors: (state) => {
      // Fetch all the 'subsectors' of a given sector ID
      return (sector: string): OptionsType => {
        console.warn('Deprecated: use `idaOptions` instead')
        const lang = useI18NStore().code
        const groupName: groupName = 'subsector'
        return state.selectOptionsIDb.getOpts({ groupName, lang, filterParams: [sector] }) ?? []
      }
    },
    outputs: (state) => {
      // Fetch all the 'outputs' for a give subsector
      return (subsector?: string): OptionsType => {
        console.warn('Deprecated: use `idaOptions` instead')
        const outputs = typeof subsector !== 'undefined' ? state.allOutputs.filter((it) => it.sub_sector === parseInt(subsector, 10)) : state.allOutputs
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return outputs.map((row) => { return { value: `${row.outputid}`, label: useI18NStore().pgettext('output', row.output!) } })
      }
    },
    getOutputById: (state) => {
      return (id: string): Output | undefined => {
        return state.allOutputs.find((it) => it.outputid === parseInt(id))
      }
    },
    translatedOptions: (state) => {
      // Indicate options to get translated values for with text like `$translatedOptions(["yes", "no"])`
      return (...options: string[]): OptionsType => {
        const i18nStore = useI18NStore()
        return options.map((it) => { return { value: it, label: i18nStore.gettext(it) } })
      }
    },
    allOutputsByTF611result () {
      // get project outputs options based on TF611 verified submission, and filer it by district and admin_post
      return (district?: string, adminPost?: string, suku?: string): OptionsType => {
        const i18nStore = useI18NStore()
        const submissionStore = useSubmissionsStore()
        const options: OptionsType = []
        const seen = new Set<string>()

        const submissions = submissionStore.submissions.filter((submission: SubmissionType) => {
          if (typeof suku !== 'undefined' && suku !== null && suku !== '') {
            return submission.fields.suco === suku
          } else if (typeof adminPost !== 'undefined' && adminPost !== null && suku !== '') {
            return submission.fields.administrative_post === adminPost
          } else if (typeof district !== 'undefined' && district !== null && suku !== '') {
            return submission.fields.district === district
          }
          return submission
        })
        submissions.forEach((s: SubmissionType) => {
          if (typeof s.fields.project_name !== 'undefined') {
            const projectName = (s.fields.project_name as string | number).toString()
            if (!seen.has(projectName)) {
              seen.add(projectName)
              const opt = this.get('output', projectName)
              if (typeof opt !== 'undefined') {
                options.push({ label: opt.label.translated(i18nStore.code) ?? '?', value: opt.value })
              }
            }
          }
        })
        return options
      }
    },
    getOutputNameById: (state) => {
      return (outputId: string): string | undefined => {
        const output = state.allOutputs.find((output: Output) => output.outputid === parseInt(outputId))
        return typeof output !== 'undefined' ? output?.output : outputId
      }
    },
    getSectorById: (state) => {
      return (sectorId: string): SlimSector | undefined => {
        const sector = state.allSectors.find(sector => sector.sector_id === parseInt(sectorId))
        return sector
      }
    },
    getTranslatedSelectOptions: (state) => { // translate selectOptions
      return (key: string, translateKey?: string) => {
        const i18nStore = useI18NStore()
        return state.selectOptions[key].map((it) => { return { value: it.value, label: typeof translateKey === 'undefined' ? i18nStore.gettext(it.label) : i18nStore.pgettext(translateKey, it.label) } })
      }
    },
    getSelectOptionsOptionByValue: (state) => { // get translated label by select options value
      return (key: string, value: string) => {
        const i18nStore = useI18NStore()
        const label = state.selectOptions[key].find(it => it.value === value)?.label ?? ''
        return i18nStore.pgettext(key, label)
      }
    }
  },
  actions: {
    async initialize () {
      this.selectOptionsIDb = await this.selectOptionsIDb.getOptions()
      void updateOptions().then(async () => { this.selectOptionsIDb = await this.selectOptionsIDb.getOptions() })
      if (this.state !== 'not loaded') return
      this.state = 'loading'
      await fetchPndsData()
      const db = await getPndsDataDb()
      await db.getAll('sector').then((sectors) => this.allSectors.push(...sectors))
      await db.getAll('subsector').then((it) => this.allSubsectors.push(...it))
      await db.getAll('outputs').then((it) => this.allOutputs.push(...it))
      this.state = 'loaded'
      // Load data from the store options, if available
      // Load data from the "options" endpoint, if available, then update the store's options
    },

    async PostOption (body: idaOption) {
      const { data, error, response } = await client.POST('/api/ida_options/options', { body })
      if (typeof data !== 'undefined') {
        await updateOptions()
        await this.selectOptionsIDb.getOptions()
      }
      return { data, error, response }
    }
  }
})
