<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import { reactive, ref, onMounted } from 'vue'
import { useProjectManagerStore } from '../../stores/projectmanager'
import { getCsrf } from '../../_helpers/common.helpers'
import { useToast } from 'vue-toastification'
import { useI18NStore } from '../../stores/i18n'
import { IMAGE_JPG, IMAGE_PNG, PDF } from '../../_constants/fileupload.constant'

const props = withDefaults(defineProps<{
  fileLabel?: string
  formKey: string | undefined
}>(), {
  fileLabel: ''
})

const emit = defineEmits <{(e: 'upload', value: FileList | null): void}>()
const dropzone = ref()
const i18nStore = useI18NStore()
const toast = useToast()
const projectManagerStore = useProjectManagerStore()
const uploadState = reactive({
  progress: 0,
  isLoading: false,
  activeFileName: '',
  isDragged: false
})

const xhrUpload = async (formData: FormData): Promise<string> => {
  const response = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', '/api/submissions/file', true)
    xhr.setRequestHeader('X-CSRFToken', getCsrf())
    xhr.upload.onprogress = (event: ProgressEvent) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100)
        uploadState.progress = progress
        uploadState.isLoading = true
      }
    }
    xhr.onload = () => {
      toast.success(i18nStore.gettext('File uploaded successfully'))
      resolve(xhr.responseText)
    }
    xhr.onerror = () => {
      toast.warning(i18nStore.gettext('File did not upload successfully'))
      reject(xhr.statusText)
    }
    xhr.send(formData)
  })

  return response as string
}

const onFileInput = async (event: Event) => {
  if (!(event.target instanceof HTMLInputElement)) return
  const files = event.target.files
  if (files === null) return
  if (props.formKey === undefined) throw new Error('FormKey is not provided')
  uploadState.isLoading = true
  for (let index = 0; index < files.length; index++) {
    if (files[index].type !== IMAGE_JPG && files[index].type !== IMAGE_PNG && files[index].type !== PDF) {
      toast.error(i18nStore.interpolate(i18nStore.gettext('%(fileName)s has unsupported file type'), { fileName: files[index].name }))
    } else {
      const formDataUpload = new FormData()
      formDataUpload.append('submission', props.formKey)
      formDataUpload.append('comment', props.fileLabel)
      formDataUpload.append('file', files[index])
      uploadState.activeFileName = files[index].name
      await xhrUpload(formDataUpload)
    }
  }
  uploadState.isLoading = false
  setTimeout(() => {
    emit('upload', files)
  }, 2000)
}

onMounted(() => {
  dropzone.value.addEventListener('dragover', () => {
    uploadState.isDragged = true
  })
  dropzone.value.addEventListener('dragleave', () => {
    uploadState.isDragged = false
  })
  dropzone.value.addEventListener('drop', () => {
    uploadState.isDragged = false
  })
})
</script>
<template>
  <div
    ref="dropzone"
    :class="{ 'dragged': uploadState.isDragged }"
  >
    <FormKit
      id="dropzone"
      type="file"
      :label="gettext('Upload an image')"
      accept=".pdf,.png,.jpg,.jpeg"
      input-class="absolute top-0 w-full h-full left-0 opacity-0 cursor-pointer"
      wrapper-class="px-5 relative flex items-center rounded-[5px] border-2 border-dashed border-emerald-600 min-h-[204px] bg-white/50 transition-all ease-in-out duration-500"
      no-files-class="hidden"
      file-remove-class="hidden"
      file-list-class="hidden"
      multiple="true"
      @change="onFileInput"
    >
      <template #label="context">
        <label
          :for="context.id"
          :class="{ 'hidden': uploadState.isLoading }"
          class="text-base whitespace-nowrap font-bold w-full inline-flex justify-center items-center cursor-pointer la-cloud-upload-alt before:font-icon before:mr-2 before:text-xl h-[50px] pl-5 pr-4 after:text-base after:font-bold after:ml-4 rounded-2.5xl bg-emerald-600 text-white formkit-disabled:opacity-50 formkit-disabled:pointer-events-none"
        >{{ context.label }}</label>
        <div
          v-if="uploadState.isLoading"
          class="absolute text-center left-0 right-0 text-base font-bold leading-tight"
        >
          <span class="las text-2xl mb-1 la-cloud-upload-alt"></span>
          <p>{{ gettext('Uploading') }}</p>
          <p>'{{ uploadState.activeFileName }}'</p>
          <h2 class="text-2xl mt-2">
            {{ uploadState.progress }}%
          </h2>
        </div>
        <div
          v-if="uploadState.isLoading"
          class="absolute h-2 left-0 bottom-0 bg-zinc-400 w-full"
        >
          <div
            class="h-2 bg-green-300 transition-all ease-in-out duration-300 block w-0 rounded-br-md rounded-tr-md"
            :style="{ 'width': `${uploadState.progress}%` }"
          ></div>
        </div>
      </template>
    </FormKit>
  </div>
</template>
<style lang="postcss">
.dragged {
  .formkit-wrapper {
    @apply bg-white/80 border-solid;
  }

}
</style>
