import { defineStore } from 'pinia'
import { getCsrf, objectToFormData } from '../_helpers/common.helpers'
import type { DjangoModel } from '../_django_model'

export enum StoreState {
  INITIAL,
  QUERYING,
  ERRORED,
  IDLE
}

interface IGroup {
  name: string
}

export class Group implements IGroup {
  model: DjangoModel
  constructor (model: DjangoModel) {
    this.model = model
  }

  get name (): string {
    return this.model.fields.name as string
  }

  get id (): number {
    return this.model.pk as number
  }
}

const groupListPath = '/accounts_api/groups/'
const groupCreatePath = '/accounts_api/group/create'
function getGroupUpdatePath (id: string | number): string {
  return `/accounts_api/group/${id}/update`
}
function getGroupDeletePath (id: string | number): string {
  return `/accounts_api/group/${id}/delete`
}

export interface GroupsState {
  state: StoreState
  groups?: Group[]
}
const initialState: GroupsState = {
  state: StoreState.INITIAL
}

export interface GroupReturn {
  group?: Group
  error?: 'notfound' | 'network'
}
export const useGroupStore = defineStore('groups', {
  state: () => (initialState),
  getters: {
    groupOptions: ({ groups }) => {
      return groups !== undefined
        ? groups.map((g) => ({ value: g.id, label: g.name }))
        : undefined
    }
  },
  actions: {
    async initialize () {
      if (this.state !== StoreState.QUERYING) {
        this.state = StoreState.QUERYING
        try {
          const response = await fetch(groupListPath)
          if (response.ok) {
            const models = await response.json() as DjangoModel[]
            this.groups = models.map(m => new Group(m))
            this.state = StoreState.IDLE
          } else {
            this.state = StoreState.ERRORED
          }
        } catch {
          this.state = StoreState.ERRORED
        }
      }
    },

    localGroup (id: string): Group | undefined {
      return this.groups !== undefined
        ? this.groups.find((g) => {
          return `${g.id}` === id
        })
        : undefined
    },

    async getGroup (id: string): Promise<GroupReturn> {
      let group = this.localGroup(id)
      if (group !== undefined) return { group }
      await this.initialize()
      if (this.state === StoreState.ERRORED) return { error: 'network' }
      group = this.localGroup(id)
      if (group !== undefined) return { group }
      return { error: 'notfound' }
    },

    async updateGroup (group: Group): Promise<{
      success: boolean
      errors?: any
    }> {
      const response = await fetch(
        getGroupUpdatePath(group.model.pk),
        {
          method: 'POST',
          body: objectToFormData({
            name: group.name
          }),
          headers: {
            Accept: 'application/json',
            'X-CSRFToken': getCsrf()
          }

        }
      )
      if (response.ok) {
        return {
          success: true
        }
      } else {
        const errorResponse = await response.json()
        return {
          success: false,
          errors: errorResponse
        }
      }
    },

    async deleteGroup (group: Group): Promise<boolean> {
      const response = await fetch(
        getGroupDeletePath(group.model.pk),
        {
          method: 'POST',
          headers: {
            'X-CSRFToken': getCsrf()
          }
        }
      )
      return response.ok
    },

    async addGroup (group: Record<string, string | undefined>): Promise<{
      success: boolean
      errors?: any
    }> {
      const payload: Record<string, string | undefined> = {
        name: group.name
      }
      const response = await fetch(
        groupCreatePath,
        {
          method: 'POST',
          body: objectToFormData(payload),
          headers: {
            Accept: 'application/json',
            'X-CSRFToken': getCsrf()
          }
        }
      )
      const returnData = {
        success: response.ok,
        errors: undefined,
        resetLink: ''
      }
      if (response.ok) {
        returnData.resetLink = await response.text()
      } else {
        returnData.errors = await response.json()
      }
      return returnData
    }
  }
})
