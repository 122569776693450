import { App, Plugin } from 'vue'
import Toast, { PluginOptions, POSITION, TYPE } from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

const options: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
  pauseOnHover: true,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  toastClassName: 'toastr',
  icon: {
    iconClass: 'undefined',
    iconChildren: '',
    iconTag: 'i'
  },
  transition: {
    enter: 'fade-enter-active',
    leave: 'fade-leave-active',
    move: 'fade-move'
  },
  rtl: false,
  toastDefaults: {
    [TYPE.SUCCESS]: {
      icon: {
        iconClass: 'las la-check-circle text-xl',
        iconChildren: '',
        iconTag: 'i'
      }
    },
    [TYPE.ERROR]: {
      icon: {
        iconClass: 'las la-exclamation-circle',
        iconChildren: '',
        iconTag: 'i'
      }
    },
    [TYPE.ERROR]: {
      icon: {
        iconClass: 'las la-info-circle',
        iconChildren: '',
        iconTag: 'i'
      }
    }
  }
}

export const toastrPlugin: Plugin = {
  install (app: App) {
    app.use(Toast, options)
  }
}
