/**
 * Contains type definitions for language translations
 * Specifically,
 * a `Translatable` represents a row retrieved from our Django database
 * a `Message` or `extendedMessage` represents a value we store in IDB
 * a mapping of `maybeContextualString` to  `singularOrPlural` represents how gettext sees translations
 */

import { components } from '../_api-services/openapi'

const languages = [{ code: 'tet', name: 'Tetum' }, { code: 'pt', name: 'Portuguese' }, { code: 'en', name: 'English' }]

/**
 * This is the return value from our 'Django like' endpoints
 * It parallels the database table
 */
type Translatable = components['schemas']['Translatable']

interface interpolationValues { [key: string]: number | string }

/**
 * This lets us check for any missing translations
 * to be posted to the server
 */
type missingTranslationHandler = (m: Partial<Translatable>) => void

/**
 * gettext allows 'context' translations
 * These are defined in Django as strings separated by a `/x04` character
 */
type maybeContextualString = string

type singularOrPlural = string | string[]

/** This is very close to the gettext definition of a translated item
 * By splitting the `key` on the separator you would get the `context`
 * Depending on whether the value is a string or array (this is `csep`, or `\x04`) you're looking at
 * a plural or singular translation
 */
interface CatalogContent {
  [key: maybeContextualString]: singularOrPlural
}

type fetchInitial = (language: string) => Promise<Translatable[]>
type fetchInitialHash = () => Promise<{ [code: string]: Translatable[] }>
type saveMessages = (language: string, messages: Translatable[]) => Promise<void>
export type {
  Translatable, singularOrPlural, maybeContextualString, fetchInitial, fetchInitialHash, saveMessages, interpolationValues, missingTranslationHandler, CatalogContent
}

export {
  languages
}
