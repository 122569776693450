<script setup lang="ts">
import MainNav from '../components/base/MainNav.vue'
import ContentSection from '../components/base/ContentSection.vue'
import LanguageToggle from '../components/forms/LanguageToggle.vue'
import MainFooter from '../components/base/MainFooter.vue'
import LoadingAnimation from '../components/commons/LoadingAnimation.vue'
import { computed, ComputedRef } from 'vue'
import { useAppStore } from '../stores/app'

const props = withDefaults(defineProps<{
  title?: string
  subTitle?: string
  titleBackgroundColor?: boolean
}>(), {
  title: '',
  subTitle: '',
  titleBackgroundColor: false
})

const appStore = useAppStore()
const isLoading: ComputedRef<boolean> = computed(() => appStore.$state.loading)

</script>
<template>
  <div class="min-h-screen flex flex-col">
    <div class="flex-1 flex flex-col lg:flex-row">
      <main class="lg:w-[calc(100%-260px)] lg:mr-0 lg:ml-auto">
        <LoadingAnimation
          v-if="isLoading"
          theme="fixed"
        />
        <content-section :class="{ 'relative bg-[#eeeee4] after:content-[` `] after:inline-block after:h-full after:absolute after:top-0 after:-left-10 after:w-12 after:bg-[#eeeee4]': titleBackgroundColor }">
          <div class="flex flex-wrap justify-between items-center">
            <div v-if="title">
              <h3 class="text-2.5xl md:text-2.75xl font-black font-sans">
                {{ title }}
              </h3>
              <p class=" text-left text-zinc-700">
                {{ subTitle }}
              </p>
            </div>
            <div v-else>
              <slot name="title"></slot>
            </div>
            <slot name="info"></slot>
          </div>
        </content-section>
        <slot></slot>
        <content-section
          :has-border="false"
        >
          <MainFooter />
        </content-section>
      </main>
      <MainNav />
    </div>
  </div>
</template>
