<script setup lang="ts">
import Vue3EasyDataTable from 'vue3-easy-data-table'
import IconCircleBox from './IconCircleBox.vue'
import BasicButton from './BasicButton.vue'
import DropdownSelectable from '../forms/DropdownSelectable.vue'
import TextField from '../forms/TextField.vue'
import DropdownGroup from '../commons/DropdownGroup.vue'
import type { Header, Item, ClickRowArgument, FilterOption, ServerOptions, HeaderItemClassNameFunction, BodyItemClassNameFunction } from 'vue3-easy-data-table'
import { ref, computed, toRaw, Ref, reactive, onMounted, watch } from 'vue'
import { router } from '../../router'
import { LooseObject } from '../../_types/commons'
import { OptionType } from '../../_types/components/forms/select-dropdown'
import { DataTableFilterOptions } from '../../_types/components/commons/datatable'
import { useI18NStore } from '../../stores/i18n'
import { FormKitSchema, FormKit } from '@formkit/vue'
import { FormKitSchemaFormKit } from '@formkit/core'
import { useOptionsStore } from '../../stores/options'
import { useLocationStore } from '../../stores/locations'
import { ORDER_LABEL_MOVE_TO_TOP, ORDER_LABEL_MOVE_DOWN, ORDER_LABEL_MOVE_TO_BOTTOM, ORDER_LABEL_MOVE_UP } from '../../_constants/table.constant'
import { generateYears, strCapitalized } from '../../_helpers/common.helpers'
import { SUBMISSIONS_STATUS_NAME, DATA_COLLECTION_STATUS, FORM_SCHEMA_STATUS_DRAFT, FORM_SCHEMA_STATUS_PUBLISHED } from '../../_constants/common.constant'
import { complaintStatus } from '../../_constants/options.constant'
import Sortable from 'sortablejs'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { PROJECT_STATUS_ICON } from '../../_constants/submission.constant'
import CustomScrollbar from './CustomScrollbar.vue'
import { useRoute } from 'vue-router'

const optionsStore = useOptionsStore()
const i18Store = useI18NStore()
const locationStore = useLocationStore()
const route = useRoute()

export interface ReOrderContext {
  item: Item
  newOrder: number
}

const props = withDefaults(defineProps<{
  headers: Header[]
  items: Item[]
  redirectPath?: string
  selectable?: boolean
  hasDeleteButton?: boolean
  spacing?: string
  filters?: DataTableFilterOptions
  enableFilterBySubmissionDate?: boolean
  sortBy?: string
  sortType?: string,
  filterPlaceholder?: string
  filterPrefixIcon?: string
  filterSuffixIcon?: string
  filterSize?: string
  theme?: string
  filterFieldsSchema?: FormKitSchemaFormKit[] | undefined
  themeColor?: string,
  rowOrder?: boolean,
  serverItemsLength?: number
  serverOptions?: ServerOptions
  loading?: boolean
  viewMoreIcon?: string
  tableId?: string
}>(), {
  theme: 'primary',
  redirectPath: undefined,
  spacing: 'default',
  sortBy: undefined,
  sortType: 'asc',
  filters: undefined,
  filterPlaceholder: 'Search',
  filterSuffixIcon: undefined,
  filterPrefixIcon: undefined,
  filterSize: 'md',
  filterFieldsSchema: undefined,
  themeColor: 'light',
  rowOrder: false,
  enableFilterBySubmissionDate: false,
  serverItemsLength: undefined,
  serverOptions: undefined,
  viewMoreIcon: 'la-angle-right',
  tableId: 'datatable'
})

const emit = defineEmits <{(e: 'delete', value: object): void, (e: 'update:serverOptions', value: ServerOptions): void, (e: 'view', value: object): void, (e: 'reorder', value: ReOrderContext): void, (e: 'publish', value: Item): void}>()

const headersTranslated = computed(() => {
  return props.headers.map(hdr => ({ ...hdr, ...{ text: i18Store.gettext(hdr.text) } }))
})

let sortedSource: Item[]

const itemsSource = computed<Item[]>({
  get () {
    return sortedSource || props.items as Item[]
  },
  set (items: Item[]) {
    sortedSource = items
  }
})

const enableFilters = computed(() => {
  return typeof props.filters !== 'undefined' || props.enableFilterBySubmissionDate === true || typeof props.filterFieldsSchema !== 'undefined'
})

/**
 * total of active filter indicator
 */
const totalActiveFilter = computed(() => {
  const totalActiveField = Object.keys(filterByFieldsValues.value).length > 0 ? Object.values(filterByFieldsValues.value).filter(value => value !== undefined).length : 0
  return totalActiveField
})

const itemsTranslated = computed(() => {
  return itemsSource.value.map(item => ({ ...item, ...{ status: i18Store.gettext(item.status) } }))
})

const serverOptionsRef: Ref<ServerOptions | undefined> = ref(props.serverOptions)

// table spacing
const tableSpacing: LooseObject = {
  default: {
    '--easy-table-header-item-padding': '1.6563rem 1rem',
    '--easy-table-body-item-padding': '1.375rem 1rem'
  },
  narrow: {
    '--easy-table-header-item-padding': '1.25rem 1rem',
    '--easy-table-body-item-padding': '1rem'
  },
  thick: {
    '--easy-table-header-item-padding': '2rem 1rem',
    '--easy-table-body-item-padding': '1.75rem 1rem'
  }
}

const complaintStatusIcon: Record<string, { boxClass: string, icon: string }> = {
  0: {
    boxClass: 'bg-emerald-400/10  text-emerald-400',
    icon: 'la-check'
  },
  1: {
    boxClass: 'bg-amber-400/10 text-amber-400',
    icon: 'la-pause-circle'
  }
}

// pagination related
const maxPaginationNumber: Ref<number> = computed(() => dataTable.value?.maxPaginationNumber)
const currentPaginationNumber: Ref<number> = computed(() => dataTable.value?.currentPaginationNumber)

const isFirstPage = computed(() => dataTable.value?.isFirstPage)
const isLastPage = computed(() => {
  return dataTable.value?.isLastPage
})

const nextPage = () => {
  dataTable.value.nextPage()
}
const prevPage = () => {
  dataTable.value.prevPage()
}
const updatePage = (paginationNumber: number) => {
  dataTable.value.updatePage(paginationNumber)
  if (serverOptionsRef.value !== undefined) {
    serverOptionsRef.value.page = paginationNumber
  }
}

// per page related
const rowsPerPageOptions: Ref<number[]> = computed(() => dataTable.value?.rowsPerPageOptions)
const rowsPerPageActiveOption: Ref<number> = computed(() => dataTable.value?.rowsPerPageActiveOption)

const updateRowsPerPageSelect = (e: Event) => {
  dataTable.value.updateRowsPerPageActiveOption(Number((e.target as HTMLInputElement).value))
}

const dataTable = ref()

const showRow = (item: ClickRowArgument) => {
  if (props.redirectPath) {
    const query = searchValue.value ? { ...route.query, ...{ keyword: searchValue.value } } : route.query
    router.push({ name: props.redirectPath, params: item.redirectParams, query })
  }
}

const itemsSelected = props.selectable ? ref<Item[]>([]) : null

const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

const deleteAction = (event: Event, item: LooseObject) => {
  emit('delete', item)
  event.stopPropagation()
}

const viewAction = (event: Event, item: LooseObject) => {
  emit('view', item)
  event.stopPropagation()
}

const publish = (event: Event, item: Item) => {
  item.status = item.status === FORM_SCHEMA_STATUS_PUBLISHED ? FORM_SCHEMA_STATUS_DRAFT : FORM_SCHEMA_STATUS_PUBLISHED
  emit('publish', item)
  event.stopPropagation()
}

const searchValue = ref('')

// filters part
const filterSectionForm = ref()
const filterValues = ref<OptionType[]>([])
const filterByFieldsModels: Ref<Record<string, string | number>> = ref({})
const filterByFieldsValues: Ref<Record<string, string>> = ref({})
const showFilter = ref(false)
const filterByStatusValue = ref('')
const filterOptions = computed((): FilterOption[] => {
  const filterOptionsArray: FilterOption[] = []
  if (props.filters !== undefined) {
    const criteria = filterByStatusValue.value.split(',').map(filterValue => filterValue).join(',')
    filterOptionsArray.push({
      field: props.filters.field,
      criteria,
      comparison: (value: string, criteria: string): boolean => {
        if (criteria.length < 1) return true
        return criteria.includes(value)
      }
    })
  }
  if (props.filterFieldsSchema !== undefined) {
    props.filterFieldsSchema.forEach((schema: FormKitSchemaFormKit) => {
      const field = schema.name as keyof typeof getOptionName
      filterOptionsArray.push({
        field,
        criteria: filterByFieldsValues.value[schema.name],
        comparison: (value: string | number, criteria: string | undefined): boolean => {
          // 'criteria === undefined' means 'all' or 'empty value' is selected
          // else filter on the selected value
          if (typeof criteria === 'undefined') return true
          const getCriteriaLabel: string | undefined = getOptionName[field](criteria)
          if (typeof getCriteriaLabel === 'undefined') return true
          return (typeof value === 'number' ? value.toString() : value) === getCriteriaLabel.toString()
        }
      })
    })
  }

  if (props.enableFilterBySubmissionDate !== undefined) {
    filterOptionsArray.push({
      field: 'date',
      criteria: '',
      comparison: (value: string): boolean => {
        if (typeof filterByFieldsValues.value?.year_from === 'undefined' || typeof filterByFieldsValues.value?.year_to === 'undefined' || value === '') {
          return true
        }
        const yearFrom = Number(filterByFieldsValues.value?.year_from)
        const yearTo = Number(filterByFieldsValues.value?.year_to)
        const selectedYear = new Date(value).getFullYear()
        return yearFrom <= selectedYear && selectedYear <= yearTo
      }
    })
  }
  return filterOptionsArray
})

const applyFilters = () => {
  const filterFormatted = toRaw(filterValues.value).map((filter: OptionType) => filter.value)
  const filterByFieldsModelsAsString = filterByFieldsModels.value as Record<string, string>
  filterByStatusValue.value = filterFormatted.join(',')
  filterByFieldsValues.value = filterByFieldsModelsAsString
  showFilter.value = false
  addFiltersToUrlParams()
}

const clearFilters = () => {
  filterValues.value = []
  filterByStatusValue.value = ''
  filterByFieldsValues.value = {}
  filterByFieldsModels.value = {}
  showFilter.value = false
  searchValue.value = ''

  router.push({
    query: {}
  })
}

/**
 * add filters value to query params
 */
function addFiltersToUrlParams () {
  const query = filterByFieldsValues.value
  if (filterByStatusValue.value) {
    query.submission_status = filterByStatusValue.value
  }
  router.push({
    query
  })
}

/**
 * set filler value based on query params
 */
function setFiltersValueFromUrlQuery () {
  const routeQuery = Object.fromEntries(Object.entries(route.query)) as Record<string, string | number>

  if (routeQuery.submission_status && props.filters) {
    const submissionOptions = props.filters.options
    const submissionStatusFromQuery = (routeQuery.submission_status as string).split(',')
    const submissionStatus: OptionType[] = submissionOptions.filter(it => submissionStatusFromQuery.includes(it.label))
    if (submissionStatus) {
      filterValues.value = submissionStatus
    }
  }

  if (routeQuery.keyword) {
    searchValue.value = routeQuery.keyword as string
  }

  filterByFieldsModels.value = {
    ...filterByFieldsModels.value,
    ...routeQuery
  }
}

/**
 * set params from URL query before mounted
 */
setFiltersValueFromUrlQuery()

const formKitSchemaData = reactive({
  /*
  read more about this here: https://formkit.com/essentials/schema#references
  This exposes a function `$ida` to form schemas to get options based on the "group" name and
  some filters.
  */
  ida: (groupName: groupName, ...filterParams: string[]) => optionsStore.options(groupName, i18Store.code, filterParams),
  locations: (...parents: number[]): OptionType[] => {
    /* Provided parent values, return the options available */
    const locations = locationStore.getLocations(...parents).map((opt) => {
      return { label: opt.name, value: opt.path.at(-1) }
    })
    return locations as OptionType[]
  },
  getLocationByLevel: (level: number): OptionType[] => {
    const locations = locationStore.getByLevel(level).map((opt) => { return { label: opt.name, value: opt.path.at(-1) } })
    return locations as OptionType[]
  }
})

const getOptionName = reactive({
  project_sector: (id: string | number) => optionsStore.getLabel('sector', id),
  district: (id: string | number) => optionsStore.getLabel('munisipiu', id),
  administrative_post: (id: string | number) => optionsStore.getLabel('postuadministrativu', id),
  suco: (id: string | number) => optionsStore.getLabel('suku', id),
  project_status: (id: string | number) => id.toString(), // Since the project_status use ID instead of label as the value, we need to return the ID instead of the label. This is because we're using item-slot for project status `#item-project_status`
  cycle: (id: string | number) => optionsStore.getLabel('cycle', id)
} as const)

/**
 * generate the page list
 * @param currentPage number
 * @param totalPages number
 * @param displayedPages number
 * @returns array
 */
function generatePagination (currentPage: number, totalPages: number, displayedPages: number): Array<(string | number)> {
  const pagination: (number | string)[] = []

  if (totalPages <= displayedPages) {
    // If the total number of pages is less than or equal to the fixed number of pages,
    // add all page numbers to the pagination array
    for (let i = 1; i <= totalPages; i++) {
      pagination.push(i)
    }
  } else {
    const ellipsis = '...'

    // Add ellipsis or first page
    if (currentPage > Math.ceil(displayedPages / 2)) {
      // If the current page is greater than half of the fixed pages, add ellipsis to indicate there are pages before the displayed range
      pagination.push(ellipsis)
    } else {
      // If the current page is within the first half of the fixed pages, add the first page to the pagination array
      pagination.push(1)
    }

    // Add middle pages
    let startPage: number
    let endPage: number

    if (currentPage <= Math.ceil(displayedPages / 2)) {
      // If the current page is within the first half of the fixed pages, display the pages from 2 to the displayedPages
      startPage = 2
      endPage = displayedPages
    } else if (currentPage >= totalPages - Math.floor(displayedPages / 2)) {
      // If the current page is within the last half of the pages, display the last displayedPages pages
      startPage = totalPages - displayedPages + 1
      endPage = totalPages
    } else {
      // For other cases, display the pages around the current page with the displayedPages range
      const halfRange = Math.floor(displayedPages / 2)
      startPage = currentPage - halfRange
      endPage = currentPage + halfRange
    }

    // Add the middle pages to the pagination array
    for (let i = startPage; i <= endPage; i++) {
      pagination.push(i)
    }

    // Add ellipsis or last page
    if (endPage < totalPages) {
      // If the last displayed page is not the last page, add ellipsis to indicate there are more pages
      pagination.push(ellipsis)
    }
  }

  // Middle pagination with ellipsis
  return pagination
}

/**
 * Move the selected item to the top of the list.
 * @param selectedItem The item to move.
 */
const moveToTop = (selectedItem: Item) => {
  const selectedIndex = itemsSource.value.findIndex(item => item.order === selectedItem.order)
  itemsSource.value.splice(selectedIndex, 1)
  itemsSource.value.unshift(selectedItem)
  updateOrder(selectedItem, 0)
}
/**
 * Move the selected item up by one position.
 * @param selectedItem The item to move.
 */
const moveUp = (selectedItem: Item) => {
  const selectedIndex = itemsSource.value.findIndex(item => item.order === selectedItem.order)
  if (selectedIndex > 0) {
    itemsSource.value.splice(selectedIndex, 1)
    itemsSource.value.splice(selectedIndex - 1, 0, selectedItem)
    updateOrder(selectedItem, selectedIndex - 1)
  }
}
/**
 * Move the selected item down by one position.
 * @param selectedItem The item to move.
 */
const moveDown = (selectedItem: Item) => {
  const selectedIndex = itemsSource.value.findIndex(item => item.order === selectedItem.order)
  if (selectedIndex < itemsSource.value.length - 1) {
    itemsSource.value.splice(selectedIndex, 1)
    itemsSource.value.splice(selectedIndex + 1, 0, selectedItem)
    updateOrder(selectedItem, selectedIndex + 1)
  }
}
/**
 * Move the selected item to the bottom of the list.
 * @param selectedItem The item to move.
 */
const moveToBottom = (selectedItem: Item) => {
  const selectedIndex = itemsSource.value.findIndex(item => item.order === selectedItem.order)
  if (selectedIndex !== -1 && selectedIndex < itemsSource.value.length - 1) {
    // Remove the item from its current position
    itemsSource.value.splice(selectedIndex, 1)
    // Push the item to the bottom
    itemsSource.value.push(selectedItem)
    updateOrder(selectedItem, (itemsSource.value.length - 1))
  }
}
/**
 * Update the order property of all items in the list.
 * This function should be called after any reordering operation.
 */
const updateOrder = (selectedItem: Item, newOrder: number) => {
  const data = [...itemsSource.value]
  for (let i = 0; i < data.length; i++) {
    data[i].order = i + 1
  }
  itemsSource.value = data
  emit('reorder', {
    item: selectedItem,
    newOrder: newOrder + 1 // need to add 1 because this is array index
  })
}

// init sortablejs
/**
 * activate sortable table if rowOrder = true
 */
const initSortable = () => {
  if (props.rowOrder === false) {
    return
  }
  const table = document.querySelector('tbody')
  // this way we avoid data binding
  const dragNdrop: Item[] = itemsSource.value
  if (table) {
    Sortable.create(table, {
      onEnd ({
        newIndex,
        oldIndex
      }) {
        const item = dragNdrop.splice(oldIndex, 1)
        dragNdrop.splice(
          newIndex,
          0,
          ...item
        )

        itemsSource.value = dragNdrop
        updateOrder(item[0] ?? {}, newIndex)
      }
    })
  }
}

const reOrderTo = (orderType: string, value: Item) => {
  switch (orderType) {
    case ORDER_LABEL_MOVE_TO_TOP:
      moveToTop(value)
      break
    case ORDER_LABEL_MOVE_TO_BOTTOM:
      moveToBottom(value)
      break
    case ORDER_LABEL_MOVE_UP:
      moveUp(value)
      break
    case ORDER_LABEL_MOVE_DOWN:
      moveDown(value)
      break
    default:
      // updateOrder(value)
      break
  }
  // sortableFn.value
}

watch(
  () => serverOptionsRef.value,
  (value) => {
    if (value !== undefined) {
      emit('update:serverOptions', value)
    }
  },
  { deep: true }
)

onMounted(async () => {
  initSortable()
  applyFilters()
})

const columnItemClassNameFunction: HeaderItemClassNameFunction = (header: Header, columnNumber: number): string => {
  if (header.value === 'submission_status' || header.value === 'action' || header.value === 'project_status') return 'column-center'
  return ''
}

const bodyItemClassNameFunction: BodyItemClassNameFunction = (column: string, rowNumber: number): string => {
  if (column === 'submission_status' || column === 'action') return 'column-center'
  return ''
}

const getComplaintStatusLabel = (value: number) => {
  return complaintStatus.find(it => it.value === value)?.label ?? '-'
}

</script>
<template>
  <div
    v-if="theme === 'secondary'"
    class="flex items-center justify-between flex-wrap md:flex-nowrap pb-5 relative mb-9"
  >
    <div class="flex items-center w-full relative">
      <TextField
        v-model="searchValue"
        name="keyword"
        type="text"
        :icon="filterPrefixIcon"
        :suffix-icon="filterSuffixIcon"
        class="mr-4 !h-[3.125rem] w-full bg-white"
        :class="{ 'max-w-[17.0625rem]': filterSize === 'sm', 'max-w-[27.25rem]': filterSize === 'md', 'max-w-[36.875rem]': filterSize === 'lg'}"
        :placeholder="gettext(filterPlaceholder)"
      />
      <div
        v-if="enableFilters"
        v-click-outside="() => showFilter = false"
        class="md:relative"
      >
        <button
          type="button"
          class="inline-flex items-center text-base font-semibold rounded-[1.25rem] px-4 py-3"
          :class="{'bg-zinc-400': showFilter}"
          @click="() => showFilter = !showFilter"
        >
          <i class="las la-filter text-xl mr-2"></i>
          {{ gettext('Filter') }}
        </button>
        <div
          class="absolute mx-auto right-0 lg:right-auto top-13 lg:left-1/2 transform lg:-translate-x-1/2 w-full md:w-[21.0625rem] bg-white rounded-2.5xl shadow-lg z-30 border-zinc-400"
          :class="{ 'hidden': !showFilter }"
        >
          <div class="absolute w-[.875rem] h-[.875rem] transform rotate-45 bg-white rounded-sm lg:left-0 right-9 lg:right-0 mx-auto -top-2"></div>
          <div class="pt-8 px-6 pb-6 border-b border-b-zinc-400">
            <div v-if="enableFilters">
              <FormKit
                v-model="filterByFieldsModels"
                type="group"
              >
                <FormKitSchema
                  v-if="filterFieldsSchema"
                  :schema="filterFieldsSchema"
                  :data="formKitSchemaData"
                />
                <div
                  v-if="enableFilterBySubmissionDate"
                  class="grid grid-cols-2 gap-4"
                >
                  <FormKit
                    id="year_from"
                    type="select"
                    name="year_from"
                    :placeholder="gettext('Select year')"
                    :label="gettext('Year from')"
                    :options="generateYears(2010)"
                  />
                  <FormKit
                    id="year_to"
                    type="select"
                    name="year_to"
                    :placeholder="gettext('Select year')"
                    :label="gettext('Year to')"
                    :options="generateYears(Number(filterByFieldsModels?.year_from ?? 2010))"
                  />
                </div>
              </FormKit>
            </div>
            <p
              v-if="filters"
              class="text-sm mb-3"
            >
              {{ gettext('Status') }}
            </p>
            <DropdownSelectable
              v-if="filters"
              ref="filterSectionForm"
              v-model="filterValues"
              placeholder="Select status"
              :options="filters.options"
            />
          </div>

          <div class="px-6 pb-5 pt-3.5 flex">
            <basic-button
              theme="text"
              class="!w-1/2 !px-1"
              @click="clearFilters"
            >
              {{ gettext('Clear filters') }}
            </basic-button>
            <basic-button
              class="!w-1/2 !px-2"
              @click="applyFilters"
            >
              {{ gettext('Apply filters') }}
            </basic-button>
          </div>
        </div>
      </div>
    </div>

    <slot name="header-action"></slot>
    <hr class="w-[calc(100%+40px)] lg:w-[calc(100%+80px)] xl:w-[calc(100%+170px)] absolute bottom-0 -left-5 lg:-left-10 xl:-left-22" />
  </div>
  <div
    class="rounded-2.5xl shadow-lg bg-white  datatable"
    :class="[themeColor]"
  >
    <div
      v-if="theme !== 'secondary'"
      class="px-4 py-4 flex items-center justify-between flex-wrap md:flex-nowrap"
      :class="{ 'justify-center': theme === 'primary' }"
    >
      <div
        class="inline-flex items-center w-full mb-4 md:mb-0 relative"
        :class="{ 'max-w-[17.0625rem]': filterSize === 'sm', 'max-w-[27.25rem]': filterSize === 'md', 'max-w-[36.875rem]': filterSize === 'lg' }"
      >
        <TextField
          v-model="searchValue"
          name="keyword"
          type="text"
          :icon="filterPrefixIcon"
          :suffix-icon="filterSuffixIcon"
          class="mr-6 w-full"
          :class="{ 'max-w-[17.0625rem]': filterSize === 'sm', 'max-w-[27.25rem]': filterSize === 'md', 'max-w-[36.875rem]': filterSize === 'lg', 'bg-white !h-[3.125rem]': themeColor === 'dark', '!h-[2.5rem]': themeColor !== 'dark'}"
          :placeholder="gettext(filterPlaceholder)"
        />
        <div
          v-if="enableFilters"
          v-click-outside="() => showFilter = false"
          class="md:relative"
        >
          <button
            type="button"
            class="inline-flex items-center text-base font-semibold rounded-[1.25rem] px-4 py-3 relative"
            :class="{'bg-zinc-100': showFilter}"
            @click="() => showFilter = !showFilter"
          >
            <i class="las la-filter text-xl mr-2"></i>
            {{ gettext('Filter') }}
            <span
              v-if="totalActiveFilter > 0"
              class="border border-stone-100 inline-flex items-center justify-center right-0 top-1 absolute w-5 h-5 rounded-full bg-emerald-600 text-white text-[.625rem] font-bold"
            >
              {{ totalActiveFilter }}
            </span>
          </button>
          <div
            class="absolute mx-auto right-0 md:right-auto top-13 md:left-1/2 transform md:-translate-x-1/2 w-full md:w-[21.0625rem] bg-white rounded-2.5xl drop-shadow-lg z-30 border-zinc-400"
            :class="{ 'hidden': !showFilter }"
          >
            <div class="absolute w-[.875rem] h-[.875rem] transform rotate-45 bg-white rounded-sm md:left-0 right-4 md:right-0 mx-auto -top-1"></div>
            <div
              class="pt-8 px-6 pb-6 border-b border-b-zinc-400"
            >
              <div v-if="(filterFieldsSchema || enableFilterBySubmissionDate)">
                <FormKit
                  v-model="filterByFieldsModels"
                  type="group"
                >
                  <FormKitSchema
                    v-if="filterFieldsSchema"
                    :schema="filterFieldsSchema"
                    :data="formKitSchemaData"
                  />
                  <div
                    v-if="enableFilterBySubmissionDate"
                    class="grid grid-cols-2 gap-4"
                  >
                    <FormKit
                      id="year_from"
                      type="select"
                      name="year_from"
                      :placeholder="gettext('Select year')"
                      :label="gettext('Year from')"
                      :options="generateYears(2010)"
                    />
                    <FormKit
                      id="year_to"
                      type="select"
                      name="year_to"
                      :placeholder="gettext('Select year')"
                      :label="gettext('Year to')"
                      :options="generateYears(Number(filterByFieldsModels?.year_from ?? 2010))"
                    />
                  </div>
                </FormKit>
              </div>
              <div v-if="(typeof filters !== 'undefined')">
                <p class="text-sm mb-3">
                  {{ gettext('Status') }}
                </p>
                <DropdownSelectable
                  ref="filterSectionForm"
                  v-model="filterValues"
                  placeholder="Select status"
                  :options="filters.options"
                />
              </div>
            </div>

            <div class="px-6 pb-5 pt-3.5 flex">
              <basic-button
                theme="text"
                class="!w-1/2 !px-1"
                @click="clearFilters"
              >
                {{ gettext('Clear filters') }}
              </basic-button>
              <basic-button
                class="!w-1/2 !px-2"
                @click="applyFilters"
              >
                {{ gettext('Apply filters') }}
              </basic-button>
            </div>
          </div>
        </div>
      </div>
      <slot name="header-action"></slot>
    </div>
    <custom-scrollbar
      scrolled-element-selector="table"
      scrolled-content-parent=".vue3-easy-data-table__main"
    >
      <Vue3EasyDataTable
        :id="tableId"
        ref="dataTable"
        v-model:items-selected="itemsSelected"
        v-model:server-options="serverOptionsRef"
        :headers="headersTranslated"
        :items="itemsTranslated"
        theme-color="#1d90ff"
        table-class-name="customize-table"
        header-text-direction="none"
        body-text-direction="none"
        :table-min-height="800"
        buttons-pagination
        :loading="loading"
        hide-footer
        row-reorder
        :rows-items="[10, 25, 50, 100]"
        :rows-per-page="10"
        :style="tableSpacing[spacing]"
        :search-value="searchValue"
        :class="`table-${spacing}`"
        :filter-options="filterOptions"
        :sort-by="sortBy"
        :sort-type="sortType"
        :empty-message="gettext('No Available Data')"
        :server-items-length="serverItemsLength"
        :header-item-class-name="columnItemClassNameFunction"
        :body-item-class-name="bodyItemClassNameFunction"
        @click-row="showRow"
      >
        <template
          #item-status="// @ts-ignore
            item"
        >
          <span
            class="text-center block"
          >
            <span
              v-if="item.status"
              class="w-5 h-5 inline-flex items-center justify-center bg-emerald-400/10 rounded-[.25rem] text-emerald-400 font-bold mr-2"
            >
              <i class="las la-check"></i>
            </span>
            <span
              v-else
              class="w-5 h-5 inline-flex items-center justify-center bg-pink-700/10 rounded-[.25rem] text-pink-700 font-bold mr-2"
            >
              <i class="las la-times"></i>
            </span>
            {{ item.status === true ? gettext('Active') : gettext('Inactive') }}
          </span>
        </template>
        <template
          #item-submission_status="// @ts-ignore
            item"
        >
          <span
            class="text-center block text-sm"
          >
            <span
              v-if="item.submission_status === SUBMISSIONS_STATUS_NAME.verified || item.submission_status === DATA_COLLECTION_STATUS.SUBMITTED"
              class="w-5 h-5 inline-flex items-center justify-center bg-emerald-400/10 rounded-[.25rem] text-emerald-400 font-bold mr-1 text-xs"
            >
              <i class="las la-check"></i>
            </span>
            <span
              v-else-if="item.submission_status === SUBMISSIONS_STATUS_NAME.newSubmission || item.submission_status === DATA_COLLECTION_STATUS.SUBMITTED"
              class="w-5 h-5 inline-flex items-center justify-center bg-blue-300/[.55] rounded-[.25rem] text-blue-300 font-bold mr-1 text-xs"
            >
              <i class="las la-pencil-alt"></i>
            </span>
            <span
              v-else-if="item.submission_status === SUBMISSIONS_STATUS_NAME.changesRequested"
              class="w-5 h-5 inline-flex items-center justify-center bg-amber-400/[.45] rounded-[.25rem] text-amber-400 font-bold mr-1 text-xs"
            >
              <i class="las la-undo-alt"></i>
            </span>
            <span
              v-else-if="item.submission_status === DATA_COLLECTION_STATUS.PENDING_SUBMIT"
              class="w-5 h-5 inline-flex items-center justify-center bg-amber-400/[.45] rounded-[.25rem] text-amber-400 font-bold mr-1 text-xs"
            >
              <i class="las la-clock"></i>
            </span>
            <span
              v-else
              class="w-5 h-5 inline-flex items-center justify-center bg-pink-700/10 rounded-[.25rem] text-pink-700 font-bold mr-1 text-xs"
            >
              <i class="las la-times"></i>
            </span>
            {{ gettext(item.submission_status) }}
          </span>
        </template>
        <template
          #item-project_status="// @ts-ignore
            { project_status }"
        >
          <span
            class="text-center block text-sm"
          >
            <span
              v-if="project_status"
              class="w-5 h-5 inline-flex items-center justify-center rounded-[.25rem] font-bold mr-1 text-xs"
              :class="PROJECT_STATUS_ICON[project_status].boxClass"
            >
              <i
                class="las"
                :class="PROJECT_STATUS_ICON[project_status].icon"
              ></i>
            </span>
            {{ optionsStore.getLabel('subprojectstatus1', project_status) }}
          </span>
        </template>
        <template
          #item-women_priority="// @ts-ignore
            item"
        >
          <span
            class="text-center block"
          >
            <span
              v-if="item.women_priority === true || item.women_priority === '1'"
              class="w-5 h-5 inline-flex items-center justify-center bg-emerald-400/10 rounded-[.25rem] text-emerald-400 font-bold mr-2"
            >
              <i class="las la-check"></i>
            </span>
            <span
              v-else
              class="w-5 h-5 inline-flex items-center justify-center bg-pink-700/10 rounded-[.25rem] text-pink-700 font-bold mr-2"
            >
              <i class="las la-minus-circle"></i>
            </span>
          </span>
        </template>
        <template
          #item-is_active="// @ts-ignore
            item"
        >
          <span
            class="text-center block"
          >
            <span
              v-if="item.is_active"
              class="w-5 h-5 inline-flex items-center justify-center bg-emerald-400/10 rounded-[.25rem] text-emerald-400 font-bold mr-2"
            >
              <i class="las la-check"></i>
            </span>
            <span
              v-else
              class="w-5 h-5 inline-flex items-center justify-center bg-pink-700/10 rounded-[.25rem] text-pink-700 font-bold mr-2"
            >
              <i class="las la-minus-circle"></i>
            </span>
          </span>
        </template>
        <template
          #header-view="// @ts-ignore
            header"
        >
          <div class="text-center w-full">
            {{ header.text }}
          </div>
        </template>
        <template
          #header-status="// @ts-ignore
            header"
        >
          <div class="text-center w-full">
            {{ header.text }}
          </div>
        </template>
        <template
          #item-view="// @ts-ignore
            item"
        >
          <div class="text-center">
            <button
              class="w-9 h-9 rounded-full bg-emerald-600 hover:bg-white hover:text-emerald-600 border border-emerald-600 text-white inline-flex items-center justify-center text-center"
              @click="viewAction($event, item)"
            >
              <i
                class="las"
                :class="viewMoreIcon"
              ></i>
            </button>
          </div>
        </template>
        <template
          #item-action="// @ts-ignore
            item"
        >
          <button
            type="button"
            @click="deleteAction($event, item)"
          >
            <icon-circle-box class="border-red-300 hover:bg-red-300 hover:text-white">
              <i class="las la-trash text-xl"></i>
            </icon-circle-box>
          </button>
        </template>
        <template
          #item-order="// @ts-ignore
            item"
        >
          <DropdownGroup
            v-if="props.rowOrder"
            :label="item.order"
            :options="[
              {
                label: 'Move to top',
                value: ORDER_LABEL_MOVE_TO_TOP,
                icon: 'la-angle-double-up'
              },
              {
                label: 'Move up',
                value: ORDER_LABEL_MOVE_UP,
                icon: 'la-angle-up'
              },
              {
                label: 'Move down',
                value: ORDER_LABEL_MOVE_DOWN,
                icon: 'la-angle-down'
              },
              {
                label: 'Move to bottom',
                value: ORDER_LABEL_MOVE_TO_BOTTOM,
                icon: 'la-angle-double-down'
              }
            ]"
            @click="reOrderTo($event, item)"
          />
          <span v-else>
            {{ item.order }}
          </span>
        </template>
        <template
          #item-publish="// @ts-ignore
            item"
        >
          <div class="whitespace-nowrap text-center">
            <button
              type="button"
              class="mr-2"
              @click="publish($event, item)"
            >
              <span
                v-if="item.status === FORM_SCHEMA_STATUS_PUBLISHED"
                class="w-5 h-5 inline-flex items-center justify-center bg-emerald-400/10 rounded-[.25rem] text-emerald-400 font-bold mr-2"
              >
                <i class="las la-check"></i>
              </span>
              <span
                v-else
                class="w-5 h-5 inline-flex items-center justify-center bg-blue-300/[.10] rounded-[.25rem] text-blue-300 font-bold mr-2"
              >
                <i class="las la-ellipsis-h"></i>
              </span>
              {{ gettext(strCapitalized(item.status)) }}
            </button>
          </div>
        </template>
        <template
          #item-complaint_status="// @ts-ignore
            item"
        >
          <div class="flex items-center">
            <span
              v-if="item.complaint_status !== undefined"
              class="w-5 h-5 inline-flex items-center justify-center rounded-[4px] font-bold mr-2 text-xs"
              :class="complaintStatusIcon[item.complaint_status].boxClass"
            >
              <i
                class="las"
                :class="complaintStatusIcon[item.complaint_status].icon"
              ></i>
            </span>
            {{ gettext(getComplaintStatusLabel(item.complaint_status)) }}
          </div>
        </template>
      </Vue3EasyDataTable>
    </custom-scrollbar>
  </div>
  <div
    v-if="maxPaginationNumber > 0 || (serverItemsLength !== undefined && serverItemsLength > 0)"
    class="customize-footer mt-8 flex justify-center relative"
  >
    <div class="customize-rows-per-page absolute left-0 top-0 hidden md:inline-block">
      <select
        class="cursor-pointer h-[2.5rem] py-0 appearance-none rounded-[.625rem] leading-none bg-white border-zinc-400 border text-[.9375rem]"
        @change="updateRowsPerPageSelect"
      >
        <option
          v-for="(item, index) in rowsPerPageOptions"
          :key="index"
          :selected="item === rowsPerPageActiveOption"
          :value="item"
        >
          {{ interpolate(gettext('View %(item)s results'), {item}, true) }}
        </option>
      </select>
    </div>
    <div
      v-if="maxPaginationNumber > 0 || (serverItemsLength !== undefined && serverItemsLength > 0)"
      class="customize-pagination flex items-center"
    >
      <button
        class="prev-page w-10 h-10 rounded-full border-2 border-emerald-600 inline-flex items-center justify-center text-xl font-black mr-4"
        :disabled="isFirstPage"
        @click="prevPage"
      >
        <i class="las la-angle-left"></i>
      </button>
      <div class="customize-buttons">
        <span
          v-for="(paginationNumber, index) in generatePagination(serverOptionsRef ? serverOptionsRef.page : currentPaginationNumber, serverItemsLength ?? maxPaginationNumber , 5)"
          :key="index"
          class="customize-button w-[1.875rem] h-[1.875rem] rounded-full border-2 border-zinc-400 inline-flex items-center justify-center text-[.8125rem] mr-[.8125rem] last:mr-0 cursor-pointer"
          :class="{'!border-emerald-600 bg-emerald-600 text-white': paginationNumber === currentPaginationNumber}"
          @click="(typeof paginationNumber === 'number') ? updatePage(paginationNumber) : false"
        >
          {{ paginationNumber }}
        </span>
      </div>
      <button
        class="next-page w-10 h-10 rounded-full border-2 border-emerald-600 inline-flex items-center justify-center text-xl font-black ml-4"
        :disabled="isLastPage"
        @click="nextPage"
      >
        <i class="las la-angle-right"></i>
      </button>
    </div>
    <button
      class="absolute right-0 top-0 font-semibold hidden md:inline-block"
      type="button"
      @click="scrollToTop"
    >
      <i class="las la-angle-up mr-2"></i>
      {{ gettext('Back to top') }}
    </button>
  </div>
</template>
<style lang="postcss">
@import url("vue3-easy-data-table/dist/style.css");
.customize-table {
  --easy-table-border: none;
  --easy-table-row-border: none;

  --easy-table-header-whitescape: nowrap;
  --easy-table-header-font-size: .9375rem;

  --easy-table-body-row-font-size: .9375rem;

  --easy-table-footer-background-color: transparent;
  .vue3-easy-data-table__main {
    @apply min-h-0 border-collapse rounded-bl-2.5xl rounded-br-2.5xl;
    /* For Webkit-based browsers (Chrome, Safari and Opera) */
    &::-webkit-scrollbar {
      display: none;
    }
    /* For IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    th {
      @apply text-left capitalize text-base font-black;
    }
    td {
      @apply text-base !border-l border-r-0 !border-t !border-b border-solid !border-light static;
      &:first-child {
        @apply !border-l-transparent;
      }
      &:last-child {
        @apply !border-r-transparent;
      }
    }
    tbody {
      tr {
        @apply cursor-pointer;
      }
    }
    .easy-checkbox {
      label {
        &:before {
          height: 1.625rem;
          width: 1.625rem;
          border: .075rem solid #E3E3D8;
          border-radius: .25rem;
          background-color: #FFFFFF;

        }
        &:after {
          @apply mx-auto right-0 top-1;
        }
      }
      input:checked, input.partSelected {
        + label {
          &:before {
            @apply bg-emerald-600;
          }
        }
      }
    }
    &.hoverable {
      tr:hover {
        @apply !border-l-0;
        td {
          @apply bg-light !border-l-gray-300 !border-b !border-t first:!border-l-transparent !border-b-gray-300 !border-t-gray-300 ;
        }
      }
    }
  }
}
.table-narrow {
  .vue3-easy-data-table__main {
    td {
      @apply text-3xs leading-4;
    }
    th, td {
      &:first-child {
        @media (max-width: theme('screens.sm')) {
          @apply pl-6;
        }
      }
      &:last-child {
        @media (max-width: theme('screens.sm')) {
          @apply pr-6;
        }
      }
    }
  }
}
.datatable {
  &.dark {
    @apply !bg-zinc-400;
    .vue3-easy-data-table__header {
      th {
        @apply bg-gray-800 text-white text-3xs leading-tight !border-r-0 border-l border-t-0 border-b-0 !border-solid !border-l-zinc-700 first:border-l-0;
      }
    }
  }
}
.vue3-easy-data-table__message {
  height: 5.125rem;
  @apply flex items-center justify-center rounded-bl-2.5xl;
}
.vue3-easy-data-table__header th.column-center .header, .vue3-easy-data-table__body td.column-center {
  @apply justify-center text-center;
}
</style>
