
function sumOfField<T extends Record<string, unknown>> (items: T[], field: keyof T): number {
  return items.reduce((total, item) => {
    if (typeof item === 'undefined') return total
    if (!(field in item)) return total
    // If value is numeric, add it up
    const add = item[field]
    if (typeof add === 'boolean') return total
    if (typeof add === 'number' && !isNaN(add)) {
      return total + add
    }
    // Else, try casting to a number
    const cast = Number(add)
    if (typeof cast === 'number' && !isNaN(cast)) {
      return total + cast
    }
    return total
  }, 0)
}

function sumOfFields<T extends Record<string, unknown>, K extends keyof T> (items: T[], startValues: { [P in K]: number }): { [P in K]: number } {
  const keys = Object.keys(startValues)
  return items.reduce((total, item) => {
    keys.forEach(it => {
      const adda = item[it]
      if (typeof adda === 'number' && !isNaN(adda)) total[it as K] += adda
    })
    return total
  }, startValues)
}

function reduceFields<T extends Record<string, unknown>, K extends keyof T> (items: T[], ...fields: K[]): number {
  return items.reduce((sum, item) => {
    fields.forEach(it => {
      const adda = item[it]
      if (typeof adda === 'number' && !isNaN(adda)) sum += adda
    })
    return sum
  }, 0)
}

export { sumOfFields, sumOfField, reduceFields }
