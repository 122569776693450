<script setup lang="ts">
import { onMounted, reactive, computed } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import IconCard from '../commons/IconCard.vue'
import { useI18NStore } from '../../stores/i18n'

import { IS_MOBILE_MAX_SCREEN_WIDTH } from '../../_constants/common.constant'

import 'vue3-carousel/dist/carousel.css'

import { useAppStore } from '../../stores/app'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'

const appStore = useAppStore()
const i18nStore = useI18NStore()

const settings = {
  itemsToShow: 1.15,
  snapAlign: 'start',
  wrapAround: true
}

const state = reactive({ isMobile: false })

const _isMobileChecker = () => {
  state.isMobile = document.body?.clientWidth ? document.body.clientWidth < IS_MOBILE_MAX_SCREEN_WIDTH : false
}

const cards = computed(() => {
  return {
    totalBudget: {
      content: {
        title: i18nStore.gettext('Total budget allocated'),
        content: formatCurrencyWithDollar(appStore.landingPageData.total_infrastructure_fund, 'currency', 0),
        footer: i18nStore.gettext('From the Infrastructure fund')
      },
      color: '#68BDDE',
      iconclass: 'las la-money-bill-wave'
    },
    communityPartisipation: {
      content: {
        title: i18nStore.gettext('Total community participation'),
        content: appStore.getTotalParticipants,
        footer: i18nStore.gettext('In the whole process at the Aldeia level')
      },
      color: '#787EE1',
      iconclass: 'las la-people-carry'
    },
    totalProjects: {
      content: {
        title: i18nStore.gettext('Total infrastructure projects'),
        content: appStore.getTotalProjects,
        footer: i18nStore.gettext('New or rehabilitated facilities')
      },
      color: '#FCBF30',
      iconclass: 'las la-archway'
    }
  }
})

onMounted(() => {
  _isMobileChecker()
  window.addEventListener('resize', () => {
    _isMobileChecker()
  }, {
    passive: true
  })
})
</script>

<template>
  <div
    v-if="state.isMobile"
    class="-mx-5"
  >
    <Carousel :settings="settings">
      <Slide class="px-[12.5px]">
        <icon-card
          v-bind="cards.totalBudget"
          box-class="text-center px-12 h-full w-full"
        />
      </Slide>
      <Slide class="px-[12.5px]">
        <icon-card
          v-bind="cards.communityPartisipation"
          box-class="text-center px-12 h-full w-full"
        />
      </Slide>
      <Slide class="px-[12.5px]">
        <icon-card
          v-bind="cards.totalProjects"
          box-class="text-center px-12 h-full w-full"
        />
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
  <div
    v-else
    class="md:grid md:grid-cols-2 xl:grid-cols-3 md:gap-5 lg:gap-7.5"
  >
    <icon-card v-bind="cards.totalBudget" />
    <icon-card v-bind="cards.communityPartisipation" />
    <icon-card v-bind="cards.totalProjects" />
  </div>
</template>

<style>
.carousel__viewport {
  padding-bottom: 25px;
  padding-left: 12.5px;
}
.carousel__pagination-button::after {
  width: 12px;
  height: 12px;
  @apply rounded-full bg-zinc-400;
}
.carousel__pagination-button.carousel__pagination-button--active::after {
  @apply bg-teal-500;
}
</style>
