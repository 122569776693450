export const SUBMISSION_OPERATION_TYPE_CREATED = '+'
export const SUBMISSION_OPERATION_TYPE_UPDATED = '~'
export const SUBMISSION_OPERATION_TYPE_REMOVED = '-'

export const PROJECT_STATUS_ICON: Record<string, { boxClass: string, icon: string }> = {
  /*
  The keys in this record are derived from the `ProjectStatus1` table, values are 1 through 7 inclusive.
  This is also available dynamically through IDB, or in Python:
  list(SubProjectStatus1Label.objects.filter(lang='tet').values('option_id', 'label'))
  [{'option_id': 1, 'label': 'Aprova'},
  {'option_id': 3, 'label': 'Kompleta'},
  {'option_id': 4, 'label': 'Suspende'},
  {'option_id': 5, 'label': 'Troka'},
  {'option_id': 6, 'label': 'Faila'},
  {'option_id': 7, 'label': 'Kansela'},
  {'option_id': 2, 'label': 'Prosesu Hela'}]
  */
  1: {
    boxClass: 'bg-emerald-400/10  text-emerald-400',
    icon: 'la-check'
  },
  2: {
    boxClass: 'bg-blue-300/10 text-blue-300',
    icon: 'la-pencil-alt'
  },
  3: {
    boxClass: 'bg-emerald-500/10  text-emerald-500',
    icon: 'la-stamp'
  },
  4: {
    boxClass: 'bg-amber-400/10 text-amber-400',
    icon: 'la-pause-circle'
  },
  5: {
    boxClass: 'bg-amber-400/10 text-amber-400',
    icon: 'la-undo-alt'
  },
  6: {
    boxClass: 'bg-pink-400/10 text-pink-400',
    icon: 'la-exclamation-triangle'
  },
  7: {
    boxClass: 'bg-pink-300/10 rounded-[.25rem] text-red-300',
    icon: 'la-times-circle'
  }
}
