<script setup lang="ts">
import { ref, reactive } from 'vue'
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'

import { useToast } from 'vue-toastification'
import { addUserSchema } from '../_formkit/schema/add_user.schema'

import { useAuthStore } from '../stores/auth'
import { useUsersStore } from '../stores/users'
import { FormKitNode } from '@formkit/core'
import { FormKitSchema } from '@formkit/vue'

import { useI18NStore } from '../stores/i18n'
import { USER_MESSAGES } from '../_constants/toastmessages.constant'

import QRCode from 'qrcode'

const resetLink = ref<string | undefined>('')

const authStore = useAuthStore()
const userStore = useUsersStore()
const toast = useToast()

async function submit (data: any, node: FormKitNode | undefined) {
  const response = await userStore.addUser(data)
  if (response.success) {
    toast.success(useI18NStore().gettext(USER_MESSAGES.success))
    if (response.resetLink) {
      resetLink.value = response.resetLink
      QRCode.toCanvas(document.getElementById('qr_code_canvas'), resetLink.value, function (error: any) {
        if (error) console.error(error)
      })
    }
  } else {
    toast.error(useI18NStore().gettext(USER_MESSAGES.error))
    node?.setErrors(response.errors)
  }
}

const formKitData = reactive({
  gettext: useI18NStore().gettext
})
</script>

<template>
  <base-layout
    :title="gettext('Add User')"
  >
    <content-section>
      <div v-if="!authStore.can('ida.add_idauser')">
        {{ gettext('Should not have even got here') }}
      </div>
      <div v-show="resetLink">
        {{ gettext('You have created a new user, if you added an email they will have been sent this link in an email') }}
        {{ resetLink }}
        {{ gettext('Or they can scan this QRCode to set their password') }}
        <canvas id="qr_code_canvas"></canvas>
      </div>
      <div v-if="!resetLink">
        <div class="max-w-xl">
          <FormKit
            type="form"
            :submit-label="gettext('Submit')"
            :submit-attrs="{
              wrapperClass: 'max-w-[220px]'
            }"
            novalidate
            message-class="text-red-500 text-sm"
            :disabled="!authStore.can('ida.add_idauser')"
            @submit="submit"
          >
            <FormKitSchema
              :schema="addUserSchema"
              :data="formKitData"
            />
          </FormKit>
        </div>
      </div>
    </content-section>
  </base-layout>
</template>
