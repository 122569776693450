<script setup lang="ts">
import { ref, Ref } from 'vue'
import { FormKitNode } from '@formkit/core'

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  context: Object
})

const value: Ref<number> = ref(props.context?.value)
const isEdit = ref<boolean>(false)

function blockNonNumber (e: InputEvent) {
  const isValid = /[0-9.]/
  // Just for good measure we’ll block non-numeric values:
  if (e.data && !isValid.test(e.data)) {
    e.preventDefault()
  }
}

function formatNumber (value?: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  if (typeof value === 'undefined') return ''
  const valueAsNum = Number(Number(value).toFixed(2))
  return formatter.format(valueAsNum)
}

async function onInput (val: string, node: FormKitNode) {
  await props.context?.node?.input(val)
}

</script>
<template>
  <FormKit
    :id="context?.id"
    v-bind="context?.attrs"
    :key="context?.key"
    v-model="value"
    type="text"
    :name="context?.node?.name"
    class="w-full mb-4 border border-zinc-400 rounded-lg text-2sm h-[40px] focus:border-2 focus:border-emerald-600 shadow-none formkit-invalid:border-red-500 formkit-disabled:bg-gray-200 formkit-disabled:opacity-50 mt-4"
    outer-class="!mb-0 relative"
    :input-class="{'text-transparent': !isEdit}"
    :help="formatNumber(value)"
    :help-class="isEdit ? 'absolute !text-2sm top-[9px] left-[12px] invisible !mt-0 !text-zinc-900' : '!text-2sm absolute top-[9px] left-[12px] visible !mt-0 !text-zinc-900'"
    @beforeinput="blockNonNumber"
    @blur="() => isEdit = false"
    @focus="() => isEdit = true"
    @input="onInput"
  />
</template>
