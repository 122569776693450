import { FormKitSchemaFormKit } from '@formkit/core'
import { selectOptions } from '../../_helpers/common.helpers'
import { useOptionsStore } from '../../stores/options'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { generateDatepicker } from './forms/common.schema'
export const labelFields: FormKitSchemaFormKit[] = selectOptions.value.map((option) => {
  return {
    $formkit: 'text',
    name: `label_${option.value}`,
    label: `$title(${option.label})`,
    required: option.value === 'en'
  }
})

const valueField: FormKitSchemaFormKit = {
  $formkit: 'number',
  name: 'value',
  label: '$gettext("Value")',
  required: true
}

const startDate = generateDatepicker({
  key: 'start_date',
  name: 'start_date',
  id: 'start_date',
  label: '$gettext("Start date")',
  innerClass: 'bg-white',
  valueFormat: 'YYYY-MM-DD',
  _maxDateSource: 'end_date',
  disabledDays: '$setDisabledDays'
})

const endDate = generateDatepicker({
  key: 'end_date',
  name: 'end_date',
  id: 'end_date',
  label: '$gettext("End date")',
  innerClass: 'bg-white',
  valueFormat: 'YYYY-MM-DD',
  _minDateSource: 'start_date',
  disabledDays: '$setDisabledDays'
})

const subsector = {
  $formkit: 'select',
  id: 'subsector_id',
  key: 'subsector_id',
  label: '$gettext(Subsector)',
  name: 'subsector_id',
  options: '$ida(subsector)'
}

const sector = {
  $formkit: 'select',
  id: 'sector_id',
  key: 'sector_id',
  label: '$gettext(Sector)',
  name: 'sector_id',
  options: '$ida(sector)'
}

const teampositiongroup = {
  $formkit: 'select',
  id: 'group_id',
  key: 'group_id',
  label: '$gettext(Group)',
  name: 'group_id',
  options: '$ida(teampositiongroup)'
}

const programactivitytype = {
  $formkit: 'select',
  id: 'type_id',
  key: 'type_id',
  label: '$gettext(Program Activity Type)',
  name: 'type_id',
  options: '$ida(programactivitytype)'
}

const programactivitylevel = {
  $formkit: 'select',
  id: 'level_id',
  key: 'level_id',
  label: '$gettext(Program Activity Level)',
  name: 'level_id',
  options: '$ida(programactivitylevel)'
}

// Adds options for the many-to-many links for
// Output Activity Ids

const outputActivityIds = {
  $formkit: 'transferlist',
  id: 'activity_ids',
  key: 'activity_ids',
  label: '$gettext("Activities")',
  name: 'activity_ids',
  options: '$ida(activity)'
}

const outputObjectiveIds = {
  $formkit: 'transferlist',
  id: 'output_ids',
  key: 'output_ids',
  label: '$gettext("Outputs")',
  name: 'output_ids',
  options: '$ida(output)'
}

const isActive = {
  $formkit: 'radio',
  id: 'is_active',
  key: 'is_active',
  label: '$gettext("Active")',
  name: 'is_active',
  options: '$ida(yesno)'
}

export const getSchema = (group: groupName, value?: string) => {
  /* Validation and option reset */
  let validation
  const schema: FormKitSchemaFormKit[] = []
  schema.push({
    $formkit: 'hidden',
    name: 'group_name',
    value: group
  })
  if (typeof value === 'undefined') {
    const optsgroup = useOptionsStore().filterByGroup(group)
    const ids = Array.from(optsgroup?.values() ?? []).map((it) => it.value).join(',')
    validation = `required|number|not:${ids}`
  } else validation = 'required|number'
  schema.push({ ...valueField, validation, readOnly: typeof value !== 'undefined' })
  schema.push(...labelFields, isActive)
  if (group === 'cycle') schema.push(startDate, endDate)
  if (group === 'output') schema.push(subsector, outputActivityIds)
  if (group === 'subsector') schema.push(sector)
  if (group === 'objective') schema.push(outputObjectiveIds)
  if (group === 'programactivity') schema.push(programactivitytype, programactivitylevel)
  if (group === 'teamposition') schema.push(teampositiongroup)
  // Disable all options unless 'active'
  schema.forEach((it) => {
    if (it.name !== 'is_active') it.disabled = '$get(is_active).value === "0" || $get(is_active).value === false'
  })
  return schema
}
