<script setup lang="ts">
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import { useIdaFormsStore } from '../stores/idaforms'
import IconCircleBox from '../components/commons/IconCircleBox.vue'

const store = useIdaFormsStore()
store.initialize()

</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center ">
        <router-link
          :to="`/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
        </router-link>
        <h1 class="text-xl font-black">
          {{ gettext('Add a New Form') }}
        </h1>
        <i
          class="las text-xl relative la-angle-right text-gray-400 mx-2"
        ></i>
      </div>
    </template>
  </base-layout>
</template>
