export const API_STATUS = {
  API_SUCCESS: 200,
  API_CREATED: 201,
  API_NO_CONTENT: 204,
  API_MULTIPLE_CHOICES: 300,
  API_BAD_REQUEST: 400,
  API_TOKEN_EXPIRED: 401,
  API_FORBIDDEN: 403,
  API_INVALID_TOKEN: 419,
  API_VALIDATION_ERROR: 422,
  API_MAINTENANCE: 503,
  API_PERMANENT_REDIRECT: 308
}

export const API_METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}
