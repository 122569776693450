<script setup lang="ts">
/**
 * Wrapper component of form list page
 */
import { useFormsStore } from '../../stores/forms'
import BasicCard from '../commons/BasicCard.vue'
import IconCircleBox from '../commons/IconCircleBox.vue'
import CollapseBox from '../commons/CollapseBox.vue'
import { FORM_SCHEMA_STATUS_PUBLISHED } from '../../_constants/common.constant'
const store = useFormsStore()
const colors = ['border-teal-500 group-hover:bg-teal-500', 'border-yellow-500 group-hover:bg-yellow-500', 'border-pink-500 group-hover:bg-pink-500', 'border-purple-500 group-hover:bg-purple-500', 'border-blue-500 group-hover:bg-blue-500']

// temporary solution for the form icon
const icons: Record<string, string> = {
  social: 'las la-user-friends',
  finance: 'las la-wallet',
  technical: 'las la-server',
  monitoring: 'las la-graph-line'
}

</script>

<template>
  <basic-card
    v-for="(forms, key) in store.getFormsByType(FORM_SCHEMA_STATUS_PUBLISHED)"
    :id="`form-card-${key}`"
    :key="key"
    class="px-6 md:px-10 py-6 mb-7 !overflow-visible "
  >
    <collapse-box
      :id="`collapse-box-${key}`"
      :active="true"
    >
      <template #collapseToggle>
        <h3 class="capitalize text-1.5xl font-black">
          <i
            class="font-bold mr-2 text-2xl text-gray-400"
            :class="icons[key]"
          ></i>
          {{ gettext(key) }}
        </h3>
      </template>
      <template #collapseContent>
        <div class="grid md:grid-cols-3 gap-6 mt-7 mb-3">
          <basic-card
            v-for="(form, index) in forms"
            :key="index"
            class="group transition-all ease-in-out duration-200 !p-6 !rounded-5xl"
            shadow="sm"
          >
            <router-link
              :to="{ name: 'submissions', params: { formType: form.id } }"
              class="text-base flex items-center justify-between flex-row-reverse md:block text-left text-2base"
            >
              <icon-circle-box
                class="ml-5 md:ml-0 md:mb-5 transition-all ease-in-out duration-100 group-hover:text-white"
                :class="colors[index % colors.length]"
              >
                <i class="las la-angle-right text-2xl"></i>
              </icon-circle-box>
              <span class="block"><b>{{ form.name }}</b><i class="block">{{ form.description }}</i></span>
            </router-link>
          </basic-card>
        </div>
      </template>
    </collapse-box>
  </basic-card>
</template>

<style scoped>

</style>
