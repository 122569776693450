<script setup lang="ts">
import { type PropType, reactive, onUpdated } from 'vue'
import { OptionsType, OptionType } from '../../_types/components/forms/select-dropdown'

const props = defineProps({
  modelValue: {
    type: Array as PropType<OptionsType>,
    required: true
  },
  options: {
    type: Array as PropType<OptionsType>,
    required: true
  },
  placeholder: {
    type: String,
    default: 'Select'
  }
})

const emit = defineEmits <{(e: 'update:modelValue', value: OptionsType): void}>()
const state = reactive<{ selected: OptionType[]; show: boolean;}>({
  selected: [],
  show: false
})

const select = (option: OptionType) => {
  const checkExistingValue = state.selected.findIndex((value: OptionType) => value.value === option.value)
  if (checkExistingValue < 0) {
    state.selected.push(option)
    emit('update:modelValue', state.selected)
  }
  toggleShow()
}

const remove = (event: Event, option: OptionType) => {
  event.stopPropagation()
  const newSelectedData = state.selected.filter((select: OptionType) => select.value !== option.value)
  state.selected = newSelectedData
  emit('update:modelValue', state.selected)
}

const toggleShow = () => {
  state.show = !state.show
}

onUpdated(() => {
  if (props.modelValue !== undefined) {
    state.selected = props?.modelValue
  }
})

toggleShow() // show selected item

defineExpose({ toggleShow })

/*
  gettext('Draft')
  gettext('Submitted')
  gettext('Pending')
  gettext('New Submission')
  gettext('Rejected')
  gettext('Verified')
  gettext('Changes Requested')
*/
</script>
<template>
  <div
    v-click-outside="() => state.show = false"
    class="relative text-sm"
  >
    <button
      type="button"
      class="border border-zinc-400 rounded-lg flex items-center justify-between min-h-[42px] px-3 w-full"
      :class="{ 'pt-[5px] pl-[5px]': state.selected.length > 0 }"
      @click="toggleShow"
    >
      <div class="inline-flex flex-wrap items-center">
        <span v-if="state.selected.length < 1">{{ gettext(placeholder) }}</span>
        <label
          v-for="(value, i) in state.selected"
          :key="i"
          class="mr-[5px] last:mr-0 h-[30px] rounded-lg bg-zinc-400 inline-flex justify-between items-center pl-4 pr-[6px] mb-[5px]"
        >
          {{ gettext(value.label) }}
          <button
            class="ml-[6px] text-xs relative "
            type="button"
            @click="remove($event, value)"
          ><i class="las la-times"></i></button>
        </label>
      </div>
      <i
        class="las la-angle-down"
        :class="{ 'relative bottom-[2.5px]': state.selected.length > 0}"
      ></i>
    </button>
    <ul
      class="absolute z-20 top-full bg-white w-full left-0 shadow-lg rounded-lg"
      :class="{ 'hidden': !state.show }"
    >
      <li
        v-for="(option, i) in props.options"
        :key="i"
      >
        <button
          type="button"
          class="px-4 py-3 hover:bg-gray-100 w-full text-left"
          @click="select(option)"
        >
          {{ gettext(option.label) }}
        </button>
      </li>
    </ul>
  </div>
</template>
