<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import DataTable from '../../components/commons/DataTable.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import { FormKitSchema, FormKit } from '@formkit/vue'
import type { Header, Item } from 'vue3-easy-data-table'
import { computed, ComputedRef, reactive, ref, watch, toRaw, Ref, onMounted } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useI18NStore } from '../../stores/i18n'
import { useOptionsStore } from '../../stores/options'
import { useSukuTeamsStore } from '../../stores/sukuteams'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { useRouter } from 'vue-router'
import { SUKU_TEAMS_TYPE } from '../../_constants/sukumanager.constant'
import { isFormKitSchemaNode, useIdaFormsStore } from '../../stores/idaforms'
import { FormKitSchemaFormKit } from '@formkit/core'
import { SUKU_MANAGER_MENU } from '../../_constants/navigation.constant'

const i18nstore = useI18NStore()
const optionsStore = useOptionsStore()
const router = useRouter()
const sukuTeamsStore = useSukuTeamsStore()
const activeTeams: ComputedRef<'management-teams' | string> = computed(() => router.currentRoute?.value.params?.section as string)
const formStore = useIdaFormsStore()

await optionsStore.initialize()
await sukuTeamsStore.initialize(activeTeams.value)

const showCreateForm = ref(false)

const activeSukuTeamMenu = computed(() => SUKU_MANAGER_MENU.find(it => it.value === router.currentRoute?.value.params?.section as string))

const headers: ComputedRef<Header[]> = computed(() => [
  { sortable: true, value: 'position', text: 'Position' },
  { sortable: true, value: 'name', text: 'Name' },
  { sortable: true, value: 'gender', text: 'Gender' },
  { sortable: true, value: 'phone_number', text: 'Phone number' },
  { value: 'status', text: 'Status' },
  { value: 'view', text: 'Visualize And Edit', class: 'text-center' }
])

const items: ComputedRef<Item[]> = computed(() => {
  return sukuTeamsStore.$state.teams.map(team => ({
    position: optionsStore.getLabel('teamposition', team.position),
    name: team.name,
    gender: optionsStore.getLabel('gender', team.gender),
    phone_number: team.phone_number,
    status: team.active_status,
    view: team.uuid
  }))
})

const geolocationStore = useGeoLocationStore()
const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)

const values: Ref<Record<string, any>> = ref({
  uuid: '',
  submission_key: '',
  position: '',
  name: '',
  gender: undefined,
  person_with_disability: undefined,
  phone_number: undefined,
  status: undefined,
  date_exit_committee: '',
  exit_details: ''
})

const getRepeaterContent = (formId: string, repeaterId: string) : FormKitSchemaFormKit[] => {
  const schema = formStore.forms.find((it) => it.key === formId)
  if (typeof schema === 'undefined') throw new TypeError('Schema Key is invalid')
  if (typeof schema.schema_id === 'undefined') throw new TypeError('Schema Key is invalid')
  const node = formStore.getNodeByKey(schema.schema_id)
  if (typeof node === 'undefined') throw new TypeError('Schema Node is invalid')
  const formSchema = formStore.nodeAsFormkitSchema(node, false)
  const children = formSchema.children as FormKitSchemaFormKit[]
  // Fetch the 'group' inside the formSchema
  const group = children.find(it => it.id === repeaterId)
  if (typeof group === 'undefined') throw new TypeError('Schema Key is invalid')
  const repeater = (group.children as FormKitSchemaFormKit[])[0]
  return ((repeater.children ?? []) as FormKitSchemaFormKit[]).filter(it => isFormKitSchemaNode(it)) as FormKitSchemaFormKit[]
}

const schemasReady: Ref<boolean> = ref(false)
onMounted(async () => {
  await formStore.initialize()
  schemasReady.value = true
})
const detailsFormSchema: ComputedRef<FormKitSchemaFormKit[] | undefined> = computed(() => {
  if (activeTeams.value === SUKU_TEAMS_TYPE.repeaterProjectTeam) return getRepeaterContent('SF_1_3', 'projectteam')
  if (activeTeams.value === SUKU_TEAMS_TYPE.repeaterPlanning) return getRepeaterContent('SF_1_3', 'planning')
  if (activeTeams.value === SUKU_TEAMS_TYPE.repeaterSukus) return getRepeaterContent('SF_1_3', 'sukus')
  return undefined
})

const formSchemaData = reactive({
  /* Allows using `$gettext` and `$pgettext` in the forms */
  gettext: i18nstore.gettext,
  ida: (groupName: groupName, ...filterParams: string[]) => optionsStore.options(groupName, i18nstore.code, filterParams),
  getoptions: {
    common: {
      translatedOptions: optionsStore.translatedOptions
    }
  },
  getCurrentDate: () => {
    const currDate = new Date()
    return {
      day: currDate.getDate(),
      month: currDate.getMonth(),
      year: currDate.getFullYear()
    }
  }
})

const submit = async (values: any) => {
  const update = await sukuTeamsStore.save(values, activeTeams.value)
  if (update) {
    showCreateForm.value = false
  }
}

const viewMore = (item: any) => {
  showCreateForm.value = true
  const selectedTeam = sukuTeamsStore.getTeamById(item.view)
  if (typeof selectedTeam !== 'undefined') {
    values.value = structuredClone(toRaw(selectedTeam))
    if (values.value.phone_number !== undefined) {
      values.value.phone_number = values.value.phone_number.toString()
    }
  }
}

watch(activeTeams, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await sukuTeamsStore.initialize(newValue)
  }
})

</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <h1 class="text-2.5xl font-black mb-10">
        {{ gettext('List of') }} <span class="lowercase">{{ activeSukuTeamMenu?.label ? gettext(activeSukuTeamMenu?.label) : '-' }}</span>
      </h1>
      <DataTable
        :headers="headers"
        :items="items"
        filter-size="md"
        theme-color="dark"
        spacing="narrow"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl !text-gray-400"
        @view="viewMore"
      />
    </content-section>
    <modal-box
      v-if="showCreateForm && detailsFormSchema && schemasReady"
      size="lg"
      spacing="md"
    >
      <button
        class="fixed right-6 top-6 text-lg w-[28px] h-[28px] rounded-full bg-emerald-600 text-white"
        @click="() => showCreateForm = false"
      >
        <i class="las la-times"></i>
      </button>
      <template #header>
        <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
          <i class="text-gray-400 mr-3 text-2xl las la-edit"></i>
          <span class="first-letter:uppercase">
            {{ interpolate(gettext('%(title)s detail'), {
              title: gettext(activeTeams.replace(/-/, ' '))
            }, true) }}
          </span>
        </h4>
      </template>
      <FormKit
        ref="form"
        v-model="values"
        type="form"
        :actions="false"
        @submit="submit"
      >
        <FormKitSchema
          :schema="detailsFormSchema"
          :data="formSchemaData"
        />
        <FormKit
          type="submit"
          outer-class="max-w-[254px] mx-auto mt-8"
          prefix-icon="search"
        >
          <i class="las la-check text-2xl mr-3"></i>
          {{ gettext('Save') }}
        </FormKit>
        <div class="text-center">
          <basic-button
            theme="text"
            class="mt-2"
            @click="() => showCreateForm = false"
          >
            {{ gettext('Cancel') }}
          </basic-button>
        </div>
      </FormKit>
    </modal-box>
  </base-layout>
</template>
