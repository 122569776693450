import { FormKitSchemaFormKit } from '@formkit/core'
import { getSchemaDB } from './idb_formkit'

/**
Generators for "select" or "radio" type
form elements from the locations store
These nodes depend on having two functions passed in the data param for FormKit.
gettext and getLocations
*/

export enum Fields {
  district = 'district',
  administrative_post = 'administrative_post',
  suco = 'suco',
  aldeia = 'aldeia',
}

export const getMunicipalitySelect = () => {
  const selectElement: FormKitSchemaFormKit = {
    $formkit: 'select',
    id: Fields.district,
    name: Fields.district,
    key: Fields.district,
    label: '$gettext(Municipality)',
    options: '$getLocations()'
  }
  return selectElement
}

export const getAdministrativePostSelect = () => {
  const selectElement: FormKitSchemaFormKit = {
    $formkit: 'select',
    id: Fields.administrative_post,
    name: Fields.administrative_post,
    key: Fields.administrative_post,
    label: '$gettext("Administrative Post")',
    options: `$getLocations($get(${Fields.district}).value)`,
    if: `$get(${Fields.district}).value`
  }
  return selectElement
}

export const getSucos = () => {
  const selectElement: FormKitSchemaFormKit = {
    $formkit: 'select',
    id: Fields.suco,
    name: Fields.suco,
    key: Fields.suco,
    label: '$gettext(Suco)',
    options: `$getLocations($get(${Fields.district}).value, $get(${Fields.administrative_post}).value)`,
    if: `$get(${Fields.administrative_post}).value`
  }
  return selectElement
}

export const getAldeias = () => {
  /* Create a FormKit select option for these options */
  const selectElement: FormKitSchemaFormKit = {
    $formkit: 'select',
    id: Fields.aldeia,
    name: Fields.aldeia,
    key: Fields.aldeia,
    label: '$gettext(Aldeia)',
    options: `$getLocations($get(${Fields.district}).value, $get(${Fields.administrative_post}).value, $get(${Fields.suco}).value)`,
    if: `$get(${Fields.suco}).value`
  }
  return selectElement
}
