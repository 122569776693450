<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router'
import { ButtonTheme, ButtonSize, BUTTON_THEMES, BUTTON_SIZES } from './button'
withDefaults(defineProps<{
  theme?: ButtonTheme,
  suffixIcon?: string,
  prefixIcon?: string,
  to?: RouteLocationRaw,
  size?: ButtonSize,
  download?: boolean,
  downloadFilename?: string
}>(), {
  theme: 'primary',
  suffixIcon: undefined,
  prefixIcon: undefined,
  size: 'default',
  to: undefined,
  download: false,
  downloadFilename: undefined
})

const emit = defineEmits(['click'])

</script>
<template>
  <component
    :is="to && !download ? 'router-link' : to && download ? 'a' : 'button'"
    :to="to"
    :href="to && download ? to : ''"
    :download="downloadFilename"
    type="button"
    class="rounded-2.5xl text-base w-full md:w-auto text-center relative inline-flex items-center disabled:!pointer-events-none disabled:opacity-50"
    :class="[BUTTON_THEMES[theme], BUTTON_SIZES[size], { 'pr-20' : suffixIcon }, { 'pr-10': !suffixIcon }, { 'pl-20' : prefixIcon }, { 'pl-10': !prefixIcon }, { 'justify-center': !prefixIcon && !suffixIcon}]"
    @click="emit('click', $event)"
  >
    <i
      v-if="prefixIcon"
      class="las absolute inline-block top-1/2 transform -translate-y-1/2 left-10 text-xl"
      :class="prefixIcon"
    ></i>
    <slot></slot>
    <i
      v-if="suffixIcon"
      class="las absolute inline-block top-1/2 transform -translate-y-1/2 right-10 text-xl"
      :class="suffixIcon"
    ></i>
  </component>
</template>
