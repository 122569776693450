import { defineStore } from 'pinia'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
import { format } from 'date-fns'
import { SUBMISSION_OPERATION_TYPE_CREATED, SUBMISSION_OPERATION_TYPE_REMOVED } from '../_constants/submission.constant'
import { useI18NStore } from './i18n'
import { extractFieldNames } from '../_helpers/common.helpers'
import { useToast } from 'vue-toastification'

type History = components['schemas']['HistorySchema']
interface HistoryQueryContext {
  page?: number
  page_size?: number
}
interface HistoryState {
  histories: History[]
}

const initialState: HistoryState = {
  histories: []
}

export const useHistoryStore = defineStore('history', {
  state: () => (initialState),
  actions: {
    async initialize (query: HistoryQueryContext) {
      try {
        const { data, error } = await client.GET('/api/submissions/history', { params: { query } })
        this.histories = (typeof data !== 'undefined') ? data.response : []
        return data
      } catch (e) {
        useToast().info(useI18NStore().gettext('Failed to fetch history data'))
      }
    }
  },
  getters: {
    getHistories (state) {
      const i18nstore = useI18NStore()
      return state.histories.map((history, index) => ({
        no: index + 1,
        username: history.user_details,
        description: i18nstore.interpolate('%(text)s %(form)s %(text2)s %(thing)s', { text: i18nstore.gettext('New changes in'), form: history.form_type, text2: i18nstore.gettext('in the fields:'), thing: extractFieldNames(history.fields).join(', ') }),
        operation: history.type === SUBMISSION_OPERATION_TYPE_CREATED ? i18nstore.gettext('Add') : history.type === SUBMISSION_OPERATION_TYPE_REMOVED ? i18nstore.gettext('Delete') : i18nstore.gettext('Update'),
        date_time: format(new Date(history.created_at), 'dd-LLL-YYY, hh:mm:ss a')
      }))
    }
  }
})
