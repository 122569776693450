<script setup lang="ts">

import PopOver from '../../components/commons/PopOver.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import MultipleLabelText from '../commons/MultipleLabelText.vue'

import { useRouter } from 'vue-router'
import { useModal } from '../../stores/modal'

const router = useRouter()
const modal = useModal()

defineProps<{
    submission: string
    idaProjectId: string
    qrCodeUrl: string
    showDelete: boolean
}>()

const emit = defineEmits <{(e: 'downLoadFactSheet', value?: void): void, (e: 'deleteProject', value?: void): void }>()

</script>

<template>
  <div class="mt-6 2xl:mt-0">
    <multiple-label-text class="!mr-2.5">
      <template #prefixLabel>
        <i class="las la-info-circle mr-2"></i>
        {{ gettext('Project code') }}<strong class="ml-1">{{ idaProjectId }}</strong>
      </template>
      <template #suffixLabel>
        <pop-over>
          <template #toggle>
            <span class="underline h-[50px] text-center">
              {{ gettext('Show QR Code') }}
            </span>
          </template>
          <template #toggled>
            <span class="underline h-[50px] text-center">
              {{ gettext('Hide QR Code') }}
            </span>
          </template>
          <template #footer>
            <basic-button
              download
              :download-filename="`${submission}.png`"
              :to="qrCodeUrl"
              size="md"
              class="!rounded-[15px]"
            >
              <i class="las la-download mr-2"></i> {{ gettext('Download QR code') }}
            </basic-button>
          </template>
          <div class="flex mb-2.5">
            <div class="w-[80px] mh-[80px] border border-zinc-700">
              <img
                width="80"
                height="80"
                :src="qrCodeUrl"
                alt="QR CODE"
              />
            </div>
            <div class="pl-5">
              <p class="text-2sm w-[122px] text-zinc-700">
                {{ gettext('Share this project by downloading and sending this QR code') }}
              </p>
            </div>
          </div>
        </pop-over>
      </template>
    </multiple-label-text>
    <div class="inline-flex w-full md:w-auto mt-6 md:mt-0">
      <basic-button
        class="mr-2.5 !px-6 mb-5 md:mb-0"
        @click="emit('downLoadFactSheet')"
      >
        <i class="las la-file-alt mr-2"></i> {{ gettext('Download fact sheet') }}
      </basic-button>
      <basic-button
        v-if="showDelete"
        class="!p-0 !rounded-full !min-w-[50px] !w-[50px] !h-[50px] inline-flex items-center justify-center"
        theme="primaryOutline"
        @click="modal.open('confirm', {
          label: 'Delete',
          modalTitle: 'Are you sure?',
          modalCaption: 'Do you really want to delete this project? This process cannot be undone',
          callback: async () => {
            {
              emit('deleteProject')
            }
            modal.close()
            router.push({ name: 'projectsIndex' })
          }
        })"
      >
        <i class="las la-trash-alt text-xl"></i>
      </basic-button>
    </div>
  </div>
</template>
