<script setup lang="ts">
import { LooseObject } from '../../_types/commons'
withDefaults(defineProps<{
  size?: string
}>(), {
  size: 'lg'
})
const sizes: LooseObject = {
  lg: 'w-10 min-w-[40px] h-10 border-2',
  md: 'w-7.5 h-7.5 border'
}
</script>
<template>
  <div
    class="rounded-full inline-flex items-center justify-center"
    :class="sizes[size]"
  >
    <slot></slot>
  </div>
</template>
