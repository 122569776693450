<script setup lang="ts">
import { useI18NStore } from '../../stores/i18n'
const i18nstore = useI18NStore()
const emit = defineEmits <{(e: 'viewSubmission', value: Event): void }>()

defineProps<{
  message: string
}>()

function clicked (e: Event) {
  emit('viewSubmission', e)
}

</script>
<template>
  <div>
    {{ i18nstore.gettext(message) }}.
    <button
      class="underline ml-2 cursor-pointer appearance-none p-0 bg-transparent inline-block"
      @click.stop="clicked"
    >
      {{ i18nstore.gettext('View submission') }}
    </button>
  </div>
</template>
