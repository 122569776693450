import { App, Plugin } from 'vue'
import {
  useAuthStore
} from '../stores/auth'

// The Install function used by Vue to register the plugin
export const AuthPlugin: Plugin = {
  install (app: App) {
    const authStore = useAuthStore()
    // allow any componenet to access the i18nStore
    app.config.globalProperties.auth = authStore
  }
}
