<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import { computed, ComputedRef, onMounted } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import BasicCard from '../../components/commons/BasicCard.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import MultipleLabelText from '../../components/commons/MultipleLabelText.vue'
import { useI18NStore } from '../../stores/i18n'
import { SukuSnapShotMenu } from '../../_types/dropdown'
import { FormKit } from '@formkit/vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../../stores/auth'

const authStore = useAuthStore()

const i18nstore = useI18NStore()

const router = useRouter()

const locationStore = useGeoLocationStore()

const activeGeoLocation = computed(() => locationStore.activeGeolocation)

const subtext = (verb: string, thing: string) => {
  return i18nstore.interpolate('%(verb)s %(place)s %(thing)s', {
    verb: i18nstore.gettext(verb),
    place: activeGeoLocation.value?.properties?.name ?? '',
    thing: i18nstore.gettext(thing)
  })
}

const menus: ComputedRef<SukuSnapShotMenu[]> = computed(() => {
  const menuItems: SukuSnapShotMenu[] = [
    {
      icon: 'la-list-alt',
      iconColor: 'bg-purple-500',
      buttonColor: '!border-purple-500 hover:bg-purple-500 focus:bg-purple-500',
      title: i18nstore.gettext('Suku profile'),
      text: subtext('Discover', 'suku profile via sectors'),
      ctaText: i18nstore.gettext('View sector profile'),
      to: `/suku-manager/${activeGeoLocation.value?.properties?.id}/profile/`
    },
    {
      icon: 'la-icons',
      iconColor: 'bg-orange-500',
      buttonColor: '!border-orange-500 hover:bg-orange-500 focus:bg-orange-500',
      title: i18nstore.gettext('Priorities'),
      text: subtext('View', 'suku planning priorities'),
      ctaText: i18nstore.gettext('View suku priorities'),
      to: `/suku-manager/${activeGeoLocation.value?.properties?.id}/priorities/`,
      permissions: [
        'pnds_data.add_sucopriorities',
        'pnds_data.change_sucopriorities',
        'pnds_data.view_sucopriorities'
      ]
    },
    {
      icon: 'la-coins',
      iconColor: 'bg-green-250',
      buttonColor: '!border-green-250',
      selectorClass: 'hover:!bg-green-250 aria-expanded:!bg-green-250',
      title: i18nstore.gettext('Budget'),
      text: subtext('Get insights into', 'budget and expenditures'),
      ctaText: i18nstore.gettext('View suku budget'),
      options: [
        {
          label: i18nstore.gettext('Operational budget'),
          icon: 'la-coins',
          iconColor: 'bg-green-250',
          value: `/suku-manager/${activeGeoLocation.value?.properties?.id}/operational-budget/`
        },
        {
          label: i18nstore.gettext('Infrastructure budget'),
          value: `/suku-manager/${activeGeoLocation.value?.properties?.id}/infrastructure-budget/`,
          icon: 'la-coins',
          iconColor: 'bg-green-250'
        }
      ],
      permissions: [
        'ida_forms.view_pom_1',
        'ida_forms.change_pom_1',
        'ida_forms.add_pom_1'
      ]
    },
    {
      icon: 'la-handshake',
      iconColor: 'bg-amber-400',
      buttonColor: '!border-amber-500 hover:!bg-amber-500 focus:!bg-amber-500',
      title: i18nstore.gettext('Suppliers'),
      text: subtext('Learn about', 'supplier network'),
      ctaText: i18nstore.gettext('View supplier profile'),
      to: `/suku-manager/${activeGeoLocation.value?.properties?.id}/suppliers/`,
      permissions: [
        'ida.add_supplier',
        'ida.change_supplier',
        'ida.view_supplier'
      ]
    },
    {
      icon: 'la-tools',
      iconColor: 'bg-[#F63DB4]',
      buttonColor: '!border-[#F63DB4] hover:!bg-[#F63DB4] focus:!bg-[#F63DB4]',
      title: i18nstore.gettext('Complaints'),
      text: subtext('View and manage', 'complaints register'),
      ctaText: i18nstore.gettext('View complaints'),
      to: `/suku-manager/${activeGeoLocation.value?.properties?.id}/complaints/`,
      permissions: [
        'ida.add_complaint',
        'ida.change_complaint',
        'ida.view_complaint'
      ]
    },
    {
      icon: 'la-comment-dots',
      iconColor: 'bg-[#007BDD]',
      buttonColor: '!border-[#007BDD] hover:!bg-[#007BDD] focus:!bg-[#007BDD]',
      title: i18nstore.gettext('Activities'),
      text: subtext('Dive deeper into', 'activity register'),
      ctaText: i18nstore.gettext('View activities'),
      to: `/suku-manager/${activeGeoLocation.value?.properties?.id}/activities/`
    },
    {
      icon: 'la-users',
      iconColor: 'bg-emerald-600',
      buttonColor: '!border-emerald-600',
      selectorClass: 'hover:!bg-emerald-600 aria-expanded:!bg-emerald-600',
      title: i18nstore.gettext('Teams'),
      text: subtext('View and manage', 'teams register'),
      ctaText: i18nstore.gettext('View teams'),
      options: [
        {
          label: i18nstore.gettext('Project Management Teams'),
          icon: 'la-users',
          iconColor: 'bg-emerald-600',
          value: `/suku-manager/${activeGeoLocation.value?.properties?.id}/management-teams/`
        },
        {
          label: i18nstore.gettext('Planning and Accountability Commision - KPA'),
          value: `/suku-manager/${activeGeoLocation.value?.properties?.id}/kpa/`,
          icon: 'la-users',
          iconColor: 'bg-emerald-600'
        },
        {
          label: i18nstore.gettext('Suku facilitators'),
          value: `/suku-manager/${activeGeoLocation.value?.properties?.id}/suku-facilitators/`,
          icon: 'la-users',
          iconColor: 'bg-emerald-600'
        }
      ]
    }
  ]

  return menuItems.filter(menuItem => {
    return !menuItem.permissions || authStore.canAny(menuItem.permissions)
  })
})

const menuChange = (value?: string) => {
  if (typeof value === 'string') router.push(value)
}

/**
 * if no suku ID found, it will redirect to sukuMap page
 */
if (activeGeoLocation.value === undefined) {
  router.push({
    name: 'sukuMap'
  })
}

</script>
<template>
  <base-layout
    class="relative"
  >
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          to="/suku-manager/"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ gettext('Suku manager') }}</span>
        </router-link>
        <h5
          class="text-2.75xl font-black inline-flex items-center whitespace-nowrap"
        >
          <i
            class="las text-lg relative top-1 la-angle-right text-gray-400 mx-2"
          ></i>
          {{ activeGeoLocation?.properties?.name }}
        </h5>
      </div>
    </template>
    <template #info>
      <multiple-label-text>
        <template #prefixLabel>
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </template>
        <template #suffixLabel>
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </template>
      </multiple-label-text>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <h1 class="text-2xl font-bold">
        {{ `${activeGeoLocation?.properties?.name} ${gettext('snapshot')}` }}
      </h1>
      <div class="mt-9 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7.5">
        <basic-card
          v-for="(menu, index) in menus"
          :key="index"
          class=" transition-all ease-in-out duration-200 !p-8 md:!px-6 md:!py-8 2xl:!p-10 !rounded-5xl !overflow-visible flex flex-col justify-between"
          shadow="sm"
        >
          <div class="text-base block text-left">
            <span
              class="w-11.5 h-11.5 rounded-lg flex items-center justify-center"
              :class="menu.iconColor"
            >
              <i
                class="las text-2.75xl text-white"
                :class="menu.icon"
              ></i>
            </span>
            <h3 class="text-[22px] font-bold mt-3.5 mb-2">
              {{ menu.title }}
            </h3>
            <p class="text-base font-medium leading-5">
              {{ menu.text }}
            </p>
          </div>
          <basic-button
            v-if="menu.to"
            class="mt-4.5 !pl-1 !pr-6 w-full !flex"
            :class="menu.buttonColor"
            :to="menu.to"
            theme="primaryOutline"
          >
            {{ menu.ctaText }}
            <i class="las la-angle-right absolute right-4 top-1/2 transform -translate-y-1/2"></i>
          </basic-button>

          <FormKit
            v-if="menu.options"
            name="sukumanager"
            type="dropdown"
            outer-class="!mb-0 mt-4.5 relative top-1 ml-1"
            :selector-class="`font-bold !pl-2 !pr-8 flex items-center justify-center h-[50px] !transition-none px-2 !rounded-2.5xl [&>div>div>span]:hidden border-2 aria-expanded:!rounded-bl-none aria-expanded:!rounded-br-none hover:!text-white aria-expanded:!text-white ${menu.buttonColor} ${menu.selectorClass}`"
            :dropdown-wrapper-class="`!rounded-bl-2.5xl !rounded-br-2.5xl !min-h-max !max-h-max !pb-1 ${menu.buttonColor}`"
            listitem-class="flex item-center py-2 !text-sm font-bold border-t border-t-zinc-400 hover:bg-stone-100 hover:text-zinc-900 [&>div]:items-center !px-[15px]"
            :options="menu.options"
            :placeholder="menu.ctaText"
            select-icon="angleDown"
            select-icon-class="!top-4 !transition-none group-hover:!fill-white group-aria-expanded:!fill-white"
            @input="menuChange"
          >
            <template #option="{ option }">
              <div class="formkit-option flex text-left leading-5">
                <span
                  class="min-w-[30px] w-7.5 h-7.5 rounded-md flex items-center justify-center text-xl mr-2.5"
                  :class="option.iconColor"
                >
                  <i
                    class="las text-lg text-white"
                    :class="option.icon"
                  ></i>
                </span>
                {{ option.label }}
              </div>
            </template>
          </FormKit>
        </basic-card>
      </div>
    </content-section>
  </base-layout>
</template>
