<template>
  <div class="bg-white rounded-2.5xl border border-zinc-400">
    <div class="px-5 xl:px-[46px] py-6 border-b border-zinc-400">
      <slot name="title"></slot>
    </div>
    <div class="px-5 xl:px-[46px] pt-8 pb-8">
      <slot></slot>
    </div>
  </div>
</template>
