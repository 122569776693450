import { FormKitSchemaFormKit, FormKitSchemaDOMNode, FormKitNode } from '@formkit/core'
import { ComputedRef } from 'vue'
import { Crumb } from '../stores/idaforms'
import { OptionsType } from '../_types/components/forms/select-dropdown'
import { components } from '../_api-services/openapi'
import { languages } from '../_types/translations'

// Whatever comes out of the `CreateForm` should match this schema
type Form = components['schemas']['FormSchema']
type FormSchemaIn = components['schemas']['FormSchemaIn']
// Converting from the Django style names to Formkit style

export const DjangoToFormkitFields = (field: Form['name_set']): Record<string, string> => {
  return field.reduce<Record<string, string>>((r, val) => { r[val.lang] = val.label; return r }, {})
}

export const FormKitToDjangoFields = (fields: Record<string, string>): Form['name_set'] => {
  return Object.entries(fields).map(([lang, label]) => {
    return { lang, label }
  })
}

export const DjangoFormTypeToEditFields = (formIn: Form): FormSchemaIn => {
  // The Django form type has an array of fields for translations
  // The Formkit model works best with key-value type translations
  return {
    key: formIn.key as string,
    enabled: formIn?.enabled,
    submission_type: formIn?.submission_type as 'finance' | 'technical' | 'social',
    status: (formIn?.status ?? 'draft') as 'draft' | 'published' | 'protected',
    description: DjangoToFormkitFields(formIn?.description_set ?? []),
    name: DjangoToFormkitFields(formIn?.name_set ?? [])
  }
}

export const EditFieldsToDjangoFormType = (formIn: FormSchemaIn): Omit<Form, 'schema_id'> => {
  return {
    key: formIn.key,
    enabled: formIn?.enabled,
    submission_type: formIn?.submission_type,
    description_set: FormKitToDjangoFields(formIn?.description as Record<string, string> ?? []),
    name_set: FormKitToDjangoFields(formIn?.name as Record<string, string> ?? [])
  }
}

const iconOptions = [
  {
    label: 'Users',
    value: 'las la-users'
  },
  {
    label: 'Users cog',
    value: 'las la-users-cog'
  },
  {
    label: 'Info circle',
    value: 'las la-info-circle'
  },
  {
    label: 'Money',
    value: 'las la-money-bill'
  },
  {
    label: 'Map marked',
    value: 'las la-map-marked-alt'
  },
  {
    label: 'List',
    value: 'las la-list-alt'
  },
  {
    label: 'Clock',
    value: 'las la-clock'
  },
  {
    label: 'Balance scale',
    value: 'las la-balance-scale'
  },
  {
    label: 'Address book',
    value: 'las la-address-book'
  }
]

const FormKitType: Record<string, string> = {
  TEXT: 'text',
  TEL: 'tel',
  CURRENCY: 'currency',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  NUMBER: 'number',
  GROUP: 'group',
  LIST: 'list',
  PASSWORD: 'password',
  BUTTON: 'button',
  RADIO: 'radio',
  FORM: 'form',
  DATE: 'date',
  DATEPICKER: 'datepicker',
  DROPDOWN: 'dropdown',
  REPEATER: 'repeater',
  AUTOCOMPLETE: 'autocomplete',
  EMAIL: 'email',
  UUID: 'uuid',
  TEXTAREA: 'textarea',
  HIDDEN: 'hidden'
} as const

const AvailableFormKitTypes: Record<keyof typeof FormKitType, string> = {
  [FormKitType.TEXT]: 'Text',
  [FormKitType.TEXTAREA]: 'TextArea',
  // [FormKitType.TEL]: 'Telephone',
  [FormKitType.CURRENCY]: 'Currency',
  [FormKitType.SELECT]: 'Select',
  [FormKitType.CHECKBOX]: 'Checkbox',
  [FormKitType.NUMBER]: 'Number',
  // [FormKitType.GROUP]: 'Group',
  // [FormKitType.LIST]: 'List',
  // [FormKitType.PASSWORD]: 'Password',
  // [FormKitType.BUTTON]: 'Button',
  [FormKitType.RADIO]: 'Radio',
  // [FormKitType.FORM]: 'Form',
  [FormKitType.DATE]: 'Date',
  [FormKitType.DATEPICKER]: 'Datepicker',
  [FormKitType.DROPDOWN]: 'Dropdown',
  // [FormKitType.REPEATER]: 'Repeater',
  [FormKitType.AUTOCOMPLETE]: 'Autocomplete',
  [FormKitType.EMAIL]: 'Email'
  // [FormKitType.UUID]: 'UUID'
}

const repeaterTypes = Object.fromEntries(
  Object.entries(FormKitType).filter(([key, value]) => {
    return [FormKitType.CHECKBOX, FormKitType.AUTOCOMPLETE, FormKitType.DROPDOWN, FormKitType.RADIO, FormKitType.SELECT].includes(value)
  }
  )
)

const getOptions = () => {
  const options: OptionsType = []
  for (const [value, label] of Object.entries(AvailableFormKitTypes)) {
    options.push({
      label,
      value
    })
  }
  return options
}

type validationRule = (node: FormKitNode) => boolean

const addGroupSchema = (nameOrIdNotUsedInScope: validationRule) => {
  return [
    {
      $formkit: 'text',
      name: '$: "$formkit"',
      disabled: true,
      value: FormKitType.GROUP
    },
    {
      $formkit: 'group',
      name: 'additional_props',
      children: [
        {
          $formkit: 'text',
          name: 'title',
          label: '$gettext("Enter a title for your group")'
        },
        {
          $formkit: 'dropdownIcon',
          name: 'icon',
          label: '$gettext("Icon for your group")',
          options: iconOptions
        }
      ]
    }
  ]
}

const formkitTypePicker = {
  $formkit: 'select',
  // if: `$get(${nodeTypePicker.id}).value === '$formkit')`,
  name: '$: "$formkit"',
  id: 'formkit_type',
  placeholder: '$gettext("Select type")',
  label: '$gettext("What type of form element would you like?")',
  validation: 'required',
  options: getOptions()
}

const labelField = () => {
  return {
    $formkit: 'text',
    name: 'label',
    id: 'label',
    validation: 'required',
    label: '$gettext("Enter a label for your input")',
    help: '$gettext("This labels the field in the data entry")'
  }
}

const placeholderField = {
  $formkit: 'text',
  name: 'placeholder',
  id: 'placeholder',
  label: '$gettext("Enter a placeholder text for your field")'
}

const helpField = {
  $formkit: 'text',
  name: 'help',
  id: 'help',
  label: '$gettext("Enter a help text for your field")'
}

const requiredField = {
  $formkit: 'radio',
  name: 'additional_props.validation',
  id: 'additional_props.validation',
  label: '$gettext("Is it a mandatory field ?")',
  options: [
    {
      label: 'Yes',
      value: 'required'
    },
    {
      label: 'No',
      value: ''
    }
  ]
}

/** This is a valid FormKit string which filters nodes to "select" types */
const hasOptions = Object.values(repeaterTypes).map(it => `$get(${formkitTypePicker.id}).value == "${it}"`).join(' || ')

const idaGroupPicker = {
  $formkit: 'select',
  if: hasOptions,
  name: 'options',
  label: '$gettext("Select which options to show in this question")',
  options: '$getIdaOptions()'
}

export const addOrEditNodeSchema = (nodes: ComputedRef<Crumb[]>, nameOrIdNotUsedInScope: validationRule): Array<FormKitSchemaFormKit | FormKitSchemaDOMNode> => {
  if (nodes.value.length === 1) return addGroupSchema(nameOrIdNotUsedInScope)
  return [
    // nodeTypePicker,
    formkitTypePicker,
    // After user has chosen a type of value, show Name and Label
    {
      $el: 'div',
      if: `$get(${formkitTypePicker.id}).value`,
      children: [labelField(), placeholderField, helpField]
    },
    // Some field types have a 'picker' component
    idaGroupPicker,
    requiredField
  ]
}

// The 'Add or update form' schema
export const addOrUpdateFormSchema = ({ uniqueKey, isEdit }: { uniqueKey?: validationRule, isEdit?: boolean }): FormKitSchemaFormKit[] => {
  const keyField = {
    $formkit: FormKitType.TEXT,
    name: 'key',
    placeholder: 'porezemplu: TF_6_1_1',
    label: "$gettext('Form Key')",
    help: "$gettext('Provide a unique text key for your new form')",
    validation: [['required'], ['matches', /^[\w]+$/]],
    validationRules: {
      uniqueKey
    },
    validationMessages: {
      matches: "$gettext('use letters, numbers and underscores only')",
      uniqueKey: "$gettext('we already have one of these')"
    }
  }

  const readOnlyKeyField = {
    ...keyField,
    readonly: true
  }

  const submissionTypeField = {
    $formkit: FormKitType.SELECT,
    name: 'submission_type',
    options: {
      finance: "$gettext('Finance')",
      social: "$gettext('Social')",
      technical: "$gettext('Technical')"
    },
    label: "$gettext('What type is this form')"
  }

  const nameFields = {
    $formkit: FormKitType.GROUP,
    name: 'name',
    children: languages.map(({ code, name }) => {
      return {
        $formkit: FormKitType.TEXT,
        name: code,
        placeholder: `$gettext('Naran (${code})')`,
        label: code === 'en' ? "$gettext('Provide a display name for this form')" : `$gettext('Provide a display name for this form in ${name}')`
      }
    })
  }

  const descriptionFields = {
    $formkit: FormKitType.GROUP,
    name: 'description',
    children: languages.map(({ code, name }) => {
      return {
        $formkit: FormKitType.TEXT,
        name: code,
        placeholder: `$gettext('Deskrisaun (${code})')`,
        label: code === 'en' ? "$gettext('Provide a description of this form')" : `$gettext('Provide a description of this form in ${name}')`
      }
    })
  }

  const statusField = {
    $formkit: FormKitType.RADIO,
    name: 'status',
    label: '$gettext("Status")',
    options: '$statusOptions()'
  }

  const readOnlyStatusField = {
    ...statusField, readonly: true
  }

  return [
    isEdit ?? false ? readOnlyKeyField : keyField,
    isEdit ?? false ? readOnlyStatusField : statusField,
    // Ensure a 'create' will not overwrite an existing form
    { $formkit: FormKitType.HIDDEN, name: 'action', value: isEdit ?? false ? 'update' : 'create' },
    submissionTypeField,
    nameFields,
    descriptionFields]
}
