import { styles } from './theme_default'

const datepicker = {
  // This is adapted from the "genesis" theme which ships with FormKit
  outer: 'mb-5',
  label: 'mb-2.5 text-2sm block font-semibold',
  input: 'w-full block relative pl-3 pr-3  rounded-2.5xl bg-transparent !border-none outline-none ',

  inner: 'formkit-invalid:border-red-500 formkit-invalid:border-2 flex bg-white w-full border border-zinc-400 rounded-lg text-2sm h-[40px] focus:border-2 focus:border-emerald-600 shadow-none formkit-disabled:bg-gray-200 formkit-disabled:opacity-50',
  panelWrapper: 'absolute shadow-lg p-5 bg-white z-10 rounded-xl',
  panelHeader: 'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b border-zinc-400 mb-4 pb-4',
  monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
  timeHeader: 'flex items-center justify-center col-start-2 col-end-2',
  overlayPlaceholder: 'text-gray-400',
  months: 'flex flex-wrap',
  month: `
  flex items-center justify-center
  w-[calc(33%_-_1em)] m-2 p-2 rounded-md
  bg-gray-200
  aria-selected:bg-emerald-600 aria-selected:text-white
  focus:outline focus:outline-2 focus:outline-emerald-600 focus:outline-offset-2 focus:bg-white focus:text-black
  data-[is-extra=true]:opacity-25
  formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
`,
  yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
  years: 'flex flex-wrap max-w-[35em]',
  year: `
  flex items-center justify-center
  w-[calc(20%_-_1em)] m-2 p-2 rounded-md
  bg-gray-200
  aria-selected:bg-emerald-600 aria-selected:text-white
  focus:outline focus:outline-2 focus:outline-emerald-600 focus:outline-offset-2 focus:bg-white focus:text-black
  data-[is-extra=true]:opacity-25
  formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
`,
  weekDays: 'flex',
  weekDay: 'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase',
  week: 'flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
  dayCell: '[&[aria-selected=true]>div]:text-white [&[aria-selected=true]>div]:bg-emerald-600',
  day: `relative 
  flex items-center justify-center
  w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
  bg-gray-200
  focus:outline focus:outline-2 focus:outline-emerald-600 focus:outline-offset-2 focus:bg-white focus:text-black 
  hover:border-2 hover:border-emerald-600 hover:bg-white hover:text-black
  data-[is-extra=true]:opacity-25
  formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none cursor-pointer
`,
  daysHeader: 'flex items-center justify-center',
  prev: 'mr-auto px-3 py-1 hover:bg-gray-100 hover:rounded-lg col-start-1 col-end-1',
  prevLabel: 'hidden',
  prevIcon: 'flex w-[18px] select-none [&>svg]:w-full',
  dayButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-emerald-600',
  monthButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-emerald-600',
  yearButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-emerald-600',
  next: 'ml-auto px-3 py-1 hover:bg-gray-100 hover:rounded col-start-3 col-end-3',
  nextLabel: 'hidden',
  nextIcon: 'flex w-[18px] select-none [&>svg]:w-[full]',
  openButton: `
  flex appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
  focus-visible:outline-none focus-visible:text-white focus-visible:bg-emerald-600
`,
  calendarIcon: 'flex w-8 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]',
  message: 'formkit-invalid:text-red-500 text-sm mt-2 font-medium'
}

const textFieldClasses = {
  outer: 'mb-5 focus:bg-red-800',
  wrapper: 'relative group',
  inner: 'rounded-lg border border-zinc-400 flex items-center pl-3 pr-3 relative text-zinc-700 text-base h-[50px] formkit-invalid:bg-red-100 formkit-invalid:border-red-500 formkit-invalid:border-2',
  input: 'shadow-none bg-transparent appearance-none !border-none outline-0 w-full h-full pl-0 pr-0 pb-0 relative z-10 text-base peer pt-2',
  message: 'text-red-500 text-sm mt-1',
  prefixIcon: 'w-[20px] mr-[12px] formkit-complete:text-emerald-600 formkit-invalid:text-red-500',
  label: 'absolute left-[48px] text-base top-[13px] transition-all ease-in-out duration-200 top-3 left-12 formkit-complete:text-2xs formkit-complete:transform formkit-complete:-translate-y-3 formkit-complete:-translate-x-[6px] peer-focus:text-2xs peer-focus:transform peer-focus:-translate-y-3 peer-focus:-translate-x-[6px] peer-valid:text-2xs peer-valid:transform peer-valid:-translate-y-3 peer-valid:-translate-x-[6px]',
  suffixIcon: 'w-[22px] text-emerald-600 cursor-pointer'
}
const textareaFieldClasses = {
  outer: 'mb-5 focus:bg-red-800',
  wrapper: 'relative group',
  inner: 'rounded-lg border border-zinc-400 flex items-center pl-3 pr-3 relative text-zinc-700 text-base h-[50px] formkit-invalid:bg-red-100 formkit-invalid:border-red-500 formkit-invalid:border-2',
  input: 'shadow-none bg-transparent appearance-none !border-none outline-0 w-full h-full pl-0 pr-0 pb-0 relative z-10 text-base peer pt-2',
  message: 'text-red-500 text-sm mt-1',
  prefixIcon: 'w-[20px] mr-[12px] formkit-complete:text-emerald-600 formkit-invalid:text-red-500',
  label: 'text-2sm mb-2.5 font-semibold block',
  suffixIcon: 'w-[22px] text-emerald-600 cursor-pointer'
}

const buttonClassification = {
  wrapper: 'mt-5 formkit-has-suffix-icon:bg-red-600',
  input:
    'h-[50px] rounded-2.5xl px-10 flex items-center text-base w-full justify-center text-center relative bg-emerald-600 !hover:bg-emerald-600/90 !focus:bg-emerald-600/90 text-white font-bold hover:bg-emerald-600 disabled:opacity-50 disabled:pointer-events-none',
  suffixIcon: 'w-[22px] ml-4'
}

const basicClasses = {
  input: 'w-full border read-only:bg-gray-200/50 read-only:!border read-only:!border-zinc-400 border-zinc-400 rounded-lg text-2sm h-[40px] focus:border-2 focus:border-emerald-600 shadow-none formkit-invalid:border-red-500 formkit-invalid:border-2 formkit-disabled:bg-gray-200 formkit-disabled:opacity-50',
  outer: 'mb-5',
  label: 'mb-2.5 text-2sm font-semibold block',
  message: 'text-red-500 text-sm mt-2 font-medium',
  help: 'mt-2 text-3xs text-gray-500'
}

const selectionClasses = {
  wrapper: 'flex items-center mb-3 cursor-pointer',
  outer: 'mb-5',
  legend: 'text-2sm mb-4 font-semibold',
  label: 'text-2sm',
  input: ' bg-transparent focus:outline-none focus:ring-0 focus:shadow-none absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
  inner: 'inline-flex items-center mr-2.5',
  decorator: 'formkit-disabled:opacity-20 min-w-[22px] h-[22px] text-sm rounded-full peer-checked:bg-emerald-600 peer-checked:border-emerald-600 peer-focus:shadow-border inline-flex items-center justify-center text-center !leading-5 border border-zinc-400 bg-white text-white before:content-[` `] before:inline-block before:w-[12px] before:h-[12px] before:rounded-full before:bg-white'

}

const selectionSquareClasses = {
  ...selectionClasses,
  outer: '',
  decorator: 'formkit-disabled:opacity-20 min-w-[22px] h-[22px] text-sm rounded-md peer-checked:bg-white peer-checked:text-emerald-600 peer-checked:border-emerald-600 peer-focus:shadow-border inline-flex items-center justify-center text-center !leading-5 border-2 border-zinc-400 bg-white text-white las before:inline-block before:w-[16px] before:h-[16px] before:rounded-md before:left-0 before:right-0 before:mx-auto before:absolute relative before:bg-white la-check'
}

const repeaterClasses = {
  controls: 'mt-7.5 group-data-[disabled]:pointer-events-none group-data-[disabled]:hidden',
  removeIcon: 'w-[16px] mr-4',
  addButton: '[&>div]:w-full group-data-[disabled]:pointer-events-none group-data-[disabled]:hidden [&>div]:h-full [&>div>button]:h-full [&>div>button]:w-full cursor-pointer h-[50px] rounded-2.5xl px-10 flex items-center text-base w-full justify-center text-center relative bg-emerald-600 !hover:bg-emerald-600/90 !focus:bg-emerald-600/90 text-white font-bold hover:bg-emerald-600 disabled:bg-emerald-600/50 hover:drop-shadow-lg mt-4',
  removeControl: 'disabled:hidden cursor-pointer flex items-center justify-center flex-row-reverse h-[50px] rounded-2.5xl px-4 w-full bg-white border-2 border-solid border-emerald-600 text-zinc-900 hover:bg-emerald-600 hover:text-white font-bold text-base',
  item: 'py-7 relative after:content-[` `] first:pt-3 last:after:hidden last:pb-0 after:block after:absolute after:-left-4 after:right-0 after:w-[calc(100%+32px)] after:h-[2px] after:bg-zinc-400 after:bottom-0',
  fieldset: '[&:disabled>.formkit-add-button]:opacity-50 [&:disabled>.formkit-add-button]:pointer-events-none',
  outer: 'group'
}

const dropdownClasses = {
  outer: 'mb-5',
  label: 'block mb-2.5 font-semibold text-2sm',
  selector: 'peer formkit-invalid:border-red-500 formkit-invalid:border-2 bg-white group aria-expanded:border-b-transparent aria-expanded:rounded-bl-none aria-expanded:rounded-br-none formkit-disabled:bg-gray-200 formkit-disabled:opacity-50 relative formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none w-full border border-zinc-400 rounded-lg text-2sm h-[40px] aria-expanded:border-2 aria-expanded:border-emerald-600 shadow-none px-4 flex items-center',
  inner: 'relative',
  selectIcon: 'w-4 absolute right-0 top-[10px] right-4 group-aria-expanded:transform group-aria-expanded:rotate-180',
  selectedIcon: 'hidden',
  dropdownWrapper: 'max-h-[170px] pb-2 !top-full !bottom-none peer-aria-expanded:block hidden border-l-2 border-r-2 border-b-2 border-solid !bottom-none !shadow-none border-emerald-600 border-t-0 w-full [&amp;::-webkit-scrollbar]:hidden top-[40px] !bottom-none bg-white !rounded-tl-0 !rounded-tr-0 rounded-bl-lg rounded-br-lg',
  listitem: 'px-4 text-base py-1 cursor-pointer hover:bg-teal-500/5 hover:text-teal-500 aria-selected:bg-teal-500 aria-selected:text-white',
  message: 'formkit-invalid:text-red-500 text-sm mt-2 font-medium'
}

const maskClasses = {
  input: 'w-full px-3 border border-zinc-400 rounded-lg text-2sm h-[40px] focus:border-2 outline-none focus:border-emerald-600 shadow-none formkit-invalid:border-red-500 formkit-invalid:border-2 formkit-disabled:bg-gray-200 formkit-disabled:opacity-50',
  outer: 'mb-5',
  label: 'mb-2.5 text-2sm block font-semibold',
  message: 'text-red-500 text-sm mt-2 font-medium'
}

const selectClasses = {
  input: 'w-full cursor-pointer border border-zinc-400 rounded-lg text-2sm h-[40px] focus:border-2 focus:border-emerald-600 shadow-none formkit-invalid:border-red-500 formkit-invalid:border-2 formkit-disabled:bg-gray-200 formkit-disabled:opacity-50',
  outer: 'mb-5',
  label: 'mb-2.5 text-2sm block font-semibold',
  message: 'text-red-500 text-sm mt-2 font-medium',
  help: 'mt-2 text-3xs text-gray-500'
}

const searchClasses = {
  ...basicClasses,
  prefixIcon: 'absolute w-[20px] h-[24px] left-4 top-1/2 transform -translate-y-1/2 mt-[2px]',
  inner: 'relative',
  input: 'w-full pl-11 border border-zinc-400 rounded-lg text-2sm h-[40px] focus:border-2 focus:border-emerald-600 shadow-none formkit-invalid:border-red-500 formkit-invalid:border-2 formkit-disabled:bg-gray-200 formkit-disabled:opacity-50'
}

const autoCompleteClasses = {
  ...basicClasses,
  prefixIcon: 'absolute w-[20px] h-[24px] left-4 top-1/2 transform -translate-y-1/2 mt-[2px]',
  suffixIcon: 'absolute w-[20px] h-[24px] right-4 top-1/2 transform -translate-y-1/2 mt-[2px]',
  inner: 'relative',
  input: 'w-full pl-3 border border-zinc-400 rounded-lg text-2sm h-[40px] focus:border-2 focus:border-emerald-600 shadow-none formkit-invalid:border-red-500 formkit-invalid:border-2 formkit-disabled:bg-gray-200 formkit-disabled:opacity-50',
  dropdownWrapper: 'bg-white rounded-lg shadow-lg !z-[1001]',
  listitem: 'px-4 py-2 cursor-pointer hover:bg-stone-100 flex',
  selectedIcon: 'w-4 mr-2 relative top-[3px]',
  emptyMessage: 'px-4 py-2 text-center text-gray-400 text-2sm'
}

const textareaClass = {
  ...basicClasses,
  input: 'w-full border border-zinc-400 rounded-lg text-2sm focus:border-2 focus:border-emerald-600 shadow-none formkit-invalid:border-red-500 formkit-invalid:border-2 formkit-disabled:bg-gray-200 formkit-disabled:opacity-50 min-h-[128px]'
}

const transferlist = { ...styles.transferlist }

export default {
  number: basicClasses,
  date: basicClasses,
  tel: basicClasses,
  textarea: textareaClass,
  mask: maskClasses,
  datepicker,
  select: selectClasses,
  radio: selectionClasses,
  checkbox: selectionSquareClasses,
  textareaFloating: textareaFieldClasses,
  textFloating: textFieldClasses,
  email: basicClasses,
  password: textFieldClasses,
  submit: buttonClassification,
  text: basicClasses,
  currency: basicClasses,
  repeater: repeaterClasses,
  dropdown: dropdownClasses,
  dropdownIcon: dropdownClasses,
  search: searchClasses,
  autocomplete: autoCompleteClasses,
  transferlist
}
