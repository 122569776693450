import { useToast } from 'vue-toastification'
import { getCsrf } from '../../_helpers/common.helpers'
import { useI18NStore } from '../../stores/i18n'
import { API_RESPONSE_MESSAGE } from '../../_constants/toastmessages.constant'

const toast = useToast()

const createFetchInterceptor = (fetch: typeof window.fetch) => {
  /**
   * Wraps `fetch` to always include Django's `csrftoken` for authentication
   */
  return async function (input: RequestInfo, init?: RequestInit) {
    const modifiedInit: RequestInit = {
      ...init,
      headers: {
        ...init?.headers,
        'X-CSRFToken': getCsrf()
      }
    }

    return await fetch(input, modifiedInit)
      .then((response) => {
        if (response.ok) {
          return response
        }
        throw new Error()
      })
      .catch((error) => {
        let errorMessage = API_RESPONSE_MESSAGE.error
        if (error instanceof Error) {
          errorMessage += error.message
        }
        toast.error(useI18NStore().gettext(errorMessage))
      })
  }
}

const interceptedFetch = createFetchInterceptor(window.fetch)

export default interceptedFetch
