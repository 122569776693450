<script setup lang="ts">
import { useFormsStore } from '../../stores/forms'
import DataTable from '../../components/commons/DataTable.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import type { Header, Item } from 'vue3-easy-data-table'
import { useI18NStore } from '../../stores/i18n'
import { LooseObject } from '../../_types/commons'
import { computed } from 'vue'
import { DATA_COLLECTION_STATUS_ARRAY, FORM_SCHEMA_STATUS_PUBLISHED } from '../../_constants/common.constant'
import { strCapitalized } from '../../_helpers/common.helpers'
import { useLocationStore } from '../../stores/locations'
import { Fields } from '../../_formkit/templates/_locations'
import { headersForm, locationHeader } from '../../_formkit/schema/form.schema'
import { useModal } from '../../stores/modal'
import { MODAL_DELETE_SUBMISSION } from '../../_constants/modal.constant'
import { format } from 'date-fns'
const i18Store = useI18NStore()
const store = useFormsStore()
const modal = useModal()

/** Provides lookup information for the store */
const locationStore = useLocationStore()

/** This is required before rendering the list to provide key to name lookup for location fields */
await locationStore.initialize()
const props = withDefaults(defineProps<{
  formType: string,
  formName?: string,
}>(), {
  formName: 'Form name'
})

/**
 * check whether the form is published or not
 */
const isFormPublished = computed(() => store.getFormByFormType(props.formType)?.status === FORM_SCHEMA_STATUS_PUBLISHED)

const submissions = computed(() => store.submissionsOfType(props.formType))
const getHeader: Header[] = typeof headersForm[props.formType] !== 'undefined'
  ? headersForm[props.formType].map((header: Header) => {
    return {
      ...header,
      ...{ text: i18Store.gettext(header.text) }
    }
  })
  : locationHeader
const headers = computed(() => {
  let headers: Header[] = [
    { text: i18Store.gettext('Form'), value: 'form_name', width: 220 }
  ]
  if (submissions.value.length) {
    headers = headers.concat(getHeader)
    headers = headers.concat(
      [
        {
          text: i18Store.gettext('Status'),
          value: 'submission_status',
          sortable: true
        },
        {
          text: '',
          value: 'action'
        }
      ]
    )
  }
  return headers
})
const items = computed(() => {
  const items: Item[] = []
  if (submissions.value.length) {
    submissions.value.forEach(sub => {
      const status = strCapitalized(sub.status)
      const itemData: LooseObject = {
        form_name: computed(() => i18Store.pgettext('form', props.formName)),
        submission_status: status,
        redirectParams: {
          formKey: sub.key,
          formType: props.formType
        }
      }
      const formItemsKeys = Object.getOwnPropertyNames(sub.clonableData.fields)

      const nameFromPlaceIds = (fields: string[]) => {
        const path = fields.map((k) => parseInt((sub.clonableData.fields as {[k: string]: string})[k], 10))
        return locationStore.getName(path)
      }
      formItemsKeys.forEach((key) => {
        itemData[key] = sub.clonableData.fields[key]
        // Lookup the values for Location fields. These are Pcodes in our submission / database.
        if (key === Fields.district) itemData[key] = nameFromPlaceIds([Fields.district])
        else if (key === Fields.administrative_post) itemData[key] = nameFromPlaceIds([Fields.district, Fields.administrative_post])
        else if (key === Fields.suco) itemData[key] = nameFromPlaceIds([Fields.district, Fields.administrative_post, Fields.suco])
        else if (key === Fields.aldeia) itemData[key] = nameFromPlaceIds([Fields.district, Fields.administrative_post, Fields.suco, Fields.aldeia])
        else if (key === 'date') itemData[key] = typeof itemData[key] !== 'undefined' && itemData[key] !== '' ? format(new Date(itemData[key]), 'dd/MM/yyyy') : ''
      })
      items.push(itemData)
    })
  }
  return items
})

const deleteAction = async (item: LooseObject) => {
  modal.open('confirm', {
    label: 'Delete',
    modalTitle: MODAL_DELETE_SUBMISSION.title,
    modalCaption: MODAL_DELETE_SUBMISSION.caption,
    callback: async () => {
      await store.setActiveSubmission(item?.redirectParams?.formKey)
      await store.deleteActiveSubmission()
      modal.close()
    }
  })
}

const dataTableFilters = {
  field: 'submission_status',
  options: DATA_COLLECTION_STATUS_ARRAY
}

const submissionRouteName = computed(() => {
  // This is a temporary mapping of a "form type" to a "route name"
  // if (props.formType === 'SF_1_1') return 'submission_demo'
  // else if (props.formType === 'SF_2_3') return 'submission_sf23'
  // else if (props.formType === 'SF_1_3') return 'submission_sf13'
  // else if (props.formType === 'SF_1_2') return 'submission_sf12'
  // else if (props.formType === 'SF_4_1') return 'submission_sf41'
  // else return 'submission'
  return 'submission'
})

</script>

<template>
  <div
    v-if="submissions !== undefined"
    class="!pt-0"
  >
    <div
      class="-mx-6 md:mx-0"
    >
      <DataTable
        :headers="headers"
        :items="items"
        has-delete-button
        :redirect-path="submissionRouteName"
        theme-color="dark"
        spacing="narrow"
        :filters="dataTableFilters"
        filter-placeholder="Search form submissions"
        filter-suffix-icon="la-search !text-xl text-gray-400"
        @delete="deleteAction"
      >
        <template #header-action>
          <basic-button
            v-if="isFormPublished"
            class="whitespace-nowrap"
            :to="{ name: submissionRouteName, params: { formType: props.formType, formKey: 'new' } }"
          >
            {{ gettext('Complete a form') }}
          </basic-button>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<style scoped>

</style>
