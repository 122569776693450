import { FormkitInput } from '../../../_types/components/commons/form'
import { date, repeaterRemoveProps, repeaterUniqueIdField } from './common.schema'
import { municipality, adminpost, sucos } from './location.schema'

export const priorities = [
  repeaterUniqueIdField,
  {
    key: 'aldeia',
    name: 'aldeia',
    label: 'Aldeia',
    placeholder: 'Select one',
    node_type: 'formkit',
    $formkit: 'select',
    options: '$getLocations($get(district).value, $get(administrative_post).value, $get(suco).value)'
  },
  {
    $formkit: 'select',
    label: '$gettext("Project Sector")',
    key: 'project_sector',
    placeholder: '$gettext("Please select")',
    options: '$ida(sector)',
    name: 'project_sector',
    id: 'project_sector'
  },
  {
    $formkit: 'select',
    label: '$gettext("Project Sub-Sector")',
    key: 'project_sub_sector',
    placeholder: '$gettext("Please select")',
    if: '$get("project_sector").value && $ida(subsector, "sector_id="+$get(project_sector).value).length',
    options: '$ida(subsector, "sector_id="+$get(project_sector).value)',
    name: 'project_sub_sector',
    id: 'project_sub_sector'
  },
  {
    $formkit: 'select',
    label: '$gettext("Project name")',
    key: 'project_name',
    placeholder: '$gettext("Please select")',
    if: '$get("project_sub_sector").value && $ida(output, "subsector_id="+$get(project_sub_sector).value).length',
    options: '$ida(output, "subsector_id="+$get(project_sub_sector).value)',
    name: 'project_name',
    id: 'project_name'
  },
  {
    $formkit: 'text',
    label: '$gettext("Place")',
    name: 'place'
  },
  {
    key: 'unit_select',
    name: 'unit',
    label: "$pgettext('sf23_priority', 'Volume / Unit')",
    node_type: 'formkit',
    $formkit: 'select',
    if: '$get(project_name).value',
    options: '$ida(unit)'
  },
  {
    key: 'project_type',
    name: 'project_type',
    label: '$gettext("Project type")',
    placeholder: '$gettext("Please select")',
    node_type: 'formkit',
    $formkit: 'select',
    options: '$ida(activity)'
  },
  {
    $formkit: 'number',
    label: '$gettext("No. of beneficiaries female")',
    name: 'beneficiaries_female',
    validation: 'shouldNotAcceptNegativeValue',
    min: '0'
  },
  {
    $formkit: 'number',
    label: '$gettext("No. of beneficiaries male")',
    name: 'beneficiaries_male',
    validation: 'shouldNotAcceptNegativeValue',
    min: '0'
  },
  {
    $formkit: 'number',
    label: '$gettext("No. of households")',
    name: 'households',
    validation: 'shouldNotAcceptNegativeValue',
    min: '0'
  },
  {
    key: 'women_priority',
    name: 'women_priority',
    label: "$pgettext('sf23_priority', 'From priorities identified by women (yes/no)')",
    node_type: 'formkit',
    $formkit: 'select',
    placeholder: '$gettext("Please select")',
    options: '$ida(yesno)'
  },
  {
    name: 'cost_estimation',
    label: "$pgettext('sf23_priority', 'Cost Estimation')",
    node_type: 'formkit',
    $formkit: 'currency',
    min: 0,
    step: '0.01'
  }
]

export const sf23Schema: FormkitInput[] = [
  {
    id: 'location',
    title: 'Location',
    $formkit: 'group',
    children: [municipality, adminpost, sucos, date],
    icon: 'las la-map-marked-alt'
  },
  {
    id: 'priorities',
    title: 'Priorities',
    $formkit: 'group',
    children: [
      {
        repeaterUniqueIdField,
        name: 'priorities',
        node_type: 'formkit',
        $formkit: 'repeater',
        upControl: false,
        downControl: false,
        addLabel: '$pgettext("sf23_priority", "Add another priority")',
        id: 'priorities',
        ...repeaterRemoveProps,
        children: [
          {
            $el: 'div',
            children: [
              '$gettext("priority")', ' ',
              {
                $el: 'span',
                attrs: {
                  class: 'ml-1'
                },
                children: ['$: ($index + 1)']
              }
            ],
            attrs: {
              class: 'rounded-full px-5 py-2 bg-zinc-400 text-lg font-bold mb-5'
            }
          },
          ...priorities
        ]
      },
      {
        $formkit: 'hidden',
        name: 'submitted_from',
        value: 'data-collection'
      }
    ],
    icon: 'las la-list-alt'
  }
]
