type Color = string

class Palette extends Map<string | number, Color> {}

const ColorList = {
  Purple: '#3A43CD',
  Pink: '#F63DB4',
  LightBlue: '#5BAEFF',
  LightYellow: '#FFC783',
  Orange: '#FF862E',
  LightGreen: '#ACCE89',
  PrimaryTeal: '#008575',
  DarkerBlue: '#007BDD',
  Yellow: '#FFBF00',
  Green: '#88BF4D',
  LightPink: '#FF90E5',
  PaleRed: '#F66569',

  // Common colors for map
  MapMarkerBlue: '#4F57D4'
} as const

const SectorColors = new Palette([
  [1, ColorList.Purple],
  [2, ColorList.Pink],
  [3, ColorList.LightBlue],
  [4, ColorList.LightYellow],
  [5, ColorList.Orange],
  [6, ColorList.LightGreen],
  [7, ColorList.PrimaryTeal],
  [8, ColorList.DarkerBlue]
])

const BeneficiariesColors = new Palette([
  ['men', '#E7AC78'],
  ['women', '#7172A8']
])

export { ColorList, SectorColors, BeneficiariesColors }
