<script setup lang="ts">
import { computed } from 'vue'
import { components } from '../../_api-services/openapi'
import { headers, calculateTableData } from './helpers'
import DataTable from '../../components/commons/DataTable.vue'
import downloadElement from '../../_helpers/downloadElement'
import TooltipBox from '../../components/commons/TooltipBox.vue'

// Uncomment below to experience 'slow' loading
// import { delay } from '../../stores/idaforms'
// await delay(15000)

const props = defineProps<{dataFunction: Promise<components['schemas']['LegacyProfileData'] | undefined>, locationName?: string, sectorId: number | undefined }>()
const data = await props.dataFunction

const legacyProjectItems = computed(() => calculateTableData(data, props.sectorId ?? 0))

</script>
<template>
  <div class="mb-8 lg:mb-[60px]">
    <div class="flex items-center mb-9">
      <h1 class="text-2.5xl font-bold">
        {{ gettext('Legacy projects') }}
      </h1>
      <div class="rounded-full border border-zinc-400 bg-zinc-400/50 h-9 flex text-xl px-2 ml-6 hover:bg-zinc-400/20 hover:border-gray-100">
        <tooltip-box :text="gettext('Legacy project list')">
          <button class="px-1 hover:text-emerald-600">
            <i class="las la-info-circle"></i>
          </button>
        </tooltip-box>
        <tooltip-box :text="gettext('Download data as image')">
          <button
            class="px-1 hover:text-emerald-600"
            @click="downloadElement({elementId: 'legacy-projects-table'})"
          >
            <i class="las la-download"></i>
          </button>
        </tooltip-box>
      </div>
    </div>
    <transition appear>
      <div>
        <DataTable
          :headers="headers"
          :items="legacyProjectItems"
          theme-color="dark"
          spacing="narrow"
          view-more-icon="la-pencil-alt"
          table-id="legacy-projects-table"
        />
      </div>
    </transition>
  </div>
</template>
