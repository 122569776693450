import { defineStore } from 'pinia'
import { getSchemaDB, getNodes, getRelations, getForms } from '../_formkit/templates/idb_formkit'
import { components } from '../_api-services/openapi'
import { FormKitSchemaCondition, FormKitSchemaDOMNode, FormKitSchemaFormKit, FormKitSchemaTextNode } from '@formkit/core'
import { client } from '../_api-services/urls'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
import { FORM_SCHEMA_STATUS_PUBLISHED } from '../_constants/common.constant'

type NodeReturnType = components['schemas']['NodeReturnType']
type NodeStringType = components['schemas']['NodeStringType']
type NodeInactivateType = components['schemas']['NodeInactiveType']
type NodeChildrenOut = components['schemas']['NodeChildrenOut']
type FormKitNodeIn = components['schemas']['FormKitNodeIn']
type FormKitErrors = components['schemas']['FormKitErrors']

type Form = components['schemas']['FormSchema']

type NodeFromIdb = NodeReturnType | NodeStringType
const toast = useToast()

export type FormKitEditable = FormKitSchemaFormKit | FormKitSchemaDOMNodeWithUUID | string
/* In the editor the string is parsed to an object */
export type Crumb = FormKitSchemaFormKit | FormKitSchemaDOMNodeWithUUID | UuidStringNode

export interface UuidStringNode {
  uuid: string
  node: string
  name?: string
  $formkit?: string
}

export const isUuidStringNode = (node: object | unknown): node is UuidStringNode => {
  if (typeof node !== 'object') return false
  if (node === null) return false
  if ('uuid' in node && 'node' in node) return true
  return false
}

export const stringToUuidStringNode = (node: string | unknown): UuidStringNode | false => {
  if (typeof node !== 'string') throw new Error('Node is not a string')
  try {
    const j = JSON.parse(node)
    if (isUuidStringNode(j)) return j
  } catch (e) {
    console.warn(`Could not parse ${node} as JSON`)
    return false
  }
  return false
}

export const getNodeIfIsUUIDString = (node: string | object) => {
  if (isUuidStringNode(node)) return node.node
  const uuidnode = stringToUuidStringNode(node)
  if (uuidnode === false) throw new Error('Node is not a UUID-string')
  return uuidnode.node
}

export const isNodeReturnType = (it: object | undefined): it is NodeReturnType => typeof it !== 'undefined' && ('node' in it) && typeof it.node !== 'string'
const isInactiveNode = (node: object | string): node is NodeInactivateType => typeof node !== 'string' && ('is_active' in node) && node.is_active === false
const isTextNode = (node: object | string): node is FormKitSchemaTextNode => typeof node === 'string'
export const isDOMNode = (node: object | string): node is FormKitSchemaDOMNode => typeof node === 'object' && ('$el' in node) && typeof node.$el === 'string'
export const isFormKitSchemaNode = (node: object | string | undefined): node is FormKitSchemaFormKit => typeof node === 'object' && ('$formkit' in node) && typeof node.$formkit === 'string'
// While we don't use these, it is useful to exclude them from types
export const isFormkitCondition = (node: object | string | any | any[]): node is FormKitSchemaCondition => typeof node === 'object' && ('if' in node) && 'then' in node

/* When editing we need to have the UUID in the node. This is additional to the normal FormKit
DOM node properties. */
export type FormKitSchemaDOMNodeWithUUID = FormKitSchemaDOMNode & {
  uuid?: string
  $formkit?: string
  protected?: boolean
}

const convertNode = (rawNode: NodeFromIdb | NodeInactivateType, uuid: string | false): FormKitEditable | undefined => {
  /**
   * This handles a conversion from a node as stored in IDB schema to
   * a node as used by our Formkit Editor
   * This involves embedding the UUID as a property of the node
   * If string, it's JSON encoded; if an object it is added as a property
   */
  if (isInactiveNode(rawNode)) return undefined
  const node = rawNode.node
  if (isTextNode(node)) return typeof uuid === 'string' ? JSON.stringify({ node, uuid }) : node
  if (isDOMNode(node)) return typeof uuid === 'string' ? { ...node, uuid } satisfies FormKitSchemaDOMNodeWithUUID : node
  if (isFormKitSchemaNode(node)) return typeof uuid === 'string' ? { ...node, uuid } satisfies FormKitSchemaFormKit : node
  throw new TypeError()
}

/* Display the text of a DOM node nicely */

export const DOMText = (node: FormKitSchemaDOMNode): string => {
  if (typeof node.children === 'string') return node.children
  if (isFormkitCondition(node.children)) return ''
  if (typeof node.children === 'undefined') return ''
  return node.children.filter((child) => typeof child === 'string').map(getNodeIfIsUUIDString).join(' ')
}

class RelationsArray extends Array<NodeChildrenOut> {
  get parents () {
    return this.reduce<Set<string>>(
      (nodes, it) => nodes.add(it.parent), new Set()
    )
  }

  get children () {
    /* UUIDs of nodes which appear as a 'child' */
    return this.reduce<Set<string>>((nodes, it) => { it.children?.forEach(c => nodes.add(c)); return nodes }, new Set())
  }

  get topNodes () {
    /** UUIDs of nodes which appear as parents but not as children */
    const parents = this.parents
    const children = this.children
    return new Set(Array.from(parents).filter(it => !children.has(it)))
  }
}
class NodeArray extends Array<NodeFromIdb> {
  rels: RelationsArray = new RelationsArray()
  get topLevelNodes () { return new NodeArray(...this.filter(it => this.rels.topNodes.has(it.key))) }
  /** Helper functions to return a single `node` by its `name` (applies to schemas) and/or `key`(uuid) for all instances */
  getFormNode (formName: string) { return this.byName(formName) as NodeReturnType }
  byName (nodeName: string) {
    const node = this.find(it => typeof it.node !== 'undefined' && typeof it.node !== 'string' && ('name' in it.node) && it.node.name === nodeName)
    if (typeof node === 'undefined') throw new TypeError(`No node with key ${nodeName} was found`)
    return node
  }

  byKey (nodeKey: string) {
    const node = this.find(it => it.key === nodeKey)
    if (typeof node === 'undefined') throw new TypeError(`No node with key ${nodeKey} was found`)
    return node
  }

  /** Convert a single 'node' from the server to something FormKit can create a form for */
  toSchemaNode (node?: NodeFromIdb, embedids = true): FormKitEditable {
    /** Recursively calls 'convertNode' & 'addChildren' to repack node definitions from the server
     * Note that strings are converted to a JSON string: this is to ensure that the UUID is correctly
     * handled. But this will look terrible unless reversed before showing the form for text elements.
    */
    if (typeof node === 'undefined') throw new TypeError('An undefined node was passed to `schemaNode')
    const addChildren = (node: NodeFromIdb, uuid: string): FormKitEditable => {
      /* If the given node is something with 'children', fetch the UUIDs of its descendants and rehydrate them */
      const converted = convertNode(node, embedids ? uuid : false)

      if (typeof converted === 'undefined') throw new TypeError('An undefined node was passed to `schemaNode')
      if (typeof converted === 'string') return converted
      const protect = 'protected' in node ? node.protected : false

      const childUuids = this.rels.find(it => it.parent === uuid)?.children
      if (typeof childUuids === 'undefined' || childUuids.length === 0) return { ...converted, protected: protect }
      let childReturnNodes = childUuids.map(n => this.byKey(n))
      /* 'inactive' nodes are not considered */
      childReturnNodes = childReturnNodes.filter(it => !isInactiveNode(it))

      // Recursive part of this function fetches "child" nodes
      const children = childReturnNodes.map((n) => {
        const childNode = convertNode(n, embedids ? n.key : false)
        // if (typeof childNode !== 'undefined' && typeof childNode !== 'string') debugger
        if (typeof childNode !== 'undefined') {
          return addChildren(n, n.key)
        }
        throw new TypeError('A node returned undefined')
      })
      return embedids ? { ...converted, children, uuid, protected: protect } : { ...converted, children, protected: protect }
    }
    return addChildren(node, node.key)
  }
}

interface storeState {
  nodes: NodeArray
  forms: Form[]
}

const initialState: storeState = {
  nodes: new NodeArray(),
  forms: []
}
// temporary fun to mock a wait time
export const delay = async (delayInms: number) => {
  return await new Promise(resolve => setTimeout(resolve, delayInms))
}

export const useIdaFormsStore = defineStore('idaforms', {
  state: () => (initialState),
  getters: {
    getSchemaByName: (state) => {
      /* Return an entire "schema" */
      return (formName: string) => state.nodes.getFormNode(formName)
    },
    getSchemaByUuid: (state) => {
      /* Return an entire "schema" */
      return (uuid: string) => {
        let node
        try {
          node = state.nodes.byKey(uuid)
        } catch (e) {
          console.warn(e)
          return
        }
        try {
          return state.nodes.toSchemaNode(node)
        } catch (e) {
          console.warn(e)
        }
      }
    },
    getNodeByKey: (state) => {
      return (nodeId: string) => {
        return state.nodes.find(it => it.key === nodeId)
      }
    },
    childNodes: (state) => {
      /** Return the child nodes of a given 'parent' node */
      return (node?: NodeReturnType) => {
        const schemaNode = state.nodes.toSchemaNode(node)
        if (typeof schemaNode === 'string') return []
        return schemaNode.children
      }
    },
    nodeAsFormkitSchema: (state) => {
      return (node: NodeFromIdb, embedids = true) => state.nodes.toSchemaNode(node, embedids) as FormKitSchemaFormKit
    }
  },
  actions: {
    async initialize () {
      // Fetch from idb
      const db = await getSchemaDB()
      this.nodes = new NodeArray(...await db.getAll('nodes'))
      this.nodes.rels = new RelationsArray(...await db.getAll('relations'))
      this.forms = await db.getAll('forms')
      void this.updateNodesFromNetwork()
      void this.updateFormsFromNetwork()
    },
    /** Gets the latest forms from the network, updates idb, and sets local state */
    async updateFormsFromNetwork () {
      const db = await getSchemaDB()
      await getForms().then(async (result) => {
        if (result) this.forms = await db.getAll('forms')
      })
    },
    /** Gets the latest nodes and relations from the network, updates idb, and sets local state */
    async updateNodesFromNetwork () {
      const db = await getSchemaDB()
      void Promise.all([getNodes(), getRelations()]).then(async (successes) => {
        if (successes.includes(true)) {
          const newNodes = new NodeArray(...await db.getAll('nodes'))
          newNodes.rels = new RelationsArray(...await db.getAll('relations'))
          this.nodes = newNodes
        }
      })
    },
    async createOrUpdateForm (body: components['schemas']['FormSchemaIn']) {
      const url = '/api/submissions/forms/create_or_update/'
      const { data, response, error } = await client.POST(url, { body })
      if (response.ok) {
        void this.updateFormsFromNetwork()
        void this.updateNodesFromNetwork()
      }
      return { data, response, error }
    },
    async createOrUpdateNode (body: FormKitNodeIn) {
      const sanityCheck = (): FormKitErrors | undefined => {
        /** Todo: Implement checks + balances here */
        return undefined
      }
      const check = sanityCheck()
      if (check !== undefined) { return { data: undefined, response: undefined, error: check } }
      // If a `datepicker`, extend our value to include `valueFormat` property
      // This helps to always have a `date` value
      if (body.$formkit === 'datepicker') {
        if ('additional_props' in body && typeof body.additional_props !== 'undefined') body.additional_props.valueFormat = 'YYYY-MM-DD'
        else body.additional_props = { valueFormat: 'YYYY-MM-DD' }
      }
      const { data, response, error } = await client.POST(
        '/api/formkit/create_or_update_node',
        { body }
      )
      if (response.ok) {
        void this.updateNodesFromNetwork()
      }
      return { data, response, error }
    },
    async deleteNode (uuid: string): Promise<boolean> {
      const response = await client.DELETE(
        '/api/formkit/delete',
        {
          params: {
            query: {
              node_id: uuid
            }
          }
        }
      )
      if (response.response.ok) {
        void this.updateNodesFromNetwork()
      }
      return response.response.ok
    },
    async deleteForm (key: string): Promise<boolean> {
      const response = await client.DELETE(
        '/api/submissions/forms/delete/',
        {
          body: {
            key,
            enabled: false
          }
        }
      )
      if (response.response.ok) {
        void this.updateFormsFromNetwork()
        void this.updateNodesFromNetwork()
      }
      return response.response.ok
    },

    async updateFormStatus (body: components['schemas']['FormSchemaSetStatus']) {
      const { response } = await client.POST('/api/submissions/forms/set-status', { body })
      if (response.ok) {
        void this.updateFormsFromNetwork()
        toast.success(useI18NStore().gettext(body.status === FORM_SCHEMA_STATUS_PUBLISHED ? 'Form has been successfully published' : 'Form has been successfully unpublished'))
      } else {
        toast.error(useI18NStore().gettext('Form was not successfully updated'))
      }
      return response.ok
    },
    /**
     * generate form schema based on formKey
     * @param key string
     * @returns FormKitSchemaFormKit
     */
    async generateFormSchema (key: string) {
      const schema = this.forms.find((it) => it.key === key)
      if (typeof schema === 'undefined') return
      if (typeof schema.schema_id === 'undefined') throw new TypeError('Schema Key is invalid')
      const node = this.getNodeByKey(schema.schema_id)
      if (typeof node === 'undefined') throw new TypeError('Schema Node is invalid')
      return this.nodeAsFormkitSchema(node, false)
    }
  }
})

/**
 * Based on parameters provided in the path,
 * recursively follow the ancestors of the form node
 * to the 'form schema' top level node
 */
export const getBreadcrumbs = (node: FormKitEditable, childpath: string[]) => {
  if (typeof node === 'undefined') return []
  if (typeof node === 'string') throw new Error('Expected form_node to be a FormKitSchemaFormKit instance')
  const nodes: Array<FormKitSchemaDOMNodeWithUUID | FormKitSchemaFormKit | UuidStringNode> = [node]
  if ((typeof node === 'object' && !Array.isArray(node.children)) || typeof childpath === 'undefined') {
    return nodes
  }
  for (const path of childpath) {
    const parentNode = nodes[nodes.length - 1]
    if (typeof parentNode === 'undefined' || typeof parentNode === 'string') return nodes
    if ('children' in parentNode && Array.isArray(parentNode.children)) {
      const pathInt = parseInt(path, 10)
      if (pathInt !== undefined) {
        // we have a numerical path
        // The type assertion here is to endure that we have `$formkit` or a JSON string
        const childNode = parentNode.children[pathInt]
        if (typeof childNode === 'string') {
          const asUuid = stringToUuidStringNode(childNode)
          if (asUuid !== false) nodes.push(asUuid)
          console.warn('Received an incorrect text node: got a string, expected a JSON  encoded string with a UUID property')
        } else if ('uuid' in childNode) nodes.push(childNode)
        else console.warn('expected an object with a UUID property')
      } else {
        throw new Error('expecting numerical path element')
      }
    } else {
      throw new Error('expecting children')
    }
  }
  return nodes
}
