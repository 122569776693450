<script setup lang="ts">
import { FormKitSchemaFormKit } from '@formkit/core'
import { DOMText, UuidStringNode, isFormKitSchemaNode, isUuidStringNode, isDOMNode, FormKitSchemaDOMNodeWithUUID } from '../stores/idaforms'
import { unwrapgettext } from './helpers'

export interface Props {
  element: FormKitSchemaFormKit | UuidStringNode | FormKitSchemaDOMNodeWithUUID
}
const props = withDefaults(defineProps<Props>(), {})
</script>

<template>
  <template v-if="isUuidStringNode(props.element)">
    <td>
      Text
    </td>
    <td>
      {{ props.element.node }}
    </td>
  </template>
  <template v-else-if="isFormKitSchemaNode(props.element)">
    <td
      v-if="props.element.$formkit !== 'group'"
      class="first-letter:uppercase"
    >
      {{ props.element.$formkit }}
    </td>
    <td>
      <strong>{{ unwrapgettext(props.element.label || props.element.title || props.element?.additional_props?.title ) }}</strong>
    </td>
  </template>
  <template
    v-else-if="isDOMNode(props.element)"
  >
    <td class="first-letter:uppercase">
      {{ props.element.$el }}
    </td>
    <td>
      {{ DOMText(props.element) }}
    </td>
  </template>
</template>
