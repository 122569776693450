import { FormKitSchemaFormKit } from '@formkit/core'
import { DBSchema, IDBPDatabase, StoreNames, openDB } from 'idb'
import { components } from '../../_api-services/openapi'
import { client } from '../../_api-services/urls'
import { del, get, keys, set } from '../../_helpers/idb_keyval'
import range from 'lodash-es/range'

type NodeReturnType = components['schemas']['NodeReturnType']
type NodeStringType = components['schemas']['NodeStringType']
type NodeInactivateType = components['schemas']['NodeInactiveType']
type NodeChildrenOut = components['schemas']['NodeChildrenOut']
type Form = components['schemas']['FormSchema']

const isInactive = (it: object): it is NodeInactivateType => { return 'inactive' in it && it.inactive === true }

interface FormkitDB extends DBSchema {
  // Store FormKit schema definitions offline
  'formkit': {
    key: string
    value: FormKitSchemaFormKit
  }
  'nodes': {
    key: string
    value: NodeReturnType | NodeStringType
  }
  'relations': {
    key: string
    value: NodeChildrenOut
  }
  'forms': {
    key: string
    value: Form
  }
}

const dbName = 'schemas'

/** access ( and update ) the db, to be called by all db access methods */
async function getSchemaDB (): Promise<IDBPDatabase<FormkitDB>> {
  return await openDB<FormkitDB>(dbName, 3, {
    upgrade (db, oldVersion, newVersion, transaction) {
      switch (oldVersion) {
        case 0:
          addFormkitStore(db)
          // falls through
        case 1:
          addNodeStore(db)
          addNodeRelationStore(db)
          // falls through
        case 2:
          addFormStore(db)
          // falls through
      }
      function addFormkitStore (db: IDBPDatabase<FormkitDB>) {
        db.createObjectStore('formkit', {
          keyPath: 'key'
        })
      }
      function addNodeStore (db: IDBPDatabase<FormkitDB>) {
        db.createObjectStore('nodes', {
          keyPath: 'key'
        })
      }
      function addNodeRelationStore (db: IDBPDatabase<FormkitDB>) {
        db.createObjectStore('relations', {
          keyPath: 'parent'
        })
      }
      function addFormStore (db: IDBPDatabase<FormkitDB>) {
        db.createObjectStore('forms', {
          keyPath: 'key'
        })
      }
    }
  }
  )
}

export { getSchemaDB }

/* Update the idb 'node' and 'relations' stores */
async function getNodesFromLastChange (lastChange: number) {
  const db = await getSchemaDB()
  const nodes = await client.GET('/api/formkit/list-nodes', { params: { query: { latest_change: lastChange } } }).catch(() => false)
  if (typeof nodes === 'boolean') return lastChange
  if (typeof nodes.data === 'undefined') return lastChange
  let latestChange = lastChange

  const tx = db.transaction('nodes', 'readwrite')
  await Promise.all([
    ...nodes.data.filter(isInactive).map(async (it) => await tx.store.delete(it.key)),
    ...nodes.data.filter(it => !isInactive(it)).map(async (it) => await tx.store.put(it as NodeReturnType)),
    tx.done
  ])
  db.close()
  nodes.data.forEach((it) => {
    if (typeof it.last_updated === 'number' && it.last_updated > latestChange) latestChange = it.last_updated
  })
  return latestChange
}

/* Update the idb 'node' and 'relations' stores */
async function getRelationsFromLastChange (lastChange: number) {
  const db = await getSchemaDB()
  const nodes = await client.GET('/api/formkit/list-related-nodes', { params: { query: { latest_change: lastChange } } })
  const tx = db.transaction('relations', 'readwrite')
  if (typeof nodes.data === 'undefined') return lastChange
  let latestChange = lastChange
  await Promise.all([
    ...nodes.data.map(async (it) => await tx.store.put(it)),
    tx.done
  ])
  nodes.data.forEach((it) => {
    if (typeof it.latest_change === 'number' && it.latest_change > latestChange) latestChange = it.latest_change
  })
  db.close()
  return latestChange
}

/* Update the 'forms' store */
async function getFormsFromLastChange (lastChange: number) {
  const db = await getSchemaDB()
  const nodes = await client.GET('/api/submissions/forms', { params: { query: { latest_change: lastChange } } }).catch(() => false)
  if (typeof nodes === 'boolean') return lastChange
  const tx = db.transaction('forms', 'readwrite')
  if (typeof nodes.data === 'undefined') return lastChange
  let latestChange = lastChange
  nodes.data.map(async (it) => {
    if (it.enabled === false && typeof it.key === 'string') await tx.store.delete(it.key)
    else await tx.store.put(it)
    if (typeof it.change_id === 'number' && it.change_id > latestChange) latestChange = it.change_id
  })
  await tx.done
  db.close()
  return latestChange
}

class DataFetcher<T> {
  constructor (
    public template: string, // Template to use for idb `keyval` string
    public version: number, // Which is the 'current' version of the store; if bumped store will be cleared
    public fetcher: (lastChange: number) => Promise<number>, // Fetch all data from server since `lastChange`
    public getDb: () => Promise<IDBPDatabase<T>>, // Get the IDB database
    public storeName: StoreNames<T>
  ) {}

  clearOldData = async () => {
    /**
     * Removes the 'last change' keys from IDB and clears the store
     * if the given key is found in the keyval store.
     * The purpose of this is to 'upgrade' the data in IDB.
     * For instance, if the server changes the format of the data,
     * bump the `currentversion` parameter passed to this function and
     * the old data in IDB will be cleared and replaced with the new data.
     * Returns the value of the 'latest' IDB key version to be used for the next update.
     */
    const db = await this.getDb()
    const keys_ = await keys()
    const oldKeys = range(1, this.version).map(it => `${this.template}${it}`)
    const toClear = keys_.filter(it => typeof it === 'string' && oldKeys.includes(it))
    if (toClear.length > 0) {
      await db.clear(this.storeName)
    }
    await Promise.all(toClear.map(async (k) => await del(k)))
    // If the current key is not yet in IDB, expect that the store is empty.
    // This addresses changes in the key template string
    const currentKey = `${this.template}${this.version}`
    const currentKeyExists = await get(currentKey)
    if (toClear.length === 0 && currentKeyExists === false) {
      await db.clear(this.storeName)
    }
    db.close()
  }

  fetchNewData = async () => {
    const lastChangeKey = `${this.template}${this.version}`
    const lastChange = await get(lastChangeKey) ?? -1
    let newLastChange = lastChange
    try {
      newLastChange = await this.fetcher(lastChange)
    } catch (e) {
      console.warn(e)
    }
    if (newLastChange === lastChange) return false
    await set(lastChangeKey, newLastChange)
    return true
  }

  syncData = async () => {
    try {
      await this.clearOldData()
      return await this.fetchNewData()
    } catch (e) {
      console.warn(e)
      return false
    }
  }
}

const formsFetcher = new DataFetcher<FormkitDB>(
  '/api/forms/submissions.lastChange.v',
  2, // bumped to '2' to handle deletion of inactive forms
  getFormsFromLastChange,
  getSchemaDB,
  'forms'
)

const nodesFetcher = new DataFetcher<FormkitDB>(
  '/api/formkit/list-nodes.lastChange.v',
  1,
  getNodesFromLastChange,
  getSchemaDB,
  'nodes'
)

const relationsFetcher = new DataFetcher<FormkitDB>(
  '/api/formkit/list-related-nodes.lastChange.v',
  2,
  getRelationsFromLastChange,
  getSchemaDB,
  'relations'
)

const getNodes = nodesFetcher.syncData
const getForms = formsFetcher.syncData
const getRelations = relationsFetcher.syncData

export { getNodes, getRelations, getForms }
