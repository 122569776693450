import domtoimage from 'dom-to-image'
import { getCurrentDateTimeFileName } from './common.helpers'

const getElementAsBlob = async ({ elementId }: { elementId: string }) => {
  const element = document.getElementById(elementId)
  if (!(element instanceof HTMLDivElement)) throw new TypeError('Received a non element')
  const blob = await domtoimage.toBlob(element)
  return URL.createObjectURL(blob)
}

const getFilename = ({ elementId, prefix }: { elementId: string, prefix?: string }) => {
  const dateTime = getCurrentDateTimeFileName()
  return (typeof prefix !== 'undefined') ? `${prefix}-${elementId}-${dateTime}.png` : `${elementId}-${dateTime}.png`
}

/**
 * capture the specific section based on `id` and export it as PNG
 * @param elementId string
 */
const downloadElement = async ({ elementId, prefix }: { elementId: string, prefix?: string }) => {
  const link = document.createElement('a')
  // sourcery skip: dont-self-assign-variables
  link.href = await getElementAsBlob({ elementId })
  link.download = getFilename({ elementId, prefix })
  link.click()
  URL.revokeObjectURL(link.href)
}

export default downloadElement
