<script setup lang="ts">
import BaseLayout from '../layouts/BaseLayout.vue'
import LoadingAnimation from '../components/commons/LoadingAnimation.vue'
import ContentSection from '../components/base/ContentSection.vue'
import { useEmbedsStore } from '../stores/embeds'
import { ref, watch, onMounted } from 'vue'
import { useI18NStore } from '../stores/i18n'
import { iframeResizer } from 'iframe-resizer'
import { useRouter } from 'vue-router'

const store = useEmbedsStore()
const router = useRouter()
const i18nStore = useI18NStore()

const props = defineProps<{
  section: string,
  id: string,
}>()

const iframeLoading = ref(true)

function embed () {
  return store.getDashboard(props.id)
}
function title () {
  if (store.error) return 'Dashboard Error'
  const name = embed()?.name
  if (name) return i18nStore.gettext(name)
  return ''
}

function description () {
  return i18nStore.gettext(store.error ? 'Something wrong when fetching the dashboard data' : embed()?.description || '')
}

function iframeSrc (): string {
  const ebd = embed()
  return ebd ? `${store.host}${ebd.path}` : ''
}
function iframeLoaded () {
  iframeLoading.value = false
  iframeResize()
}

function iframeResize () {
  iframeResizer({
    checkOrigin: [store.host ?? ''],
    bodyBackground: 'background-color: #FFFFFF',
    inPageLinks: true
  }, '#dashboard-iframe')
}

watch(
  () => props.id,
  () => {
    iframeLoading.value = true
  }
)

onMounted(() => {
  if (store.getDashboard(props.id) === undefined) {
    router.push('/')
  }
  window.addEventListener('resize', () => {
    iframeResize()
  })
})
</script>

<template>
  <base-layout
    :title="title()"
    :sub-title="description()"
  >
    <content-section>
      <template v-if="store.error">
        {{ store.error }}
      </template>
      <template v-if="!store.error">
        <div
          v-if="iframeLoading"
          class="h-[400px] md:h-[600px] w-full flex items-center justify-center"
        >
          <LoadingAnimation />
        </div>
        <div
          v-show="!iframeLoading"
          class="overflow-hidden min-h-[90vh] bg-white rounded-md"
        >
          <iframe
            id="dashboard-iframe"
            :src="iframeSrc()"
            class="min-w-full"
            @load="iframeLoaded"
          ></iframe>
        </div>
      </template>
    </content-section>
  </base-layout>
</template>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
}
</style>
