<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import { getNode } from '@formkit/core'
import { useRouter } from 'vue-router'
import { Ref, ref, computed } from 'vue'
import { useI18NStore } from '../../stores/i18n'
import { permissionCode, useAuthStore } from '../../stores/auth'
import { SUKU_MANAGER_MENU } from '../../_constants/navigation.constant'

const i18nstore = useI18NStore()
const authStore = useAuthStore()

const router = useRouter()
const sectionParam = router.currentRoute.value?.params?.section as string
const value: Ref<string> = ref(sectionParam ?? '')

const input = (val: string | undefined) => {
  const node = getNode('sukubudgetdropdown')

  if (typeof val === 'undefined') {
    node?.input(value.value)
    return
  }
  const id = router.currentRoute.value?.params?.id
  router.push(`/suku-manager/${id}/${val}/`)
}

const options = computed(() => {
  return SUKU_MANAGER_MENU.filter(item => {
    return item.permissions ? authStore.canAny(item.permissions as permissionCode[]) : true
  })
})
</script>
<template>
  <div class="relative h-[55px]">
    <FormKit
      id="sukubudgetdropdown"
      :value="value"
      name="sukumanager"
      type="dropdown"
      outer-class="!mb-0 relative top-1 ml-1 data-[expanded=true]:w-[364px] data-[expanded=true]:absolute data-[expanded=true]:left-0 data-[expanded=true]:top-[4px]"
      selector-class="!text-2.75xl leading-[27px] !font-black h-[55px] border-2 pr-9 !rounded-2.5xl [&>div>div>span]:hidden !border-zinc-400 aria-expanded:!border-b-transparent aria-expanded:!rounded-bl-none aria-expanded:!rounded-br-none bg-white"
      wrapper-class="w-content"
      dropdown-wrapper-class="!border-zinc-400 !rounded-bl-2.5xl !rounded-br-2.5xl !bg-white !max-h-max !pb-0"
      listitem-class="flex item-center py-2.5 text-lg font-bold border-t border-t-zinc-400 hover:!bg-stone-100 hover:!text-zinc-900"
      selection-wrapper-class="[&>span]:block [&>span]:max-w-[270px] [&>span]:whitespace-nowrap [&>span]:overflow-hidden [&>span]:text-ellipsis"
      :options="options"
      select-icon="angleDown"
      select-icon-class="!relative !-right-4 top-[2px]"
      @input="input"
    >
      <template #selection="{ option }">
        <span>{{ gettext(option.label) }}</span>
      </template>
      <template #option="{ option }">
        <div class="formkit-option flex">
          <span
            class="min-w-[30px] icon w-7.5 h-7.5 rounded-md flex items-center justify-center text-xl mr-2.5 relative"
            :class="option.iconColor"
          >
            <i
              class="las text-lg text-white"
              :class="option.icon"
            ></i>
          </span>
          <span class="inline-block">
            {{ i18nstore.gettext(option.label) }}
          </span>
        </div>
      </template>
    </FormKit>
  </div>
</template>
