import { defineStore } from 'pinia'
import { LocationObject } from '../_types/commons'
import LocationService from '../_helpers/location-service'
import { useToast } from 'vue-toastification'
import { client } from '../_api-services/urls'
import { components } from '../_api-services/openapi'
import { getData, initializeDB } from '../_locations/idb_locations'
import { FormKitOptionsProp } from '@formkit/inputs'
const toast = useToast()

type FeatureCollection = components['schemas']['FeatureCollection']
type Feature = components['schemas']['Feature']

interface LocationState {
  location: LocationObject
  geolocations?: FeatureCollection
  activeGeolocation?: Feature
  recentGeolocationSearch?: Array<components['schemas']['BaseModel']>
}

const initialState: LocationState = {
  location: {
    long: 0,
    lat: 0
  },
  recentGeolocationSearch: JSON.parse(localStorage.getItem('geoLocationRecentSearch') ?? '[]')
}

export const useGeoLocationStore = defineStore('geolocation', {
  state: () => (initialState),
  getters: {
    getCurrentLocation (state) {
      return state.location
    },
    getGeoLocationData (state) {
      return state.geolocations
    },
    getGeoLocationRecentSearch (state) {
      return state.recentGeolocationSearch
    },
    getActiveGeolocation (state) {
      return state.activeGeolocation
    },
    getGeoLocationOptions (state): FormKitOptionsProp {
      const geolocations = { ...state.geolocations }
      if (geolocations.features === undefined) {
        return []
      }
      return geolocations?.features?.map((feature) => {
        return {
          value: feature.properties.id,
          label: feature.properties.name,
          adminPost: feature.properties.adminpost_name,
          municipality: feature.properties.municipality_name
        }
      })
    },

    getGeoLocationBySucoId (state) {
      return (sucoId: number): Feature | undefined => {
        return state.geolocations?.features.find(it => it.properties.id === sucoId)
      }
    }
  },

  actions: {
    async initialize () {
      const locationService = new LocationService()
      // Check if the error message has already been shown
      const errorMessageShown = sessionStorage.getItem('geoLocationErrorMessageShown')

      const getLocation = await locationService.getCurrentLocation().catch(error => {
        // Show the toast warning only if it hasn't been shown before
        if (errorMessageShown == null) {
          toast.warning(error.message)
          // Set a flag in session storage
          sessionStorage.setItem('geoLocationErrorMessageShown', 'true')
        }
      })

      this.location = {
        long: getLocation?.long ?? 0,
        lat: getLocation?.lat ?? 0
      }
    },

    async getGeoLocationList () {
      if (this.geolocations == null) {
        // get Data from IDB
        const db = await initializeDB()
        // Check if data is already available in IndexedDB
        const locationData = await getData(db, 'geodata')
        if (locationData !== undefined) {
          locationData.features = locationData.features.filter((f) => f.properties.kind === 'suco')
          this.geolocations = locationData
        }
      }
    },
    async getGeoDataById (id: number) {
      const r = await client.GET('/api/locations/area/by-id/{area_id}.geojson', {
        params: {
          path: {
            area_id: id
          }
        }
      })
      if (typeof r.data !== 'undefined') {
        this.activeGeolocation = r.data
      }
    },

    setActiveGeoLocation (id: number) {
      const findGeoLocationById: number = this.geolocations !== undefined ? this.geolocations?.features.findIndex((feature: Feature) => feature.properties.id === id) : -1
      this.activeGeolocation = findGeoLocationById > -1 ? this.geolocations?.features[findGeoLocationById] : undefined
    },

    setGeoLocationRecentSearch (geodata: components['schemas']['BaseModel'] | undefined) {
      if (geodata === undefined) {
        return
      }
      const exist = this.recentGeolocationSearch?.findIndex(geoloc => geoloc.id === geodata.id)
      if ((exist == null) || exist > -1) {
        return
      }
      if (this.recentGeolocationSearch == null) {
        this.recentGeolocationSearch = []
      }
      this.recentGeolocationSearch.unshift(geodata)
      if (this.recentGeolocationSearch.length > 3) {
        this.recentGeolocationSearch.pop()
      }
      this.recentGeolocationSearch = this.recentGeolocationSearch.slice(-3)
      localStorage.setItem('geoLocationRecentSearch', JSON.stringify(this.recentGeolocationSearch))
    }
  }
})
