<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import { useOptionsStore } from '../../stores/options'
import { useAuthStore } from '../../stores/auth'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { useToast } from 'vue-toastification'
import { getSchema } from '../../_formkit/schema/option.schema'
import { Ref, ref, toRaw, reactive, computed, ComputedRef } from 'vue'
import { router } from '../../router'
import { FormKitNode, FormKitSchemaFormKit } from '@formkit/core'
import { components, operations } from '../../_api-services/openapi'
import { useI18NStore } from '../../stores/i18n'
import { OPTIONS_MENU } from '../../_constants/navigation.constant'
type idaOption = components['schemas']['IdaOption']
type responseStatus = keyof operations['ida_options_api_post_option']['responses']

const optionsStore = useOptionsStore()
const authStore = useAuthStore()
const i18nstore = useI18NStore()
const toast = useToast()

const props = defineProps<{
  group: groupName,
  value?: string
}>()

const ida = (groupName: groupName, ...filterParams: string[]) => optionsStore.options(groupName, i18nstore.code, filterParams)
const gettext = i18nstore.gettext
const title = (label: string) => i18nstore.interpolate('%(title)s %(label)s', { title: i18nstore.gettext('Title'), label: i18nstore.gettext(label) })

const isNew = typeof props.value === 'undefined'
const option: Ref<Partial<idaOption>> = ref({})

const optionLabel: ComputedRef<string> = computed(() => OPTIONS_MENU.find(it => it.value === props.group)?.label ?? props.group)

const formData = reactive({
  ida,
  gettext,
  title,
  /**
   * to disable days on the datepicker
   * need to add `_maxDateSource` or `_minDateSource` on the formKit schema. The value should be the existing field name. It will looking the value on `formData` and will use that as min date or max date
   * @param node FormkitNode
   * @param date selected Date
   */
  setDisabledDays: (node: FormKitNode, date: Date) => {
    const values = toRaw(option.value)
    const maxDateSource = node.props.attrs._maxDateSource
    const minDateSource = node.props.attrs._minDateSource
    if (maxDateSource) {
      const target: string = values[maxDateSource as keyof idaOption] as string
      if (target) {
        return date > new Date(target)
      }
    }
    if (minDateSource) {
      const target: string = values[minDateSource as keyof idaOption] as string
      if (target) {
        return date < new Date(target)
      }
    }
  },
  getCurrentDate: () => {
    const currDate = new Date()
    return {
      day: currDate.getDate(),
      month: currDate.getMonth(),
      year: currDate.getFullYear()
    }
  }
})
const schema: Ref<Array<FormKitSchemaFormKit>> = ref([])

const update = () => {
  schema.value = getSchema(props.group, props.value)
  if (typeof props.value !== 'undefined') {
    const opts = optionsStore.get(props.group, props.value)?.originalOption
    const extra :Record<string, string[]> = {}
    // We also need to override some things to get them labelled properly in the transfer list
    // They need to be strings to match what's returned as `ida options` value
    for (const [k, v] of Object.entries(opts ?? {})) {
      if (Array.isArray(v)) extra[k] = v.flatMap(it => it ? [it.toString()] : [])
    }
    option.value = { ...opts, ...extra }
  }
}
update()
optionsStore.$subscribe((mutation, state) => update())

async function submit (body: idaOption, node: FormKitNode) {
  const { error, response } = await optionsStore.PostOption(body)
  if (error) {
    toast.error(gettext('There was a problem saving this option'))
    node.setErrors(error)
    return
  }
  router.push({ name: 'options', params: { group: props.group } })
  toast.success({
    201: gettext('Option created'),
    202: gettext('Option updated'),
    204: gettext('Option deleted'),
    400: gettext('Error on creating')
  }[response.status as responseStatus])
}
</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="{ name: 'options', params: { group: props.group }}"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
        </router-link>
        <h1 class="text-2.5xl font-black">
          {{ gettext( isNew ? 'Add' : 'Edit') }} - {{ gettext(optionLabel) }}
        </h1>
      </div>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <div class="max-w-xl">
        <FormKit
          v-model="option"
          type="form"
          :submit-label="gettext(isNew? 'Create' : 'Save')"
          :submit-attrs="{
            wrapperClass: 'max-w-[220px]'
          }"
          novalidate
          message-class="text-red-500 text-sm"
          :disabled="!authStore.can('ida_options.change_activity')"
          @submit="submit"
        >
          <FormKitSchema
            :schema="schema"
            :data="formData"
          />
        </FormKit>
      </div>
    </content-section>
  </base-layout>
</template>
