/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DBSchema, IDBPDatabase, openDB } from 'idb'
import { components } from '../_api-services/openapi'
import { OptionsType } from '../_types/components/forms/select-dropdown'
import { languageCodes, useI18NStore } from '../stores/i18n'
import { isWithinInterval, parseISO, isValid } from 'date-fns'
type Sector = components['schemas']['ZSector']
type SlimSector = Omit<Sector, 'subsectors'>

type Subsector = components['schemas']['ZSubSector']
type SlimSubsector = Omit<Subsector, 'outputs'>

type Output = components['schemas']['ZOutput']

type Form = components['schemas']['FormSchema']
type Option = components['schemas']['IdaOption']

type optionValue = Option['value']
type filterOptsReturn = Partial<Record<keyof components['schemas']['IdaOption'] | 'current', string | number | null | boolean>>

/* Group names are taken from the table names in the database */
/* Python code to regenerate this is: */
/* [t._meta.model_name for t in ida_options.Option.__subclasses__()] */
export type groupName = 'cmtposition' | 'activity' | 'activitytype' | 'category' | 'documentfiletype' | 'documenttype' | 'electionround' | 'financedisbursementtype' | 'financialdisbursementactivity' | 'gender' | 'indicator' | 'sector' | 'subsector' | 'output' | 'pndsfreebalancetranslation' | 'programactivitylevel' | 'programactivitytype' | 'programactivity' | 'reporttype' | 'subprojectstatus' | 'subprojectstatus1' | 'sucophase' | 'sucostatus' | 'unit' | 'financemonitor' | 'operationbudgetstatus' | 'fundingsource' | 'year' | 'month' | 'yesno' | 'objective' | 'cycle' | 'teamposition' | 'teampositiongroup' | 'munisipiu' | 'postuadministrativu' | 'suku' | 'aldeia' | 'meetingobjective' | 'project'

interface PndsDB extends DBSchema {
  // Store FormKit schema definitions offline
  'sector': {
    key: number
    value: SlimSector
  }
  'subsector': {
    key: number
    value: SlimSubsector
    indexes: { 'by-sector': number }
  }
  'outputs': {
    key: [string, string]
    value: Output
    indexes: { 'by-sector': number, 'by-subsector': number }
  }
  'options': {
    key: [string, string]
    value: Option
    indexes: { 'by-group-value': [string, string], 'by-group': string, 'by-change_id': number }
  }
  'dependency': {
    key: number
    value: unknown
    indexes: {
      'by-change_id': number
      'by-sector': number
      /** Subsector */
      'by-subsector': number
      /** Output */
      'by-output': number
      /** Unit */
      'by-unit': number
      /** Activity */
      'by-activity': number
      'by-objective': number
    }

  }
  'form': {
    key: string
    value: Form
  }
}

const dbName = 'pnds_data'

/** access ( and update ) the db, to be called by all db access methods */
async function getPndsDataDb (): Promise<IDBPDatabase<PndsDB>> {
  return await openDB<PndsDB>(dbName, 4, {
    upgrade (db, oldVersion, newVersion, transaction) {
      switch (oldVersion) {
        case 0:
          addObjectStores(db)
          // falls through
        case 1:
          addOptionStores(db)
          // falls through
        case 2:
          addDependencyStore(db)
      }
      function addObjectStores (db: IDBPDatabase<PndsDB>) {
        db.createObjectStore('sector', {
          keyPath: 'sector_id'
        })
        const subSectorStore = db.createObjectStore('subsector', {
          keyPath: 'sub_sectorid'
        })
        subSectorStore.createIndex('by-sector', 'sector_id')
        const outputStore = db.createObjectStore('outputs', {
          keyPath: 'outputid'
        })
        outputStore.createIndex('by-sector', 'sector_id')
        outputStore.createIndex('by-subsector', 'sub_sectorid')
      }
      function addOptionStores (db: IDBPDatabase<PndsDB>) {
        // Adds new stores based on the reformatted "options" from
        // schema import and from the zTables to ida_options migrate
        const store = db.createObjectStore('options', { keyPath: ['group_name', 'value'] })
        store.createIndex('by-group', 'group_name')
        store.createIndex('by-group-value', ['group_name', 'value'])
        store.createIndex('by-change_id', 'change_id')
      }
      function addDependencyStore (db: IDBPDatabase<PndsDB>) {
        const store = db.createObjectStore('dependency', { keyPath: 'id' })
        store.createIndex('by-change_id', 'change_id')
        store.createIndex('by-sector', 'sector', { unique: false })
        store.createIndex('by-subsector', 'subsector', { unique: false })
        store.createIndex('by-output', 'output', { unique: false })
        store.createIndex('by-unit', 'unit', { unique: false })
        store.createIndex('by-activity', 'activity', { unique: false })
        store.createIndex('by-objective', 'objective', { unique: false })
      }
    }
  })
}

export type { PndsDB, Sector, SlimSector, Subsector, SlimSubsector, Output }
export { getPndsDataDb }

interface Translated { translated: (lang: languageCodes) => string | undefined }
interface Filterable { filter_: (options: filterOptsReturn) => boolean}

/**
 * Represents a label with translations for multiple languages.
 */
class Label extends Map<languageCodes, string> implements Translated {
  /**
   * Retrieves the translated value for the specified language code.
   * If the translation is not available for the specified language code,
   * it returns the default value.
   *
   * @param languageCode - The language code for the translation.
   * @param defaultValue - The default value to return if the translation is not available.
   * @returns The translated value for the specified language code, or the default value if not available.
   */
  translated (lang: languageCodes) {
    return this.get(lang) ?? this.get('tet') ?? this.get('en') ?? this.get('pt')
  }
}

class Params extends Map<keyof filterOptsReturn, string | number | boolean | number[]> implements Filterable {
  get start_date () { return this.get('start_date') ? parseISO(this.get('start_date') as string) : null }
  get end_date () { return this.get('end_date') ? parseISO(this.get('end_date') as string) : null }
  get isWithinInterval () {
    const start = this.start_date
    const end = this.end_date
    if (start && end) {
      return isWithinInterval(new Date(), { start, end })
    }
    return false
  }

  filter_ (opts: filterOptsReturn) {
    for (const [key, filterValue] of Object.entries(opts)) {
      if (key !== 'current') {
        const optValue = this.get(key as keyof filterOptsReturn)
        if (typeof optValue === 'undefined') return false
        if (['number', 'string', 'boolean'].includes(typeof optValue) && optValue !== filterValue) return false
        if (Array.isArray(optValue) && !(optValue as unknown[]).includes(filterValue)) return false
      }
    }

    // A special clause for handling `current` in cycle options
    if (this.get('group_name') === 'cycle' && 'current' in opts) {
      const start = parseISO(this.get('start_date') as string)
      const end = parseISO(this.get('end_date') as string)
      const now = new Date()

      if (isValid(start) && isValid(end) && !(isWithinInterval(now, { start, end }))) return false
    }

    return true
  }
}

const filterFromStrings = (rest: string[]): filterOptsReturn => {
  /**
   * Converts a list of strings into a `filterParams` object
   * ['sector_id=3', 'subsector_id=3'] => {sector_id: 3, subsector_id: 3}
   */
  return rest.reduce<filterOptsReturn>((acc, it) => {
    const [k, v] = it.split('=', 2)
    acc[k as keyof components['schemas']['IdaOption']] = parseInt(v, 10)
    return acc
  }, {})
}

export const idaOptionToClass = (opt: components['schemas']['IdaOption']) => {
  const label = new Label()
  if ('label_tet' in opt && typeof opt.label_tet !== 'undefined') label.set('tet', opt.label_tet)
  if ('label_en' in opt && typeof opt.label_en !== 'undefined') label.set('en', opt.label_en)
  if ('label_pt' in opt && typeof opt.label_pt !== 'undefined') label.set('pt', opt.label_pt)
  const params = new Params()
  Object.entries(opt).forEach(([key, val]) => { if (!key.startsWith('label_')) params.set(key as never, val) })
  return new IdaOption(opt.value, params, label, opt)
}

class IdaOption {
  constructor (readonly value: string, readonly params: Params, readonly label: Label, readonly originalOption: components['schemas']['IdaOption']) {}
  convert (lang: languageCodes) {
    return {
      value: this.value,
      label: this.label.translated(lang) ?? this.value
    }
  }
}

export class OptionMap extends Map<optionValue, IdaOption> { }

export const getOptions_ = async (): Promise<OptionGroupMap> => {
  /**
   * Return a new options list fetched from IDB
   */
  const db = await getPndsDataDb()
  const opts = await db.getAll('options')
  const map = new OptionGroupMap()
  opts.forEach((o) => {
    map.get(o.group_name as groupName).set(o.value, idaOptionToClass(o))
  })
  // Project labels
  // TODO: Set the label to the output
  map.get('project').forEach(p => {
    const projectNameId = p.params.get('project_name_id') as number
    if (projectNameId === undefined) return
    const projectName = map.get('output').get(projectNameId.toString())
    if (projectName === undefined) return
    const languages: languageCodes[] = ['tet', 'en', 'pt']
    languages.forEach(language => {
      // If the project name has already been defined (on the server)
      // keep what's been specified there
      if (p.label.get(language) !== undefined) return
      // the translated project name
      const n = projectName.label.get(language)
      if (n !== undefined) {
        p.label.set(language, `${n} (${p.value})`)
      }
    })
  })
  return map
}

// Mapping a specific ida option group to another group
// For instance a `suku` has a `postu_administrativu_id` which is a key of the `postu_administrativu` group
// We can map this to the `postu_administrativu` group
// This is used in the `options` store to filter the options by the `suku` selected
// and then to display the `postu_administrativu` options

// Groups are any key of `Option` except for the following
type omitKeys = 'value' | 'group_name' | 'label_tet' | 'label_en' | 'label_pt' | 'change_id' | 'is_active'
type relatedProps = keyof Omit<Option, omitKeys>
type relation = Map<groupName, relatedProps>
type optionRelation = Map<groupName, relation>

const relations: optionRelation = new Map()

relations.set('aldeia', new Map([
  ['suku', 'suku_id'],
  ['postuadministrativu', 'postu_administrativu_id'],
  ['munisipiu', 'munisipiu_id']
]))
relations.set('suku', new Map([
  ['postuadministrativu', 'postu_administrativu_id'],
  ['munisipiu', 'munisipiu_id']
]))
relations.set('postuadministrativu', new Map([
  ['munisipiu', 'munisipiu_id']
]))
relations.set('subsector', new Map([
  ['sector', 'sector_id']
]))
relations.set('output', new Map([
  ['subsector', 'subsector_id']
]))
relations.set('programactivity', new Map([
  ['programactivitytype', 'type_id'],
  ['programactivitylevel', 'level_id']
]))
relations.set('teamposition', new Map([
  ['teampositiongroup', 'group_id']
]))

export class OptionGroupMap extends Map<groupName, OptionMap> {
  get (groupName: groupName) {
    /** Override `get` to create if a groupName does not exist yet in the map */
    let group = super.get(groupName)
    if (typeof group === 'undefined') {
      group = new OptionMap()
      this.set(groupName, group)
    }
    return group
  }

  public getOpts ({ groupName, lang, filterParams }: { groupName: groupName, lang: languageCodes, filterParams?: string[] }) {
    const items: OptionsType = []
    const params = filterFromStrings(filterParams ?? [])

    const group = this.get(groupName)
    if (typeof group === 'undefined') {
      const groups = Array.from(this.keys()).join(',')
      console.warn(`Group ${groupName} is not in groups ${groups}`)
      return items
    }
    for (const value of group.values()) {
      if (value.params.get('is_active') !== false && value.params.filter_(params)) items.push(value.convert(lang))
    }
    return items
  }

  public groupNames () { return Array.from(this.keys()).sort() }

  async getOptions () {
    /* This is an async constructor to get a new OptionGroupMap */
    return await getOptions_()
  }

  /**
   * Retrieves the related data based on the specified option and relation.
   * @param option - The option object.
   * @param relatesTo - The name of the related group.
   * @returns The related data if found, otherwise undefined.
   */
  getRelated (option: IdaOption, relatesTo: groupName): IdaOption | undefined {
    const group = relations.get(option.originalOption.group_name as groupName)
    const relatedProperty = group?.get(relatesTo)
    if (typeof relatedProperty === 'undefined') {
      return
    }
    const relatedVal = option.originalOption[relatedProperty] as optionValue | number | undefined
    if (typeof relatedVal === 'undefined') {
      return
    }
    return this.get(relatesTo).get(relatedVal.toString())
  }

  getLabel (group: groupName, value: string | number | undefined | null, code?: languageCodes | undefined) {
    if (typeof value === 'undefined' || value === null) return
    const value_ = typeof value === 'string' ? value : value.toString()
    const code_ = typeof code === 'undefined' ? useI18NStore().code : code
    return this.get(group)?.get(value_)?.label.translated(code_)
  }
}
