import { openDB } from 'idb'

const dbPromise = openDB('keyval-store', 1, {
  upgrade (db) {
    db.createObjectStore('keyval')
  }
})

export async function get (key: IDBValidKey) {
  return await (await dbPromise).get('keyval', key)
}
export async function set (key: IDBValidKey, val: unknown) {
  return await (await dbPromise).put('keyval', val, key)
}
export async function del (key: IDBValidKey) {
  return await (await dbPromise).delete('keyval', key)
}
export async function clear () {
  return await (await dbPromise).clear('keyval')
}
export async function keys () {
  return await (await dbPromise).getAllKeys('keyval')
}
