<template>
  <div class="table-row ">
    <slot></slot>
  </div>
</template>
<style scoped lang="postcss">
.table-row:last-child {
  :deep(.table-cell) {
    @apply first:rounded-bl-2.5xl last:rounded-br-2.5xl;
  }
}
.table-row:first-child {
  :deep(.table-cell) {
    @apply first:rounded-tl-2.5xl last:rounded-tr-2.5xl;
  }
}
</style>
