<script setup lang="ts">
import { computed, reactive } from 'vue'
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import BasicButton from '../components/commons/BasicButton.vue'
import DataTable from '../components/commons/DataTable.vue'

import type { Header } from 'vue3-easy-data-table'

import { useAuthStore } from '../stores/auth'
import { useUsersStore, StoreState } from '../stores/users'

const authStore = useAuthStore()
const userStore = useUsersStore()

userStore.initialize()

const headers: Header[] = reactive([
  { text: 'Username', value: 'username', sortable: true },
  { text: 'First Name', value: 'firstName', sortable: true },
  { text: 'Last Name', value: 'lastName', sortable: true },
  { text: 'Email', value: 'email', sortable: true },
  { text: 'Is Active', value: 'status', sortable: true }
])

const userItems = computed(() => {
  return userStore.users
    ? userStore.users.map((user) => {
      return {
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        status: user.isActive,
        redirectParams: { username: user.username }
      }
    })
    : []
})
</script>

<template>
  <base-layout
    :title="gettext('Users')"
    :sub-title="gettext('For admin users, allows management of user accounts, managing permissions, and other user-related settings.')"
  >
    <content-section>
      <div v-if="!authStore.can('ida.view_idauser')">
        Should not have even got here
      </div>
      <DataTable
        :headers="headers"
        :items="userItems"
        theme-color="dark"
        spacing="narrow"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl top-[2px] relative !text-zinc-900"
        filter-size="lg"
        redirect-path="user"
      >
        <template #header-action>
          <basic-button
            :to="{ name:'add_user'}"
          >
            <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('Add') }}
          </basic-button>
        </template>
      </DataTable>
    </content-section>
  </base-layout>
</template>
