<script setup lang="ts">
import { useSubmissionsStore, SubmissionType } from '../../stores/submissions'
import ContentSection from '../../components/base/ContentSection.vue'
import DataTable from '../../components/commons/DataTable.vue'
import type { Header, Item } from 'vue3-easy-data-table'
import { reactive, computed, ComputedRef } from 'vue'
import BaseLayout from '../../layouts/BaseLayout.vue'
import { useI18NStore } from '../../stores/i18n'
import { useOptionsStore } from '../../stores/options'
import { useProjectManagerStore } from '../../stores/projectmanager'

const i18nstore = useI18NStore()

const submissionStore = useSubmissionsStore()
const projectManagerStore = useProjectManagerStore()
const optionsStore = useOptionsStore()

projectManagerStore.fetchIdaProjects()

let submissions = submissionStore.submissions
submissions = submissions.sort((a: SubmissionType, b: SubmissionType) => {
  const ADate = a.created
  const BDate = b.created
  return new Date(BDate).getTime() - new Date(ADate).getTime()
})
await optionsStore.initialize()

const headers: Header[] = reactive([
  { text: 'Project ID', value: 'project_id', sortable: true, width: 110 },
  { text: 'Project name', value: 'project_name', sortable: true, width: 270 },
  { text: 'Activity', value: 'cycle', sortable: true },
  { text: 'Sector', value: 'project_sector', sortable: true },
  { text: 'Municipality', value: 'district', sortable: true },
  { text: 'Administrative Post', value: 'administrative_post', sortable: true },
  { text: 'Suco', value: 'suco', sortable: true },
  { text: 'Status', value: 'project_status' }
])

const items: ComputedRef<Item[]> = computed(() => {
  return projectManagerStore.$state.idaProjects.map(project => {
    const sukuId = project.properties.suco?.toString()
    const suku = optionsStore.get('suku', sukuId || '?')
    const adminPost = optionsStore.getRelated(suku, 'postuadministrativu')
    const munisipiu = optionsStore.getRelated(adminPost, 'munisipiu')
    const { code } = i18nstore.$state
    return {
      project_id: `IDA-${project.properties.project_id}`,
      redirectParams: { projectId: project.properties.project_id },
      project_name: optionsStore.getLabel('output', project.properties.output),
      project_sector: optionsStore.getLabel('sector', project.properties.sector),
      cycle: optionsStore.getLabel('cycle', project.properties.cycle),
      suco: suku?.label.translated(code),
      district: munisipiu?.label.translated(code),
      administrative_post: adminPost?.label.translated(code),
      project_status: project.properties.project_status
    }
  })
})
</script>

<template>
  <base-layout
    :title="gettext('Project Manager')"
    :sub-title="gettext('The Project Manager offers tools and features to manage, monitor and evaluate PNDS projects efficiently.')">
    <content-section
      :has-border="false"
      class="!pt-5"
    >
      <DataTable
        :headers="headers"
        :items="items"
        filter-placeholder="Search"
        filter-prefix-icon="la-search !text-xl top-[2px] relative !text-zinc-900"
        filter-size="lg"
        redirect-path="projectsDetail"
        theme-color="dark"
        spacing="narrow"
        :filter-fields-schema="[
          {
            $formkit: 'select',
            label: i18nstore.gettext('Sector'),
            placeholder: i18nstore.gettext('Select sector'),
            name: 'project_sector',
            key: 'project_sector',
            id: 'project_sector',
            options: '$ida(sector)',
            labelClass: 'text-sm',
            inputClass: 'text-sm'
          },
          {
            $formkit: 'select',
            label: i18nstore.gettext('Activity'),
            placeholder: i18nstore.gettext('Select activity'),
            name: 'cycle',
            key: 'cycle',
            id: 'cycle',
            options: '$ida(cycle)',
            labelClass: 'text-sm',
            inputClass: 'text-sm'
          },
          {
            $formkit: 'select',
            label: i18nstore.gettext('Status'),
            placeholder: i18nstore.gettext('Select status'),
            name: 'project_status',
            key: 'project_status',
            id: 'project_status',
            options: '$ida(subprojectstatus1)',
            labelClass: 'text-sm',
            inputClass: 'text-sm'
          },
          {
            $formkit: 'autocomplete',
            label: i18nstore.gettext('Municipality'),
            placeholder: i18nstore.gettext('Select a municipality'),
            name: 'district',
            key: 'district',
            id: 'district',
            options: '$ida(munisipiu)',
            inputClass: '!pl-3 text-sm',
            dropdownWrapperClass: 'max-h-[180px]',
            listitemClass: 'stop-click-outside',
            optionClass: 'stop-click-outside',
            labelClass: 'text-sm'
          },
          {
            $formkit: 'autocomplete',
            label: i18nstore.gettext('Administrative Post'),
            placeholder: i18nstore.gettext('Select a Administrative Post'),
            name: 'administrative_post',
            key: 'administrative_post',
            id: 'administrative_post',
            if: '$get(district).value',
            options: '$ida(postuadministrativu,\'munisipiu_id=\'+$get(district).value)',
            inputClass: '!pl-3 text-sm',
            dropdownWrapperClass: 'max-h-[180px]',
            listitemClass: 'stop-click-outside',
            optionClass: 'stop-click-outside',
            labelClass: 'text-sm'
          },
          {
            $formkit: 'autocomplete',
            label: i18nstore.gettext('Suco'),
            placeholder: i18nstore.gettext('Select a Suco'),
            name: 'suco',
            key: 'suco',
            id: 'suco',
            if: '$get(district).value && $get(administrative_post).value',
            options: '$ida(suku,\'postu_administrativu_id=\'+$get(administrative_post).value)',
            inputClass: '!pl-3 text-sm',
            dropdownWrapperClass: 'max-h-[180px]',
            listitemClass: 'stop-click-outside',
            optionClass: 'stop-click-outside',
            labelClass: 'text-sm'
          }
        ]"
      />
    </content-section>
  </base-layout>
</template>

<style scoped>

</style>
