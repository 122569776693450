import { AuthState } from './../stores/auth'
import { useAuthStore } from '../stores/auth'
import { useFormsStore } from '../stores/forms'
import { SubscriptionCallbackMutation } from 'pinia'

const DEFAULT_PERIOD = 5000

export default class SyncManager {
  period: number
  lastUpdateDate: Date | null = null
  synching = false
  timeOut: NodeJS.Timeout | null = null
  formsStore = useFormsStore()

  constructor () {
    this.period = DEFAULT_PERIOD
    const authStore = useAuthStore()
    authStore.$subscribe((m, s) => this.authStateChange(m, s))
  }

  authStateChange (_mutation: SubscriptionCallbackMutation<AuthState>, state: AuthState): void {
    if (state.isAuthenticated) {
      this.startSynching()
    } else {
      this.stopSynching()
    }
  }

  startSynching (): void {
    if (this.timeOut == null) {
      this.timeOut = setTimeout(() => { void this.update() }, this.period)
    }
  }

  stopSynching (): void {
    if (this.timeOut != null) {
      clearTimeout(this.timeOut)
      this.timeOut = null
    }
  }

  async update (): Promise<void> {
    if (this.synching) { this.synching = true }
    this.lastUpdateDate = new Date()
    await this.formsStore.sync()
    this.synching = false
    this.timeOut = setTimeout(() => { void this.update() }, this.period)
  }
}
