import { defineStore } from 'pinia'
import { computed, ComputedRef, toRaw } from 'vue'
import { FormStatus, OfflineForm as Submission, FormFile } from '../forms/form'
import { SubmissionLocation } from '../_types/commons'
import { useGeoLocationStore } from './geolocation'
import { useOptionsStore } from './options'
import { components } from '../_api-services/openapi'
import { UploadedFileType } from '../_types/components/commons/form'
import { client } from '../_api-services/urls'
import { getFilesByFormKey } from '../forms/idb'
import { mapToArray, getCsrf } from '../_helpers/common.helpers'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
import { FILE_UPLOAD_MESSAGE } from '../_constants/toastmessages.constant'
import { DATA_COLLECTION_FILE_LABEL } from '../_constants/fileupload.constant'
import { useAppStore } from './app'
const toast = useToast()

/*
In effect, this extends components['schemas']['FormOut']
with an optional 'id' property
*/
type FormDetails = components['schemas']['FormSchema']
type extendedForm = FormDetails & { id: string, name: string | ComputedRef<string>, description: string | ComputedRef<string> }

export interface FormsState {
  forms: extendedForm[]
  detecting: boolean
  error?: string
  submissions: Submission[]
  activeSubmission?: Submission
  activeFile?: Array<components['schemas']['SubmissionFileOut']>
}

const initialState: FormsState = {
  forms: [] as extendedForm[],
  detecting: false,
  submissions: [],
  activeFile: []
}

export const useFormsStore = defineStore('forms', {
  state: () => (initialState),
  getters: {
    submissionsOfType: (state) => {
      return (type: string) => state.submissions.filter(s => s.type === type)
    },
    getActiveFile: (state) => {
      return toRaw(state.activeFile)
    },
    getFormsByType: (state) => {
      return (status?: string) => {
        const formsList = status !== undefined ? state.forms.filter(it => it.status === status) : state.forms

        const forms = formsList.reduce<Record<string, extendedForm[]>>((sum, form) => {
          if (form.enabled === false) return sum
          if (typeof form.submission_type === 'undefined') return sum
          else if (form.submission_type in sum) sum[form.submission_type].push(form)
          else sum[form.submission_type] = [form]
          return sum
        }, {})

        return Object.keys(forms).sort().reduce((acc: Record<string, extendedForm[]>, key) => { // sort alphabetically
          acc[key] = forms[key]
          return acc
        }, {})
      }
    },
    getFormByFormType: (state) => {
      return (formType: string) => state.forms.find(form => form.key === formType)
    }
  },
  actions: {
    async initialize (force = false) {
      const i18nStore = useI18NStore()
      const store = useOptionsStore()
      const getTranslatedField = (object: FormDetails, fieldName: 'name_set' | 'description_set') => {
        const langcode = i18nStore.$state.code
        for (const code of [langcode, 'tet', 'en', 'pt']) {
          const value = object[fieldName].find(it => it.lang === code)
          if (value != null) return value
        }
        return { label: null }
      }
      void store.initialize()
      // if ((this.forms != null) && !force) return
      try {
        this.error = undefined
        this.detecting = true
        useAppStore().$state.loading = true
        const { data, error, response } = await client.GET('/api/submissions/forms', {})
        if (typeof data !== 'undefined') {
          const forms = data.map((d) => {
            return {
              ...d,
              description: computed(() => getTranslatedField(d, 'description_set').label ?? '') as unknown as string,
              name: computed(() => getTranslatedField(d, 'name_set').label ?? '') as unknown as string,
              id: d.key ?? ''
            }
          })
          this.forms = forms
        }
        if (typeof error !== 'undefined') this.error = error
        // toast.success(i18nStore.gettext('Forms loaded'))
      } catch (e) {
        throw new Error('Unable to fetch the Submission Forms; you might be offline')
      } finally {
        this.detecting = false
        useAppStore().$state.loading = false
      }
    },
    async sync () {
      // get all the pending item info from idb
      const pending = await Submission.pendingSubmissionRecords()
      // update local items with that info
      const location: SubmissionLocation = await this.getSubmissionLocation(FormStatus.SUBMITTED)
      const subs = pending.map((s) => {
        let sub = this.submissions.find(({ key }) => key === s.key)
        if (sub != null) {
          sub.clonableData = s
        } else {
          sub = Submission.createFromClonable(s)
        }

        // set location during postToServer
        const locations = this.checkSubmissionLocationByStatus(sub.clonableData.locations, location, FormStatus.SUBMITTED)
        sub.clonableData.locations = locations

        return sub
      })
      // send things to server
      return await Promise.allSettled(
        subs.map(async (s) => {
          const post = await s.postToServer()
          if (post) {
            s.status = FormStatus.SUBMITTED
            return await toRaw(s).saveToIdb()
          }
        })
      )
    },
    async ensureSubmissionsOfType (formType: string) {
      const known = this.submissions.filter(s => s.type === formType)
      const newSubs = await Submission.getAllByType(
        formType,
        known.map(s => s.key)
      )
      this.submissions.push(...newSubs)
    },
    async ensureSubmission (formKey: string) {
      const s = this.submissions.find(({ key }) => key === formKey)
      if (s != null) {
        await s.updateFromIdb()
      } else {
        const submission = await Submission.createFromIDBKey(formKey)
        if (submission != null) {
          this.submissions.push()
        }
      }
    },
    setActiveSubmission (formKey: string) {
      this.activeSubmission = this.submissions?.find(
        (s) => s.key === formKey
      )
    },
    async setActiveSubmissionFiles (formKey: string) {
      if (typeof this.activeSubmission !== 'undefined') {
        const files = this.activeSubmission?.clonableData.status === FormStatus.SUBMITTED ? [] : await getFilesByFormKey(formKey)
        const mapFiles: Map<string, FormFile> = files.reduce((acc, obj) => {
          acc.set(obj.key, FormFile.createFromClonable(obj))
          return acc
        }, new Map<string, FormFile>())
        this.activeSubmission.files = mapFiles
      }
    },

    setActiveSubmissionNew (formType: string) {
      this.activeSubmission = Submission.createFromEmpty(formType)
    },
    unsetActiveSubmission () {
      this.activeSubmission = undefined
    },
    async deleteActiveSubmission () {
      if (this.activeSubmission !== undefined) {
        await toRaw(this.activeSubmission).deleteFromIdb()
        const activeIndex = this.submissions.indexOf(this.activeSubmission)
        if (activeIndex !== -1) {
          this.submissions.splice(activeIndex, 1)
        }
        delete this.activeSubmission
      }
    },
    async saveActiveSubmissionDraft () {
      if (this.activeSubmission !== undefined) {
        this.activeSubmission.status = FormStatus.DRAFT
        await this.setSubmissionLocation(FormStatus.DRAFT)
        await toRaw(this.activeSubmission).saveToIdb()
      }
    },
    async deleteActiveSubmissionFilesByFileKey (key: string) {
      if (this.activeSubmission !== undefined) {
        const files = mapToArray(this.activeSubmission.files).filter(file => file.key !== key)
        // remove files from store
        await this.activeSubmission.replaceFiles(files)
        // delete files on the IDB based on file key
        await toRaw(this.activeSubmission).deleteFilesFromIdbByFileKey(key) // remove files from IDB
      }
    },

    async saveActiveSubmissionSubmit () {
      if (this.activeSubmission !== undefined) {
        this.activeSubmission.status = FormStatus.PENDING_SUBMIT
        await this.setSubmissionLocation(FormStatus.PENDING_SUBMIT)
        await toRaw(this.activeSubmission).saveToIdb()
      }
    },
    setActiveFile (files: UploadedFileType[]) {
      this.activeFile = files
    },

    /*
      generate location including the form status
    */
    async getSubmissionLocation (status: string): Promise<SubmissionLocation> {
      const geoLocationStore = useGeoLocationStore()
      const getLocation = geoLocationStore.$state.location

      return {
        long: getLocation?.long ?? 0,
        lat: getLocation?.lat ?? 0,
        actionType: status
      }
    },

    /*
      set submission location during save as draft and submit
    */
    async setSubmissionLocation (status: string) {
      if (this.activeSubmission !== undefined) {
        const location: SubmissionLocation = await this.getSubmissionLocation(status)
        if (typeof location.long !== undefined) {
          const existingLocations = this.activeSubmission.locations ?? []
          const locations = this.checkSubmissionLocationByStatus(existingLocations, location, status)

          this.activeSubmission.locations = locations
        }
      }
    },

    checkSubmissionLocationByStatus (locations: SubmissionLocation[], location: SubmissionLocation, status: string) {
      const findExistingLocationByActionType = locations.findIndex(location => location.actionType === status)

      if (findExistingLocationByActionType < 0) {
        locations.push(location)
      } else {
        locations[findExistingLocationByActionType] = location
      }

      return locations
    },

    async xhrUpload (formData: FormData): Promise<boolean> {
      const i18nStore = useI18NStore()
      try {
        const response = await fetch('/api/submissions/file', {
          method: 'POST',
          headers: {
            'X-CSRFToken': getCsrf()
          },
          body: formData
        })

        if (response.ok) {
          toast.success(i18nStore.gettext(FILE_UPLOAD_MESSAGE.success))
          return true
        } else {
          throw new Error(`Request failed with status: ${response.status}`)
        }
      } catch (error) {
        if (!navigator.onLine) {
          toast.success(i18nStore.gettext(FILE_UPLOAD_MESSAGE.offline))
        } else {
          toast.warning(i18nStore.gettext(FILE_UPLOAD_MESSAGE.failure))
        }
        return false
      }
    },

    /**
     * Uploads an array of files to a server using XMLHttpRequest and performs related actions.
     * @param {FormFile[]} files - An array of files to be uploaded.
     */
    async uploadFiles (files: FormFile[]) {
      // Create an array of promises for each file upload
      const uploadPromises = files.map(async (file) => {
        if (typeof file.clonableData.bits !== 'undefined') {
          // Create a FormData object to prepare the file for upload
          const formDataUpload = new FormData()
          formDataUpload.append('submission', file.clonableData.formKey)
          formDataUpload.append('comment', DATA_COLLECTION_FILE_LABEL)
          formDataUpload.append('file', new File([file.clonableData.bits], file.clonableData.name, {
            type: file.clonableData.bits.type
          }))
          // Perform the file upload using (xhrUpload)
          await this.xhrUpload(formDataUpload).then(async (success: boolean) => {
            if (success) {
              // If the upload was successful, delete the file from the active submission
              await this.deleteActiveSubmissionFilesByFileKey(file.key)
            } else {
              // If the upload failed, update the activeSubmission files
              const mapFiles: Map<string, FormFile> = files.reduce((acc, obj) => {
                acc.set(obj.key, obj)
                return acc
              }, new Map<string, FormFile>())
              if (typeof this.activeSubmission !== 'undefined' && (this.activeSubmission.key === file.clonableData.formKey)) {
                this.activeSubmission.files = mapFiles
              }
            }
          })
        }
      })

      await Promise.all(uploadPromises)
    }

  }
})
