import { computed } from "vue"

interface ObjectKeyWithString {
  [key: string]: string
}

interface LooseObject {
  [key: string]: any
}

interface ObjectWithNumberKey {
  [key: number]: string
}

interface SubmissionLocation {
  lat: number
  long: number
  actionType: string
}

interface LocationObject {
  lat: number
  long: number
}

interface MenuType {
  label: string
  id: string
  active?: boolean
  disabled?: boolean
}

interface NestedObject {
  [key: string]: NestedObject | string | number | Array<NestedObject | string | number>
}

export type {
  ObjectKeyWithString,
  LooseObject,
  ObjectWithNumberKey,
  SubmissionLocation,
  LocationObject,
  MenuType,
  NestedObject
}

/**
 * Timor uses "PCodes" to uniquely identify areas
 */
type pcode = number

/**
 * PlaceNode represents one location.
 * You can determine the relationship between one placenode
 * and another from how their "paths" relate.
 */
export interface PlaceNode { name: string, path: pcode[] }

const compareArrays = (a: number[], b: number[]) =>
  a.length === b.length &&
  a.every((element, index) => element === b[index])

/**
 * PlaceNodes uses a materialized path storage model
 * Each element of the PlaceNodes map is an area
 * This has performance implications so there are embedded caches
 */
export class PlaceNodes extends Array<PlaceNode> {
  private readonly nameCache: Map<string, PlaceNode>
  private readonly parentCache: Map< PlaceNode, PlaceNode | undefined>
  private readonly byParentNodesCache: Map<number[], PlaceNode[]>
  constructor (...items: PlaceNode[]) {
    super(...items)
    this.nameCache = new Map<string, PlaceNode>()
    this.parentCache = new Map<PlaceNode, PlaceNode | undefined>()
    this.byParentNodesCache = new Map<number[], PlaceNode[]>()
  }

  private cachedFindByPath (path: number[]) {
    if (path.every(it => isNaN(it))) return undefined
    const key = JSON.stringify(path)
    const cache = this.nameCache
    let lookup = cache.get(key)
    if (typeof lookup === 'undefined') {
      lookup = this.find((it) => compareArrays(path, it.path))
      if (typeof lookup !== 'undefined') {
        cache.set(key, lookup)
      }
    }
    return lookup
  }

  private cachedGetParentNode (node: PlaceNode) {
    const cache = this.parentCache
    let parentNode = cache.get(node)
    if (typeof parentNode !== 'undefined') return parentNode
    parentNode = this.find((it) => compareArrays(it.path, node.path.slice(0, -1)))
    if (typeof parentNode === 'undefined') cache.set(node, parentNode)
    return parentNode
  }

  private cachedGetByParentNodes (...parents: number[]) {
    const cache = this.byParentNodesCache
    let lookup = cache.get(parents)
    if (typeof lookup !== 'undefined') return lookup
    lookup = this.filter((it) => {
      if (it.path.length !== parents.length + 1) return false
      if (parents.map((pcode, index) => {
        return (it.path.at(index) === pcode)
      }).includes(false)) return false
      return true
    })
    if (typeof lookup !== 'undefined') cache.set(parents, lookup)
    return lookup
  }

  findByPath (path: number[]) { return this.cachedFindByPath(path) }
  getChildNodes (node: PlaceNode): PlaceNode[] { return this.filter((it) => compareArrays(it.path.slice(0, -1), node.path)) }
  getParentNode (node: PlaceNode): PlaceNode | undefined { return this.cachedGetParentNode(node) }
  getByLevel (level: number) { return Array.from(this.values()).filter((it) => it.path.length === level) }
  getByParentNodes (...parents: number[]) { return this.cachedGetByParentNodes(...parents)
  }
}

export function isNumeric (value: unknown): value is number {
  return typeof value !== 'undefined' && typeof value === 'number'
}
