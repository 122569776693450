<script setup lang="ts">
import { useAuthStore } from '../../stores/auth'
import { useSuppliersStore } from '../../stores/suppliers'
import { useAppStore } from '../../stores/app'
import { useModal } from '../../stores/modal'
import { computed } from 'vue'
import { FormKit } from '@formkit/vue'
import AlertBox from '../commons/AlertBox.vue'

const authStore = useAuthStore()
const supplierStore = useSuppliersStore()
const appStore = useAppStore()
const modal = useModal()

const suppliersOption = computed(() => supplierStore.getSuppliersAsOption())
const supplierDataByProject = computed(() => supplierStore.getSuppliersByProjects())

defineProps<{
  sucoId: number | undefined
}>()

// add supplier on the project
const handleSupplierSubmit = async (values: { supplier: number | undefined }) => {
  if (typeof values.supplier === 'undefined') return
  appStore.$state.loading = true
  await supplierStore.updateSupplierProject(values.supplier)
  appStore.$state.loading = false
}

</script>
<template>
  <h2 class="font-bold text-2.5xl mb-7">
    {{ gettext('Suppliers') }}
  </h2>
  <div
    v-if="authStore.can('ida.change_project')"
    class="shadow-sm rounded-2.5xl overflow-hidden border border-light bg-white p-4"
  >
    <FormKit
      type="form"
      :actions="false"
      form-class="flex items-center "
      :disabled="suppliersOption.length < 1"
      @submit="handleSupplierSubmit"
    >
      <FormKit
        key="supplier"
        type="select"
        name="supplier"
        id:="supplier"
        :placeholder="gettext('Select supplier')"
        :options="suppliersOption"
        outer-class="!mb-0 w-full mr-3"
        required
      />
      <FormKit
        type="submit"
        :disabled="suppliersOption.length < 1"
        wrapper-class="!mt-0"
      >
        <i class="las la-plus text-xl mr-2"></i>
        {{ gettext('Add') }}
      </FormKit>
    </FormKit>
    <alert-box
      v-if="suppliersOption.length < 1"
      theme="warning"
      rounded="2.5xl"
      class="mt-4"
    >
      <div class="flex items-center">
        <i class="las la-exclamation-triangle text-2xl"></i>
        <div class="pl-5">
          <p class="text-2sm font-bold">
            {{ gettext('Suppliers currently unavailable') }}
          </p>
          <p class="text-2sm mt-1">
            {{ gettext('Ensure suppliers have been added into the platform via') }}
            <router-link
              :to="{
                name: 'sukuSuppliers',
                params: {
                  id: sucoId,
                  section: 'suppliers'
                }
              }"
              target="_blank"
              class="underline"
            >
              {{ gettext('the Suku Manager') }}.
            </router-link>
          </p>
        </div>
      </div>
    </alert-box>
  </div>
  <table class="mt-8 text-center text-3xs table-primary shadow-lg table-primary-narrow--cell w-full border-spacing-0 rounded-2.5xl border-separate font-semibold">
    <thead>
      <tr>
        <th>
          {{ gettext('Supplier') }}
        </th>
        <th>
          {{ gettext('Contact number') }}
        </th>
        <th>
          {{ gettext('Address') }}
        </th>
        <th>
          {{ gettext('Delete') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="supplierDataByProject.length < 1">
        <td colspan="4">
          {{ gettext('No data available') }}
        </td>
      </tr>
      <tr
        v-for="(supplier, index) in supplierDataByProject"
        :key="index"
      >
        <td>
          {{ supplier.name }}
        </td>
        <td>
          {{ supplier.contact_number }}
        </td>
        <td>
          {{ supplier.address }}
        </td>
        <td>
          <button
            type="button"
            class="text-xl text-zinc-900 w-7.5 h-7.5 rounded-full border-[1.5px] border-emerald-650 bg-white inline-flex items-center justify-center"
            @click="modal.open('confirm', {
              label: 'Delete',
              modalTitle: 'Are you sure?',
              modalCaption: 'Do you really want to delete this file? This process cannot be undone',
              callback: async () => {
                await supplierStore.updateSupplierProject(supplier.id, true)
                modal.close()
              }
            })"
          >
            <i class="las la-trash text-base"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
