<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useAuthStore } from '../stores/auth'
import { useI18NStore } from '../stores/i18n'
import AuthLayout from '../layouts/AuthLayout.vue'
import { forgotPasswordSchema } from '../_formkit/schema/auth.schema'
import { FormKitSchema } from '@formkit/vue'
import { ForgotPasswordData, ForgotPasswordResponse } from '../_types/auth'
import AlertBox from '../components/commons/AlertBox.vue'
import BasicButton from '../components/commons/BasicButton.vue'

const i18Store = useI18NStore()
const authStore = useAuthStore()

const data = reactive({
  gettext: i18Store.gettext
})
const formKitSubmitAttrs = {
  suffixIcon: 'arrowRight'
}

const response = ref<ForgotPasswordResponse>()
const submit = async (data: ForgotPasswordData) => {
  response.value = await authStore.submitForgotPassword(data)
}
</script>

<template>
  <auth-layout
    :has-border="false"
    content-size="lg"
  >
    <div class="flex items-center justify-center text-zinc-900 pt-16 lg:pt-[144px] pb-14">
      <div
        v-if="!response"
        class="max-w-[440px]"
      >
        <h1 class="text-4.5xl font-black mb-2">
          {{ gettext('Reset your password') }}
        </h1>
        <p class="text-xl mb-10">
          {{ gettext('Enter your username or email address below and we’ll email you your password reset instructions') }}
        </p>
        <FormKit
          type="form"
          :submit-label="gettext('Reset password')"
          :submit-attrs="formKitSubmitAttrs"
          novalidate
          message-class="text-red-500 text-sm"
          @submit="submit"
        >
          <FormKitSchema
            :schema="forgotPasswordSchema"
            :data="data"
          />
        </FormKit>
        <router-link
          to="/login/"
          class="text-emerald-600 underline text-center block mt-5"
        >
          {{ gettext('Return to login') }}
        </router-link>
      </div>
      <div
        v-else
        class="max-w-[440px]"
      >
        <h1 class="text-4.5xl font-black mb-2">
          {{ response.success ? gettext('Password reset sent!') : gettext('Incorrect email') }}
        </h1>
        <p class="text-xl mb-10">
          {{ response.success ? gettext('If the email doesn’t arrive soon, check your spam folder.') : gettext('You have entered an invalid e-mail address. Please try again') }}
        </p>
        <alert-box
          :theme="response.success ? 'success' : 'error'"
          class="mt-9 md:max-w-[440px]"
        >
          <div class="flex items-center md:pr-10">
            <div class="mr-5">
              <i
                class="text-2xl las"
                :class="response.success ? 'la-check-circle' : 'la-exclamation-triangle'"
              ></i>
            </div>
            <div class="text-[15px]">
              <p class="font-bold mb-1">
                {{ response.success ? gettext('Success!') : gettext('Incorrect email') }}
              </p>
              <p>
                {{
                  response.success ?
                    gettext('If your email is known to us we have sent password reset instructions to your associated email address.')
                    :
                    gettext('Email not found')
                }}
              </p>
            </div>
          </div>
        </alert-box>
        <basic-button
          class="!w-full md:max-w-[440px] mt-7.5 justify-center"
          suffix-icon="la-arrow-right"
          :to="{ name: 'login' }"
        >
          {{ gettext('Return to login') }}
        </basic-button>
      </div>
    </div>
  </auth-layout>
</template>
