import { FormKitSchemaDOMNode, FormKitSchemaFormKit } from '@formkit/core'

export const unwrapgettext = (str: string | undefined): string | undefined => {
  if (typeof str === 'undefined') return
  if (str.startsWith('$gettext("')) {
    return str.slice(10, -2)
  }
  if (str.startsWith('$gettext(')) {
    return str.slice(9, -1)
  }
  return str
}

export const getElementInfo = (element: FormKitSchemaFormKit | string) => {
  if (typeof element === 'string') {
    return 'text display'
  }
  if (element.$formkit === 'group') {
    return 'group'
  }
  if (element.$formkit === 'text') {
    return 'enter some text'
  }
  if (element.$formkit === 'datepicker') {
    return 'choose a date'
  }
  if (element.$formkit === 'select') {
    let text = 'choose from'
    switch (element.options) {
      case '$getLocations()':
        text += ' all districts'
        break
      case '$getLocations($get(district).value)':
        text += ' all administrative posts filtered by district'
        break
      case '$getLocations($get(district).value, $get(administrative_post).value)':
        text += ' all sucos filtered by selected district and administrative post'
        break
    }
    return text
  }
}

export const displayElement = (element: FormKitSchemaFormKit) => {
  if (typeof element === 'string') return ''
  if ('$formkit' in element) {
    if (['text', 'group', 'radio', 'datepicker'].includes(element.$formkit)) {
      return ''
    } else if (['currency', 'number'].includes(element.$formkit)) {
      return `${element.$formkit}: ${element.title as string ?? ''} name: ${element.name as string ?? ''} Label: ${element.name as string ?? ''}`
    } else if (element.$formkit === 'repeater') {
      return `$Name: ${element.name as string}`
    } else if (element.$formkit === 'uuid') {
      return 'Unique ID field'
    } else if (element.$formkit === 'hidden') {
      return `Hidden ${element.name as string} field`
    }
  }
  return `${element.$formkit} field`
}
