import { Store } from 'pinia'
import { App, Plugin } from 'vue'
import { AuthStore } from '../stores/auth'
import {
  useI18NStore
} from '../stores/i18n'

/** The gettext signature used inside vue components - matches what django makemessages looks for */
type ComponentGettextCallable = (str: string) => string
type ContextComponentGettextCallable = (ctx: string, msgid: string) => string

/** The interpolate callable - unchanged from waht django delivers */
type InterpolateCallable = (
  formats: string,
  data: Record<string, any>,
  named: boolean) => string

// The Install function used by Vue to register the plugin
export const I18nPlugin: Plugin = {
  install (app: App) {
    const i18nStore = useI18NStore()
    // allow any component to acces the i18nStore
    app.config.globalProperties.i18n = i18nStore
    // calling gettext in any component will use the i18nStore gettext
    app.config.globalProperties.gettext = i18nStore.gettext
    // calling interpolate in any component will use the global interpolate
    app.config.globalProperties.interpolate = i18nStore.interpolate
    app.config.globalProperties.pgettext = i18nStore.pgettext
  }
}

// Allow ts access to store and gettext functions
declare module 'vue' {
  interface ComponentCustomProperties {
    i18n: Store<'i18n', { code: string }>
    gettext: ComponentGettextCallable
    pgettext: ContextComponentGettextCallable
    interpolate: InterpolateCallable
    auth: AuthStore
  }
}
