enum COMPLAINT_STATUS_CHOICES {
  CLOSED = 0,
  ONGOING = 1
}

enum COMPLAINT_METHOD_CHOICE {
  MONITORING = 1,
  LETTER = 2,
  PERSONAL_CONTACT = 3,
  SOCIAL_MEDIA = 4,
  OTHER = 5
}

enum COMPLAINT_CATEGORY {
  POLITICAL_SYSTEM = 1,
  CONSTRUCTION = 2,
  FRAUD = 3
}

export { COMPLAINT_STATUS_CHOICES, COMPLAINT_CATEGORY, COMPLAINT_METHOD_CHOICE }
