<script setup lang="ts">
import { RouteLocationRaw, useRouter } from 'vue-router'
import { computed } from 'vue'

const router = useRouter()
const props = defineProps<{
  to: RouteLocationRaw
}>()

const subMenuActive = computed(() => {
  const path = router.resolve(props.to).path
  const currentPath = router?.currentRoute.value.path
  const checkPath = currentPath.includes(path)
  return checkPath
})
</script>
<template>
  <router-link
    class="font-semibold submenu relative text-[15px] text-white/60 py-2 block hover:text-white [&.router-link-active]:bg-[#000] hover:font-semibold [&.router-link-active]:text-white after:w-[4px] after:h-full after:bg-transparent after:inline-block after:content-[''] after:absolute after:left-0 after:top-0 [&.router-link-active]:after:bg-emerald-600"
    :to="to"
    :class="{ 'router-link-active': subMenuActive }"
  >
    <slot></slot>
  </router-link>
</template>
<style lang="postcss">

</style>
