import { DBSchema, IDBPDatabase, openDB } from 'idb'
import { components } from '../_api-services/openapi'
import { Translatable } from '../_types/translations'
import { languageCodes } from '../stores/i18n'

interface TranslationDB extends DBSchema {
  // Store all local offline form submissions
  'translations': {
    key: string
    value: components['schemas']['Translatable']
    indexes: {
      'by-language': string
      'by-context': string
    }
  }
}

const dbName = 'translations-id'

/** access ( and update ) the db, to be called by all db access methods */
async function getLanguageDb (): Promise<IDBPDatabase<TranslationDB>> {
  return await openDB<TranslationDB>(dbName, 2, {
    upgrade (db, oldVersion, newVersion, transaction, event) {
      const translations = db.createObjectStore('translations', {
        keyPath: 'id'
      })
      translations.createIndex('by-language', 'langcode')
      translations.createIndex('by-context', 'msgctxt')
    }
  })
}

interface TranslationMethods {
  gettext: (msgid: string) => Translatable | undefined
  pgettext: (msgctxt: string, msgid: string) => Translatable | undefined

}

const csep = '\x04'

const contextualize = (msgctxt: string | undefined, msgid: string) => typeof msgctxt === 'undefined' ? msgid : `${msgctxt}${csep}${msgid})`

export class TranslationsForLanguage extends Map<string, Translatable> implements TranslationMethods {
  gettext (msgid: string) { return this.get(msgid) }
  pgettext (msgctxt: string | undefined, msgid: string) {
    return this.gettext(
      contextualize(msgctxt, msgid))
  }

  addTranslation (it: Translatable) {
    const ctx = contextualize(it.msgctxt, it.msgid)
    if (this.get(ctx) === it) return
    this.set(ctx, it)
  }
}

let lastUpdated = ''

export class MapTranslations extends Map<languageCodes, TranslationsForLanguage> {
  /** TODO: Replace the slow `list` implementation of Translations with a faster `map` implementation */

  getByLanguage (lc: languageCodes) {
    let cat = this.get(lc)
    if (typeof cat === 'undefined') {
      cat = new TranslationsForLanguage()
      this.set(lc, cat)
    }
    return cat
  }

  async fromDb () {
    const db = await getLanguageDb()
    const t = await db.getAll('translations')
    t.forEach((it) => this.push(it))
  }

  getLatest () { return lastUpdated }

  async toDb (translations: Translatable[]): Promise<MapTranslations> {
    const db = await getLanguageDb()
    const tx = db.transaction('translations', 'readwrite')
    const store = tx.store
    for (const translation of translations) {
      await store.put(translation)
    }
    const newt = new MapTranslations()
    await newt.fromDb()
    return newt
  }

  push (it: Translatable | Translatable[]) {
    /**
     * Replicates array-like
     */
    if (Array.isArray(it)) {
      it.map((it_) => this.push(it_))
      return
    }
    this.getByLanguage(it.langcode as languageCodes).addTranslation(it)
    if (it.last_updated > lastUpdated || lastUpdated === '') lastUpdated = it.last_updated
  }
}

/** Catalog of translations */
export class Translations extends Array<Translatable> implements TranslationMethods {
  getByLanguage (code: string) { return new Translations(...this.filter((it) => it.langcode === code)) }
  // getByDomain (domain: string) { return new Translations(...this.filter((it) => it.domain === domain)) }
  gettext (msgid: string) { return this.find((it) => it.msgid === msgid) }
  pgettext (msgctxt: string, msgid: string) { return this.find((it) => it.msgid === msgid && it.msgctxt === msgctxt) }

  getLatest (): string {
    /**
     * Return the latest update for all translations in this catalog. Used for incremental updates.
     */
    if (this.length === 0) return ''
    const lastUpdated = this.reduce((prev, current) => (prev.last_updated > current.last_updated) ? prev : current)
    return lastUpdated.last_updated
  }

  async fromDb () {
    const db = await getLanguageDb()
    const t = await db.getAll('translations')
    return new Translations(...t)
  }

  async toDb (translations: Translatable[]): Promise<Translations> {
    const db = await getLanguageDb()
    const tx = db.transaction('translations', 'readwrite')
    const store = tx.store
    for (const translation of translations) {
      await store.put(translation)
    }
    const newt = await this.fromDb()
    return newt
  }
}
