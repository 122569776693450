<script setup lang="ts">
import { ref } from 'vue'
import BasicCard from '../commons/BasicCard.vue'
import BasicButton from '../commons/BasicButton.vue'
import LabelText from '../commons/LabelText.vue'
import { FormKit } from '@formkit/vue'
import { generateYears } from '../../_helpers/common.helpers'
import { useOptionsStore } from '../../stores/options'
import { SUKU_BUDGET_MONTHLY_REPORT_VISUALIZE, SUKU_BUDGET_MONTHLY_REPORT_CREATE } from '../../_constants/sukumanager.constant'
const optionsStore = useOptionsStore()

interface MonthlyReportFilterSchema { year: number | undefined, month: number | undefined, actionType: string }

defineProps<{
    showReport: boolean
    hasReport: boolean,
    modelValue: MonthlyReportFilterSchema
}>()

const emit = defineEmits <{(e: 'update:modelValue', value: MonthlyReportFilterSchema): void, (e: 'submit', value: boolean): void}>()

const monthlyReportManageValues = ref({
  year: undefined,
  month: undefined,
  actionType: SUKU_BUDGET_MONTHLY_REPORT_VISUALIZE
})

function submit () {
  emit('update:modelValue', monthlyReportManageValues.value)
  emit('submit', true)
}

</script>
<template>
  <div>
    <h1 class="text-2.5xl font-black mb-10 mt-[59px]">
      {{ gettext('Manage monthly reports') }}
    </h1>
    <basic-card
      class="max-w-[652px] !rounded-2.5xl !p-[30px]"
      :class="{ '!mb-[30px]': !showReport, '!mb-10': showReport}"
    >
      <div class="text-left">
        <h2 class="text-1.5xl font-black mb-7.5">
          <i class="las la-calendar mr-2"></i> {{ gettext('Select reporting period') }}
        </h2>
        <FormKit
          v-model="monthlyReportManageValues"
          type="form"
          :actions="false"
          incomplete-message=" "
          @submit="submit"
          @input="false"
        >
          <FormKit
            id="year"
            type="select"
            name="year"
            :label="gettext('Reporting year')"
            :placeholder="gettext('Reporting year')"
            :options="generateYears(2012).reverse()"
            validation="required"
            label-class="hidden"
            @input="false"
          />
          <FormKit
            id="month"
            type="select"
            name="month"
            :label="gettext('Reporting month')"
            :placeholder="gettext('Reporting month')"
            :options="optionsStore.options('month')"
            validation="required"
            label-class="hidden"
            @input="false"
          />
          <div
            class="flex justify-between"
          >
            <basic-button
              type="submit"
              class="!w-full"
              @click="() => monthlyReportManageValues.actionType = SUKU_BUDGET_MONTHLY_REPORT_VISUALIZE"
            >
              <i class="las la-external-link-alt text-2xl mr-3"></i>
              {{ gettext('View') }}
            </basic-button>
            <basic-button
              class="!w-full ml-[13px]"
              type="submit"
              @click="() => monthlyReportManageValues.actionType = SUKU_BUDGET_MONTHLY_REPORT_CREATE"
            >
              <i class="las la-pencil-alt text-2xl mr-3"></i>
              {{ gettext('Edit') }}
            </basic-button>
          </div>
          <label-text
            v-if="showReport && !hasReport"
            class="text-2sm font-bold flex items-center mt-5"
            theme="error"
          >
            <p>{{ gettext('There is no FF12 submission that being submitted on this period') }}</p>
          </label-text>
        </FormKit>
      </div>
    </basic-card>
  </div>
</template>
