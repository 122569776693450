import { Topology, feature } from 'topojson-client'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
type FeatureCollection = components['schemas']['FeatureCollection']

// Function to convert TopoJSON to GeoJSON
const topoJSONToGeoJSON = async (topojson: Topology<unknown>): Promise<FeatureCollection> => {
  return await new Promise((resolve, reject) => {
    try {
      const geojson = feature(topojson, topojson.objects.data) as FeatureCollection
      resolve(geojson)
    } catch (error) {
      reject(error)
    }
  })
}

async function fetchTopoJson (slug: string) {
  const { data, error } = await client.GET('/api/timor-locations/{slug}.topojson', { params: { path: { slug } } })
  if (typeof data !== 'undefined') return await topoJSONToGeoJSON(data)
  else if (typeof error !== 'undefined') console.error('Error fetching TopoJSON file:', error)
}

export default fetchTopoJson
