<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue'

const props = withDefaults(defineProps<{
  scrolledElementSelector: string;
  scrolledContentParent: string;
  maxWidthScrolledArea?: string | undefined
}>(),
{
  maxWidthScrolledArea: '100%'
})

// Refs to store references to DOM elements
const scrollContent = ref<HTMLElement | null>()
const scrollbarThumb = ref<HTMLElement | undefined>()
const scrollbarTrack = ref<HTMLElement | undefined>()
const scrollableArea = ref<HTMLElement>()
// Reactive state object to store component state
const state = reactive({
  isTruncated: false,
  scrollbarThumbWidth: 0,
  scrollbarTrackWidth: 0,
  isDragging: false,
  lastX: 0,
  scrollContainerWidth: 0,
  scrollContentWidth: 0
})

// Function to initialize the scrollbar component
async function initialize () {
  if (!scrollContent.value) {
    return
  }
  // Select the scrolled element and its parent
  const scrolledElement: HTMLElement | null = scrollContent.value.querySelector(props.scrolledElementSelector)
  const scrollContentWidth = scrollContent.value.clientWidth || 0
  const scrolledElementWidth = scrolledElement?.clientWidth || 0
  const scrolledContentParent: HTMLElement | null = scrolledElement?.closest(props.scrolledContentParent) ?? null

  // Update component state based on element sizes
  state.isTruncated = scrolledElementWidth > scrollContentWidth
  state.scrollContainerWidth = scrollContentWidth
  state.scrollbarTrackWidth = scrollContentWidth
  state.scrollContentWidth = scrolledElementWidth

  // Calculate and set scrollbar thumb width if the content is truncated
  if (state.isTruncated && scrollContentWidth > 0) {
    const scrollbarWidth = 100 - (((scrolledElementWidth - scrollContentWidth) / scrolledElementWidth) * 100)
    state.scrollbarThumbWidth = Math.abs(scrollbarWidth) // scrollbarthumbwidth in `%`
  }

  // Add a scroll event listener to the scrollable area to sync the thumb position
  if (scrolledContentParent !== undefined && scrolledContentParent !== null) {
    scrollableArea.value = scrolledContentParent

    const handleScroll = (e: Event) => {
      const scrollLeftValue = 'touches' in e ? scrollableArea.value?.scrollLeft ?? 0 : (e.target as HTMLElement).scrollLeft
      // get scrollbarthumbwidth in `px`
      const scrollbarThumbWidthPx = (Math.abs(state.scrollbarThumbWidth) / 100) * (scrollbarTrack.value?.clientWidth ?? 0) // max scrollbar track
      if (scrollbarThumb.value && (scrollLeftValue < (state.scrollbarTrackWidth - scrollbarThumbWidthPx))) {
        scrollbarThumb.value.style.left = scrollLeftValue + 'px'
      }
    }

    // Add scroll event listener for both mouse and touch events
    scrollableArea.value.addEventListener('scroll', handleScroll)
    scrollableArea.value.addEventListener('touchmove', (e: TouchEvent) => {
      if (e.isTrusted) {
        handleScroll(e)
      }
    })
  }
}

// Function to handle the drag interaction on the scrollbar thumb
function handleDrag (event: MouseEvent | TouchEvent) {
  if (state.isDragging && scrollbarThumb.value && scrollbarTrack.value) {
    const maxScroll = state.scrollContentWidth - state.scrollContainerWidth
    const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX

    const deltaX = clientX - state.lastX

    // Calculate new thumb position within the track
    const newThumbPosition = Math.min(
      Math.max(0, scrollbarThumb.value.offsetLeft + deltaX),
      scrollbarTrack.value.clientWidth - scrollbarThumb.value.clientWidth
    )

    // Update thumb position
    scrollbarThumb.value.style.left = newThumbPosition + 'px'

    // Update scroll position in content based on thumb position
    const newScrollPosition = (newThumbPosition / (scrollbarTrack.value.clientWidth - scrollbarThumb.value.clientWidth)) * maxScroll

    if (scrollableArea.value) {
      scrollableArea.value.scrollLeft = newScrollPosition
    }

    state.lastX = clientX
  }
}

// Function to stop the drag interaction
function stopDrag () {
  state.isDragging = false
}

// Function to start the drag interaction
function startDrag (event: MouseEvent | TouchEvent) {
  state.isDragging = true
  state.lastX = 'touches' in event ? event.touches[0].clientX : event.clientX
}

// Add a window resize event listener to reinitialize the component on resize
onMounted(async () => {
  window.addEventListener('resize', () => {
    initialize()
  }, {
    passive: true
  })
})

</script>

<template>
  <div
    class="relative group w-full"
    :style="{
      maxWidth: maxWidthScrolledArea
    }"
  >
    <!-- Scrollbar Track -->
    <div
      v-if="state.isTruncated"
      ref="scrollbarTrack"
      class="hidden group-hover:block absolute top-0 left-0 z-20 h-3 w-full bg-white"
      @mousedown="startDrag"
      @touchstart.prevent="startDrag"
      @mouseup="stopDrag"
      @touchend.prevent="stopDrag"
      @mousemove="handleDrag"
      @touchmove.prevent="handleDrag"
    >
      <!-- Scrollbar Thumb -->
      <div
        ref="scrollbarThumb"
        class="absolute h-3 left-0 top-0 w-10 cursor-pointer py-[2px] opacity-70 hover:opacity-100"
        :style="{ width: state.scrollbarThumbWidth + '%', left: '0' }"
      >
        <div class="w-full h-full relative bg-gray-400 rounded-md"></div>
      </div>
    </div>
    <!-- Scrollable Content -->
    <div
      ref="scrollContent"
      class="relative scrollable-parent"
      style="overflow-x: auto;"
      @mouseover="initialize"
    >
      <slot></slot>
    </div>
  </div>
</template>
