import { Header } from 'vue3-easy-data-table'
import { components } from '../../_api-services/openapi'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'

const filterBySector = <T extends { sector_id?: number | undefined }>(data: T[], sectorId: T['sector_id']) => { return data.filter(it => it.sector_id === sectorId) }

const projectIds = <T extends { suco_subproject_id: string }>(data: T[]) => { return new Set(data.map((p) => p.suco_subproject_id)) }

const spendByProjects = <T extends { subproject_id: string, labour_spend?: number | undefined, material_spend?: number | undefined }>(data: T[]): Map<T['subproject_id'], number> => {
  const ret = new Map<T['subproject_id'], number>()
  for (const it of data) {
    ret.set(it.subproject_id, (ret.get(it.subproject_id) ?? 0) + (it.labour_spend ?? 0) + (it.material_spend ?? 0))
  }
  return ret
}

const budgetForProjects = <T extends { suco_subproject_id: string, materials_budget?: number | undefined, labour_budget?: number | undefined }>(data: T[]): Map<T['suco_subproject_id'], number> => {
  const ret = new Map<T['suco_subproject_id'], number>()
  for (const it of data) {
    ret.set(it.suco_subproject_id, (ret.get(it.suco_subproject_id) ?? 0) + (it.materials_budget ?? 0) + (it.labour_budget ?? 0))
  }
  return ret
}

// From the data returned by the endpoint, caclulate the data in the table

interface tableRow {
  subproject_name: string
  total_budget_allocated: string
  total_expenditures: string
  balance: string
  last_progress: number | undefined
  project_status: number | undefined
}

const calculateTableData = (data: components['schemas']['LegacyProfileData'] | undefined, sectorId: number): tableRow[] => {
  if (typeof data === 'undefined') return []
  const projects = sectorId === 0 ? data.projects : filterBySector(data.projects, sectorId)
  const ids = projectIds(projects)
  const spend = spendByProjects(data.spend.filter(it => ids.has(it.subproject_id)))
  const budget = budgetForProjects(projects)

  // The table values include these lookups sums we calculated
  return projects.map((p) => {
    return {
      subproject_name: p.subproject_name,
      total_budget_allocated: formatCurrencyWithDollar(budget.get(p.suco_subproject_id) ?? 0),
      total_expenditures: formatCurrencyWithDollar(spend.get(p.suco_subproject_id) ?? 0),
      balance: formatCurrencyWithDollar((budget.get(p.suco_subproject_id) ?? 0) - (spend.get(p.suco_subproject_id) ?? 0)),
      last_progress: p.project_progress,
      project_status: p.status_id
    }
  })
}

type LegacyProjectHeader = Header & { value: keyof tableRow }
const headers: LegacyProjectHeader[] = [
  { sortable: true, value: 'subproject_name', text: 'Project name' },
  { sortable: true, value: 'total_budget_allocated', text: 'Total budget allocated' },
  { sortable: true, value: 'total_expenditures', text: 'Total expenditures' },
  { sortable: true, value: 'balance', text: 'Balance' },
  { sortable: true, value: 'last_progress', text: 'Last progress' },
  { sortable: true, value: 'project_status', text: 'Project status' }
]

export { headers, calculateTableData, filterBySector }
