<script setup lang="ts">
const props = withDefaults(defineProps<{
  fill?: string
  width?: number
  height?: number
  scale?: number
}>(),
{
  fill: 'black',
  width: 20,
  height: 20,
  scale: 1
})

const transform = `scale(${props.scale} ${props.scale})`

</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width * scale"
    :height="height * scale"
  >
    <g :transform="transform">
      <path
        d="M6.75 0c1.22 0 2.35.3 3.4.9a7 7 0 0 1 2.44 2.43 6.6 6.6 0 0 1 .91 3.39 6.1 6.1 0 0 1-.28 1.68c-.16.53-.4 1.16-.73 1.9a42 42 0 0 1-2.09 4.02l-2.1 3.36L6.75 20l-1.57-2.3-2.1-3.36a42 42 0 0 1-2.09-4.02C.68 9.57.44 8.94.28 8.4A6.1 6.1 0 0 1 0 6.73a6.6 6.6 0 0 1 .91-3.39A7 7 0 0 1 3.35.91C4.4.3 5.53 0 6.75 0z"
        :fill="fill"
      />
      <circle
        fill="#fff"
        cx="6.75"
        cy="6.75"
        r="2.25"
      />
    </g>
  </svg>
</template>
