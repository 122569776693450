import { defineStore } from 'pinia'
import authService from '../_api-services/auth/auth.service'
import { getCsrf, objectToFormData } from '../_helpers/common.helpers'
import { client } from '../_api-services/urls'
import { ForgotPasswordData, ForgotPasswordResponse, ResetPasswordData, ResetPasswordResponse } from '../_types/auth'

interface UserDetails {
  name: string
  id: number
  perms: permissionCode[]
}

interface LoginReturn {
  success: boolean
  reason?: 'network' | 'server' | 'badrequest'
}
export interface AuthState {
  user?: UserDetails
  isAuthenticated: boolean
  pendingLogout: boolean
  userMunicipality: number[]
}

const initialState: AuthState = {
  user: undefined,
  isAuthenticated: false,
  pendingLogout: false,
  userMunicipality: []
}

/** The name of the cache where we store the login check response */
const userDetailsCacheName = 'ida-user-details'
/** The path we retrieve the login state from */
const passwordResetPath = '/accounts_api/password_reset'
/** The path we retrieve the login state from */
const userDetailsPath = '/accounts_api/check'
/** The local storage key where we store if network logout has failed */
const pendingLogoutStorageKey = 'idaLogoutPending'
/** The acceptable age in milliseconds of the user details cache */
const millisecondsAcceptableCacheAge = 60000

const userMunicipalityCachename = 'cache'
const userMunicipalityPath = '/api/ida/usermunisipiu/'
/**
 * Store helper function to return if the user has a given permission
 * @param state
 * @param permissionCode
 * @returns true if the user can permissionCode
 */
function _can (state: AuthState, permissionCode: permissionCode): boolean {
  const modelPerms = state.user?.perms
  if (modelPerms !== undefined) {
    return modelPerms.includes(permissionCode)
  } else {
    return false
  }
}

/**
 * Do we need to refresh the user details?
 * @param response the user details response object from the cache
 * @returns true if older than specified
 */
function _areDetailsStale (response: Response): boolean {
  const dateHeader = response.headers.get('date')
  if (dateHeader !== null) {
    const date = new Date(dateHeader)
    return date.getTime() < new Date().getTime() - millisecondsAcceptableCacheAge
  }
  return true
}

/**
 * Get the user details from the network, caching if success, and deleting the cache if 401
 * @param cache
 * @returns The user details response
 */
async function _fetchUserDetails (cache: Cache): Promise<Response | undefined> {
  try {
    const networkResponse = await fetch(userDetailsPath)
    if (networkResponse.ok) {
      // the response is good, cache it for the future
      void cache.put(
        userDetailsPath,
        networkResponse.clone()
      )
    }
    if (networkResponse.status === 401) {
      // the network responded saying we are not authenticated, delete the cache
      void cache.delete(userDetailsPath)
    }
    return networkResponse
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.warn('SyntaxError caught:', e, userDetailsPath)
    } else if (e instanceof TypeError) {
      console.warn('Failed to Fetch caught:', e, userDetailsPath)
    }
  }
}
/**
 * Get the user municipality data from the network, caching if success, and deleting the cache if 401
 * @param cache
 * @returns The user municipality response
 */
async function _fetchUserMunicipality (cache: Cache): Promise<Response> {
  const { response } = await client.GET('/api/ida/usermunisipiu/', {})
  return response
}

/**
 * Get the user details, from the cache or the network
 * @returns the user details response
 */
async function _getUserDetails (): Promise<Response | undefined> {
  const detailsCache = await caches.open(userDetailsCacheName)
  const cachedDetails = await detailsCache.match(userDetailsPath)

  if (cachedDetails !== undefined) {
    // we have a cache
    if (_areDetailsStale(cachedDetails)) {
      // the cache is stale, refresh it but don't wait
      void _fetchUserDetails(detailsCache)
    }
    // return the cached details
    return cachedDetails
  } else {
    // we need to fetch
    return await _fetchUserDetails(detailsCache)
  }
}
/**
 * Get the user municipality data, from the cache or the network
 * @returns the user municipality response
 */
async function _getUserMunicipalityDetails (): Promise<Response> {
  const detailsCache = await caches.open(userMunicipalityCachename)
  const cachedDetails = await detailsCache.match(userMunicipalityPath)
  if (cachedDetails !== undefined) {
    // fetch new data
    void _fetchUserMunicipality(detailsCache)
    // return the cached details
    return cachedDetails
  } else {
    // we need to fetch
    return await _fetchUserMunicipality(detailsCache)
  }
}

/**
 * Clear the cached user details (on logout)
 */
async function _clearDetails (): Promise<void> {
  const detailsCache = await caches.open(userDetailsCacheName)
  const userMunicipalityCache = await caches.open(userMunicipalityCachename)
  await detailsCache.delete(userDetailsPath)
  await userMunicipalityCache.delete(userMunicipalityPath)
}

// This copied and adapted from `ida.user_details.py`
export type permissionCode = (
  'ida.view_idauser' |
  'ida.add_idauser' |
  'ida.change_idauser' |
  'ida.delete_idauser' |
  'ida_options.change_activity' |
  'form_submission.read_submissionform' |
  'form_submission.add_submissionform' |
  'form_submission.approve_submissionform' |
  'form_submission.reject_submissionform' |
  'form_submission.can_approve_reject_submission' |
  'form_submission.read_submission_history' |
  'form_submission.reopen_submission' |
  // Suppliers
  'ida.view_supplier' |
  'ida.add_supplier' |
  'ida.change_supplier' |
  // Complaints
  'ida.add_complaint' |
  'ida.change_complaint' |
  'ida.view_complaint' |
  // Group editing
  'auth.view_group' |
  'auth.change_group' |
  'auth.delete_group' |
  'auth.add_group' |
  'ida.view_project' |
  'ida.change_project' |
  'metabase.view_mbreportdashboard' |
  'pnds_data.view_zsuco' |
  // Suco priorities
  'pnds_data.view_sucopriorities' |
  'pnds_data.add_sucopriorities' |
  'pnds_data.change_sucopriorities' |
  // Suco Finances
  'ida_forms.view_pom_1' |
  'ida_forms.change_pom_1' |
  'ida_forms.add_pom_1' |
  // Form editors
  'formkit_ninja.change_formkitschemanode'
)

export const useAuthStore = defineStore('auth', {
  state: () => (initialState),

  getters: {
    name: (state) => state.isAuthenticated ? state.user?.name : 'Guest',
    can: (state) => (permissionCode: permissionCode) => _can(state, permissionCode),
    canAny: (state) => (permissionCodes: permissionCode[]) => {
      return permissionCodes.some((p) => {
        return _can(state, p)
      })
    },
    user_municipality: (state) => state.userMunicipality.length > 0 ? state.userMunicipality[0] : null
  },

  actions: {
    /** Initialize the auth store (optimized for frequent calling) */
    async initialize () {
      if (localStorage.getItem(pendingLogoutStorageKey) !== null) {
        // in the past we tried to logout but the network request failed
        await this.logoutNetwork()
      }
      try {
        const userResponse = await _getUserDetails()
        if (typeof userResponse !== 'undefined' && userResponse.ok) {
          // the user is logged in (or was last time they had connection)
          this.isAuthenticated = true
          this.user = await userResponse.json()
        } else {
          // the server has told us we are not authenticated
          if (this.isAuthenticated) {
            // we were logged in so we should now be forced out
            await this.logout()
          }
        }

        // if (userMunicipalityResponse.ok) {
        //   this.userMunicipality = await userMunicipalityResponse.json() // set user municipality data
        // }
      } catch {
        // we have no network, and no cached details
        this.user = undefined
        this.isAuthenticated = false
      }
    },

    async initializeUserMunicipality () {
      try {
        const userMunicipalityResponse = await _getUserMunicipalityDetails() // get the user municipality
        if (userMunicipalityResponse.ok) {
          this.userMunicipality = await userMunicipalityResponse.json() // set user municipality data
        }
      } catch (error: any) {
        this.userMunicipality = []
        throw new Error(error)
      }
    },

    async login (payload: FormData): Promise<LoginReturn> {
      try {
        const r = await authService.login(payload)
        const returnVal: LoginReturn = {
          success: r.ok
        }
        if (r.ok) {
          this.isAuthenticated = true
          this.user = await r.json()
        } else if (r.status === 400) {
          returnVal.reason = 'badrequest'
        } else if (r.status === 500) {
          returnVal.reason = 'server'
        }
        return returnVal
      } catch (error) {
        return {
          success: false,
          reason: 'network'
        }
      }
    },

    async logout () {
      await this.logoutLocal()
      await this.logoutNetwork()
      void this.router.push({ name: 'login' })
    },

    async logoutLocal () {
      await _clearDetails()
      this.isAuthenticated = false
      this.user = undefined
    },

    async logoutNetwork () {
      try {
        await fetch('/accounts_api/logout')
        localStorage.removeItem(pendingLogoutStorageKey)
      } catch {
        // the logout failed, we have a pending http only cookie
        localStorage.setItem(pendingLogoutStorageKey, 'true')
      }
    },

    async submitForgotPassword (data: ForgotPasswordData): Promise<ForgotPasswordResponse> {
      const response = await fetch(
        passwordResetPath,
        {
          method: 'POST',
          body: objectToFormData(data),
          headers: {
            Accept: 'application/json',
            'X-CSRFToken': getCsrf()
          }
        }
      )
      return { success: response.ok }
    },

    async resetPassword (
      data: ResetPasswordData,
      uidb64: string,
      token: string): Promise<ResetPasswordResponse> {
      const s = new FormData()
      s.append('new_password1', data.password)
      s.append('new_password2', data.password_confirm)
      const response = await fetch(
        `/accounts_api/reset/${uidb64}/${token}/`,
        {
          method: 'POST',
          body: s,
          headers: {
            Accept: 'application/json',
            'X-CSRFToken': getCsrf()
          }
        }
      )
      if (response.ok) {
        return { success: true }
      } else {
        const responseBody = await response.json()
        const responseErrors: Record<string, string[]> = responseBody.errors
        const errors: Record<string, string | string[]> = {}
        for (const key in responseErrors) {
          if (key === 'new_password1') {
            errors.password = responseErrors[key]
          }
          if (key === 'new_password2') {
            errors.passwordConfirm = responseErrors[key]
          }
        }
        return {
          success: false,
          errors
        }
      }
    }
  }
})

export type AuthStore = ReturnType<typeof useAuthStore>
