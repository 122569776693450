<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
import MenuWrapper from '../menu/MenuWrapper.vue'
import MenuItem from '../menu/MenuItem.vue'
import SubMenuItem from '../menu/SubMenuItem.vue'
import LanguageToggle from '../forms/LanguageToggle.vue'
import { useAuthStore } from '../../stores/auth'
import { reactive, computed } from 'vue'
import { MAIN_MENUS } from '../../_constants/navigation.constant'
const authStore = useAuthStore()

const state = reactive({ isOpen: false })

function logout () {
  authStore.logout()
}

const toggleMenu = () => {
  state.isOpen = !state.isOpen
}

const forceCloseNav = (trigger: boolean) => {
  if (!trigger) {
    state.isOpen = false
  }
}

const menuList = computed(() => {
  return MAIN_MENUS
    .filter((menu) => !menu.permissions || authStore.canAny(menu.permissions))
    .map((menu) => ({
      ...menu,
      subMenus: menu.subMenus ? menu.subMenus.filter(sub => !sub.permissions || authStore.canAny(sub.permissions)) : undefined
    }))
})
</script>
<template>
  <nav class="order-first w-full lg:top-0 lg:fixed lg:w-[260px] bg-zinc-900 lg:rounded-tr-[40px] text-center overflow-y-scroll lg:h-full lg:max-h-screen scrollbar-hide flex lg:block py-3.5 px-5 lg:px-0 lg:py-8 justify-between lg:justify-start z-[10002]">
    <router-link
      class="text-white text-center text-2xl lg:text-4xl font-bold"
      :to="{ name: 'home' }"
    >
      <img
        src="../../assets/IDA-logo-light-352.webp"
        width="88"
        alt="32"
        class="mx-auto"
      />
    </router-link>

    <div
      class="fixed lg:relative left-0 top-[59px] lg:left-auto lg:top-auto w-full lg:w-auto bg-zinc-900 lg:bg-transparent overflow-hidden lg:max-h-max transition-all ease-in-out duration-300"
      :class="{ 'max-h-0': !state.isOpen, 'max-h-[2000px]': state.isOpen }"
    >
      <menu-wrapper
        v-if="authStore.$state.isAuthenticated"
        class="lg:mt-6 pb-5 lg:pb-0"
      >
        <menu-item class="mb-9 hidden lg:block">
          <p class="">
            {{ gettext("Good morning") }},
          </p>
          <h3 class="font-bold text-xl">
            {{ authStore.name }}
          </h3>
        </menu-item>
        <menu-item
          v-for="(menu, index) in menuList"
          :key="index"
          :has-submenu="menu.subMenus !== undefined"
          :parent-menu="menu.parentMenu"
          :menu-name="menu.label"
          :to="menu.to"
          @click="forceCloseNav(menu.subMenus !== undefined)"
        >
          <template
            v-if="menu.subMenus !== undefined"
            #toggle
          >
            {{ gettext(menu.label) }}
          </template>
          <template v-if="menu.subMenus === undefined">
            {{ gettext(menu.label) }}
          </template>
          <template
            v-if="menu.subMenus !== undefined"
            #submenu
          >
            <sub-menu-item
              v-for="(submenu, i) in menu.subMenus"
              :key="i"
              :to="submenu.to"
              @click="forceCloseNav(false)"
            >
              {{ gettext(submenu.label) }}
            </sub-menu-item>
          </template>
        </menu-item>
        <li>
          <button
            v-if="auth.$state.isAuthenticated"
            class="font-semibold px-5 text-2base border-l-4 border-l-transparent hover:border-l-teal-500 transition-all ease-in-out duration-300 hover:bg-black block w-full py-4 px-5  "
            @click="logout"
          >
            {{ gettext('Logout') }}
          </button>
        </li>
        <menu-item
          class="border-t border-t-white/10 mt-7 lg:border-t-0 lg:mt-0"
        >
          <LanguageToggle class="light" />
        </menu-item>
      </menu-wrapper>
      <menu-wrapper
        v-else
        class="lg:mt-6 pb-5 lg:hidden"
      >
        <menu-item
          to="/login"
          class="!mb-0"
        >
          {{ gettext("Login") }}
        </menu-item>
        <menu-item class="border-t border-t-white/10 mt-3 lg:hidden">
          <LanguageToggle class="light" />
        </menu-item>
      </menu-wrapper>
    </div>
    <router-link
      v-if="!authStore.$state.isAuthenticated"
      class="text-white hidden lg:block"
      to="/login"
    >
      {{ gettext("Login") }}
    </router-link>
    <button
      type="button"
      class="lg:hidden text-sm font-semibold text-white inline-flex items-center"
      @click="toggleMenu"
    >
      Menu <span
        class="burger ml-2"
        :class="{ 'toggled': state.isOpen }"
      >
        <span></span>
        <span></span>
        <span></span>
      </span>
    </button>
  </nav>
</template>

<style lang="postcss">
.burger {
  width: 27px;
  height: 29px;
  @apply relative inline-block;
  span {
    width: 21px;
    height: 2px;
    @apply left-0 right-0 mx-0 absolute bg-white inline-block transition-all ease-in-out duration-200;
    &:nth-child(1) {
      top: 6px;
    }
    &:nth-child(2) {
      @apply top-1/2 -translate-y-1/2;
    }
    &:nth-child(3) {
      bottom: 6px;
    }
  }

  &.toggled {
    span {
      &:nth-child(1) {
        @apply rotate-45 translate-y-[10px];
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        @apply -rotate-45 -translate-y-[5px];
      }
    }
  }
}
</style>
