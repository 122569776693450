export const SUKU_BUDGET_STATUS = {
  active: '2',
  closed: '3',
  inactive: '1'
} as const

export const SUKU_BUDGET_TYPE = {
  infrastructure: 'infrastructure-budget',
  operational: 'operational-budget'
} as const

export type SUKU_BUDGET_TYPES = typeof SUKU_BUDGET_TYPE[keyof typeof SUKU_BUDGET_TYPE]

export const SUKU_BUDGET_MONTHLY_REPORT_VISUALIZE = 'visualize'
export const SUKU_BUDGET_MONTHLY_REPORT_CREATE = 'create'
export const SUKU_BUDGET_MONTHLY_REPORT_EDIT = 'edit'

export const SUKU_BUDGET_MONTHLY_REPORT_STATUS = {
  unverified: '1',
  verified: '2',
  closed: '3'
} as const

export const SUKU_TEAMS_TYPE = {
  repeaterProjectTeam: 'management-teams',
  repeaterPlanning: 'kpa',
  repeaterSukus: 'suku-facilitators'
}
