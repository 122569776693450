<script setup lang="ts">
/**
 * Wrapper for form page that display the list of data on the datatable
 */
import { computed, provide, onMounted } from 'vue'
import { useFormsStore } from '../../stores/forms'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useRoute } from 'vue-router'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'

const route = useRoute()
const props = defineProps<{
  formType: string,
  formKey?: string,
}>()

const store = useFormsStore()
const geoLocationStore = useGeoLocationStore()

const formDef = computed(() => {
  return (store.forms)
    ? store.forms.find(it => it.key === props.formType)
    : null
})

const backToTarget = computed(() => props.formKey ? { name: 'submissions', params: { formType: props.formType }, query: route.query } : { name: 'submitFormsList' })

provide('formDef', formDef)

onMounted(async () => {
  await geoLocationStore.initialize()
})

</script>

<template>
  <div :class="{ 'lg:max-w-5xl': formKey }">
    <div
      v-if="formDef"
      class="border-b-0"
    >
      <div class="flex items-center mb-6 ">
        <router-link
          :to="backToTarget"
          class="text-xl font-bold inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left"></i>
          </icon-circle-box>
          <span :class="{ 'after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-1 after:left-0 after:bg-emerald-600 relative hidden lg:inline-block' : formType }">{{ gettext('Forms') }}</span>
        </router-link>
        <h5
          v-if="formType"
          class="text-xl font-bold inline-flex items-center whitespace-nowrap"
          :class="{ '!hidden lg:!inline-flex': formKey }"
        >
          <i
            class="las la-angle-right text-gray-400 mx-2"
            :class="{ '!hidden lg:!inline-block': !formKey, '!inline-block': formKey}"
          ></i>
          <span :class="{ 'after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block' : formKey }">{{ gettext('Active form') }}</span>
        </h5>
        <h5
          v-if="formKey"
          class="text-xl font-bold inline-flex items-center"
        >
          <i class="las la-angle-right text-gray-400 mx-2 !hidden lg:!inline-block"></i>
          {{ pgettext("form", formDef?.name) }}
        </h5>
      </div>
    </div>
    <router-view :form-name="formDef?.name" />
  </div>
</template>

<style scoped>
</style>
