import { OptionsType } from '../_types/components/forms/select-dropdown'

export const units: OptionsType = [
  {
    value: '1',
    label: 'm2'
  },
  {
    value: '2',
    label: 'm'
  },
  {
    value: '3',
    label: 'm3'
  },
  {
    value: '4',
    label: 'Number'
  },
  {
    value: '5',
    label: 'Depth Mete'
  },
  {
    value: '6',
    label: 'Package'
  },
  {
    value: '7',
    label: 'Km'
  },
  {
    value: '8',
    label: 'Sheet'
  },
  {
    value: '9',
    label: 'Box'
  }
]

export const zActivities: OptionsType = [
  {
    value: 2,
    label: 'Rehabilitation'
  },
  {
    value: 1,
    label: 'Construction'
  },
  {
    value: 5,
    label: 'Repair'
  },
  {
    value: 3,
    label: 'Extension'
  },
  {
    value: 6,
    label: 'Conservation (Maintenance)'
  },
  {
    value: 7,
    label: 'Supply'
  },
  {
    value: 4,
    label: 'Installation'
  }
]

export const sectors: OptionsType = [
  {
    value: '1',
    label: 'Health'
  },
  {
    value: '2',
    label: 'Water & Sanitation'
  },
  {
    value: '3',
    label: 'Education Culture & Sports'
  },
  {
    value: '4',
    label: 'Agriculture & Food Security'
  },
  {
    value: '5',
    label: 'Road, Bridge and Flood Control'
  },
  {
    value: '6',
    label: 'Social solidarity'
  },
  {
    value: '7',
    label: 'Other Sector'
  },
  {
    value: '8',
    label: 'UKL'
  }
]

export const subActivity2: OptionsType = [
  {
    value: 1,
    label: 'Suco Socialisation'
  },
  {
    value: 2,
    label: 'Aldea Socialisation'
  },
  {
    value: 4,
    label: 'CMT election at suco'
  },
  {
    value: 28,
    label: 'Accountability meeting 2'
  },
  {
    value: 11,
    label: 'Annual Grant Agreement Signed'
  },
  {
    value: 16,
    label: 'Project Implementation agreement signed'
  },
  {
    value: 17,
    label: 'Accountability meeting 1'
  },
  {
    value: 20,
    label: 'District socialization'
  },
  {
    value: 21,
    label: 'Sub-district socialization'
  },
  {
    value: 22,
    label: 'CMT nomination at aldeia'
  },
  {
    value: 23,
    label: 'Priority setting at aldeia'
  },
  {
    value: 24,
    label: 'Priority setting at suco'
  },
  {
    value: 40,
    label: 'Priority setting at aldeia-Women'
  }
]

export const subActivity1: OptionsType = [
  {
    value: 32,
    label: 'Module E -Proposal Writing'
  },
  {
    value: 41,
    label: 'Module UKL - UKL Verification Guideline'
  },
  {
    value: 31,
    label: 'Module C -Sf Orientation'
  },
  {
    value: 30,
    label: 'Module B -Womens Empowerment 1'
  },
  {
    value: 39,
    label: 'Module L -Monitoring O & M'
  },
  {
    value: 38,
    label: 'Module K -Financial Management 3'
  },
  {
    value: 29,
    label: 'Module A -Introduction & Planning'
  },
  {
    value: 37,
    label: 'Module J -Operations and Maintainance'
  },
  {
    value: 36,
    label: 'Module I -Monitoring & Accountability'
  },
  {
    value: 35,
    label: 'Module H -Financial Management 2'
  },
  {
    value: 34,
    label: 'Module G -Managing Construction 1'
  },
  {
    value: 3,
    label: 'Module D -Financial Management 1'
  },
  {
    value: 33,
    label: 'Module F -Womens Empowerment 2'
  }
]

export const complaintsMethod: OptionsType = [
  {
    value: 1,
    label: 'Monitoring'
  },
  {
    value: 2,
    label: 'Letter'
  },
  {
    value: 3,
    label: 'Personal Contract'
  },
  {
    value: 4,
    label: 'Social Media'
  },
  {
    value: 5,
    label: 'Other'
  }
]

export const complaintStatus: OptionsType = [
  {
    value: 0,
    label: 'Solved'
  },
  {
    value: 1,
    label: 'Ongoing'
  }
]

export const complaintsCategories: OptionsType = [
  {
    value: 1,
    label: 'Politic system, procedure, principle'
  },
  {
    value: 2,
    label: 'Construction'
  },
  {
    value: 3,
    label: 'Fraud and/or abuse of power'
  }
]

export const POM1_STATUS: OptionsType = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 2,
    label: 'Inactive'
  },
  {
    value: 3,
    label: 'Closed'
  }
]
