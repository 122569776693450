<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import DataTable from '../../components/commons/DataTable.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import { FormKitSchema, FormKit } from '@formkit/vue'
import { FormKitSchemaFormKit, FormKitSchemaNode } from '@formkit/core'
import type { Header, Item } from 'vue3-easy-data-table'
import { computed, ComputedRef, reactive, ref, Ref, onMounted, toRaw } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useSuppliersStore } from '../../stores/suppliers'
import { useI18NStore } from '../../stores/i18n'
import { useAppStore } from '../../stores/app'
import { useSukuProfileStore } from '../../stores/sukuprofile'
import { useLocationStore } from '../../stores/locations'
import { components } from '../../_api-services/openapi'
import { Fields } from '../../_formkit/templates/_locations'
import { useAuthStore } from '../../stores/auth'

const i18nstore = useI18NStore()
const appStore = useAppStore()
const supplierStore = useSuppliersStore()
const locationStore = useLocationStore()
const sukuProfileStore = useSukuProfileStore()
const authStore = useAuthStore()

const showCreateForm = ref(false)
const action = ref('create')

const headers: ComputedRef<Header[]> = computed(() => [
  { sortable: true, value: 'no', text: 'No.' },
  { sortable: true, value: 'name', text: 'Name supplier' },
  { sortable: true, value: 'director', text: 'Owner name' },
  { sortable: true, value: 'contact_number', text: 'Contact number(s)' },
  { sortable: true, value: 'email', text: 'Email' },
  { sortable: true, value: 'status', text: 'Status' },
  { value: 'view', text: 'View', class: 'text-center' }
])

const items: ComputedRef<Item[]> = computed(() => supplierStore.getSuppliers())

const geolocationStore = useGeoLocationStore()
const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)

const formSchema: Array<FormKitSchemaFormKit | FormKitSchemaNode> = [
  {
    $formkit: 'text',
    name: 'name',
    key: 'name',
    id: 'name',
    label: '$gettext("Name supplier")',
    validation: 'required'
  },
  {
    $formkit: 'text',
    name: 'director',
    key: 'director',
    id: 'director',
    label: '$gettext("Owner name")'
  },
  {
    $formkit: 'textarea',
    name: 'address',
    key: 'address',
    label: '$gettext("Address")',
    validation: 'required'
  },
  {
    $formkit: 'tel',
    name: 'contact_number',
    key: 'contact_number',
    validation: 'phoneNumberValidation|required',
    id: 'contact_number',
    label: '$gettext("Contact number")'
  },
  {
    $formkit: 'email',
    name: 'email',
    key: 'email',
    id: 'email',
    label: '$gettext("Email")'
  },
  {
    $formkit: 'select',
    name: 'supplytype',
    key: 'supplytype',
    id: 'supplytype',
    label: '$gettext("Material supplier type")',
    options: '$supplierTypeOptions',
    placeholder: '$gettext("Select material supplier type")'
  },
  {
    $formkit: 'select',
    name: 'location',
    key: 'location',
    id: 'location',
    label: '$gettext("Supplier location")',
    options: '$supplierLocationOptions',
    placeholder: '$gettext("Select supplier location")'
  },
  {
    $formkit: 'radio',
    name: 'status',
    key: 'status',
    id: 'status',
    label: '$gettext("Status")',
    value: true,
    options: '$statusOptions'
  }
]

const formSchemaData = reactive({
  /* Allows using `$gettext` and `$pgettext` in the forms */
  gettext: i18nstore.gettext,
  getLocations: () => {
    /* Provided parent values, return the options available */
    const zSucoId = sukuProfileStore.$state.activezSukuId
    const emptyOption = { label: '----' }
    if (typeof zSucoId === 'undefined') return []
    const location = locationStore.getByLevel(3).find(it => it.path.includes(zSucoId))

    const locations = locationStore.getLocations(...(location?.path ?? [])).map((opt) => { return { label: opt.name, value: opt.path.at(-1) } })
    return [emptyOption, ...locations]
  },
  statusOptions: computed(() => (
    [
      {
        label: i18nstore.gettext('Active'),
        value: true
      },
      {
        label: i18nstore.gettext('Inactive'),
        value: false
      }
    ]
  )),
  supplierLocationOptions: computed(() => (
    [
      {
        label: i18nstore.gettext('Internal'),
        value: 'I'
      },
      {
        label: i18nstore.gettext('External'),
        value: 'E'
      }
    ]
  )),
  supplierTypeOptions: computed(() => (
    [
      {
        label: i18nstore.gettext('Manufactured materials'),
        value: 'F'
      },
      {
        label: i18nstore.gettext('Non-manufactured materials (local)'),
        value: 'N'
      }
    ]
  ))
})

type SupplierSchemaIn = components['schemas']['SupplierSchemaIn']
type SupplierSchema = components['schemas']['SupplierSchema']
interface SupplierFormSchema {
  name: string
  director: string
  address: string
  contact_number: string
  email: string
  status: boolean
  suco: string
  id?: number
}

const values: Ref<Record<string, any>> = ref({
  name: '',
  director: '',
  address: '',
  contact_number: '',
  email: '',
  active: false,
  id: undefined
})

const submit = async (values: SupplierFormSchema) => {
  appStore.$state.loading = true
  const data: SupplierSchemaIn = {
    ...values,
    ...{
      active: values.status,
      suco: [],
      projects: []
    }
  }
  if (action.value === 'update') {
    if (typeof values.id === 'undefined') return
    data.id = values.id
  }
  await supplierStore.createSupplier(data)
  appStore.$state.loading = false
  showCreateForm.value = false
}

const initializeSupplier = async () => {
  appStore.$state.loading = true
  await supplierStore.initialize()
  appStore.$state.loading = false
}

onMounted(async () => {
  await initializeSupplier()
})

const viewMore = (item: any) => {
  const activeSupplier = supplierStore.getSupplier(item.view)
  if (typeof activeSupplier !== 'undefined') {
    values.value = {
      ...activeSupplier,
      ...{
        status: activeSupplier.active
      }
    }

    showCreateForm.value = true
    action.value = 'update'
  }
}
</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      <h1 class="text-2.5xl font-black mb-10">
        {{ gettext('List of Suku Suppliers') }}
      </h1>
      <DataTable
        :headers="headers"
        :items="items"
        has-delete-button
        filter-size="md"
        theme-color="dark"
        spacing="narrow"
        filter-placeholder="Search"
        filter-suffix-icon="la-search !text-xl text-gray-400"
        @view="viewMore"
      >
        <template
          v-if="authStore.can('ida.add_supplier')"
          #header-action
        >
          <basic-button
            @click="() => {
              showCreateForm = true
              action = 'create'
              values = {
                name: '',
                director: '',
                address: '',
                contact_number: '',
                email: '',
                active: false
              }
            }"
          >
            <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('Add') }}
          </basic-button>
        </template>
      </DataTable>
    </content-section>
    <modal-box
      v-if="showCreateForm"
      class="text-center"
      size="lg"
      spacing="md"
    >
      <template #header>
        <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
          <i class="text-gray-400 mr-3 text-2xl las la-edit"></i>
          {{ gettext('Supplier details') }}
        </h4>
      </template>
      <button
        class="fixed right-6 top-6 text-lg w-[28px] h-[28px] rounded-full bg-emerald-600 text-white"
        @click="() => showCreateForm = false"
      >
        <i class="las la-times"></i>
      </button>
      <FormKit
        v-model="values"
        :disabled="!authStore.canAny([
          'ida.add_supplier',
          'ida.change_supplier',
        ])"
        type="form"
        :actions="false"
        :submit-label="gettext(action === 'create' ? 'Submit' : 'Update')"
        @submit="submit"
      >
        <div class="text-left">
          <FormKitSchema
            :schema="formSchema"
            :data="formSchemaData"
          />
        </div>
        <FormKit
          v-if="authStore.canAny([
            'ida.add_supplier',
            'ida.change_supplier',
          ])"
          type="submit"
          outer-class="max-w-[254px] mx-auto mt-8"
          prefix-icon="search"
        >
          <i class="las la-check text-2xl mr-3"></i>
          {{ gettext('Save supplier') }}
        </FormKit>
        <basic-button
          theme="text"
          class="mt-2"
          @click="() => showCreateForm = false"
        >
          {{ gettext('Cancel') }}
        </basic-button>
      </FormKit>
    </modal-box>
  </base-layout>
</template>
