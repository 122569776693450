import { FORM_TYPES } from '../../_constants/common.constant'
import { Fields } from '../templates/_locations'
import type { Header } from 'vue3-easy-data-table'

export const locationHeader: Header[] = [{ sortable: true, value: Fields.district, text: 'Municipality' },
  { sortable: true, value: Fields.administrative_post, text: 'Administrative Post' },
  { sortable: true, value: Fields.suco, text: 'Suco', width: 100 }]

export const headersForm: Record<string, Header[]> = {
  [FORM_TYPES.SF11]: [
    ...locationHeader,
    ...[{ sortable: true, value: 'date', text: 'Data' }]
  ]
}
