import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { permissionCode, useAuthStore } from './stores/auth'
import { useFormsStore } from './stores/forms'
import { useEmbedsStore } from './stores/embeds'
import { useSubmissionsStore } from './stores/submissions'
import { useLocationStore } from './stores/locations'
import { useGeoLocationStore } from './stores/geolocation'
import { useSukuProfileStore } from './stores/sukuprofile'
import { useOptionsStore } from './stores/options'
import { useUsersStore } from './stores/users'
import { useGroupStore } from './stores/groups'

import Landing from './pages/Landing.vue'
import Login from './pages/Login.vue'
import Profile from './pages/Profile.vue'
import Review from './pages/Review.vue'
import EmbedDashboard from './pages/EmbedDashboard.vue'
import Help from './pages/Help.vue'
import ForgotPassword from './pages/ForgotPassword.vue'
import ResetPasswordInvalid from './pages/ResetPasswordInvalid.vue'
import ResetPassword from './pages/ResetPassword.vue'
import Users from './pages/Users.vue'
import User from './pages/User.vue'
import AddUser from './pages/AddUser.vue'
import Groups from './pages/Groups.vue'
import Group from './pages/Group.vue'
import GroupAdd from './pages/GroupAdd.vue'

import IdaForms from './pages/IdaForms.vue'
import IdaFormsEdit from './pages/IdaFormsEdit.vue'
import IdaNodeEdit from './pages/IdaNodeEdit.vue'
import AddIdaForm from './pages/AddIdaForm.vue'

import SubmitData from './pages/SubmitData.vue'
import SubmissionFormViewWrapper from './components/forms/SubmissionFormViewWrapper.vue'
import SubmissionFormListWrapper from './components/forms/SubmissionFormListWrapper.vue'
import SubmissionList from './pages/submit/SubmissionList.vue'
import SubmissionForm from './components/forms/SubmissionForm.vue'

// forms Page
import FormList from './pages/forms/FormList.vue'
import FormDetail from './pages/forms/FormDetail.vue'

import SukuProfilesDetail from './pages/sukumanager/SukuProfileDetail.vue'
import SukuMap from './pages/sukumanager/SukuMap.vue'
import SukuSnapshot from './pages/sukumanager/SukuSnapshot.vue'
import SukuPriorities from './pages/sukumanager/SukuPriorities.vue'
import SukuSupplier from './pages/sukumanager/SukuSupplier.vue'
import SukuBudget from './pages/sukumanager/SukuBudget.vue'
import SukuComplaints from './pages/sukumanager/SukuComplaints.vue'
import SukuComplaintDetails from './pages/sukumanager/SukuComplaintDetails.vue'
import SukuTeams from './pages/sukumanager/SukuTeams.vue'
import SukuActivities from './pages/sukumanager/SukuActivities.vue'

import Options from './pages/options/Options.vue'
import OptionEdit from './pages/options/OptionEdit.vue'

import ProjectsIndex from './pages/projects/Index.vue'
import ProjectDetail from './pages/projects/ProjectDetail.vue'

import AdminIndex from './pages/admin/Index.vue'

import ComplaintsIndex from './pages/complaints/ComplaintsIndex.vue'
import ComplaintDetail from './pages/complaints/ComplaintDetail.vue'

import { useI18NStore } from './stores/i18n'
import { fetchLocationAndStore } from './_locations/idb_locations'
import { FORM_TYPES, SUBMISSIONS_STATUS_VALUE } from './_constants/common.constant'
import { SUKU_BUDGET_TYPE } from './_constants/sukumanager.constant'
import { checkNavigationGuard } from './_helpers/navigationGuard.helpers'
declare module 'vue-router' {
  interface RouteMeta {
    // must be declared by every route
    requiresAuth?: boolean
    requiresOneOfPerms?: permissionCode[]
  }
}

/**
 * Void-calling promises here should result in no delay
 * while in the background we load and update translations.
 */
async function ensureTranslations () {
  const i18nStore = useI18NStore()
  await i18nStore.get()
}

async function ensureforms (): Promise<void> {
  const formsStore = useFormsStore()
  await formsStore.initialize()
}

async function ensureLocations (): Promise<void> {
  const locationStore = useLocationStore()
  await locationStore.initialize()
}

async function ensureOptions (): Promise<void> {
  const optionsStore = useOptionsStore()
  await optionsStore.initialize()
}

async function ensureUsers (): Promise<void> {
  const usersStore = useUsersStore()
  await usersStore.initialize()
}

async function ensureGroups (): Promise<void> {
  const groupStore = useGroupStore()
  await groupStore.initialize()
}

async function ensureFormStore (): Promise<void> {
  const formStore = useFormsStore()
  await formStore.initialize()
}

/**
 * get history list of a submission
 * @key formkey
 */
async function ensureSubmissionHistory (to: RouteLocationNormalized): Promise<void> {
  const formKey = to.params.formKey as string
  const submissionStore = useSubmissionsStore()
  await submissionStore.setActiveSubmission(formKey)
  await submissionStore.getSubmissionHistory(formKey)
}

async function ensureSubmissions (to: RouteLocationNormalized): Promise<void> {
  const formsStore = useFormsStore()
  // When going back to the list, remove the currently active submission
  // This shows the "Add new" button and hides the "List" which should be
  // the current page
  formsStore.unsetActiveSubmission()
  await formsStore.ensureSubmissionsOfType(to.params.formType as string)
}

async function ensureSubmission (to: RouteLocationNormalized): Promise<void> {
  const formsStore = useFormsStore()
  const formType = to.params.formType as string
  const formKey = to.params.formKey as string
  if (formKey === 'new') {
    await formsStore.setActiveSubmissionNew(formType)
  } else {
    // await getSubmissionFiles(to)
    await formsStore.setActiveSubmission(formKey)
    await formsStore.setActiveSubmissionFiles(formKey)
  }
}

async function ensureEmbeds (to: RouteLocationNormalized): Promise<void> {
  const embedsStore = useEmbedsStore()
  await embedsStore.initialize()
}

const ensureSubmissionsApi = async (): Promise<void> => {
  const submissionsStore = useSubmissionsStore()
  await submissionsStore.initialize(true)
}

// get TF611 submissions
const ensureTf611SubmissionsApi = async (): Promise<void> => {
  const submissionsStore = useSubmissionsStore()
  await submissionsStore.initialize(true, { form_type: FORM_TYPES.TF611, status: SUBMISSIONS_STATUS_VALUE.verified })
}

// get TF611 data
const ensureTf611SubmissionsDataOnly = async (to: RouteLocationNormalized): Promise<void> => {
  if (to?.params?.formType === FORM_TYPES.TF1321 || to?.params?.formType === FORM_TYPES.SF62 || to?.params?.formType === FORM_TYPES.SF42 || to?.params?.formType === FORM_TYPES.CFM12FF12 || to?.params?.formType === FORM_TYPES.CFM2FF4) {
    await ensureTf611SubmissionsApi()
  }
}

const resetHistorySubmission = async (): Promise<void> => {
  const submissionsStore = useSubmissionsStore()
  submissionsStore.unSetActiveRevision()
}

const routes: RouteRecordRaw[] = [
  { path: '/', name: 'home', component: Landing, meta: { requiresAuth: true } },
  { path: '/login', name: 'login', component: Login },
  { path: '/help', name: 'help', component: Help },
  { path: '/forgot-password', name: 'forgotPassword', component: ForgotPassword },
  { path: '/reset-password/invalid/', name: 'resetPasswordInvalid', component: ResetPasswordInvalid },
  { path: '/reset-password/:uidb64/:token', name: 'resetPassword', component: ResetPassword, props: true },
  { path: '/profile', name: 'profile', component: Profile, meta: { requiresAuth: true } },
  {
    path: '/suku-manager/',
    name: 'sukuManager',
    meta: { requiresAuth: true, requiresOneOfPerms: ['pnds_data.view_zsuco'] },
    children: [
      {
        path: '/suku-manager/',
        name: 'sukuMap',
        component: SukuMap
      },
      {
        path: '/suku-manager/:id/',
        name: 'sukuSnapshot',
        component: SukuSnapshot
      },
      {
        path: '/suku-manager/:id/:section(profile)/',
        name: 'sukuProfile',
        component: SukuProfilesDetail
      },
      {
        path: '/suku-manager/:id/:section(priorities)/',
        name: 'sukuPriorities',
        meta: {
          requiresOneOfPerms: [
            'pnds_data.add_sucopriorities',
            'pnds_data.view_sucopriorities',
            'pnds_data.change_sucopriorities'
          ]
        },
        component: SukuPriorities,
        beforeEnter: [ensureLocations]
      },
      {
        path: '/suku-manager/:id/:section(suppliers)/',
        name: 'sukuSuppliers',
        component: SukuSupplier,
        beforeEnter: [ensureLocations, ensureSubmissions]
      },
      {
        path: '/suku-manager/:id/:section(complaints)/',
        name: 'sukuComplaints',
        component: SukuComplaints,
        meta: {
          requiresOneOfPerms: [
            'ida.view_complaint',
            'ida.change_complaint'
          ]
        }
      },
      {
        path: '/suku-manager/:id/:section(complaints)/:complaintId',
        name: 'sukuComplaintDetails',
        component: SukuComplaintDetails,
        meta: {
          requiresOneOfPerms: [
            'ida.view_complaint',
            'ida.change_complaint'
          ]
        }
      },
      {
        path: `/suku-manager/:id/:section(${SUKU_BUDGET_TYPE.operational}|${SUKU_BUDGET_TYPE.infrastructure})/`,
        name: 'sukuBudget',
        component: SukuBudget,
        props: true,
        meta: {
          requiresOneOfPerms: [
            'ida_forms.view_pom_1',
            'ida_forms.change_pom_1',
            'ida_forms.add_pom_1'
          ]
        },
        beforeEnter: [ensureLocations]
      },
      {
        path: '/suku-manager/:id/:section(management-teams)',
        name: 'sukuManagementTeams',
        component: SukuTeams,
        props: true
      },
      {
        path: '/suku-manager/:id/:section(kpa)',
        name: 'sukuKpaTeams',
        component: SukuTeams,
        props: true
      },
      {
        path: '/suku-manager/:id/:section(suku-facilitators)',
        name: 'sukuSukuFacilitators',
        component: SukuTeams,
        props: true
      },
      {
        path: '/suku-manager/:id/:section(activities)',
        name: 'sukuActivities',
        component: SukuActivities,
        props: true
      }
    ],
    beforeEnter: async (to: RouteLocationNormalized) => {
      const geolocationStore = useGeoLocationStore()
      const sukuProfileStore = useSukuProfileStore()
      const locationStore = useLocationStore()

      await fetchLocationAndStore()
      await geolocationStore.getGeoLocationList()
      await locationStore.fetchZsucoToLocation()
      const sukuId = to?.params?.id
      if (typeof sukuId !== 'undefined') {
        await geolocationStore.setActiveGeoLocation(Number(sukuId))
        await sukuProfileStore.setActiveZsucoId()
      }
    }
  },
  {
    path: '/form/',
    name: 'form',
    meta: { requiresAuth: true },
    children: [
      {
        path: '/form/',
        name: 'forms',
        component: FormList,
        meta: {
          formNavigation: false
        },
        beforeEnter: [ensureforms]
      },
      {
        path: '/form/:formKey/',
        name: 'formDetail',
        component: FormDetail,
        props: true,
        beforeEnter: [ensureLocations, ensureforms, ensureSubmissionHistory, resetHistorySubmission]
      }
    ],
    beforeEnter: [ensureSubmissionsApi]
  },
  {
    path: '/submit/',
    component: SubmitData,
    meta: { requiresAuth: true },
    children: [
      { path: '/submit/', name: 'submitFormsList', component: SubmissionFormListWrapper },

      {
        path: '/submit/:formType/',
        component: SubmissionFormViewWrapper,
        props: true,
        children: [
          {
            path: '/submit/:formType/',
            name: 'submissions',
            component: SubmissionList,
            props: true,
            beforeEnter: [ensureSubmissions, ensureFormStore]
          },
          {
            path: '/submit/:formType/:formKey',
            name: 'submission',
            component: SubmissionForm,
            props: true,
            beforeEnter: [ensureLocations, ensureSubmissions, ensureSubmission, ensureTf611SubmissionsDataOnly, ensureFormStore]
          }
        ]
      }
    ],
    beforeEnter: [ensureFormStore]
  },
  { path: '/review', name: 'review', component: Review, meta: { requiresAuth: true } },
  { path: '/manage/user/add', name: 'add_user', component: AddUser, meta: { requiresOneOfPerms: ['ida.add_idauser'] } },
  { path: '/manage/user', name: 'users', component: Users, meta: { requiresOneOfPerms: ['ida.view_idauser'] }, beforeEnter: [ensureUsers] },
  { path: '/manage/user/:username', name: 'user', component: User, meta: { requiresOneOfPerms: ['ida.view_idauser'] }, props: true, beforeEnter: [ensureUsers, ensureGroups] },

  { path: '/manage/group', name: 'groups', component: Groups, meta: { requiresOneOfPerms: ['auth.view_group'] } },
  { path: '/manage/add_group', name: 'add_group', component: GroupAdd, meta: { requiresOneOfPerms: ['auth.add_group'] } },
  { path: '/manage/group/:id', name: 'group', component: Group, meta: { requiresOneOfPerms: ['auth.view_group'] }, props: true },

  { path: '/manage/options/:group?', name: 'options', component: Options, meta: { requiresOneOfPerms: ['ida_options.change_activity'] }, props: true, beforeEnter: [ensureOptions] },
  { path: '/manage/options/:group/add', name: 'option_add', component: OptionEdit, meta: { requiresOneOfPerms: ['ida_options.change_activity'] }, props: true, beforeEnter: [ensureOptions] },
  { path: '/manage/options/:group/:value', name: 'option_edit', component: OptionEdit, meta: { requiresOneOfPerms: ['ida_options.change_activity'] }, props: true, beforeEnter: [ensureOptions] },

  { path: '/manage/idaforms', name: 'idaforms', component: IdaForms, meta: { requiresOneOfPerms: ['formkit_ninja.change_formkitschemanode'], requiresAuth: true } },
  { path: '/manage/idaforms/:group/:childPath*', name: 'idaformsedit', component: IdaFormsEdit, props: true, meta: { requiresOneOfPerms: ['formkit_ninja.change_formkitschemanode'], requiresAuth: true } },
  { path: '/manage/idanode/:node', name: 'idanodeedit', component: IdaNodeEdit, props: true, meta: { requiresOneOfPerms: ['formkit_ninja.change_formkitschemanode'], requiresAuth: true } },
  { path: '/manage/addidaform', name: 'addidaform', component: AddIdaForm },

  {
    path: '/:section(report|dashboard)/:id(\\d+)/',
    name: 'embed',
    component: EmbedDashboard,
    meta: { requiresAuth: true, requiresOneOfPerms: ['metabase.view_mbreportdashboard'] },
    props: true,
    beforeEnter: [ensureEmbeds]
  },
  {
    path: '/projects/',
    meta: {
      requiresAuth: true,
      requiresOneOfPerms: ['ida.view_project', 'ida.change_project']
    },
    children: [
      {
        path: '/projects/',
        name: 'projectsIndex',
        component: ProjectsIndex,
        props: true
      },
      {
        path: '/projects/:projectId',
        name: 'projectsDetail',
        component: ProjectDetail,
        props: true,
        beforeEnter: [
          async (to: RouteLocationNormalized) => {
            const locationStore = useLocationStore()
            const geolocationStore = useGeoLocationStore()
            await locationStore.fetchZsucoToLocation()
            await geolocationStore.getGeoLocationList()
          }
        ]
      }
    ],
    beforeEnter: [ensureLocations]
  },
  {
    path: '/manage/submission_history/',
    name: 'submissionHistory',
    meta: {
      requiresAuth: true,
      requiresOneOfPerms: [
        'form_submission.read_submission_history'
      ]
    },
    component: AdminIndex
  },
  {
    path: '/complaints/',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/complaints/',
        name: 'complaintsIndex',
        component: ComplaintsIndex
      },
      {
        path: '/complaints/:id',
        name: 'complaintDetails',
        props: true,
        component: ComplaintDetail
      }
    ]
  }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
  async scrollBehavior () {
    return await new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }

})

router.beforeEach(async (to, from) => {
  const requiresAuth = to.meta.requiresAuth as boolean
  const requiresOneOfPerms = to.meta.requiresOneOfPerms
  const authStore = useAuthStore()
  const geolocationStore = useGeoLocationStore()

  await authStore.initialize()
  void authStore.initializeUserMunicipality()
  const isAuthenticated: boolean = authStore.isAuthenticated
  if (to?.name === 'login' && isAuthenticated) {
    return {
      path: '/'
    }
  }
  if (to?.name === 'sukuProfileDetail' && geolocationStore.activeGeolocation === undefined) {
    return {
      path: '/suku-profiles/'
    }
  }
  if (requiresAuth && (!isAuthenticated)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath }
    }
  }

  if (requiresOneOfPerms !== undefined && !authStore.canAny(requiresOneOfPerms)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    console.warn('User does not have required permissions to access this page')
    console.warn('Required permissions:', requiresOneOfPerms)
    console.warn('User permissions:', authStore.user?.perms)

    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath }
    }
  }

  /**
   * perform navigation guard
   * if user doesn't have permission to specific page it will redirect user to home page
   */
  if (isAuthenticated) {
    const navigationGuard = checkNavigationGuard(to.path)
    if (!navigationGuard) {
      return {
        path: '/'
      }
    }
  }
  /**
   * adding a class to the body element, the class will be based on the route name. the format will be `route-{route name}`
   * will be used to hide fields in the submission form.
   */
  if (to?.name !== undefined && to?.name !== null) {
    document.body.className = ''
    document.body.classList.add(`route-${to.name as string}`)
  }

  void ensureTranslations()
})
