import { FormKitMessageMeta, FormKitNode } from '@formkit/core'
import { useI18NStore } from '../stores/i18n'

/**
 * A list of input 'name' instances for a validation rule
 * In current implementation, this is delimited by a '+' symbol
 */
type listOfInputs = string

/**
 * This needs some adjustment, but roughly tries to
 * determine whether a node is valid
 */
function isNode (node: unknown): node is FormKitNode {
  if (typeof node === 'undefined') return false
  return Object.prototype.hasOwnProperty.call(node, 'value')
}

/**
 * Try to extract node value, otherwise return a default value
 */
function getValueOfNode (node: FormKitNode, default_ = 0, isDecimal = false) {
  if (typeof node.value === 'number') return node.value
  if (typeof node.value !== 'string') return default_
  const valueOfNode = isDecimal ? parseFloat(node.value) : parseInt(node.value, 10)
  if (isNaN(valueOfNode)) return default_
  return valueOfNode
}

/**
 * Adds 'number-type' node values together
 */
function getSumOfNodes (nodes: FormKitNode[]) {
  return nodes.reduce(
    (accumulator, otherNode) => accumulator + getValueOfNode(otherNode),
    0
  )
}

/**
 * Extract FormKit nodes from a separated list
 * of names.
 */
function stringToNodes (node: FormKitNode, instring: string, sep = '+'): FormKitNode[] {
  const inputNodes = instring.split('+')
  return inputNodes.map((n) => node.at(n)).filter((it) => isNode(it)) as FormKitNode[]
}

const lessThanSum = function (node: FormKitNode, name: listOfInputs) {
  const targetValue = getSumOfNodes(stringToNodes(node, name))
  node.props.attrs.min = targetValue - 1
  return getValueOfNode(node) < targetValue
}

const greaterThanSum = function (node: FormKitNode, name: listOfInputs) {
  const targetValue = getSumOfNodes(stringToNodes(node, name))
  node.props.attrs.min = targetValue + 1
  return getValueOfNode(node) > targetValue
}

const lessThanOrEqualSum = function (node: FormKitNode, name: listOfInputs) {
  const targetValue = getSumOfNodes(stringToNodes(node, name))
  node.props.attrs.max = targetValue
  return getValueOfNode(node) <= targetValue
}

const greaterThanOrEqualSum = function (node: FormKitNode, name: listOfInputs) {
  const targetValue = getSumOfNodes(stringToNodes(node, name))
  node.props.attrs.min = targetValue
  return getValueOfNode(node) >= targetValue
}

/**
 * This validator updates the field to ensure its value is always at least equal
 * to the sum of the target fields
 */
const alwaysGreaterThanOrEqualSum = async function (node: FormKitNode, name: listOfInputs) {
  const targetValue = getSumOfNodes(stringToNodes(node, name))
  if (getValueOfNode(node) < targetValue) await node.input(targetValue)
  return true
}

const shouldNotAcceptNegativeValue = async function (node: FormKitNode, name: listOfInputs) {
  const value = getValueOfNode(node, 0, true).toString()
  await node.input(value.replace(/-/g, ''))
  return true
}

const phoneNumberValidation = async function (node: FormKitNode) {
  /**
   * This code checks if the first character of the string is a '+'. If it is, it keeps
   * the '+' and removes any non-numeric characters from the rest of the string. If the
   * first character is not a '+', it removes any non-numeric characters from the entire string.
   */
  let value = node.value as string
  value = value.startsWith('+') ? '+' + value.slice(1).replace(/[^0-9]/g, '') : value.replace(/[^0-9]/g, '')
  await node.input(value)
  return true
}

export {
  lessThanSum,
  greaterThanSum,
  lessThanOrEqualSum,
  greaterThanOrEqualSum,
  alwaysGreaterThanOrEqualSum,
  shouldNotAcceptNegativeValue,
  phoneNumberValidation
}
