<script setup lang="ts">
import { onUpdated, ref, Ref } from 'vue'

const props = withDefaults(defineProps<{
  progress: number
}>(), {
  progress: 0
})

const loadingPercentage: Ref<number> = ref(0)

const calculatePercentage = () => {
  loadingPercentage.value = 165 - ((Number(props.progress) / 100) * 165)
}

calculatePercentage()

onUpdated(() => {
  calculatePercentage()
})
</script>
<template>
  <div>
    <svg
      class="mb-2.5 mx-auto"
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        stroke="#F0EEE3"
        fill="none"
        stroke-width="8"
        stroke-linecap="round"
        cx="30"
        cy="30"
        r="25"
      />
      <circle
        class="loading-path"
        fill="none"
        stroke-width="8"
        :stroke="progress === 0 ? 'none' : '#00A591'"
        stroke-linecap="round"
        cx="30"
        cy="30"
        r="25"
        :style="{'stroke-dashoffset': loadingPercentage}"
      />
    </svg>
  </div>
</template>

<style scoped>
.loading-path {
  @apply transform -rotate-90;
  stroke-dasharray: 150;
  transform-origin: center;
}
</style>
