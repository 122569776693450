<script setup lang="ts">
import { useOptionsStore } from '../../stores/options'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import { FormKitSchema } from '@formkit/vue'
import { FormKitSchemaFormKit, FormKitSchemaNode, FormKitNode } from '@formkit/core'
import { MonthlyReportFormKitDataSchema, InfrastructureFundMonthlyReportSchema } from '../../_types/sukubudget'
import { InfrastructureMonthlyReportBalanceSchema } from '../../stores/sukubudget'
import { components } from '../../_api-services/openapi'

type FF12RepeaterInfrastructureFundContext = components['schemas']['Cfm_12_ff_12RepeaterinfrastructurefundSchema']

interface FF12RepeaterInfrastructureFundContextExtends extends FF12RepeaterInfrastructureFundContext {
  [key: string]: string | number | undefined
}

const emit = defineEmits <{(e: 'input', value: string, node: FormKitNode): void}>()

defineProps<{
    formkitSchemaData: MonthlyReportFormKitDataSchema
    infrastructureMontlyReportBalance: InfrastructureMonthlyReportBalanceSchema
    infrastructureFundsData: InfrastructureFundMonthlyReportSchema[]
    totalBalanceThisYear: number
    totalDifferenceBalance: number
    totalInfrastructureExpendituresThisMonth: number
    totalInfrastructureExpendituresCummulative: number
    totalInfrastructureExpendituresBalance: number
    totalBudget: number
}>()

const optionsStore = useOptionsStore()

function onInput (val: string, node: FormKitNode) {
  emit('input', val, node)
}

const infrastructureReportSchema: Array<FormKitSchemaFormKit | FormKitSchemaNode> = [
  {
    $el: 'tbody',
    children: [
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1004 - Subsidy Infrastructure PNDS")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'infrastructure_receipts_pnds_infrastructure_subsidy',
                id: 'infrastructure_receipts_pnds_infrastructure_subsidy',
                key: 'infrastructure_receipts_pnds_infrastructure_subsidy',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.infrastructure_receipts_pnds_infrastructure_subsidy)+$parseToFloat($get(infrastructure_receipts_pnds_infrastructure_subsidy).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.infrastructure_receipts_pnds_infrastructure_subsidy)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      },
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1005 - Last Year Balance")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'infrastructure_receipts_last_year_balance',
                id: 'infrastructure_receipts_last_year_balance',
                key: 'infrastructure_receipts_last_year_balance',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.infrastructure_receipts_last_year_balance)+$parseToFloat($get(infrastructure_receipts_last_year_balance).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.last_year_balance)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      },

      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1006 - Interest (USD)")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'infrastructure_receipts_interest',
                id: 'infrastructure_receipts_interest',
                key: 'infrastructure_receipts_interest',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.infrastructure_receipts_interest)+$parseToFloat($get(infrastructure_receipts_interest).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'p',
                attrs: {
                  class: 'font-semibold text-sm'
                },
                children: ['$gettext("Not applicable")']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      },
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1007 - Others")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'infrastructure_receipts_others',
                id: 'infrastructure_receipts_others',
                key: 'infrastructure_receipts_others',
                outerClass: '!mb-0',
                value: '0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.infrastructure_receipts_others)+$parseToFloat($get(infrastructure_receipts_others).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'p',
                attrs: {
                  class: 'font-semibold text-sm'
                },
                children: ['$gettext("Not applicable")']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      }
    ]
  },
  {
    $el: 'tfoot',
    children: [
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            attrs: {
              class: 'font-semibold'
            },
            children: ['$gettext("Total infrastructure receipts (USD)")']
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($get(infrastructure_receipts_pnds_infrastructure_subsidy).value)+$parseToFloat($get(infrastructure_receipts_last_year_balance).value)+$parseToFloat($get(infrastructure_receipts_interest).value)+$parseToFloat($get(infrastructure_receipts_others).value))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($totalData.totalInfrastructureCummulative)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.total_infrastructure_receipts)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      }
    ]
  }
]
</script>
<template>
  <h2 class="text-1.5xl font-bold mt-9">
    <i class="las la-file-invoice-dollar text-gray-400 mr-2"></i>
    {{ gettext('Infrastructure receipts') }}
  </h2>
  <table class="table table-primary table-primary--no-gap table-primary-narrow w-full text-left mt-6">
    <thead>
      <tr>
        <th class="w-[320px] min-w-[320px]">
          {{ gettext('Item') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('This month') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('Cummulative') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('Budget') }}
        </th>
        <th class="w-[120px] min-w-[120px] !border-r-0"></th>
        <th class="w-[120px] min-w-[120px] !border-r-0"></th>
      </tr>
    </thead>
    <FormKitSchema
      :schema="infrastructureReportSchema"
      :data="formkitSchemaData"
    />
  </table>

  <h2 class="text-1.5xl font-bold mt-9">
    <i class="las la-money-check-alt text-gray-400 mr-2"></i>
    {{ gettext('Infrastructure expenditures') }}
  </h2>

  <table class="table table-primary table-primary--no-gap table-primary-narrow w-full text-left mt-6">
    <thead>
      <tr>
        <th class="w-[320px] min-w-[320px]">
          {{ gettext('Item') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('This month') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('Cummulative') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('Budget') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('Balance') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('% Spent') }}
        </th>
      </tr>
    </thead>
    <tbody
      v-for="(fund, index) in infrastructureFundsData"
      :key="index"
    >
      <tr>
        <td
          class="font-semibold !py-2"
          colspan="6"
        >
          {{ optionsStore.getLabel('project', fund.project, '-') }}
        </td>
      </tr>
      <tr>
        <td class="font-semibold">
          {{ gettext('3000 - Materials') }}
        </td>
        <td>
          <FormKit
            :id="`materials_3000_index_${index}_${fund.project}`"
            outer-class="!mb-0"
            type="currency"
            :name="`materials_3000_index_${index}_${fund.project}`"
            :value="(fund?.materials_3000 ?? 0).toString()"
            data-field="materials_3000"
            :data-index="index"
            :data-project_name="fund.project"
            @input="onInput"
          />
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(fund?.materials3000cummulative, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(fund?.budgetMaterial3000, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(fund?.balanceMaterials3000 ?? 0, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ fund?.spentMaterials3000 }}%
          </div>
        </td>
      </tr>
      <tr>
        <td class="font-semibold">
          {{ gettext('3001 - Labour Incentive') }}
        </td>
        <td>
          <FormKit
            :id="`labour_incentive_3001_index_${index}_${fund.project}`"
            outer-class="!mb-0"
            type="currency"
            :name="`labour_incentive_3001_index_${index}_${fund.project}`"
            :value="(fund?.labour_incentive_3001 ?? 0).toString()"
            data-field="labour_incentive_3001"
            :data-index="index"
            :data-project_name="fund.project"
            @input="onInput"
          />
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(fund?.labourIncentive3001cummulative, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(fund.budgetLabourIncentive3001, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(fund?.balanceLabourIncentive3001 ?? 0, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ fund?.spentLabourIncentive ?? 0 }}%
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="font-semibold">
          {{ gettext('Total infrastructure expenditures (USD)') }}
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(totalInfrastructureExpendituresThisMonth, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(totalInfrastructureExpendituresCummulative, 'decimal') }}
          </div>
        </td>
        <td>
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(totalBudget, 'decimal') }}
          </div>
        </td>
        <td class="!border-r-0">
          <div class="border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50">
            {{ formatCurrencyWithDollar(totalInfrastructureExpendituresBalance, 'decimal') }}
          </div>
        </td>
        <td></td>
      </tr>
    </tfoot>
  </table>

  <h2 class="text-1.5xl font-bold mt-9">
    <i class="las la-balance-scale text-gray-400 mr-2"></i>
    {{ gettext('Infrastructure fund balance this year') }}
  </h2>

  <table class="table table-secondary w-full text-left mt-6 max-w-[60%] text-2sm">
    <thead>
      <tr>
        <th class="w-[320px] min-w-[320px]">
          {{ gettext('Infrastructure fund balance for this year') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          <strong class="text-xl">{{ formatCurrencyWithDollar(totalBalanceThisYear) }}</strong>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          colspan="2"
          class="font-black"
        >
          {{ gettext('All infrastructure fund balance') }}
        </td>
      </tr>
    </tbody>
    <tbody
      v-for="(item, index) in infrastructureMontlyReportBalance.balance"
      :key="index"
    >
      <tr>
        <td
          class="font-bold"
          colspan="2"
        >
          {{ optionsStore.getLabel('cycle', index) }}
        </td>
      </tr>
      <tr>
        <td>{{ gettext('Cash in hand') }}</td>
        <td>
          {{ formatCurrencyWithDollar(item.cash) }}
        </td>
      </tr>
      <tr>
        <td>{{ gettext('Bank balance') }}</td>
        <td>
          {{ formatCurrencyWithDollar(item.bank) }}
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr>
        <td
          class="font-black !bg-zinc-400 !border-r-0 !border-l-zinc-450 !border-b !border-b-zinc-450"
        >
          {{ gettext('Total infrastructure balance (cash & bank)') }}
        </td>
        <td class="font-semibold !bg-zinc-400 !border-r-zinc-450 !border-b !border-b-zinc-450">
          {{ formatCurrencyWithDollar(infrastructureMontlyReportBalance.total_balance) }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td
          class="font-semibold !bg-red-400/30 !border-r-red-300"
        >
          {{ gettext('Difference') }}
        </td>
        <td class="text-base font-semibold !bg-red-400/30 !border-l-red-300">
          <p class="text-xs text-pink-700">
            {{ gettext('ALLOCATION OVER') }}
          </p>
          <h4 class="text-xl">
            {{ formatCurrencyWithDollar(totalDifferenceBalance) }}
          </h4>
        </td>
      </tr>
    </tfoot>
  </table>
</template>
