<script setup lang="ts">
import { ref, computed } from 'vue'

const showPopover = ref(false)
const button = ref()
const contentTop = computed(() => (button.value.clientHeight ?? 0) + 20)

</script>

<template>
  <div
    v-click-outside="() => showPopover = false"
    class="relative"
  >
    <button
      ref="button"
      class="w-full"
      @click="showPopover = !showPopover"
    >
      <span v-show="!showPopover || (typeof $slots.toggled === 'undefined')">
        <slot
          name="toggle"
        ></slot>
      </span>
      <span v-show="showPopover && (typeof $slots.toggled !== 'undefined')">
        <slot
          name="toggled"
        ></slot>
      </span>
    </button>
    <transition
      name="fade"
      :duration="150"
    >
      <div
        v-if="showPopover"
        class="absolute shadow-lg2 z-[1000] pt-7 bg-white left-1/2 transform -translate-x-1/2 rounded-2.5xl w-max after:content-[` `] after:inline-block after:w-3 after:h-3 after:bg-white after:absolute after:transform after:rotate-45 after:-top-[2px] after:left-0 after:right-0 after:mx-auto"
        :style="{ 'top': contentTop + 'px' }"
      >
        <div class="px-[22px] pb-[17px]">
          <slot></slot>
        </div>
        <div
          v-if="typeof $slots.footer !== 'undefined'"
          class="pb-[17px] px-[22px] pt-3.5 border-t border-t-light"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
