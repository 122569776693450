<script setup lang="ts">
import { SectorColors, ColorList } from '../../_types/colours'
import { useOptionsStore } from '../../stores/options'
import { computed, ComputedRef } from 'vue'
import { useI18NStore } from '../../stores/i18n'
import MapPin from '../icons/MapPin.vue'

const optionsStore = useOptionsStore()
const i18nstore = useI18NStore()
const props = defineProps<{
  markerColors?: number[]
}>()

const markers: ComputedRef<Array<{label: string, fill: string}>> = computed(() => {
  return props.markerColors?.map(it => ({
    label: optionsStore.getLabel('sector', it) ?? i18nstore.gettext('Project'),
    fill: SectorColors.get(it) ?? ColorList.MapMarkerBlue
  })) ?? []
})
</script>
<template>
  <div class="absolute z-[1000] bottom-6 left-6 rounded-xl bg-white w-[223px] overflow-hidden text-left">
    <div class="bg-stone-100 py-3 px-4">
      <p class="font-bold text-sm text-emerald-900 flex items-center">
        <i class="las la-info-circle text-xl mr-2"></i> {{ gettext('Map legend') }}
      </p>
    </div>
    <div
      v-if="markerColors === undefined || markerColors.length < 1"
      class="pt-3 px-4 pb-4 flex items-center pl-4.5"
    >
      <MapPin
        :fill="ColorList.MapMarkerBlue"
      />
      <p class="text-xs font-bold ml-2">
        {{ gettext('Project') }}
      </p>
    </div>
    <ul class="py-2" v-else>
      <li
        v-for="(marker, index) in markers"
        :key="index"
        class="py-1 px-4 flex items-center pl-4.5"
      >
        <MapPin :fill="marker.fill"/>
        <p class="text-xs font-bold ml-2">
          {{ marker.label }}
        </p>
      </li>
    </ul>
  </div>
</template>
