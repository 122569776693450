import { defineStore } from 'pinia'
import { client } from '../_api-services/urls'
import { get, set } from '../_helpers/idb_keyval'
import { components } from '../_api-services/openapi'
import { SectorColors } from '../_types/colours'
import { reduceFields } from '../_helpers/sumfields.helpers'

type LandingPageSchema = components['schemas']['LandingPageData']
interface AppState {
  loading: boolean
  landingPageData: LandingPageSchema
}

const initialState: AppState = {
  loading: false,
  landingPageData: {
    total_infrastructure_fund: 0,
    munisipius: [],
    sectors: [],
    sukus: [],
    activities: []
  }
}

export const useAppStore = defineStore('appstore', {
  state: () => (initialState),
  getters: {
    getProjectByDistrictChart: (state) => {
      const data_ = state.landingPageData?.munisipius?.sort((a, b) => (b.ida_projects + b.legacy_projects) - (a.ida_projects + a.legacy_projects)) ?? []
      const labels = data_.map((project) => project.name)
      const data = data_.map((project) => (project.ida_projects + project.legacy_projects))
      const BarData = {
        labels,
        datasets: [
          {
            data,
            backgroundColor: ['#FFE389']
          }
        ]
      }
      return BarData
    },
    getTotalProjects: (state): string => {
      return reduceFields(state.landingPageData.munisipius, 'ida_projects', 'legacy_projects').toLocaleString()
    },
    getProjectBySectorChart: (state) => {
      const data_ = state.landingPageData.sectors
      const colors = data_.map(it => SectorColors.get(it.id ?? 1))
      const labels = data_.map(it => it.name)
      const data = data_.map(it => (it.ida_projects + it.legacy_projects))
      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: colors
          }
        ]
      }
    },
    getProjectBySectorLabels: (state) => {
      const data_ = state.landingPageData.sectors
      const projects = reduceFields(data_, 'ida_projects', 'legacy_projects')
      return data_.filter(it => it.ida_projects !== null && it.legacy_projects !== null) // exclude the sectors that doesn't have any project
        .map(it => ({
          label: it.name,
          id: it.id,
          percentage: (((it.ida_projects + it.legacy_projects) / Number(projects)) * 100).toFixed(2)
        }))
    },
    getTotalParticipants: (state) => {
      // Filter activities to 'Aldeia' level activities only
      const aldeiaActivities = new Set([2, 22, 23, 40])
      const activities = state.landingPageData.activities.filter(it => (typeof it.id !== 'undefined' && aldeiaActivities.has(it.id)))
      return reduceFields(activities, 'total_participants_ida', 'total_participants_legacy').toLocaleString()
    }
  },
  actions: {
    async fetchLandingPageData () {
      this.loading = true
      try {
        const { data, error } = await client.GET('/api/landing_page/revised-data', {})
        if (typeof error !== 'undefined') throw new Error(error)
        await set('landingPageData', data)
      } catch (e) {
        console.warn(e)
      } finally {
        this.landingPageData = await get('landingPageData') ?? initialState.landingPageData
        this.loading = false
      }
    }
  }
})
