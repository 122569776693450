<script setup lang="ts">
import { useMeta } from 'vue-meta'
import UniversalModal from './components/commons/modal/UniversalModal.vue'
import { SUBMISSION_FILE_ACTIONS, SW_UPDATE_APP_TYPE, SW_APP_OFFLINE } from './_constants/sw.constant'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { deleteFilesByFormKey } from './forms/idb'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './stores/i18n'

const showNotification = ref(false)

useMeta({
  title: 'PNDS-IDA'
  // link: [
  //   {
  //     rel: 'preconnect',
  //     href: 'https://fonts.gstatic.com'
  //   },
  //   {
  //     rel: 'preconnect',
  //     href: 'https://maxst.icons8.com'
  //   },
  //   {
  //     rel: 'stylesheet',
  //     href: 'https://fonts.googleapis.com/css?family=Lato:400,500,600,700,900&subset=latin,latin-ext&display=swap'
  //   },
  //   {
  //     rel: 'stylesheet',
  //     href: 'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css'
  //   }
  // ]
})

onMounted(() => {
  navigator.serviceWorker.addEventListener('message', handleMsgSw)
})

onBeforeUnmount(() => {
  navigator.serviceWorker.removeEventListener('message', handleMsgSw)
})

/**
 * Listens for messages in a service worker and handles them accordingly.
 * @param {MessageEvent} event - The message event containing data to be processed.
 */
const handleMsgSw = async (event: MessageEvent) => {
  const data = event.data
  // Check if the message action is 'SUBMISSION_FILE_ACTIONS.success'
  if (data.action === SUBMISSION_FILE_ACTIONS.success) {
    // Check if the 'formKey' property is defined in the message data
    if (typeof event.data.formKey !== 'undefined') {
      // If 'formKey' is defined, call the 'deleteFilesByFormKey' function to delete files
      // associated with the specified 'formKey'. This function likely deletes data
      // from an IndexedDB or a similar data store based on the provided 'formKey'.
      await deleteFilesByFormKey(event.data.formKey)
    }
  }

  if (data.type === SW_UPDATE_APP_TYPE) {
    alert(useI18NStore().gettext(data.message ?? 'New version of the application is available.'))
    window.location.reload()
  } else if (data.type === SW_APP_OFFLINE && !showNotification.value) {
    useToast().info(useI18NStore().gettext(data.message ?? 'Offline'))
    showNotification.value = true
  }
}

</script>

<template>
  <metainfo />
  <UniversalModal />
  <Suspense>
    <RouterView />
  </Suspense>
</template>
