import 'vite/modulepreload-polyfill'

import { createApp } from 'vue'
import SyncManager from './forms/sync-manager'
import App from './App.vue'

import { I18nPlugin } from './plugins/i18n'
import { AuthPlugin } from './plugins/auth'
import { PiniaPlugin } from './plugins/pinia'
import { router } from './router'
import * as Sentry from '@sentry/vue'

import { createMetaManager, defaultConfig } from 'vue-meta'
import { plugin, defaultConfig as formkitDefault, createInput } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { generateClasses } from '@formkit/themes'
import theme from './theme.js'
import { floatingLabelTextInput } from './_formkit/templates/input.template'
import './style.css'
import './line-awesome.min.css'
import { toastrPlugin } from './plugins/toastr'

import { clickOutsideDirective } from './_directives/clickOutside'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { loadEnv } from 'vite' // Don't remove! Necessary for sentry DSN

// formkit icons
import UploadIcon from '@/assets/icons/cloud-upload-alt-solid.svg'
import UserIcon from '@/assets/icons/user-alt-solid.svg'
import LockIcon from '@/assets/icons/lock-solid.svg'
import EyeIcon from '@/assets/icons/eye.svg'
import EyeClosedIcon from '@/assets/icons/eye-slash.svg'
import CheckIcon from '@/assets/icons/check-solid.svg'
import AngleDownIcon from '@/assets/icons/angle-down-solid.svg'
import angleLeftIcon from '@/assets/icons/angle-left-solid.svg'
import angleRightIcon from '@/assets/icons/angle-right-solid.svg'
import calendarIcon from '@/assets/icons/calendar.svg'
import searchIcon from '@/assets/icons/search-solid.svg'
import { greaterThanSum, lessThanSum, lessThanOrEqualSum, greaterThanOrEqualSum, alwaysGreaterThanOrEqualSum, shouldNotAcceptNegativeValue, phoneNumberValidation } from './_formkit/validation'

import { pt, tet } from '@formkit/i18n'

/**
 * custom formkit input
 */
import InputCurrency from './components/forms/InputCurrency.vue'
import InputUUID from './components/forms/InputUuid.vue'
import DropdownIcon from './components/forms/DropdownIcon.vue'

import VueLazyLoad from 'vue3-lazyload'

const { VITE_SENTRY_DSN } = import.meta.env

// This is a FormKit Pro 'free tier' license for development
// The type assertion below fixes a conflict between `Pro` and `Vue` package type checking
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
const pro = createProPlugin('fk-b340584499', inputs)
const app = createApp(App)

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true }
})

Sentry.init({
  app,
  dsn: VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

app.use(PiniaPlugin)
app.use(I18nPlugin, {})
app.use(AuthPlugin, {})

app.use(router)

app.use(metaManager)
app.use(plugin, formkitDefault({
  plugins: [pro],
  rules: { lessThanSum, alwaysGreaterThanOrEqualSum, greaterThanSum, lessThanOrEqualSum, greaterThanOrEqualSum, shouldNotAcceptNegativeValue, phoneNumberValidation },
  config: {
    classes: generateClasses(theme)
  },
  inputs: {
    password: {
      type: 'input',
      ...floatingLabelTextInput
    },
    textFloating: {
      type: 'input',
      ...floatingLabelTextInput
    },
    currency: createInput(InputCurrency),
    uuid: createInput(InputUUID),
    dropdownIcon: createInput(DropdownIcon)
  },
  icons: {
    upload: UploadIcon,
    user: UserIcon,
    lock: LockIcon,
    eye: EyeIcon,
    eyeClosed: EyeClosedIcon,
    check: CheckIcon,
    angleDown: AngleDownIcon,
    calendar: calendarIcon,
    search: searchIcon,
    angleLeft: angleLeftIcon,
    angleRight: angleRightIcon
  },
  locales: { tet, pt },
  // Define the active locale
  locale: 'tet'
}))

app.use(clickOutsideDirective)
app.use(toastrPlugin)
app.use(VueLazyLoad, {})

app.mount('#app')

const sync = new SyncManager()
