<script setup lang="ts">
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import IconCircleBox from '../components/commons/IconCircleBox.vue'
import IdaFormsElementRow from './IdaFormsElementRow.vue'
import { isFormKitSchemaNode, useIdaFormsStore, Crumb, getBreadcrumbs } from '../stores/idaforms'
import { computed, reactive, ref, Ref, watch } from 'vue'
import draggable from 'vuedraggable'
import { FormKitSchemaFormKit, FormKitNode } from '@formkit/core'
import BasicButton from '../components/commons/BasicButton.vue'
import ModalBox from '../components/commons/modal/ModalBox.vue'
import { useModal } from '../stores/modal'
import { router } from '../router'
import { components } from '../_api-services/openapi'
import { generateId, toRawDeep } from '../_helpers/common.helpers'
import { addOrEditNodeSchema, addOrUpdateFormSchema, DjangoFormTypeToEditFields } from './schemas'
import { useRoute } from 'vue-router'
import { useOptionsStore } from '../stores/options'
import { useI18NStore } from '../stores/i18n'
import { groupName } from '../_pndsdata/idb_pndsdata'
import { useToast } from 'vue-toastification'
import { OptionType, OptionsType } from '../_types/components/forms/select-dropdown'

type FormKitNodeIn = components['schemas']['FormKitNodeIn']

const route = useRoute()

const store = useIdaFormsStore()
const optionsStore = useOptionsStore()
await optionsStore.initialize()
const toast = useToast()

const i18nStore = useI18NStore()

const modal = useModal()

await store.initialize()

export interface Props {
  group: 'SF_1_3' | string // This is the 'key' of the form (like, SF_2_3)
  childPath?: string[]
}
const props = withDefaults(defineProps<Props>(), {
  childPath: () => []
})

const group_ = ref(props.group)
const childPath_ = ref(props.childPath)

/** FormKit schemas for adding new nodes */
// const optionsStore = useOptionsStore()

const ida = (groupName: groupName, ...filterParams: string[]) => optionsStore.options(groupName, undefined, filterParams)
const gettext = (t: string) => i18nStore.gettext(t)
const getIdaOptions = (): OptionsType => optionsStore.options('GROUPS').map((it): OptionType => {
  return {
    label: it.label,
    value: `$ida(${it.value})`
  }
})

const schemaData = reactive({
  ida,
  gettext,
  getIdaOptions
})

/*
This fixes an issue where child routes would not work as the props
are not reactive
*/
watch(route, () => {
  group_.value = route.params.group as string
  childPath_.value = route.params.childPath as string[]
})

const modalAction = ref('add')
const formSchema: Ref<components['schemas']['FormSchema'] | undefined> = ref()
const formNodeFromIdb: Ref<components['schemas']['NodeReturnType'] | components['schemas']['NodeStringType'] | undefined> = computed(() => formSchema.value?.schema_id ? store.nodes.byKey(formSchema.value.schema_id) : undefined)
const formNode = computed(() => store.nodes.toSchemaNode(formNodeFromIdb.value))
const refresh = () => { formSchema.value = store.forms.find(it => it.key === group_.value) }
store.$subscribe(() => { refresh() })
refresh()

const breadcrumb = computed(() => getBreadcrumbs(formNode.value, childPath_.value))

// the leaf node we are editing
const focussedNode = computed(() => {
  /**
   * Returns a clone of the last item in `breadcrumbs`
   */
  if (typeof breadcrumb.value === 'undefined') return undefined
  const node = breadcrumb.value[breadcrumb.value.length - 1]
  if (typeof node === 'undefined') return undefined
  if (typeof node === 'object') return structuredClone(toRawDeep(node))
  console.warn('Could not parse focussed node', node)
  return undefined
})

const protectNode = computed(() => {
  return typeof nodeData.value !== 'undefined' && 'protected' in nodeData.value && nodeData.value.protected === true
})

const focussedNodeChildren = computed(() => {
  if (typeof focussedNode.value === 'undefined' || !('children' in focussedNode?.value)) return []
  const children = focussedNode.value.children
  if (Array.isArray(children)) {
    return (children ?? []).map((it) => {
      if (typeof it === 'string') {
        return JSON.parse(it) as { node: string, uuid: string }
      }
      return it
    })
  } else if (typeof children !== 'undefined') {
    console.warn('Unhandled node child type. Expected an array')
    return []
  } else return []
})

// if the whole schema has changes
const changes = computed(() => {
  return false
  // return JSON.stringify(node.value) !== JSON.stringify(editingFormSchemaGroup.value)
})

function changeElement (element: FormKitSchemaFormKit | string) {
  if (typeof formNode.value === 'object' && !Array.isArray(formNode.value?.children)) {
    throw new Error('expecting children')
  }
  let node = formNode.value
  if (typeof node === 'undefined' || typeof node === 'string' || !('children' in node)) return []
  for (const path of childPath_.value) {
    if (typeof node !== 'string' && Array.isArray(node.children)) {
      const pathInt = parseInt(path, 10)
      if (pathInt !== undefined) {
        // we have a numerical path
        if (typeof node.children[pathInt] === 'string') {
          // we have a string, need to change it im place
          node.children[pathInt] = element
          return
        }
        node = node.children[pathInt] as FormKitSchemaFormKit | string
      } else {
        throw new Error('expecting numerical path element')
      }
    } else {
      throw new Error('expecting children')
    }
  }
  if (typeof element === 'string') {
    node = element
  } else {
    Object.assign(node, element)
  }
}

const showModal = ref(false)
const showFormDetailsModal = ref(false)

function addChildModal () {
  modalAction.value = 'add'
  showModal.value = true
  nodeData.value = {}
}

function breadCrumbLabel (index: number, node?: Crumb): string {
  if (typeof node === 'undefined') return 'unknown'
  if ('$el' in node) return 'html'
  if ('title' in node && 'id' in node) return index === 0 ? group_.value : node.title as string || node.id as string
  if ('node' in node && typeof node.node === 'string') return node.node
  if (index === 0 && formData.value?.name !== undefined) return formData.value.name[useI18NStore().code] ?? node?.name as string
  return node?.name as string
}

async function addChild (dataIn: FormKitNodeIn, node: FormKitNode) {
  const id = dataIn.name ?? generateId()
  if (dataIn.$formkit === 'group') {
    if (typeof dataIn.additional_props !== 'undefined') {
      (dataIn.additional_props as Record<string, string>).id = id
    } else {
      (dataIn.additional_props as unknown as Record<string, string>) = { id: dataIn.name ?? generateId() }
    }
  } else {
    // if the formkit type is not `group` it will parse the additional_props
    const additionalProps: FormKitNodeIn['additional_props'] = {}
    for (const key in dataIn) {
      if (key.includes('additional_props')) {
        const propKey = key.replace('additional_props.', '') as string
        additionalProps[propKey] = dataIn[key as keyof typeof dataIn] as string
      }
    }
    dataIn.additional_props = additionalProps
  }

  if (modalAction.value === 'add') {
    dataIn.parent_id = focussedNode?.value?.uuid
  }

  const { response, error } = await store.createOrUpdateNode(dataIn)
  if (response?.ok) {
    showModal.value = false
    toast.success(i18nStore.gettext('Changes saved'))
  } else if (error) {
    node.setErrors(error.errors ?? [], error.field_errors as Record<string, string>)
  }
}

async function deleteElement (uuid: string): Promise<boolean> {
  const success = await store.deleteNode(uuid)
  showModal.value = false
  return success
}

async function deleteForm (key: string): Promise<boolean> {
  const success = await store.deleteForm(key)
  return success
}

/* validate if the entered name or id is already used here */
function nameOrIdNotUsedInScope (node: FormKitNode): boolean {
  const f = focussedNodeChildren.value.findIndex(child => {
    return isFormKitSchemaNode(child) &&
      (modalAction.value === 'update' ? (child.uuid !== nodeData.value.uuid && (child.name === node.value || child.id === node.value)) : (child.name === node.value || child.id === node.value))
  })
  return f === -1
}

const schemas = computed(() => addOrEditNodeSchema(breadcrumb, nameOrIdNotUsedInScope))

function viewNode (id: number) {
  const activeNode = focussedNodeChildren.value[id] as FormKitSchemaFormKit
  if (activeNode?.$formkit === 'group' || activeNode?.$formkit === 'repeater') {
    router.push({ name: 'idaformsedit', params: { group: group_.value, childPath: childPath_.value !== undefined ? [...childPath_.value, id] : [id] } })
  }
}

function viewMore (e: Event, id: number) {
  modalAction.value = 'update'
  e.stopPropagation()
  const activeNode = focussedNodeChildren.value[id] as FormKitSchemaFormKit
  if (activeNode !== undefined) {
    if (activeNode.$formkit === 'group') {
      nodeData.value = {
        $formkit: activeNode.$formkit,
        name: activeNode.id,
        additional_props: {
          title: activeNode.title,
          icon: activeNode.icon
        },
        uuid: activeNode.uuid,
        protected: activeNode.protected
      }
    } else {
      nodeData.value = activeNode
      nodeData.value['additional_props.validation'] = activeNode.validation // set validation to `additional_props.validation` field
    }
  }
  showModal.value = true
}

async function createOrUpdateForm (dataIn: components['schemas']['FormSchemaIn'], node: FormKitNode) {
  const { response, error } = await store.createOrUpdateForm(dataIn)
  if (error) node.setErrors(error.errors as string[], error.field_errors as Record<string, string>)
  if (response.ok) { toast.success(i18nStore.gettext('Form has been successfully updated')) }

  showModal.value = !response.ok
  showFormDetailsModal.value = !response.ok
}

// Properties used for the "Edit Form" form
const initialState = computed(() => {
  const form = store.forms.find(it => it.key === props.group)
  if (!form) throw new TypeError(`Could not identify the form ${props.group}`)
  return DjangoFormTypeToEditFields(form)
})

const formData = ref(structuredClone(initialState.value))
const nodeData: Ref<Record<string, any>> = ref({})
const schema = computed(() => addOrUpdateFormSchema({ isEdit: true }))
const formkitSchemaData = computed(() => ({
  gettext: i18nStore.gettext,
  ida: (name: groupName, ...filterParams: string[]) => optionsStore.options(name, i18nStore.code, filterParams),
  statusOptions: () => {
    return [
      { label: i18nStore.gettext('Draft'), value: 'draft' },
      { label: i18nStore.gettext('Published'), value: 'published' }
    ]
  }
}))

</script>

<template>
  <base-layout class="!pb-[105px]">
    <template #title>
      <div class="flex items-center mb-2 ">
        <h1 class="text-xl font-bold">
          <router-link :to="{ name: 'idaforms' }">
            <icon-circle-box class="border-emerald-600 mr-4">
              <i class="las la-angle-left"></i>
            </icon-circle-box>
            <span
              class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-1 after:left-0 after:bg-emerald-600 relative"
            >{{
              gettext('Forms') }}</span>
          </router-link>
          <template
            v-for="(node, index) in breadcrumb"
            :key="index"
          >
            <i
              class="las text-xl relative la-angle-right text-gray-400 mx-2"
            ></i>
            <router-link
              :to="{
                name: 'idaformsedit', params: {
                  group: group_,
                  childPath: childPath_ !== undefined ? childPath_.slice(0, index) : ''
                }
              }"
              :class="{ 'after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-1 after:left-0 after:bg-emerald-600 relative': breadcrumb.length > 1 && index < (breadcrumb.length - 1) }"
            >
              {{ breadCrumbLabel(index, node) }}
            </router-link>
          </template>
        </h1>
        <button
          v-if="breadcrumb.length < 2"
          class="ml-4 w-9 h-9 min-w-[36px] rounded-full border border-zinc-400 bg-zinc-400/50 hover:border-gray-100 hover:bg-zinc-400/20 hover:text-emerald-600"
          type="button"
          @click="() => showFormDetailsModal = true"
        >
          <i class="las la-pencil-alt"></i>
        </button>
        <router-link
          :to="{
            name: 'submission',
            params: {
              formType: group_,
              formKey: 'new'
            }
          }"
          target="_blank"
          class="inline-flex text-lg items-center justify-center ml-4 w-9 h-9 min-w-[36px] rounded-full border border-zinc-400 bg-zinc-400/50 hover:border-gray-100 hover:bg-zinc-400/20 hover:text-emerald-600"
        >
          <i class="las la-external-link-alt"></i>
        </router-link>
        <span
          v-if="changes"
          class="ml-4 inline-flex items-center text-sm"
        >
          <span class="las la-file-alt text-lg mr-1"></span>
          {{ gettext('unsaved changed') }}
        </span>
      </div>
    </template>

    <content-section
      :has-border="false"
    >
      <div class="flex items-center justify-between mb-6">
        <div class="flex items-center pr-5">
          <h5
            class="leading-tight text-2.5xl font-black"
          >
            {{ breadcrumb.length === 1 ? gettext('Manage set of questions') : gettext('Manage form questions') }}
            <!-- {{ formData.name ? formData.name[useI18NStore().code] : '' }} -->
          </h5>
          <!-- Active when the view is of the top level group. The top level group displays Form details. -->
        </div>
        <basic-button
          v-if="typeof focussedNode !== 'undefined' && 'uuid' in focussedNode && breadcrumb.length === 1"
          class="lg:min-w-[220px]"
          @click="addChildModal"
        >
          <i class="las la-plus text-2xl mr-1 md:mr-2"></i>{{ gettext('Add set of questions') }}
        </basic-button>
        <basic-button
          v-else-if="typeof focussedNode !== 'undefined' && 'uuid' in focussedNode && breadcrumb.length > 1"
          class="lg:min-w-[220px]"
          @click="addChildModal"
        >
          <i class="las la-plus text-2xl mr-1 md:mr-2"></i>{{ gettext('Add a question') }}
        </basic-button>
      </div>
      <div v-if="focussedNodeChildren && focussedNodeChildren.length">
        <table class="table-primary table-primary-narrow--cell table-primary--clickable w-full overflow-hidden rounded-[25px] shadow-lg">
          <thead>
            <tr>
              <th v-if="breadcrumb.length > 1">
                {{ gettext('Type') }}
              </th>
              <th>{{ breadcrumb.length < 2 ? gettext('Set of questions') : gettext('Question') }}</th>
              <th class="w-[130px]">
                <p class="text-center">
                  {{ gettext('Edit') }}
                </p>
              </th>
            </tr>
          </thead>
          <draggable
            v-model="(focussedNodeChildren)"
            item-key="12"
            tag="tbody"
          >
            <template #item="{ element, index }">
              <tr
                :key="index"
                class="cursor-move"
                @click="viewNode(index)"
              >
                <IdaFormsElementRow :element="element" />
                <td class="text-center">
                  <button
                    type="button"
                    class="w-9 h-9 rounded-full bg-emerald-600 hover:bg-white hover:text-emerald-600 border border-emerald-600 text-white inline-flex items-center justify-center text-center"
                    @click="viewMore($event, index)"
                  >
                    <i class="las la-pencil-alt"></i>
                  </button>
                </td>
              </tr>
            </template>
          </draggable>
        </table>
      </div>
      <!-- FORM DETAILS MODAL -->
      <modal-box
        v-if="showFormDetailsModal"
        size="xl"
        spacing="md"
      >
        <template #header>
          <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
            <i class="text-gray-400 mr-3 text-2xl las la-edit"></i>
            {{ gettext('Form details') }}
          </h4>
        </template>
        <button
          class="w-7.5 h-7.5 rounded-full bg-emerald-600 text-white inline-flex items-center justify-center text-xl fixed top-6 right-6"
          @click="() => showFormDetailsModal = false"
        >
          <i class="las la-times"></i>
        </button>
        <div>
          <FormKit
            v-if="breadcrumb.length === 1"
            v-model="formData"
            type="form"
            :submit-label="gettext('Save')"
            :submit-attrs="{
              wrapperClass: '!w-[254px] mx-auto mt-4'
            }"
            @submit="createOrUpdateForm"
          >
            <FormKitSchema
              :schema="schema"
              :data="formkitSchemaData"
            />
          </FormKit>
        </div>
      </modal-box>
      <modal-box
        v-if="showModal"
        size="xl"
        spacing="md"
      >
        <template #header>
          <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
            <i class="text-gray-400 mr-3 text-2xl las la-edit"></i>
            {{ typeof focussedNode !== 'undefined' && 'uuid' in focussedNode && breadcrumb.length === 1 ? gettext(modalAction === 'update' ? 'Group details' : 'Add set of questions') : gettext(modalAction === 'update' ? 'Question details' : 'Add a question') }}
          </h4>
        </template>
        <button
          class="w-7.5 h-7.5 rounded-full bg-emerald-600 text-white inline-flex items-center justify-center text-xl fixed top-6 right-6"
          @click="() => showModal = false"
        >
          <i class="las la-times"></i>
        </button>
        <div>
          <FormKit
            v-model="nodeData"
            type="form"
            :actions="false"
            :disabled="protectNode"
            @submit="addChild"
          >
            <FormKitSchema
              :schema="schemas"
              :data="schemaData"
            />
            <div
              v-if="!protectNode"
              class="mt-8 flex justify-center"
            >
              <basic-button
                class="!w-[254px]"
                type="submit"
              >
                {{ gettext(modalAction === 'update' ? 'Save' : 'Add') }}
              </basic-button>
              <basic-button
                v-if="modalAction === 'update'"
                type="button"
                theme="red"
                class="!w-[254px] ml-4"
                @click="modal.open('confirm', {
                  label: 'Delete',
                  modalTitle: 'Are you sure?',
                  modalCaption: 'Do you really want to delete this data? This process cannot be undone',
                  callback: async () => {
                    deleteElement(nodeData.uuid)
                    modal.close()
                  }
                })"
              >
                {{ gettext('Delete') }}
              </basic-button>
            </div>
          </FormKit>
        </div>

        <div class="text-center">
          <basic-button
            v-if="!protectNode"
            theme="text"
            class="!w-[254px] mx-auto mt-2"
            @click="() => showModal = false"
          >
            {{ gettext('Cancel') }}
          </basic-button>
        </div>
      </modal-box>
    </content-section>
  </base-layout>
</template>
