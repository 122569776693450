import { defineStore } from 'pinia'
import { PlaceNode, PlaceNodes } from '../_types/commons'
import { reactive, Ref } from 'vue'
import { client } from '../_api-services/urls'
import { components } from '../_api-services/openapi'
import { OptionsType } from '../_types/components/forms/select-dropdown'

type locationToZsucoContext = components['schemas']['zSucoToLocations']

const nodes = new PlaceNodes()

interface LocationState {
  setStoreState: undefined | boolean | number
  locationToZsuco: locationToZsucoContext[]
}

export const useLocationStore = defineStore('locations', {
  state: (): LocationState => ({
    /** This is a sentinel value to determine whether we
     * successfully fetched locations (true), have not tried yet (undefined)
     * or encountered an error (false)
     */
    setStoreState: (undefined as undefined | boolean | number),
    locationToZsuco: []
  }),
  getters: {
    /** Wrap some of the 'PlaceNodes' code in order to make it reactive
     * This is important if a user loads a Form before `store.initialize()` has completed
     */
    get: (state) => { return (path: number[]) => nodes.find((it) => it.path === path) },
    getName: (state) => { return (path: number[]) => nodes.findByPath(path)?.name ?? null },
    getChildNodes: (state) => { return (node: PlaceNode) => nodes.getChildNodes(node) },
    getParentNode: (state) => { return (node: PlaceNode) => nodes.getParentNode(node) },
    getByLevel: (state) => { return (level: number) => nodes.getByLevel(level) },
    getLocations: (state) => {
      return (...parents: number[]) => {
        return nodes.getByParentNodes(...parents).sort((a, b) => { return a.name > b.name ? 1 : -1 })
      }
    },
    /**
     * getLocationsAsOptions: generate location's options with empty option
     * @param parents location level
     * @returns OptionsType
     */
    getLocationsAsOptions: () => {
      return (...parents: number[]): OptionsType => {
        const emptyOption = { label: '----', attrs: { disabled: true }, value: '' }
        const locations = nodes.getByParentNodes(...parents).sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((opt) => { return { label: opt.name, value: opt.path.at(-1) ?? '' } })
        return [emptyOption, ...locations]
      }
    },
    convertLocationToZsuco: (state) => {
      return (locationId: number): number | undefined => {
        if (typeof locationId === 'undefined') return undefined
        const findZsuco = state.locationToZsuco.find(location => location.location === locationId)
        return typeof findZsuco !== 'undefined' ? findZsuco.zsuco : undefined
      }
    },
    convertLocationToZsucoPcode: (state) => {
      return (locationId: number): number | undefined => {
        if (typeof locationId === 'undefined') return undefined
        const findZsuco = state.locationToZsuco.find(location => location.location === locationId)
        return typeof findZsuco !== 'undefined' ? findZsuco.zsuco_pcode : undefined
      }
    },
    convertZsucoToLocation: (state) => {
      return (zsucoId: number): number | undefined => {
        if (typeof zsucoId === 'undefined') return undefined
        const findZsuco = state.locationToZsuco.find(location => location.zsuco === zsucoId)
        return typeof findZsuco !== 'undefined' ? findZsuco.location : undefined
      }
    }
  },
  actions: {
    async initialize () {
      if (this.setStoreState === 200 || this.setStoreState === true || nodes.length > 0) return
      this.setStoreState = true
      const url = '/api/submissions/location_tree'
      try {
        const { data, response } = await client.GET(url, {})
        if (typeof data === 'undefined') {
          return
        }
        this.setStoreState = response.status
        data.forEach((loc) => nodes.push(loc))
        this.setStoreState = false
      } catch (e) {
        if (e instanceof SyntaxError) {
          console.warn('SyntaxError caught:', e, url)
        } else if (e instanceof TypeError) {
          console.warn('Failed to Fetch caught:', e, url)
        } else {
          throw e // re-throw the error unchanged
        }
      }
    },
    async fetchZsucoToLocation () {
      if (this.locationToZsuco.length < 1) {
        const url = '/api/ida/list-suco-translation'
        try {
          const { data, error } = await client.GET(url, {})
          this.locationToZsuco = data ?? []
        } catch (e) {
          if (e instanceof SyntaxError) {
            console.warn('SyntaxError caught:', e, url)
          } else if (e instanceof TypeError) {
            console.warn('Failed to Fetch caught:', e, url)
          } else {
            throw e // re-throw the error unchanged
          }
        }
      }
    }
  }
})
