<script setup lang="ts">
import { ref, watch } from 'vue'
import { useModal } from '../../../stores/modal'
const modal = useModal()
const showModal = ref(false)

const emit = defineEmits <{(e: 'delete', value: boolean): void}>()

const props = withDefaults(defineProps<{
  id?: number | undefined
  enableDelete: boolean
}>(), {
  id: undefined,
  enableDelete: false
})

watch(showModal, (newValue) => { // remove duplicate scrollbar if modal image is displayed
  if (newValue) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }
})
</script>

<template>
  <div class="relative">
    <slot></slot>
    <button
      type="button"
      class="absolute h-full w-full top-0 left-0 hover:border-2 hover:border-emerald-600 rounded-[5px]"
      @click="() => showModal = true"
    ></button>
  </div>
  <div
    v-if="showModal"
    class="fixed w-full h-full z-[100000] left-0 pt-[153px] pb-14 top-0 overflow-y-auto bg-light/80 backdrop-blur-[20px]"
  >
    <button
      type="button"
      class="fixed right-6 top-6 text-2xl"
      @click="() => showModal = false"
    >
      <i class="las la-times"></i>
    </button>
    <div class="max-w-[800px] w-full mx-auto h-auto pb-10 relative">
      <slot name="modalContent"></slot>
      <button
        v-if="props.enableDelete"
        type="button"
        class="absolute right-5 bottom-14 text-3xs bg-zinc-400 rounded-full px-5 border border-zinc-600 shadow-md h-7"
        @click="() => modal.open('confirm', {
          label: 'Delete',
          modalTitle: 'Are you sure?',
          modalCaption: 'Do you really want to delete this file? This process cannot be undone',
          callback: async () => {
            emit('delete', true)
            modal.close()
            showModal = false
          }
        })"
      >
        Delete <i class="las la-trash-alt ml-1"></i>
      </button>
    </div>
  </div>
</template>
