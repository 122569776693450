import { defineStore } from 'pinia'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
import { FORM_TYPES, SUBMISSIONS_STATUS_VALUE } from '../_constants/common.constant'
import { useSukuProfileStore } from './sukuprofile'
import { OfflineForm } from '../forms/form'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
import { SUKU_PRIORITY_MESSAGE } from '../_constants/toastmessages.constant'

const toast = useToast()
type SF23PriorityContext = components['schemas']['Sf_2_3PrioritiesSchema']
type PriorityOrderSchemaWithSF23Context = components['schemas']['PriorityOrderSchemaWithSF23']
type SubmissionInSchema = components['schemas']['SubmissionIn']
type SubmissionOutSchema = components['schemas']['SubmissionOut']
interface SF23PriorityItemContext extends SF23PriorityContext {
  submission_id: string
  date?: string
}
interface InitialState {
  priorities: PriorityOrderSchemaWithSF23Context[]
}

export const useSukuPrioritiesStore = defineStore('sukupriorities', {
  state: () => {
    const initialState: InitialState = {
      priorities: []
    }
    return initialState
  },
  getters: {
    getPriorities (state) {
      return state.priorities.sort((a, b) => {
        const orderA = Number(a?.order ?? 0)
        const orderB = Number(b?.order ?? 0)
        return orderA - orderB
      })
    },

    getPriority (state) {
      return (id: number) => {
        return state.priorities.find(it => it.id === id)
      }
    }
  },
  actions: {
    /**
     * fetch priorities list
     */
    async initialize () {
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = sukuProfileStore.$state.activezSukuId
      if (typeof zSucoId === 'undefined') return
      const { data, error } = await client.GET('/api/ida/list-priority-order/{suco_id}', { params: { path: { suco_id: zSucoId } } })
      if (data != null) {
        this.priorities = data
      }
    },
    /**
     * save priority. if formkey is provided it will update the priority
     * @param data fields
     * @param formKey string
     * @returns boolean
     */
    async submit (data: Record<string, any>): Promise<boolean> {
      const submission = OfflineForm.createFromEmpty(FORM_TYPES.SF23)
      const formKey = data.parent
      const isUpdate = typeof formKey !== 'undefined' && formKey !== ''
      let fields: Record<string, never> = {}
      if (isUpdate) { // update priority repeater
        fields = data as Record<string, never>
      } else { // create new priority
        const sukuProfileStore = useSukuProfileStore()
        const locationNode = sukuProfileStore.$state.sukuLocationNode
        submission.clonableData.fields = {
          date: data?.date,
          priorities: [data],
          submitted_from: 'suku-manager',
          ...locationNode
        } as unknown as Record<string, never>
      }
      const req = isUpdate ? await this.updatePriority(formKey, fields.uuid, fields) : await submission.postToServer()
      if (req) {
        toast.success(useI18NStore().gettext(isUpdate ? SUKU_PRIORITY_MESSAGE.update : SUKU_PRIORITY_MESSAGE.success))
        if (isUpdate) { // if its updating existing priority, it will update prorities state
          const findPriorityIndex = this.priorities.findIndex(prio => prio.sf23_priority.uuid === fields.uuid)
          if (findPriorityIndex > -1) {
            this.priorities[findPriorityIndex].sf23_priority = {
              ...this.priorities[findPriorityIndex].sf23_priority,
              ...fields
            }
          }
        }
      } else {
        toast.error(useI18NStore().gettext(SUKU_PRIORITY_MESSAGE.error))
      }
      return req
    },
    /**
     * update priority
     * @param key string
     * @param fields fields
     * @returns boolean
     */
    async updatePriority (key: string, priorityId: string, fields: Record<string, never >): Promise<boolean> {
      if (typeof key === 'undefined' || typeof priorityId === 'undefined') return false
      const { error, data } = await client.PUT('/api/submissions/update-repeater', {
        body: {
          submission: key,
          part: priorityId,
          fields
        }
      })
      if (typeof error !== 'undefined') throw new Error(error)
      return data !== undefined
    },
    /**
     * update the priority order
     * @param priorityOrderId number
     * @param payload { order, suco_id }
     */
    async updatePriorityOrder (priorityOrderId: number, payload: { order: number, suco_id: number }) {
      const { data, error } = await client.PUT('/api/ida/update-priority-order/{priority_order_id}', { params: { path: { priority_order_id: priorityOrderId } }, body: payload })
      if (typeof error === 'undefined') {
        toast.success(useI18NStore().gettext(SUKU_PRIORITY_MESSAGE.reorder))
      } else {
        toast.error(useI18NStore().gettext(SUKU_PRIORITY_MESSAGE.error))
      }
    }
  }
})
