import { API_STATUS, API_METHODS } from '../_constants/api.constant'
import ApiInterceptor from './interceptors/api.interceptor'
class ApiService {
  request = async (method: string, url: string, data: object | undefined | string, config: object | undefined): Promise<{ success: boolean, message: string, data: Record<string, any>, code: number | undefined }> => {
    let payload = {}

    const defaultConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }

    if (method !== API_METHODS.GET) {
      payload = {
        body: data
      }
    }
    /**
     * ApiInterceptor is a drop in replacement for native `fetch`
     * which always includes Django's `csrftoken`
     */
    const response = await ApiInterceptor(url, {
      method,
      ...payload,
      ...{ ...defaultConfig, ...config }
    })
    const success = response?.status !== undefined ? Math.floor(response?.status / 100) === 2 : false
    const responseData = response?.status === API_STATUS.API_NO_CONTENT ? await response?.text() : await response?.json()
    const message = response?.statusText ?? ''
    return {
      success,
      message,
      data: responseData,
      code: response?.status
    }
  }

  get = async (url: string, config = {}, params: Record<string, any> = {}) => {
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')

    return await this.request(API_METHODS.GET, `${url}?${queryString}`, {}, config)
  }

  post = async (url: string, data: object | string, config = {}) => {
    return await this.request(API_METHODS.POST, url, data, config)
  }

  put = async (url: string, data: object, config = {}) => {
    return await this.request(API_METHODS.PUT, url, JSON.stringify(data), config)
  }

  patch = async (url: string, data: object, config = {}) => {
    return await this.request(API_METHODS.PATCH, url, data, config)
  }

  delete = async (url: string, config = {}) => {
    return await this.request(API_METHODS.DELETE, url, {}, config)
  }
}

export default ApiService
