import { FormKitSchemaFormKit, FormKitSchemaNode } from "@formkit/core"

export const operationalReportSchema: Array<FormKitSchemaFormKit | FormKitSchemaNode> = [
    {
      $el: 'tbody',
      children: [
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("1000 - Subsidy Operational PNDS")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'operational_subsidy_1000',
                  id: 'operational_subsidy_1000',
                  key: 'operational_subsidy_1000',
                  outerClass: '!mb-0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.operational_subsidy_1000)+$parseToFloat($get(operational_subsidy_1000).value))']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                class: '!border-r-0'
              },
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.operational_subsidy_1000)']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                colspan: '2'
              }
            }
          ]
        },
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("1001 - Last Year Balance")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'last_year_balance_1001',
                  id: 'last_year_balance_1001',
                  key: 'last_year_balance_1001',
                  outerClass: '!mb-0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.last_year_balance_1001)+$parseToFloat($get(last_year_balance_1001).value))']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                class: '!border-r-0'
              },
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.last_year_balance)']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                colspan: '2'
              }
            }
          ]
        },
  
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("1002 - Interest")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'interest_1002',
                  id: 'interest_1002',
                  key: 'interest_1002',
                  outerClass: '!mb-0',
                  value: '0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.interest_1002)+$parseToFloat($get(interest_1002).value))']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                class: '!border-r-0'
              },
              children: [
                {
                  $el: 'p',
                  attrs: {
                    class: 'font-semibold text-sm'
                  },
                  children: ['$gettext("Not applicable")']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                colspan: '2'
              }
            }
          ]
        },
  
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("1003 - Other")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'other_1003',
                  id: 'other_1003',
                  key: 'other_1003',
                  outerClass: '!mb-0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.other_1003)+$parseToFloat($get(other_1003).value))']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                class: '!border-r-0'
              },
              children: [
                {
                  $el: 'p',
                  attrs: {
                    class: 'font-semibold text-sm'
                  },
                  children: ['$gettext("Not applicable")']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                colspan: '2'
              }
            }
          ]
        }
      ]
    },
    {
      $el: 'tfoot',
      children: [
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              attrs: {
                class: 'font-semibold'
              },
              children: ['$gettext("Total operational receipts (USD)")']
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($get(other_1003).value)+$parseToFloat($get(interest_1002).value)+$parseToFloat($get(last_year_balance_1001).value)+$parseToFloat($get(operational_subsidy_1000).value))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($totalData.totalOperationalReceiptsCummulative)']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                class: '!border-r-0'
              },
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.total_operational_receipts)']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                colspan: '2'
              }
            }
          ]
        }
      ]
    }
  ]
  
  export const operationalExpendituresSchema: Array<FormKitSchemaFormKit | FormKitSchemaNode> = [
    {
      $el: 'tbody',
      children: [
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("2000 - Community Meeting")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'community_meetings_2000',
                  id: 'community_meetings_2000',
                  key: 'community_meetings_2000',
                  outerClass: '!mb-0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.community_meetings_2000)+$parseToFloat($get(community_meetings_2000).value))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.community_meetings_2000)']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.community_meetings_2000-($parseToFloat($monthlyReportCommulative.community_meetings_2000)+$parseToFloat($get(community_meetings_2000).value)))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: [
                    {
                      $el: 'span',
                      children: {
                        if: '$parseToFloat($monthlyReportBudget.community_meetings_2000) !== 0',
                        then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.community_meetings_2000)+$parseToFloat($get(community_meetings_2000).value))/$monthlyReportBudget.community_meetings_2000*100)',
                        else: '0'
                      }
                    },
                    '%'
                  ]
                }
              ]
            }
          ]
        },
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("2001 - Community Training")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'community_training_2001',
                  id: 'community_training_2001',
                  key: 'community_training_2001',
                  outerClass: '!mb-0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.community_training_2001)+$parseToFloat($get(community_training_2001).value))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.community_training_2001)']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.community_training_2001-($parseToFloat($monthlyReportCommulative.community_training_2001)+$parseToFloat($get(community_training_2001).value)))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: [
                    {
                      $el: 'span',
                      children: {
                        if: '$parseToFloat($monthlyReportBudget.community_training_2001) !== 0',
                        then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.community_training_2001)+$parseToFloat($get(community_training_2001).value))/$monthlyReportBudget.community_training_2001*100)',
                        else: '0'
                      }
                    },
                    '%'
                  ]
                }
              ]
            }
          ]
        },
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("2002 - Incentive for Suku team")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'incentive_for_suku_2002',
                  id: 'incentive_for_suku_2002',
                  key: 'incentive_for_suku_2002',
                  outerClass: '!mb-0',
                  value: '0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.incentive_for_suku_2002)+$parseToFloat($get(incentive_for_suku_2002).value))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.incentive_for_suku_2002)']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.incentive_for_suku_2002-($parseToFloat($monthlyReportCommulative.incentive_for_suku_2002)+$parseToFloat($get(incentive_for_suku_2002).value)))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: [
                    {
                      $el: 'span',
                      children: {
                        if: '$parseToFloat($monthlyReportBudget.incentive_for_suku_2002) !== 0',
                        then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.incentive_for_suku_2002)+$parseToFloat($get(incentive_for_suku_2002).value))/$monthlyReportBudget.incentive_for_suku_2002*100)',
                        else: '0'
                      }
                    },
                    '%'
                  ]
                }
              ]
            }
          ]
        },
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              children: ['$gettext("2003 - Project Administration Cost")']
            },
            {
              $el: 'td',
              children: [
                {
                  $formkit: 'currency',
                  name: 'project_admin_cost_2003',
                  id: 'project_admin_cost_2003',
                  key: 'project_admin_cost_2003',
                  outerClass: '!mb-0'
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.project_admin_cost_2003)+$parseToFloat($get(project_admin_cost_2003).value))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.project_admin_cost_2003)']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.project_admin_cost_2003-($parseToFloat($monthlyReportCommulative.project_admin_cost_2003)+$parseToFloat($get(project_admin_cost_2003).value)))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: [
                    {
                      $el: 'span',
                      children: {
                        if: '$parseToFloat($monthlyReportBudget.project_admin_cost_2003) !== 0',
                        then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.project_admin_cost_2003)+$parseToFloat($get(project_admin_cost_2003).value))/$monthlyReportBudget.project_admin_cost_2003*100)',
                        else: '0'
                      }
                    },
                    '%'
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      $el: 'tfoot',
      children: [
        {
          $el: 'tr',
          children: [
            {
              $el: 'td',
              attrs: {
                class: 'font-semibold'
              },
              children: ['$gettext("Total operational expenditures (USD)")']
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($parseToFloat($get(community_meetings_2000).value)+$parseToFloat($get(community_training_2001).value)+$parseToFloat($get(incentive_for_suku_2002).value)+$parseToFloat($get(project_admin_cost_2003).value))']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($totalData.totalOperationalExpendituresCummulative)']
                }
              ]
            },
            {
              $el: 'td',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($monthlyReportBudget.total_operational_expenditures)']
                }
              ]
            },
            {
              $el: 'td',
              attrs: {
                class: '!border-r-0'
              },
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                  },
                  children: ['$parseToCurrency($totalData.totalOperationalExpendituresBalance)']
                }
              ]
            },
            {
              $el: 'td'
            }
          ]
        }
      ]
    }
  ]
  