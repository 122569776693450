<script setup lang="ts">
import { reactive, onMounted, ref, watch } from 'vue'
import { FORM_STATE } from '../../_constants/common.constant'
import { mapToArray } from '../../_helpers/common.helpers'
import { UploadedFileType } from '../../_types/components/commons/form'
import { useFormsStore } from '../../stores/forms'
import { useSubmissionsStore } from '../../stores/submissions'
import { useModal } from '../../stores/modal'
import { FormFile } from '../../forms/form'
import AlertBox from '../commons/AlertBox.vue'

const modal = useModal()

// This is a refactored variant of the "Multiple files" widget
// with more standard vue components replacing some of the quite complex 'Schema' parts

const store = useFormsStore()
const submissionStore = useSubmissionsStore()
const VITE_BASE_URL = import.meta.env.VITE_BASE_URL
const uploadState = reactive({
  progress: 0,
  isLoading: false,
  showErrorMessage: false
})
const props = defineProps<{
  formKey: string,
  disabled?: boolean,
  modelValue: FormFile[], // this is to get the data from IDB
  required?: boolean
}>()
const emit = defineEmits <{(e: 'update:modelValue', value: FormFile[]): void, (e: 'update:isFormUpdated', value: unknown): void}>()

const uploadHandlerData = reactive({
  comment: '',
  disabled: true,
  uploadedFiles: [] as FormFile[],
  localFiles: [] as FormFile[] // for store the thumbnails
})

const existingFiles = ref<FormFile[]>([])

const formKeyId = ref(props.formKey !== FORM_STATE.new ? props.formKey : store.activeSubmission?.clonableData?.key ?? '')

/**
 * Delete handler
 * onDeleteFile is to proceed the delete process
 */

const onDeleteFile = async (key: string) => {
  const selectedFile = uploadHandlerData.localFiles.find(file => file.key === key)
  const filteredLocalFiles = uploadHandlerData.localFiles.filter((thumb: FormFile) => {
    return thumb.key !== key
  })
  if (selectedFile?.clonableData.bits === undefined && selectedFile?.clonableData.key !== undefined) {
    await submissionStore.deleteFile(Number(selectedFile?.clonableData.key))
  }
  await store.deleteActiveSubmissionFilesByFileKey(key)
  uploadHandlerData.localFiles = filteredLocalFiles
  uploadHandlerData.disabled = filteredLocalFiles.length < 1
  // emit updated documents
  emit('update:modelValue', uploadHandlerData.localFiles)
}
/**
 * When a file is selected create a thumbnail
 */
const onFileInput = (event: Event): void => {
  if (!(event.target instanceof HTMLInputElement)) return

  uploadState.showErrorMessage = false // hide error message
  const files = event.target.files

  if (files === null || formKeyId.value === undefined) return
  for (let index = 0; index < files.length; index++) {
    uploadHandlerData.localFiles.push(FormFile.createFromFile(files[index], formKeyId.value))
  }
  uploadHandlerData.disabled = false
  store.activeSubmission?.replaceFiles(uploadHandlerData.localFiles)
  emit('update:modelValue', uploadHandlerData.localFiles)
}

onMounted(async () => {
  if (store.activeSubmission) {
    const files = mapToArray(store.activeSubmission?.files)
    uploadHandlerData.localFiles = [...uploadHandlerData.localFiles, ...files]
  }
  emit('update:modelValue', uploadHandlerData.localFiles)
})

const fetchUploadedFIles = async (): Promise<void> => {
  if (props.formKey !== FORM_STATE.new) {
    const getUploadedFiles = await submissionStore.getSubmissionFiles(props.formKey)
    const uploadedFiles: FormFile[] = getUploadedFiles.map((file: UploadedFileType) => FormFile.createFromClonable({
      key: (file.id ?? 0).toString(),
      name: file.file.replace('/media/submission_files/', ''),
      formKey: formKeyId.value,
      isUploaded: true,
      thumbnail: {
        webp: file.thumbnails[1] ?? '',
        jpg: file.thumbnails[0] ?? ''
      },
      file: VITE_BASE_URL + file.file
    }))
    existingFiles.value = [...uploadedFiles]
    uploadHandlerData.localFiles = uploadedFiles
  }
}

/**
 * trigger validation if props.required = `true`
 * @returns boolean
 */
function triggerValidation () {
  uploadState.showErrorMessage = false
  if (props.required !== true) {
    return false
  }

  uploadState.showErrorMessage = uploadHandlerData.localFiles.length < 1

  if (uploadState.showErrorMessage) { // scroll to the upload form section if there is no uploaded files
    const uploadFormEl = document.getElementById('upload-form')
    uploadFormEl?.scrollIntoView()
  }

  return !uploadState.showErrorMessage
}

defineExpose({ triggerValidation }) // expose triggerValidation so that it will accessible from component's ref

await fetchUploadedFIles()

/**
 * listen a changes for `uploadHandlerData`
 * emit `isFormUpdated` based on existingFiles and localFiles
 * existingFiles = files that coming from API
 * localFiles = new files that just added but not yet uploaded to the server
 */
watch(uploadHandlerData, (newValue) => {
  emit('update:isFormUpdated', JSON.stringify(existingFiles.value) !== JSON.stringify(newValue.localFiles))
}, { deep: true })

</script>
<template>
  <alert-box
    v-if="required && uploadState.showErrorMessage"
    theme="error"
    class="mb-6"
  >
    <div class="flex items-center">
      <i class="las la-exclamation-triangle text-2xl"></i>
      <div class="pl-5">
        <p class="text-2sm font-bold">
          {{ gettext("No documents being uploaded") }}
        </p>
        <p
          class="text-2sm mt-1"
        >
          {{ gettext("Please add the supporting file") }}
        </p>
      </div>
    </div>
  </alert-box>
  <FormKit
    id="upload-form"
    type="form"
    :actions="false"
    :disabled="props.disabled"
  >
    <div
      v-if="uploadState.isLoading"
      class="fixed z-[10000] w-full h-full top-0 left-0 flex items-center justify-center bg-white/20"
    ></div>
    <div class="relative">
      <FormKit
        id="document"
        type="file"
        name="documents"
        accept=".png,.jpg,.jpeg,.pdf"
        file-list-class="hidden"
        file-remove-class="hidden"
        input-class="appearance-none absolute top-0 left-0 right-0 bottom-0 formkit-disabled:pointer-events-none cursor-pointer text-transparent opacity-0"
        message-class="formkit-invalid:text-red-500 text-sm mt-1"
        inner-class="relative block items-center"
        outer-class="formkit-file"
        no-files-class="hidden"
        file-remove-icon="trash"
        multiple="true"
        :label="gettext('Upload document')"
        label-class="text-base font-bold w-full inline-flex justify-center items-center cursor-pointer la-cloud-upload-alt before:font-icon before:mr-4 before:text-xl min-w-[220px] mr-4 h-[50px] border-[3px] border-emerald-600 inline-flex items-center justify-center pl-5 pr-4 after:text-base after:font-bold after:ml-4 rounded-2.5xl mr-2 formkit-disabled:opacity-50 formkit-disabled:pointer-events-none"
        @change="onFileInput"
      />
      <div
        v-if="uploadState.isLoading"
        class="absolute bottom-[2px] left-[2px] max-w-[calc(100%-4px)] h-[4px] bg-green-300 rounded-bl-5xl rounded-br-5xl"
        :style="{ width: uploadState.progress + '%'}"
      ></div>
      <div
        v-if="uploadState.isLoading"
        class="fixed bottom-0 left-0 h-[5px] z-[10000] bg-green-300 rounded-bl-5xl rounded-br-5xl w-full"
        :style="{ width: uploadState.progress + '%'}"
      ></div>
    </div>
    <!-- Following code is a list of file thumbnail, name and delete buttons -->
    <ul>
      <li
        v-for="i in uploadHandlerData.localFiles"
        :key="i.clonableData.key"
        class="italic text-[15px] text-zinc-700 flex items-center py-4 border-b border-b-solid border-b-zinc-400 relative pr-[46px] break-all"
      >
        <img
          v-if="i.clonableData.isUploaded === true"
          :src="i.previewUploadedFile"
          class="w-[40px] h-[40px] rounded-[4px] mr-4"
          height="40"
          width="40"
          :alt="i.clonableData.name"
        />
        <img
          v-else
          :src="i.previewElement"
          class="w-[40px] h-[40px] rounded-[4px] mr-4"
          height="40"
          width="40"
          :alt="i.clonableData.name"
        />

        <a
          v-if="i.clonableData.isUploaded === true && i.clonableData.file"
          :href="i.clonableData.file"
          target="_blank"
          class="underline"
        >
          {{ i.clonableData.name }}
        </a>
        <span v-else>{{ i.clonableData.name }}</span>
        <button
          type="button"
          :data-id="i.clonableData.key"
          class="w-[30px] h-[30px] rounded-full border border-emerald-600 hover:bg-emerald-600 hover:text-white bg-white absolute right-0 top-[21px] disabled:opacity-50"
          :disabled="props.disabled"
          @click="modal.open('confirm', {
            label: 'Delete',
            modalTitle: 'Are you sure?',
            modalCaption: 'Do you really want to delete this file? This process cannot be undone',
            callback: () => {
              onDeleteFile(i.clonableData.key)
              modal.close()
            }
          })"
        >
          <i class="las la-trash pointer-events-none"></i>
        </button>
      </li>
    </ul>

    <!-- <FormKit
      v-if="!uploadHandlerData.disabled"
      type="submit"
      prefix-icon="check"
      :label="pgettext('Documents form', 'Upload Documents')"
      @click="onSubmitButton"
    /> -->
  </FormKit>
</template>
