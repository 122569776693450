
<script setup lang="ts">
/**
 * Wrapper for form submission page, including the navigation and form actions
 */
import { FormKitSchema } from '@formkit/vue'
import { ref, reactive, toRaw, onMounted, ComputedRef, Ref, computed, watch } from 'vue'
import { useI18NStore } from '../../stores/i18n'
import { useOptionsStore } from '../../stores/options'
import BasicCard from '../commons/BasicCard.vue'
import CollapseBox from '../commons/CollapseBox.vue'
import UploadForm from '../forms/UploadForm.vue'
import LoadingAnimation from '../commons/LoadingAnimation.vue'
import { useFormsStore } from '../../stores/forms'
import { useToast } from 'vue-toastification'
import { useRouter } from 'vue-router'
import ModalSuccess from '../commons/modal/ModalSuccess.vue'
import { useLocationStore } from '../../stores/locations'
import { useSubmissionsStore } from '../../stores/submissions'
import { schemaInformation, validationInfo, FormSchemaDataType, membersArray, FormkitInput } from '../../_types/components/commons/form'
import { FormKitNode, getNode, FormKitSchemaFormKit } from '@formkit/core'
import { FormValue, FormStatus } from '../../forms/form-types'
import { unwrapNested } from '../../_helpers/common.helpers'
import { useModal } from '../../stores/modal'
import { useAppStore } from '../../stores/app'
import { groupName } from '../../_pndsdata/idb_pndsdata'
import { useIdaFormsStore } from '../../stores/idaforms'
import { FormFile } from '../../forms/form'
import { FORM_SCHEMA_STATUS_PUBLISHED, FORM_SCHEMA_STATUS_DRAFT } from '../../_constants/common.constant'
import { useAuthStore } from '../../stores/auth'

const formStore = useIdaFormsStore()
const authStore = useAuthStore()
const toast = useToast()
const appStore = useAppStore()
const router = useRouter()
const modal = useModal()
const showModal = ref(false)
const formSchemas = ref({} as Record<string, schemaInformation>)
const isLoading: Ref<boolean> = ref(true)
const emit = defineEmits <{(e: 'update:modelValue', value: unknown): void, (e: 'update:files', value: unknown): void, (e: 'update:isFormUpdated', value: unknown): void, (e: 'update:schema', value: FormKitSchemaFormKit): void}>()

const props = withDefaults(defineProps<{
  formType: string,
  formKey: string,
  formName?: string,
  schemas?: Record<string, schemaInformation>,
  schemaData?: Record<string, any>
  isReview?: boolean
  disabled?: boolean
  requiredUploadForm?: boolean
  modelValue?: Record<string, any>
  files?: any
}>(), {
  isReview: false,
  disabled: false
})

const submissionType: Ref<string> = ref('')
const store = useFormsStore()
const locationStore = useLocationStore()
const submissionStore = useSubmissionsStore()

const i18Store = useI18NStore()
const optionsStore = useOptionsStore()
const uploadFormRef: Ref<InstanceType<typeof UploadForm> | null> = ref(null)

/**
 * If isPreview = true it will get the data from API, otherwise it will get the data from IDB
 */
const submission = computed(() => {
  if (!props.isReview) {
    return store.activeSubmission?.clonableData
  }
  return submissionStore.getSubmissionByKey(props.formKey)
})

/* Initial data to load into the form / component */
// eslint-disable-next-line prefer-const

const activeRevisionSubmissionComputed = computed(() => submissionStore.activeRevisionSubmission)

const formData = ref(!props.isReview ? { ...toRaw(store.activeSubmission?.clonableData.fields) } : { ...toRaw(submission.value?.fields), ...activeRevisionSubmissionComputed.value?.fields })

const isDataCollection: ComputedRef<boolean> = computed(() => router.currentRoute.value.name === 'submission') // we're using this component in `/form` and `/submit` page, so this will indicate whether we're on `/form` page or not

const uploadedFiles = ref([])

/**
 * if upload form is edited
 */
const isUploadFormUpdated = ref<boolean>(false)

const getoptions = reactive({
  common: {
    selectOptions: optionsStore.getTranslatedSelectOptions,
    translatedOptions: optionsStore.translatedOptions
  },
  project: {
    sectors: optionsStore.sectors,
    subsectors: optionsStore.subsectors,
    outputs: optionsStore.outputs
  },
  tf1321: {
    outputs: optionsStore.allOutputsByTF611result
  },
  sf11: {
    activitySubType: (activityType: string) => {
      return activityType === '2' ? optionsStore.getTranslatedSelectOptions('subActivity2') : activityType === '1' ? optionsStore.getTranslatedSelectOptions('subActivity1') : []
    }
  }
})
const formSchemaData = reactive<FormSchemaDataType>({
  /* Allows using `$gettext` and `$pgettext` in the forms */
  gettext: i18Store.gettext,
  pgettext: i18Store.pgettext,
  interpolate: {
    ff14: (total: string) => {
      return i18Store.interpolate('%(text)s : %(total)s %(text2)s', {
        text: i18Store.gettext('Total'),
        total,
        text2: i18Store.gettext('points')
      })
    }
  },
  getLocations: (...parents: number[]) => {
    const userMunicipality = authStore.user_municipality // if the user assigned to a municipality
    /* Provided parent values, return the options available */
    const locations = locationStore.getLocationsAsOptions(...parents)
    if (userMunicipality && isDataCollection.value && parents.length === 0) { // if its `data-collection` and the user has been assigned to a municipality it will filter the options based on `userMunicipality`
      return [...locations.filter(it => Number(it.value) === userMunicipality)]
    }
    return locations
  },
  getAldeia: (...parents: number[]) => {
    /* Provided parent values, return the options available */
    /* Aldeias are a dropdown field. This does not render the label/value type correctly so a list of names is returned. */
    const locations = locationStore.getLocations(...parents).map((opt) => { return opt.name })
    return locations
  },
  /* Also allows translating values for radio and selects */
  getOpts: {} as Record<string, (ComputedRef<string> | { value: string, label: ComputedRef<string> })[]>,
  activity_type: 1,
  /**
   * register repeater validation function
   */
  validateSukuFacilitators: () => {
    const data = toRaw(formData.value)
    if (typeof data.repeaterSukus === 'undefined') {
      return {
        valid: true,
        message: 'Success',
        id: 'card-sukus'
      }
    }
    const repeaterSukusData: Record<string, FormValue | FormValue[]>[] = data.repeaterSukus as unknown as Record<string, FormValue | FormValue[]>[]
    const findMale = repeaterSukusData.filter(suku => suku.gender === 'Male')
    const findFemale = repeaterSukusData.filter(suku => suku.gender === 'Female')
    const valid = findMale.length <= 1 && findFemale.length <= 1
    return {
      valid,
      message: valid ? 'Success' : i18Store.gettext('Only accept 1 female & 1 male'),
      id: 'card-sukus'
    }
  },
  validatePlanningMember: () => {
    const planningMembers = toRaw(formData.value).repeaterPlanning
    return hasRepeatedPosition(planningMembers as unknown as membersArray, 'card-planning')
  },
  validateProjectMember: () => {
    const planningMembers = toRaw(formData.value).repeaterProjectTeam
    return hasRepeatedPosition(planningMembers as unknown as membersArray, 'card-projectteam')
  },
  /**
   * to disable days on the datepicker
   * need to add `_maxDateSource` or `_minDateSource` on the formKit schema. The value should be the existing field name. It will looking the value on `formData` and will use that as min date or max date
   * @param node FormkitNode
   * @param date selected Date
   */
  setDisabledDays: (node: FormKitNode, date: Date) => {
    const formValues = toRaw(formData.value)
    const maxDateSource = node.props.attrs._maxDateSource
    const minDateSource = node.props.attrs._minDateSource
    if (maxDateSource) {
      const target: string = formValues[maxDateSource] as string
      if (target) {
        return date > new Date(target)
      }
    }
    if (minDateSource) {
      const target: string = formValues[minDateSource] as string
      if (target) {
        return date < new Date(target)
      }
    }
    return false
  },
  /* Allow fetching options with more customisation */
  getoptions,
  ida: (groupName: groupName, ...filterParams: string[]) => {
    return optionsStore.options(groupName, i18Store.code, filterParams)
  },
  formula: { // calculate input form
    cfm2ff4: (node: Event) => {
      const currentFieldName = (node?.target as HTMLInputElement).name
      const currentValue = getNode(currentFieldName)?.value as number
      const communityMettings = getNode('community_meetings')
      const communityTraining = getNode('community_training')
      const labourIncentive = getNode('labour_incentive')
      const projectAdminCost = getNode('project_admin_cost')
      const totalAmount = getNode('total_amount')
      const multiplier: Record<string, number> = {
        community_meetings: 0.1,
        community_training: 0.15,
        labour_incentive: 0.50,
        project_admin_cost: 0.25
      }

      const total = (currentFieldName === 'total_amount' ? ((totalAmount?.value ?? 0) as number) : (currentValue / multiplier[currentFieldName]))
      if (currentFieldName !== 'total_amount') {
        totalAmount?.input(parseFloat(total.toFixed(3)))
      }
      communityMettings?.input(parseFloat((total * multiplier.community_meetings).toFixed(3)))
      communityTraining?.input(parseFloat((total * multiplier.community_training).toFixed(3)))
      labourIncentive?.input(parseFloat((total * multiplier.labour_incentive).toFixed(3)))
      projectAdminCost?.input(parseFloat((total * multiplier.project_admin_cost).toFixed(3)))
    },
    ff14: (node: Event) => {
      let total = 0
      let status = i18Store.gettext('Insufficient')
      for (const key in formData.value) {
        if (formData.value[key] === '1') {
          total += 1
        }
      }
      if (total >= 7 && total <= 10) {
        status = i18Store.gettext('Moderated')
      } else if (total > 10) {
        status = i18Store.gettext('Satisfied')
      }
      return {
        total,
        status
      }
    },
    pom1: (node: Event) => {
      /**
       * Updates the `total value` fields when the `operational` or `infrastructure` field
       * is updated
       * Note that this depends on using correct ID's (repeater as suffix) for the fields,
       * these should be set through the Django admin
       * See details on https://github.com/catalpainternational/partisipa-import/pull/1291
       */
      const parentForm = (node.target as HTMLDivElement)?.closest('.formkit-item')
      const repeaterIndex = parentForm?.getAttribute('data-index')
      const operationalFundField = getNode(`operational_fund_${repeaterIndex}`)
      const infrastructureFundField = getNode(`infrastructure_fund_${repeaterIndex}`)
      const operationalFund = Number(operationalFundField?.value ?? 0)
      const infrastructureFund = Number(infrastructureFundField?.value ?? 0)
      const totalFundField = getNode(`total_fund_${repeaterIndex}`)
      const total = operationalFund + infrastructureFund
      // If the field seems to not be reactive check that there are no undefined values
      // in the variables above
      totalFundField?.input(parseFloat(total.toFixed(2)))
    }
  },

  getCurrentDate: () => {
    const currDate = new Date()
    return {
      day: currDate.getDate(),
      month: currDate.getMonth(),
      year: currDate.getFullYear()
    }
  },

  repeaterRemoveAction: (event: MouseEvent) => {
    const target = event.target as HTMLButtonElement
    const index = target.dataset.index
    const repeaterName = target.dataset.repeaterid
    const values: Record<string, FormValue | FormValue[]> = formData.value

    if (typeof index !== 'undefined' && typeof repeaterName !== 'undefined') {
      const repeaterData = values[repeaterName] as FormValue[] // Assuming FormValue is the type of your repeater data
      const indexToRemove = parseInt(index, 10)

      if (!isNaN(indexToRemove) && indexToRemove >= 0 && indexToRemove < repeaterData.length) {
        modal.open('confirm', {
          label: 'Delete',
          modalTitle: 'Are you sure?',
          modalCaption: 'Do you really want to delete this data? This process cannot be undone',
          callback: async () => {
            repeaterData.splice(indexToRemove, 1)
            modal.close()
          }
        })
      }
    }
  }
})

/**
 * disable the form if status = `SUBMITTED`
 */
const disabledForm: Ref<boolean> = computed(() => {
  return submission.value?.status === FormStatus.SUBMITTED || submission.value?.status === FormStatus.PENDING_SUBMIT || props.disabled || store.getFormByFormType(props.formType)?.status === FORM_SCHEMA_STATUS_DRAFT
})

/**
 *
 * @param members membersArray
 * @param id string
 * @returns RepeaterValidationResult
 * to find repetition of the position field
 */
function hasRepeatedPosition (members: membersArray, id:string): validationInfo {
  const positions = new Set<string>()
  for (const member of members) {
    if (positions.has(member.position)) {
      return {
        valid: false,
        message: i18Store.gettext('No repetition of the position'),
        id
      }
    }
    positions.add(member.position)
  }
  return {
    valid: true,
    message: 'Success',
    id
  }
}

/**
 * trigger html validation
 */

const htmlFormValidity = () => {
  return (document.getElementById('mainform') as HTMLFormElement).checkValidity()
}

/**
 * Save as draft handler
 * It will set `submissionType` as draft that will be used in the `handleSubmit` function
 * then will trigger form to be submitted
 */

async function saveDraft () {
  submissionType.value = 'draft'
  await htmlFormValidity()
}
/**
 * Form submit handler
 * both draft and submit will call this function. but inside this function we have condition based on `submissionType` to determine whether its draft or submit
 */
const handleSubmit = async () => {
  const schema = store.getFormByFormType(props.formType)
  if (schema?.status === FORM_SCHEMA_STATUS_DRAFT) return // if status is DRAFT dont proceed the submission

  const validation = await triggerValidation()
  const htmlValidation = await htmlFormValidity()
  if (store.activeSubmission && htmlValidation && validation) {
    const newFormData = unwrapNested(formData.value)
    await store.activeSubmission.replaceData(newFormData)

    if (submissionType.value === 'draft') { // save as draft
      await store.saveActiveSubmissionDraft()
      toast.success(i18Store.interpolate(i18Store.gettext('%(formName)s has been saved'), { formName: i18Store.pgettext('form', props.formName || 'Form') }))
      router.push({ name: 'submissions', params: { formType: props.formType } })
    } else if (submissionType.value === 'submit') { // submit to database
      if (props.requiredUploadForm) { // if requiredUploadForm = true , it will triggered the upload form validataion
        const hasUploadedDocs = uploadFormRef.value?.triggerValidation() // validate uploadForm component
        if (!hasUploadedDocs) {
          return
        }
      }

      appStore.$state.loading = true
      await store.uploadFiles(uploadedFiles.value).then(async () => {
        await store.activeSubmission?.replaceData(newFormData)
        await store.saveActiveSubmissionSubmit()
      })
      appStore.$state.loading = false
      showModal.value = true // display success modal
    }
  }
}

/**
 * Save to database handler
 * This will update `submissionType` = `submit` that will be used in the `handleSubmit` function
 * after that it will trigger the form to be submitted
 */
async function submit () {
  submissionType.value = 'submit'
  await htmlFormValidity()
}

/**
 * We have added a custom property for FormKit schema called `validationRules`
 * The purpose of this property is to add custom validation for repeater component.
 * For example we have a repeater that have gender field. In those section, it will only allow 2 male and 2 female.
 * This function is to handle those kind of custom validation
 */
const triggerValidation = async () => {
  const topLevelSchema = await formStore.generateFormSchema(props.formType)
  if (typeof topLevelSchema === 'undefined') return
  const activeFormSchema = (topLevelSchema.children ?? []) as FormkitInput[]
  const result: validationInfo[] = []
  activeFormSchema.forEach((schema) => {
    (schema.children ?? []).forEach((sch) => {
      // check whether repeater has validationRules or not
      if (!('validationRules' in sch)) return // 'validationRules' is not a property in DOM nodes, so we return
      if (!(typeof sch.validationRules !== 'undefined' && typeof formSchemaData[sch.validationRules as keyof typeof formSchemaData] !== 'undefined')) return
      // call validation function from formSchemaData
      const validationFunction = formSchemaData[sch.validationRules as keyof typeof formSchemaData] as () => validationInfo
      const validate = validationFunction()
      if (validate.valid) return
      // if validate.valid is false then show error message
      const formCard = document.getElementById(validate.id as string)
      if (formCard === null) return
      formCard?.classList.add('hasError')
      const formCardMessage = formCard.querySelector('.form-card-message')
      if (formCardMessage === null) return
      formCardMessage.innerHTML = validate.message as string
      result.push(validate)
    })
  })
  const error = result.filter(rslt => !rslt.valid)
  return error.length < 1
}

/**
 * on Input handler
 */
function onFormInput () {
  emit('update:modelValue', formData.value)
}

/**
 * generate FormKit schema and set initial values
 */
const schemaGenerator = async () => {
  const schema = await formStore.generateFormSchema(props.formType)
  if (typeof schema === 'undefined' || typeof schema === 'string' || !Array.isArray(schema.children)) return
  // emit formkit schema that will be used in `FormDetail.vue`
  emit('update:schema', schema)

  const schemas: Record<string, schemaInformation> = {}
  for (const input of (schema.children ?? []) as FormkitInput[]) {
    let childrens = (Array.isArray(input.children) ? [...input.children] : [])
    if (isDataCollection.value && authStore.user_municipality) { // if its in `/form/` page and the user has been assigned to a municipality, then it will disabled the `district` field
      childrens = childrens.map(item => (item.key === 'district') ? { ...item, disabled: true } : item)
    }
    schemas[input.id] = {
      header: computed(() => i18Store.gettext(input.title)),
      id: input.id,
      schema: childrens,
      icon: input.icon,
      sectionTitle: computed(() => i18Store.gettext(input.sectionTitle ?? ''))
    }
  }
  formSchemas.value = schemas
}

/**
 * Add green border on the card, that indicate the active section, when we focus on the field
 */
const onInputListener = () => {
  document.addEventListener('focus', (event: FocusEvent) => {
    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLButtonElement || event.target instanceof HTMLSelectElement) {
      setActiveCard(event.target)
    }
  }, true)

  document.addEventListener('change', (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target?.files instanceof FileList) {
      setActiveCard(target)
    }
  }, true)
}
const setActiveCard = (target: HTMLInputElement | HTMLButtonElement | HTMLSelectElement) => {
  const formCards = document.querySelectorAll('.form-card')
  formCards.forEach(card => {
    card.classList.remove('active')
  })
  const parentCard = target.closest('.form-card')
  if (parentCard) {
    parentCard.classList.add('active')
    parentCard.classList.remove('hasError')
    const formCardMessage = parentCard.querySelector('.form-card-message')
    if (formCardMessage) {
      formCardMessage.innerHTML = ''
    }
  }
}

/**
 * set `district` value = authStore.user_municipality, if its in `/form/` page
 */
function setUserMunicipality () {
  if (authStore.user_municipality && isDataCollection.value) {
    formData.value.district = authStore.user_municipality as unknown as FormValue
  }
}

/**
 * watch activeRevisionSubmission changes, if there is an update it will change the existing formData value
 */
watch(activeRevisionSubmissionComputed, (newValue) => {
  if (newValue) {
    formData.value = newValue?.fields
  }
})

watch(isUploadFormUpdated, (newValue) => {
  emit('update:isFormUpdated', newValue)
})

onMounted(async () => {
  formStore.$subscribe(() => { schemaGenerator() })
  isLoading.value = true
  if (!props.isReview) formStore.initialize()
  isLoading.value = false
  onInputListener()
  setUserMunicipality()
})

/**
 * This function updates the uploaded files and emits an event to notify other parts of the application.
 * @param files FormFile[]
 */
const updateUploadedFiles = (files: FormFile[]) => {
  emit('update:files', files)
}

/**
 * expose triggerValidation for uploadForm component
 */
function triggerUploadFormValidation () {
  return uploadFormRef.value?.triggerValidation()
}

defineExpose({ triggerUploadFormValidation })

/** That should be the end of all form-specific logic */

</script>

<template>
  <div>
    <modal-success
      v-if="showModal"
      ref="modalSuccessRef"
      :redirect="{ name: 'submissions', params: { formType: props.formType } }"
    >
      <template #title>
        {{ i18Store.interpolate(gettext('Success! Your %(formName)s has been submitted'), { formName: pgettext("form", props.formName || 'form')} ) }}
      </template>
      <template #description>
        {{ gettext('Thanks for submitting') }}
      </template>
    </modal-success>
  </div>
  <LoadingAnimation
    v-if="isLoading"
    theme="fixed"
  />
  <FormKit
    v-if="!isLoading && formSchemas"
    id="mainform"
    type="form"
    name="submissionform"
    :actions="false"
    :disabled="disabledForm"
    @input="onFormInput"
    @submit="handleSubmit"
  >
    <FormKit
      v-if="(typeof formData.id !== 'undefined')"
      key="id"
      type="hidden"
      :value="formData.id.toString()"
    />

    <!-- Sections for the form are loaded as individual "Schemas" within a single "FormKit" component -->
    <div
      v-for="schemaInfo in formSchemas"
      :key="schemaInfo.id"
    >
      <h2
        v-if="schemaInfo.sectionTitle"
        class="text-lg lg:text-xl font-bold text-center mb-5 mt-8 lg:mt-10 text-zinc-700"
      >
        {{ schemaInfo.sectionTitle }}
      </h2>
      <basic-card
        :id="`card-${schemaInfo.id}`"
        class="px-4 py-6 lg:px-11 mb-7 !overflow-visible form-card"
      >
        <collapse-box
          :id="schemaInfo.id"
          active
        >
          <template #collapseToggle>
            <h3 class="text-lg md:text-1.5xl font-bold flex items-center text-left">
              <i
                class="text-gray-400 mr-2"
                :class="schemaInfo.icon"
              ></i> {{ schemaInfo.header }}
            </h3>
          </template>
          <template #collapseContent>
            <p class="text-left mt-2 form-card-message text-red-500"></p>
            <div class="mb-5 mt-4 max-w-[476px] text-left">
              <!-- This group uses v-model to ensure that we're correctly setting properties in the formData object -->
              <FormKit
                v-model="formData"
                type="group"
              >
                <FormKitSchema
                  :schema="schemaInfo.schema"
                  :data="formSchemaData"
                />
              </FormKit>
            </div>
          </template>
        </collapse-box>
      </basic-card>
    </div>

    <div
      v-if="!isReview"
      class="fixed z-[10000] left-0 bottom-0 w-full bg-white border-t border-t-stone-50 py-4 md:py-5 px-5 shadow-lg flex justify-center md:justify-end lg:justify-center items-center "
    >
      <FormKit
        id="draft"
        type="submit"
        wrapper-class="!mt-0 mr-4"
        input-class="!bg-white !text-black border-2 !border-emerald-600 hover:!bg-emerald-600 hover:!text-white"
        :disabled="disabledForm"
        @click="saveDraft"
      >
        <span class="hidden md:inline-block">{{ gettext('Save as draft') }}</span>
        <span class="block md:hidden">{{ gettext('Save') }}</span>
      </FormKit>
      <FormKit
        id="submit"
        type="submit"
        wrapper-class="!mt-0 mr-4"
        input-class="md:!w-[160px]"
        @click="submit"
      >
        {{ gettext('Submit') }}
      </FormKit>
    </div>

    <!-- This is a special section for form uploads -->
    <basic-card
      key="uploads"
      class="px-4 py-6 lg:px-11 mb-7 form-card"
    >
      <collapse-box
        id="uploads"
        active
      >
        <template #collapseToggle>
          <h3 class="text-lg md:text-1.5xl font-bold">
            <i class="las la-file-alt text-gray-400 mr-2"></i>{{ gettext("Documents") }}
          </h3>
        </template>
        <template #collapseContent>
          <div class="mb-5 mt-4 max-w-[476px] text-left">
            <UploadForm
              ref="uploadFormRef"
              v-model="uploadedFiles"
              v-model:is-form-updated="isUploadFormUpdated"
              :required="requiredUploadForm"
              :form-key="props.formKey"
              :disabled="disabledForm"
              @update:model-value="updateUploadedFiles"
            />
          </div>
        </template>
      </collapse-box>
    </basic-card>
  </FormKit>
</template>

<style lang="postcss">
.form-card {
  @apply !border-2 border-white;
  &.active {
    @apply border-2 border-emerald-600;
  }
  &.hasError {
    @apply border-2 border-red-500;
  }
}

  /* this styles are being used to hide fields in the `/submit/:formType` and `/form/:formKey`.
  to hide a field, we need to add additional props ( `{"outerClass": "hide-in-data-collection"}` ) thought django admin. */
  /* route-submission = `/submit/:formType` , route-formDetail = `/form/:formKey` */
.route-submission, .route-formDetail {
  #mainform {
    .hide-in-data-collection {
      @apply !hidden;
    }
  }
}
</style>
