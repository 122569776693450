import L, { PathOptions, PointExpression } from 'leaflet'
import { ColorList } from '../_types/colours'

interface MarkerFactoryOptions {
  scale: number
  fill: string
  className?: string
}

interface MapMarkersOptions {
  editable?: boolean
  fill?: string
}

/* The default value of the marker is 14px by 20px */
const markerSize = {
  width: 14,
  height: 20
}

const osmLayer = () => {
  return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
    crossOrigin: 'anonymous'
  })
}

const mapTilerLayer = () => {
  const key = 'tR60fJE11Sup5WmKtYal'
  return L.tileLayer(`https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=${key}`, {
    tileSize: 512,
    zoomOffset: -1,
    minZoom: 1,
    attribution: '\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
    crossOrigin: 'anonymous'
  })
}

const markerFactory = ({ scale, fill }: MarkerFactoryOptions) => {
/**
 * markerFactory function
 *
 * This function generates a marker icon for use in mapping applications.
 * It returns a 'teardrop' SVG icon of a given size and color.
 * The 'native' size of this marker is 14x20 px.
 *
 * @param {MarkerFactoryOptions} opts - An object that contains the scale factor for the marker size.
 * @property {number} opts.scale - The scale factor for the marker size.
 *
 * @returns {string} A string representation of the SVG icon.
 */
  const width = scale * markerSize.width
  const height = scale * markerSize.height

  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" xmlns:v="https://vecta.io/nano">
      <g transform="scale(${scale} ${scale})">
        <path
            d="M6.75 0c1.22 0 2.35.3 3.4.9a7 7 0 0 1 2.44 2.43 6.6 6.6 0 0 1 .91 3.39 6.1 6.1 0 0 1-.28 1.68c-.16.53-.4 1.16-.73 1.9a42 42 0 0 1-2.09 4.02l-2.1 3.36L6.75 20l-1.57-2.3-2.1-3.36a42 42 0 0 1-2.09-4.02C.68 9.57.44 8.94.28 8.4A6.1 6.1 0 0 1 0 6.73a6.6 6.6 0 0 1 .91-3.39A7 7 0 0 1 3.35.91C4.4.3 5.53 0 6.75 0z"
            fill="${fill}" />
        <circle fill="#fff" cx="6.75" cy="6.75" r="2.25" />
      </g>
    </svg>`
}

const iconFactory = ({ scale, fill, className }: MarkerFactoryOptions) => {
  const iconSize: PointExpression = [markerSize.width * scale, markerSize.height * scale]
  const iconAnchor: PointExpression = [markerSize.width * (scale / 2), markerSize.height * scale]
  return L.divIcon({
    // html: marker_blue, //
    html: markerFactory({ scale, fill }),
    className,
    iconSize,
    iconAnchor
  })
}

const defaultOptions: MarkerFactoryOptions = { scale: 2, fill: ColorList.MapMarkerBlue, className: '' }
const editableMarkerOptions: MarkerFactoryOptions = { scale: 2, fill: ColorList.MapMarkerBlue, className: 'hover:cursor-move hover:opacity-70' }

const mapMarkers = (props: MapMarkersOptions) => {
  const options = props.editable === true ? editableMarkerOptions : defaultOptions // if its an editable marker it will have additional className which are `hover:cursor-move hover:opacity-70`
  return iconFactory({ ...options, ...props })
}

// Color for Sukus on the map
const colors: Pick<PathOptions, 'fillColor' | 'color'> = {
  fillColor: ColorList.PrimaryTeal,
  color: ColorList.PrimaryTeal
}

const normalLayer: PathOptions = {
  ...colors,
  weight: 1,
  fillOpacity: 0,
  fill: true
}

const activeLayer: PathOptions = {
  ...colors,
  weight: 2,
  fillOpacity: 0.6,
  fill: true
}

const activePoint: PathOptions = {
  ...colors,
  weight: 3,
  fillOpacity: 0.3,
  fill: true
}

export {
  mapMarkers,
  normalLayer,
  activeLayer,
  activePoint,
  osmLayer,
  mapTilerLayer
}
