<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import DataTable from '../../components/commons/DataTable.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import { reactive, ref, computed, ComputedRef } from 'vue'
import { Header, Item } from 'vue3-easy-data-table'
import { useI18NStore } from '../../stores/i18n'
import { useOptionsStore } from '../../stores/options'
import { useLocationStore } from '../../stores/locations'
import { FormKit, FormKitSchema } from '@formkit/vue'
import { OptionType } from '../../_types/components/forms/select-dropdown'
import { complaintsCategories, complaintsMethod, complaintStatus } from '../../_constants/options.constant'
import { components } from '../../_api-services/openapi'
import { useSukuComplaintStore } from '../../stores/sukucomplaint'
import { useRouter } from 'vue-router'
import { complaintSchemaIn } from '../../_formkit/schema/complaint.schema'

type ComplaintSchemaIn = components['schemas']['ComplaintSchemaIn']
const i18nstore = useI18NStore()
const sukuComplaintStore = useSukuComplaintStore()
const optionsStore = useOptionsStore()
const locationStore = useLocationStore()
const router = useRouter()

const showCreateForm = ref(false)

await optionsStore.initialize()
await locationStore.initialize()
await sukuComplaintStore.initialize()

const schemaData = reactive({
  gettext: i18nstore.gettext,
  getLocationByLevel: (level: number): OptionType[] => {
    const locations = locationStore.getByLevel(level).map((opt) => { return { label: opt.name, value: opt.path.at(-1) } })
    return locations as OptionType[]
  },
  getCurrentDate: () => {
    const currDate = new Date()
    return {
      day: currDate.getDate(),
      month: currDate.getMonth(),
      year: currDate.getFullYear()
    }
  },
  complaintsCategories: computed(() => complaintsCategories.map(it => ({ value: it.value, label: i18nstore.gettext(it.label) }))),
  complaintsMethod: computed(() => complaintsMethod.map(it => ({ value: it.value, label: i18nstore.gettext(it.label) })))
})

const headers: Header[] = reactive([
  { text: 'Date received', value: 'date', sortable: true },
  { text: 'Complainant\'s name', value: 'complaint_name', sortable: true },
  { text: 'Organization / institution', value: 'organization', sortable: true },
  { text: 'Suku', value: 'suco', sortable: true },
  { text: 'Duration', value: 'duration', sortable: true },
  { text: 'Last update', value: 'last_update', sortable: true },
  { text: 'Status', value: 'complaint_status' },
  { text: 'View', value: 'view' }
])

const items: ComputedRef<Item[]> = computed(() => sukuComplaintStore.getComplaintsTableData)

const submit = async (dataIn: ComplaintSchemaIn) => {
  dataIn.status = 1
  const req = await sukuComplaintStore.createComplaint(dataIn)
  if (req !== undefined) {
    showCreateForm.value = false
  }
}

</script>
<template>
  <base-layout>
    <template #title>
      <div class="flex items-center">
        <router-link
          :to="`/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
        </router-link>
        <h1 class="text-2.5xl font-black">
          {{ gettext('Complaints') }}
        </h1>
      </div>
    </template>
    <content-section>
      <h1 class="text-2.5xl font-black">
        {{ gettext('Manage complaints') }}
      </h1>
      <p class=" text-left text-zinc-700 mb-10">
        {{ gettext('On this page, the complaint management team can manage complaints submitted at the Suku level, including verifying complaint submission, updating and tracking status and closing complaints.') }}
      </p>
      <DataTable
        :headers="headers"
        :items="items"
        theme-color="dark"
        spacing="narrow"
        filter-placeholder="Search"
        filter-suffix-icon="la-search !text-xl text-gray-400"
        redirect-path="complaintDetails"
        :filters="{
          field: 'complaint_status',
          options: complaintStatus
        }"
        :filter-fields-schema="[
          {
            $formkit: 'autocomplete',
            label: i18nstore.gettext('Suco'),
            placeholder: i18nstore.gettext('Select a Suco'),
            name: 'suco',
            key: 'suco',
            id: 'suco',
            options: '$ida(suku)',
            inputClass: '!pl-3 text-sm',
            dropdownWrapperClass: 'max-h-[180px]',
            listitemClass: 'stop-click-outside',
            optionClass: 'stop-click-outside',
            emptyMessage: i18nstore.gettext('No suku found'),
            labelClass: 'text-sm'
          }
        ]"
        @view="(item: Item) => router.push({
          name: 'complaintDetails',
          params: {
            id: item.view
          }
        })"
      >
        <template #header-action>
          <basic-button @click="() => showCreateForm = true">
            <i class="las la-plus text-2xl mr-1 md:mr-2"></i> {{ gettext('Add complaint') }}
          </basic-button>
        </template>
      </DataTable>
    </content-section>
    <modal-box
      v-if="showCreateForm"
      class="text-center"
      spacing="md"
    >
      <template #header>
        <h4 class="text-xl md:text-1.5xl font-black flex items-center text-left">
          <i class="text-gray-400 mr-3 text-2xl las la-user"></i>
          {{ gettext('Add complaint details') }}
        </h4>
      </template>
      <button
        class="fixed right-6 top-6 text-lg w-[28px] h-[28px] rounded-full bg-emerald-600 text-white"
        @click="() => showCreateForm = false"
      >
        <i class="las la-times"></i>
      </button>
      <FormKit
        ref="form"
        type="form"
        :actions="false"
        @submit="submit"
      >
        <div class="text-left">
          <FormKitSchema
            :schema="complaintSchemaIn()"
            :data="schemaData"
          />
        </div>
        <FormKit
          type="submit"
          outer-class="max-w-[254px] mx-auto mt-8"
          prefix-icon="search"
        >
          <i class="las la-check text-2xl mr-3"></i>
          {{ gettext('Save complaint') }}
        </FormKit>
        <basic-button
          theme="text"
          class="mt-2"
          @click="() => showCreateForm = false"
        >
          {{ gettext('Cancel') }}
        </basic-button>
      </FormKit>
    </modal-box>
  </base-layout>
</template>
