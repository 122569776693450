import { defineStore } from 'pinia'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
import { SUPPLIER_CREATE_MESSAGES } from '../_constants/toastmessages.constant'
import { useProjectManagerStore } from './projectmanager'
import { useLocationStore } from './locations'
import { useGeoLocationStore } from './geolocation'
import { useSukuProfileStore } from './sukuprofile'

type SupplierSchema = components['schemas']['SupplierSchemaIn']
type SupplierSchemaData = components['schemas']['SupplierSchema']
interface initialState {
  suppliers: SupplierSchemaData[]
}
const toast = useToast()

export const useSuppliersStore = defineStore('suppliers', {
  state: () => {
    const initialState: initialState = {
      suppliers: []
    }
    return initialState
  },
  getters: {

    getSuppliers (state) {
      return () => {
        const sukuProfileStore = useSukuProfileStore()
        const zSucoId = sukuProfileStore.$state.activezSukuId
        return state.suppliers.filter(supplier => { // filter suppliers by Suco
          const findSuco = typeof supplier?.suppliersuco_set !== 'undefined' ? supplier?.suppliersuco_set.some(item => item.suco === zSucoId) : false
          return findSuco
        }).map((supplier, index) => ({ // convert the data to datatable format
          no: index + 1,
          name: supplier.name,
          director: supplier.director,
          contact_number: supplier.contact_number,
          email: supplier.email,
          active: supplier.active,
          status: supplier.active,
          view: supplier.id
        }))
      }
    },
    getSupplier (state) {
      return (supplierId: number) => {
        return state.suppliers.find(supplier => supplier.id === supplierId)
      }
    },
    getSuppliersByProjects (state) {
      return () => {
        const projectmanagerStore = useProjectManagerStore()
        const projectKey: string | undefined = projectmanagerStore.getProjectInformation()?.submission
        return state.suppliers.filter(supplier => {
          const findProject = typeof supplier?.supplierproject_set !== 'undefined' ? supplier?.supplierproject_set.some(item => item.project === projectKey) : false
          return findProject
        })
      }
    },
    getSuppliersAsOption (state) {
      return () => {
        const projectmanagerStore = useProjectManagerStore()
        const activeProjectDetail = projectmanagerStore.getProjectInformation()
        return state.suppliers.filter(supplier => { // filter suppliers by Suco
          const findSuco = typeof supplier?.suppliersuco_set !== 'undefined' ? supplier?.suppliersuco_set.some(item => item.suco === activeProjectDetail?.suco) : false
          return findSuco
        }).map(supplier => ({
          value: supplier.id,
          label: supplier.name
        }))
      }
    }
  },
  actions: {
    async initialize () {
      const { data, error } = await client.GET('/api/supplier/all', {})

      this.suppliers = data ?? []
    },
    async createSupplier (body: SupplierSchema, successMessage?: string) {
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = sukuProfileStore.$state.activezSukuId
      if (typeof zSucoId === 'undefined') return
      body.suco = [zSucoId]
      const { data, error } = await client.POST('/api/supplier/create', { body })
      if (typeof data !== 'undefined') {
        toast.success(useI18NStore().gettext(typeof successMessage !== 'undefined' ? successMessage : typeof body.id !== 'undefined' ? SUPPLIER_CREATE_MESSAGES.update : SUPPLIER_CREATE_MESSAGES.success))
      } else {
        toast.success(useI18NStore().gettext(SUPPLIER_CREATE_MESSAGES.error))
      }
      if (body.id !== undefined) {
        const findSupplierIndex = this.suppliers.findIndex(supplier => supplier.id === body.id)
        this.suppliers[findSupplierIndex] = data as SupplierSchemaData
      } else {
        this.suppliers.push(data as SupplierSchemaData)
      }
      return data
    },
    /**
     * update supplier data. if remove = `true` it will remove the project key form supplier data
     * @param supplierId number
     * @param remove boolean
     */
    async updateSupplierProject (supplierId: number | undefined, remove = false) { // add the supplier to the project
      const sukuProfileStore = useSukuProfileStore()
      if (typeof supplierId === 'undefined') return
      const projectManagerStore = useProjectManagerStore()
      const projectDetail = projectManagerStore.getProjectInformation()
      const selectedSupplier = this.getSupplier(supplierId)
      if (typeof selectedSupplier !== 'undefined' && projectDetail?.submission !== undefined) {
        const suco: number[] = selectedSupplier.suppliersuco_set?.map(suco => suco.suco) ?? []

        if (suco.length > 0) { // set active Suco based on TF611 suco
          sukuProfileStore.$state.activezSukuId = suco[0]
        }

        let projects: string[] = selectedSupplier.supplierproject_set?.map(project => project.project) ?? []
        if (remove) {
          projects = projects.filter(project => project !== projectDetail?.submission)
        } else {
          projects.push(projectDetail?.submission)
        }
        const payload: SupplierSchema = {
          name: selectedSupplier.name,
          director: selectedSupplier?.director,
          address: selectedSupplier?.address,
          contact_number: selectedSupplier?.contact_number,
          email: selectedSupplier?.email,
          active: selectedSupplier.active === true,
          id: selectedSupplier?.id,
          suco,
          projects
        }
        const data = await this.createSupplier(payload, remove ? SUPPLIER_CREATE_MESSAGES.delete : SUPPLIER_CREATE_MESSAGES.add)
        if (typeof data !== 'undefined') {
          const findSupplierIndex = this.suppliers.findIndex(supplier => supplier.id === supplierId)
          this.suppliers[findSupplierIndex] = data
        }
      }
    }
  }
})
