<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import { FormKitNode } from '@formkit/core'
import { ref, Ref } from 'vue'

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  context: Object
})

const value: Ref<string> = ref('')

</script>
<template>
  <FormKit
    :id="context?.id"
    v-bind="context?.attrs"
    :key="context?.key"
    v-model="value"
    type="dropdown"
    :options="context?.attrs.options"
    :name="context?.node?.name"
    select-icon="angleDown"
  >
    <template #selection="{ option, classes }">
      <div
        class="flex items-center"
        :class="classes.option"
      >
        <i
          class="text-xl mr-2"
          :class="option.value"
        ></i>
        <span>
          {{ option.label }}
        </span>
      </div>
    </template>
    <template #option="{ option, classes }">
      <div
        class="flex items-center"
        :class="classes.option"
      >
        <i
          class="text-xl mr-2"
          :class="option.value"
        ></i>
        <span>
          {{ option.label }}
        </span>
      </div>
    </template>
  </FormKit>
</template>
