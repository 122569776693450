import { FormKitSchemaFormKit } from '@formkit/core'
import { generateMonthsAsOptions, generateYears } from '../../../_helpers/common.helpers'

export const date: FormKitSchemaFormKit = {
  $formkit: 'datepicker',
  name: 'date',
  id: 'date',
  key: 'date',
  label: '$gettext("Date")',
  format: 'DD/MM/YYYY',
  calendarIcon: 'calendar',
  nextIcon: 'angleRight',
  prevIcon: 'angleLeft',
  _currentDate: '$getCurrentDate',
  sectionsSchema: {
    day: {
      children: [
        '$day.getDate()',
        {
          if: '$attrs._currentDate().day === $day.getDate()',
          children: [
            {
              if: '$attrs._currentDate().month === $day.getMonth()',
              children: [
                {
                  $el: 'div',
                  if: '$attrs._currentDate().year === $day.getFullYear()',
                  attrs: {
                    class: 'formkit-day-highlight'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
}

export const generateDatepicker = (props: Record<string, string>): FormKitSchemaFormKit => {
  return {
    ...date,
    ...props
  }
}

export const month: FormKitSchemaFormKit = {
  $formkit: 'select',
  name: 'month',
  label: '$gettext("Month")',
  options: generateMonthsAsOptions(),
  placeholder: '$gettext("Select month")'
}

export const years: FormKitSchemaFormKit = {
  $formkit: 'select',
  name: 'year',
  label: '$gettext("Year")',
  options: generateYears(2012),
  placeholder: '$gettext("Select year")'
}

export const activityName: FormKitSchemaFormKit = {
  $formkit: 'select',
  placeholder: '$gettext("Please select")',
  label: '$gettext("Activity name")',
  options: '$ida(cycle, "current")',
  name: 'activity',
  key: 'activity',
  id: 'activity'
}

export const repeaterRemoveProps = {
  removeLabel: '$gettext("Remove")',
  removeAction: '$repeaterRemoveAction',
  sectionsSchema: {
    remove: {
      children: [
        {
          $el: 'button',
          if: '$value.length > 1',
          attrs: {
            type: 'button',
            'data-index': '$index',
            'data-repeaterid': '$id',
            onClick: '$attrs.removeAction',
            class: 'disabled:hidden cursor-pointer flex items-center justify-center flex-row-reverse h-[50px] rounded-2.5xl px-4 w-full bg-white border-2 border-solid border-emerald-600 text-zinc-900 hover:bg-emerald-600 hover:text-white font-bold text-base'
          },
          children: ['$attrs.removeLabel']
        }
      ]
    }
  }
}

export const gender = {
  $formkit: 'select',
  label: '$gettext("Gender")',
  options: '$ida(gender)',
  placeholder: '$gettext("Please select")',
  name: 'gender'
}

export const disability = {
  $formkit: 'select',
  placeholder: '$gettext("Please select")',
  label: '$gettext("Person with a disability")',
  options: '$ida(yesno)',
  name: 'person_with_disability'
}

// This custom component generates a unique UUID if the input
// value is not initially set
export const repeaterUniqueIdField = {
  $formkit: 'uuid',
  name: 'uuid',
  readonly: true
}
