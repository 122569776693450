<script setup lang="ts">
import L, { GeoJSON, Layer, Path } from 'leaflet'
import BaseLayout from '../../layouts/BaseLayout.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import { ref, onBeforeUnmount, onMounted, computed, toRaw, Ref, nextTick } from 'vue'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useRouter } from 'vue-router'
import { FormKitOptionsProp } from '@formkit/inputs'
import { useAppStore } from '../../stores/app'
import { useSukuProfileStore } from '../../stores/sukuprofile'
import { activeLayer, normalLayer, osmLayer } from '../../_constants/map.constant'

const appStore = useAppStore()
const router = useRouter()
const sukuProfileStore = useSukuProfileStore()

const locationStore = useGeoLocationStore()

const geolocationsOptions = computed(() => locationStore.getGeoLocationOptions ?? [])

const map = ref()
const mapLayers = ref()
const selectedGeolocation: Ref<number | undefined | string> = ref()

const recentSearch = computed(() => locationStore.getGeoLocationRecentSearch ?? [])

onMounted(async () => {
  appStore.$state.loading = false

  mapInitialize()
})

onBeforeUnmount(() => {
  if (map.value) {
    map.value.remove()
  }
})

const mapInitialize = () => {
  map.value = L.map('mapContainer', { zoomControl: false }).setView([-9.12, 125.623], 8)
  osmLayer().addTo(map.value)
  L.control.zoom({ position: 'topright' }).addTo(map.value)
  mapLayers.value = L.geoJSON(locationStore.getGeoLocationData, { onEachFeature }).addTo(map.value).setStyle(normalLayer)
}

/**
 * search input and recent history handler
 * @param value suco id
 */
const onChangeLocation = async (value: unknown) => {
  const newValue: number | undefined = typeof value === 'number' ? value : undefined
  if (typeof newValue === 'undefined' || newValue.toString() === '' || isNaN(newValue)) {
    return
  }
  // unhighlight the previous value
  await locationStore.setActiveGeoLocation(newValue) // set active geolocation based on the Suco ID
  if (!locationStore.activeGeolocation) {
    return
  }
  const activeGeoLocToRaw = toRaw(locationStore.activeGeolocation)
  locationStore.setGeoLocationRecentSearch(activeGeoLocToRaw?.properties) // store the history search to localstorage

  await goToDetailProfile(activeGeoLocToRaw?.properties.id)
}

/**
 * initialize leaflet polygon and event handler using geoJSON data
 * @param feature geoJSON feature
 * @param layer geoJSON layer
 */
function onEachFeature (feature: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>, layer: Layer) {
  layer.bindTooltip(feature?.properties?.name)
  layer.on('mouseover', () => {
    const pathLayer = layer as Path
    pathLayer.setStyle(activeLayer)
  })
  layer.on('mouseout', () => {
    const pathLayer = layer as Path
    pathLayer.setStyle(normalLayer)
  })
  layer.on('click', async () => {
    const id: number = feature?.properties?.id
    await goToDetailProfile(id)
  })
}

const goToDetailProfile = async (id: number) => {
  await locationStore.setActiveGeoLocation(id)
  await sukuProfileStore.setActiveZsucoId()
  router.push(`/suku-manager/${id}/`)
}

</script>
<template>
  <base-layout
    :title="gettext('Suku Manager')"
    :sub-title="gettext('The Suku Manager supports the management and organization of information for each suku. It shows the infrastructure priorities across the sectors.')"
    class="relative"
  >
    <content-section class="!pb-4">
      <div class="flex flex-wrap items-center">
        <FormKit
          v-if="geolocationsOptions.length !== 0"
          v-model="selectedGeolocation"
          outer-class="w-full md:max-w-sm pb-5 md:pb-0 lg:max-w-[437px] !mb-4"
          input-class="h-[50px] !pl-10"
          selection-wrapper-class="hidden"
          type="autocomplete"
          prefix-icon="search"
          :placeholder="gettext('Search')"
          :options="geolocationsOptions"
          selection-appearance="option"
          :empty-message="gettext('No suku found')"
          @input="onChangeLocation"
        >
          <template #option="{ option }">
            <span>
              {{ option.label }} -
              <span class="italic text-gray-400 text-2sm">{{ option.adminPost }}, {{ option.municipality }}</span>
            </span>
          </template>
        </FormKit>
        <span
          v-if="recentSearch?.length > 0"
          class="text-sm md:ml-6 mb-4 mr-4 font-medium"
        >
          <i class="las la-clock mr-[3px]"></i> {{ gettext('Recent searches') }}
        </span>
        <ul
          v-if="recentSearch?.length > 0"
          class="inline-flex flex-wrap mb-4"
        >
          <li
            v-for="(srch, index) in recentSearch"
            :key="index"
            class="text-sm rounded-lg border border-zinc-400 bg-zinc-400/50 hover:bg-zinc-400/20 hover:border-gray-100 pl-3 pr-4 py-1 [&:not(:last-child)]:mr-2 cursor-pointer"
            @click="onChangeLocation(srch.id)"
          >
            <i class="las la-map-marker-alt mr-[2px]"></i> {{ srch.name }}
          </li>
        </ul>
      </div>
    </content-section>
    <content-section :has-border="false">
      <h2 class="leading-tight text-2.5xl font-black lg:mt-8">
        {{ gettext('Explore suku profiles via the national map') }}
      </h2>
      <div class="rounded-[30px] lg:rounded-5xl overflow-hidden mt-10">
        <div
          id="mapContainer"
          class="w-full h-[555px]"
        ></div>
      </div>
    </content-section>
  </base-layout>
</template>
<style>
@import url('leaflet/dist/leaflet.css');
.leaflet-tooltip.l-label {
  border: transparent;
  background: transparent;
  box-shadow: none;
  @apply rounded-full font-bold text-white;
}
.leaflet-interactive {
  @apply !drop-shadow-lg;
}
</style>
