<script setup lang="ts">

</script>

<template>
  <footer class="flex items-center flex-wrap">
    <img
      class="w-[69px] h-69px mr-7.5"
      width="69"
      height="69"
      src="../../assets/logos/RDTL.webp"
      alt="RDTL"
    />
    <img
      class="w-[69px] h-69px mr-7.5"
      width="69"
      height="69"
      src="../../assets/logos/MAE.webp"
      alt="MAE"
    />
    <p class="text-sm max-w-[600px] mt-5 lg:mt-0 w-full lg:w-auto lg:ml-5">
      {{ gettext("Managed by the Government of Timor Leste. Proudly supported by the Australian Government. Designed and developed by Catalpa International") }}
    </p>
  </footer>
</template>
