import { defineStore } from 'pinia'
import { components } from '../_api-services/openapi'
import { client } from '../_api-services/urls'
import { SUBMISSIONS_STATUS_VALUE, FORM_TYPES } from '../_constants/common.constant'
import { useSukuProfileStore } from './sukuprofile'
import { useAppStore } from './app'
import { PreparationDataContext } from '../_types/sukubudget'
import { SUKU_BUDGET_STATUS, SUKU_BUDGET_TYPE, SUKU_BUDGET_TYPES } from '../_constants/sukumanager.constant'
import { useToast } from 'vue-toastification'
import { useI18NStore } from './i18n'
// Define types for specific submission schemas
type Pom1SubmissionContext = components['schemas']['Pom_1Schema']
type Pom1RepeateractivitySchema = components['schemas']['Pom_1RepeateractivitySchema']
type FF12SubmissionContext = components['schemas']['Cfm_12_ff_12Schema']
type FF12RepeaterBalanceContext = components['schemas']['Cfm_12_ff_12RepeaterbalanceSchema']
type FF4SubmissionContext = components['schemas']['Cfm_2_ff_4Schema']
type FF4RepeaterInfrastructureFundContext = components['schemas']['Cfm_2_ff_4RepeaterinfrastructurefundSchema']

interface FF12SubmissionContextExtend extends FF12SubmissionContext {
  suco?: number
  district?: number
  administrative_post?: number
  submitted_from?: string
}

// Define the structure of the monthly reports data
export interface OperationalMonthlyReportDataContext {
  data: FF12SubmissionContext | undefined
  total_operational_receipts: number
  total_operational_expenditures: number
  total_balance: number
  total_infrastructure_receipts: number
  total_infrastructure_materials: number
  total_infrastructure_labour_incentive: number
  total_infrastructure_expenditures: number
  balance_cash: number
  balance_bank: number
}

export interface OperationalMonthlyReportCommulativeContext {
  operational_subsidy_1000: number
  last_year_balance_1001: number
  interest_1002: number
  total_operational_receipts: number
  community_meetings_2000: number
  community_training_2001: number
  incentive_for_suku_2002: number
  project_admin_cost_2003: number
  total_operational_expenditures: number
  bank: number
  cash: number
  pnds_regular_1003: number
  rss_1003: number
  other_1003: number
  pnds_regular_1005: number
  rss_1005: number
  interest_1006: number
  total_infrastructure_receipts: number
  infrastructure_receipts_pnds_infrastructure_subsidy: number
  infrastructure_receipts_last_year_balance: number
  infrastructure_receipts_interest: number
  infrastructure_receipts_others: number
}

export interface OperationalMonthlReportBudget {
  operational_subsidy_1000: number
  last_year_balance: number
  total_operational_receipts: number
  community_meetings_2000: number
  community_training_2001: number
  incentive_for_suku_2002: number
  project_admin_cost_2003: number
  total_operational_expenditures: number
  infrastructure_receipts_pnds_infrastructure_subsidy: number
  interest_1006: number
  total_infrastructure_receipts: number
}

export interface OperationalMonthlyReportBalance {
  operational_fund_balance_this_year: number
  cash: number
  bank: number
  total_operational_balance: number
  difference_balance: number
}

export interface InfrastructureReportData {
  cummulative: Record<string, { materials_3000: number, labour_incentive_3001: number }>
  total: number
  total_cummulative: number
  total_budget: number
  budget: Record<string, { materials_3000: number, labour_incentive_3001: number }>
}

export interface InfrastructureBalanceSchema {
  [key: string]: { cash: number, bank: number, cummulative_cash: number, cummulative_bank: number }
}

export interface InfrastructureMonthlyReportBalanceSchema {
  fund_balance_this_year: number
  balance: InfrastructureBalanceSchema
  difference_balance: number
  total_balance: number
}

// Define the structure of the brought forward data
interface BroughtForwardContext {
  [year: string]: {
    cash: number | undefined
    bank: number | undefined
  }
}

export interface InfrastructureFundAllocationItemContext extends FF4RepeaterInfrastructureFundContext {
  total?: string
}

export interface InfrastructureAllocationFundContext {
  activity_id: number
  funds: InfrastructureFundAllocationItemContext[]
}
export interface InfrastructureAllocationContext {
  total_materials: string
  total_labour_incentive_3001: string
  total_infrastructure_fund: string
  allocationFund: InfrastructureAllocationFundContext[]
}

interface ExpendituresContext {
  [year: string]: number
}

interface MonthlyReportsParamsContext {
  year: number | undefined
  month: number | undefined
}

interface InitialState {
  pom1submissions: Pom1SubmissionContext[]
  ff12submissions: FF12SubmissionContext[]
  selectedFF12Submission: FF12SubmissionContextExtend[]
  ff12submissionsCommulative: FF12SubmissionContext[]
  broughtForward: BroughtForwardContext
  allocationData: FF4SubmissionContext[]
  activeBudget: SUKU_BUDGET_TYPES
  monthlyReportsActiveParams: MonthlyReportsParamsContext
}

const toast = useToast()

export const useSukuBudgetStore = defineStore('sukubudget', {
  state: () => {
    const initialState: InitialState = {
      pom1submissions: [],
      broughtForward: {},
      allocationData: [],
      ff12submissions: [],
      ff12submissionsCommulative: [],
      selectedFF12Submission: [],
      activeBudget: SUKU_BUDGET_TYPE.operational,
      monthlyReportsActiveParams: {
        year: undefined,
        month: undefined
      }
    }
    return initialState
  },
  getters: {
    /**
     * Calculate the brought data based on activeBudget.
     * @param state InitialState
     * @returns BroughtForwardContext
     */
    getBroughtForwardData (state): BroughtForwardContext {
      const broughtForwardData: BroughtForwardContext = {}
      const data = state.ff12submissions.filter(it => it.month === 12) // Filter to 'december' submissions only
      data.forEach(dt => {
        let cash = 0
        let bank = 0
        if (this.activeBudget === SUKU_BUDGET_TYPE.infrastructure) {
          dt?.repeaterBalance?.forEach((fund: FF12RepeaterBalanceContext) => {
            cash += Number(fund.balance_cash ?? 0)
            bank += Number(fund.balance_bank ?? 0)
          })
        } else {
          cash = Number(dt?.cash ?? 0)
          bank = Number(dt?.bank ?? 0)
        }
        broughtForwardData[((dt?.year ?? 0) + 1).toString()] = { // add + 1 because brought forward data will be used for future year
          cash,
          bank
        }
      })
      return broughtForwardData
    },
    /**
     * Get preparation data based on the stored submissions and brought forward data
     * @param state InitialState
     * @returns PreparationDataContext[]
     */
    getPreparationData (state): PreparationDataContext[] {
      const broughtForward = this.getBroughtForwardData
      const expenditures = this.getExpendituresData
      const newData: PreparationDataContext[] = state.pom1submissions.map(submission => {
        let subsidyAllocated = 0
        submission.repeaterActivity?.forEach((activity: Pom1RepeateractivitySchema) => {
          // add condition based on `activeBudget`
          subsidyAllocated += Number((this.activeBudget === SUKU_BUDGET_TYPE.infrastructure ? activity.infrastructure_fund : activity.operational_fund) ?? 0)
        })
        // Retrieve brought forward data based on the submission year
        let broughtForwardBank = 0
        let broughtForwardCash = 0
        if (typeof submission?.year === 'number') {
          broughtForwardBank = broughtForward[(submission.year).toString()]?.bank ?? 0
          broughtForwardCash = broughtForward[(submission.year).toString()]?.cash ?? 0
        }
        const expenditure = typeof submission?.year !== 'undefined' ? (expenditures[submission?.year] ?? 0) : 0
        const status = this.activeBudget === SUKU_BUDGET_TYPE.infrastructure ? submission?.pom1_infrastructure_status : submission?.pom1_operational_status
        // Create a new preparation data object with calculated values
        return {
          year: submission?.year ?? 0,
          subsidy_allocated: subsidyAllocated,
          expenditure,
          brought_forward_bank: broughtForwardBank,
          brought_forward_cash: broughtForwardCash,
          total_budget_allocated: subsidyAllocated + broughtForwardBank + broughtForwardCash,
          status: (status ?? SUKU_BUDGET_STATUS.inactive) as PreparationDataContext['status'] // for testing, marked 2022 as closed fiscal year
        }
      }).sort((a: PreparationDataContext, b: PreparationDataContext) => Number(b.year) - Number(a.year))
      return newData
    },
    /**
     * get allocation data for a specific year
     * @param state InitialState
     * @returns FF4SubmissionContext if activeBudget = `operational-budget` or InfrastructureAllocationContext if activeBudget = `infrastructure-budgetr`
     */
    getAllocationDataByYear (state): (year: number) => FF4SubmissionContext | InfrastructureAllocationContext {
      return (year: number) => {
        const findData = state.allocationData.find(dt => dt.year === year)

        if (this.activeBudget === SUKU_BUDGET_TYPE.infrastructure && typeof findData?.repeaterInfrastructureFund !== 'undefined') {
          let totalMaterials = 0
          let totalLabourIncentive = 0
          const groupedData: InfrastructureAllocationFundContext[] = (findData?.repeaterInfrastructureFund ?? []).reduce<Array<{ activity_id: number, funds: FF4RepeaterInfrastructureFundContext[] }>>(
            (acc, item) => {
              const activityId = item.activity // Convert activity_id to string
              const existingGroup = acc.find(group => group.activity_id === activityId)
              const fund: InfrastructureFundAllocationItemContext = {
                ...item,
                total: (Number(item?.labour_incentive_3001 ?? 0) + Number(item?.materials ?? 0)).toString()
              }
              totalMaterials += Number(item?.materials ?? 0)
              totalLabourIncentive += Number(item?.labour_incentive_3001 ?? 0)
              if (typeof activityId !== 'undefined') {
                if (existingGroup == null) {
                  acc.push({ activity_id: activityId, funds: [fund] })
                } else {
                  existingGroup.funds.push(fund)
                }
              }

              return acc
            },
            [] // Initialize with the correct type
          )

          return {
            total_materials: totalMaterials.toString(),
            total_labour_incentive_3001: totalLabourIncentive.toString(),
            total_infrastructure_fund: (totalMaterials + totalLabourIncentive).toString(),
            allocationFund: groupedData
          }
        }

        // In case of a different budget type, return FF4SubmissionContext
        return findData as FF4SubmissionContext
      }
    },
    /**
     * calculate expenditures data based on the activeBudget
     * @param state InitialState
     * @returns ExpendituresContext
     */
    getExpendituresData (state): ExpendituresContext {
      const expenditures: ExpendituresContext = {}
      state.ff12submissions?.forEach(submission => {
        let expenditure = 0
        const currentYear = (submission?.year ?? 0).toString()
        // set expenditure by year as `0` if its not exist yet
        if (expenditures[currentYear] === undefined) {
          expenditures[currentYear] = 0
        }
        if (this.activeBudget === SUKU_BUDGET_TYPE.operational) {
          // Calculate expenditures for operational budget type
          expenditure = Number(submission?.community_meetings_2000 ?? 0) + Number(submission?.community_training_2001 ?? 0) + Number(submission?.incentive_for_suku_2002 ?? 0) + Number(submission?.project_admin_cost_2003 ?? 0)
        } else {
          // Calculate expenditures for infrastructure budget type
          submission?.repeaterInfrastructureFund?.forEach(fund => {
            expenditure += (Number(fund?.materials_3000 ?? 0) + Number(fund?.labour_incentive_3001 ?? 0))
          })
        }
        // SUM expenditure per year
        expenditures[currentYear] += expenditure
      })
      return expenditures
    },
    /**
     * visualize selected month and year report data
     * @param state ff12submissions
     * @returns OperationalMonthlyReportDataContext
     */
    getOperationalMonthlyReportsData (state): OperationalMonthlyReportDataContext {
      const ff12submission = state.selectedFF12Submission[0]
      let totalOperationalReceipts = 0
      let totalOperationalBalance = 0
      let totalOperationalExpenditures = 0
      let totalInfrastructureReceipts = 0
      let totalInfrastructureMaterials3000 = 0
      let totalInfrastructureLabourIncentive3001 = 0
      let balanceCash = 0
      let balanceBank = 0
      if (typeof ff12submission !== 'undefined') {
        totalOperationalReceipts = Number(ff12submission?.operational_subsidy_1000 ?? 0) + Number(ff12submission?.last_year_balance_1001 ?? 0) + Number(ff12submission?.other_1003 ?? 0) + Number(ff12submission?.interest_1002 ?? 0)
        totalOperationalExpenditures = Number(ff12submission?.community_meetings_2000 ?? 0) + Number(ff12submission?.community_training_2001 ?? 0) + Number(ff12submission?.incentive_for_suku_2002 ?? 0) + Number(ff12submission?.project_admin_cost_2003 ?? 0)
        totalInfrastructureReceipts = Number(ff12submission?.infrastructure_receipts_interest ?? 0) + Number(ff12submission?.infrastructure_receipts_last_year_balance ?? 0) + Number(ff12submission?.infrastructure_receipts_others ?? 0) + Number(ff12submission?.infrastructure_receipts_pnds_infrastructure_subsidy ?? 0)
        ff12submission?.repeaterInfrastructureFund?.forEach(fund => {
          totalInfrastructureMaterials3000 += Number(fund?.materials_3000 ?? 0)
          totalInfrastructureLabourIncentive3001 += Number(fund?.labour_incentive_3001 ?? 0)
        })
        if (state.activeBudget === SUKU_BUDGET_TYPE.operational) {
          balanceCash = ff12submission?.cash ?? 0
          balanceBank = ff12submission?.bank ?? 0
          totalOperationalBalance = Number(ff12submission?.cash ?? 0) + Number(ff12submission?.bank ?? 0)
        } else {
          ff12submission?.repeaterBalance?.forEach(fund => {
            balanceBank += Number(fund.balance_bank ?? 0)
            balanceCash += Number(fund.balance_cash ?? 0)
          })
          totalOperationalBalance = balanceBank + balanceCash
        }

        ff12submission.submitted_from = 'suku-budget'
      }
      return {
        data: ff12submission,
        total_balance: totalOperationalBalance,
        total_operational_expenditures: totalOperationalExpenditures,
        total_operational_receipts: totalOperationalReceipts,
        total_infrastructure_receipts: totalInfrastructureReceipts,
        total_infrastructure_materials: totalInfrastructureMaterials3000,
        total_infrastructure_labour_incentive: totalInfrastructureLabourIncentive3001,
        total_infrastructure_expenditures: totalInfrastructureLabourIncentive3001 + totalInfrastructureMaterials3000,
        balance_bank: balanceBank,
        balance_cash: balanceCash
      }
    },
    /**
     * Calculate the cumulative operational monthly report.
     * @param state ff12submissionsCommulative - An array of submissions.
     * @returns OperationalMonthlyReportCommulativeContext
     */
    getOperationalMonthlyReportCommulative (state): OperationalMonthlyReportCommulativeContext {
      return state.ff12submissionsCommulative.reduce((result, submission) => {
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          operational_subsidy_1000 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          last_year_balance_1001 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          interest_1002 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          other_1003 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          community_meetings_2000 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          community_training_2001 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          incentive_for_suku_2002 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          project_admin_cost_2003 = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          cash = 0,
          bank = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          infrastructure_receipts_pnds_infrastructure_subsidy = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          infrastructure_receipts_last_year_balance = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          infrastructure_receipts_interest = 0,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          infrastructure_receipts_others = 0
        } = submission

        result.operational_subsidy_1000 += Number(operational_subsidy_1000)
        result.last_year_balance_1001 += Number(last_year_balance_1001)
        result.interest_1002 += Number(interest_1002)
        result.other_1003 += Number(other_1003)
        result.total_operational_receipts +=
          Number(operational_subsidy_1000) + Number(last_year_balance_1001) + Number(interest_1002) + Number(other_1003)
        result.community_meetings_2000 += Number(community_meetings_2000)
        result.community_training_2001 += Number(community_training_2001)
        result.incentive_for_suku_2002 += Number(incentive_for_suku_2002)
        result.project_admin_cost_2003 += Number(project_admin_cost_2003)
        result.infrastructure_receipts_pnds_infrastructure_subsidy += Number(infrastructure_receipts_pnds_infrastructure_subsidy)
        result.infrastructure_receipts_last_year_balance += Number(infrastructure_receipts_last_year_balance)
        result.infrastructure_receipts_interest += Number(infrastructure_receipts_interest)
        result.infrastructure_receipts_others += Number(infrastructure_receipts_others)
        result.total_operational_expenditures +=
          Number(community_meetings_2000) +
          Number(community_training_2001) +
          Number(incentive_for_suku_2002) +
          Number(project_admin_cost_2003)

        result.cash += Number(cash)
        result.bank += Number(bank)
        result.total_infrastructure_receipts += Number(infrastructure_receipts_interest) + Number(infrastructure_receipts_last_year_balance) + Number(infrastructure_receipts_others) + Number(infrastructure_receipts_pnds_infrastructure_subsidy)

        return result
      }, {
        operational_subsidy_1000: 0,
        last_year_balance_1001: 0,
        interest_1002: 0,
        other_1003: 0,
        total_operational_receipts: 0,
        community_meetings_2000: 0,
        community_training_2001: 0,
        incentive_for_suku_2002: 0,
        project_admin_cost_2003: 0,
        total_operational_expenditures: 0,
        bank: 0,
        cash: 0,
        pnds_regular_1003: 0,
        rss_1003: 0,
        pnds_regular_1005: 0,
        rss_1005: 0,
        interest_1006: 0,
        total_infrastructure_receipts: 0,
        infrastructure_receipts_pnds_infrastructure_subsidy: 0,
        infrastructure_receipts_last_year_balance: 0,
        infrastructure_receipts_interest: 0,
        infrastructure_receipts_others: 0
      })
    },
    /**
     * Calculate the operational monthly report budget.
     * @param state - The state object.
     * @returns OperationalMonthlyReportBudget
     */
    getOperationalMonthlyReportBudget (state): OperationalMonthlReportBudget {
      let operationalSubsidy1000 = 0
      let lastYearBalance = 0
      let communityMeetings = 0
      let communityTraining = 0
      let incentiveforSuku = 0
      let projectAdminCost = 0
      let infrastructureReceiptsPndsInfrastructureSubsidy = 0

      // Check if the year parameter is defined.
      if (typeof this.monthlyReportsActiveParams.year !== 'undefined') {
        // get 1000 Subsidy Operational PNDS
        const operationalSubsidyData = this.pom1submissions.find(sub => sub.year === Number(this.monthlyReportsActiveParams.year))
        for (const activity of operationalSubsidyData?.repeaterActivity ?? []) {
          if (typeof activity.operational_fund !== 'undefined') {
            operationalSubsidy1000 += Number(activity.operational_fund ?? 0)
            infrastructureReceiptsPndsInfrastructureSubsidy += Number(activity.infrastructure_fund ?? 0)
          }
        }
        // get last year balance
        if (typeof this.getBroughtForwardData[this.monthlyReportsActiveParams.year] !== 'undefined') {
          lastYearBalance = Number(this.getBroughtForwardData[this.monthlyReportsActiveParams.year]?.bank ?? 0) + Number(this.getBroughtForwardData[this.monthlyReportsActiveParams.year]?.cash ?? 0)
        }

        // get operational expenditures budget
        const ff4Submission = this.allocationData.find(sub => sub.year === Number(this.monthlyReportsActiveParams.year))
        if (typeof ff4Submission !== 'undefined') {
          communityMeetings = Number(ff4Submission.community_meetings ?? 0)
          communityTraining = Number(ff4Submission.community_training ?? 0)
          incentiveforSuku = Number(ff4Submission.labour_incentive ?? 0)
          projectAdminCost = Number(ff4Submission.project_admin_cost ?? 0)
        }
      }

      return {
        operational_subsidy_1000: operationalSubsidy1000,
        last_year_balance: lastYearBalance,
        total_operational_receipts: Number(operationalSubsidy1000) + Number(lastYearBalance),
        community_meetings_2000: communityMeetings,
        community_training_2001: communityTraining,
        incentive_for_suku_2002: incentiveforSuku,
        project_admin_cost_2003: projectAdminCost,
        total_operational_expenditures: communityMeetings + communityTraining + incentiveforSuku + projectAdminCost,
        infrastructure_receipts_pnds_infrastructure_subsidy: infrastructureReceiptsPndsInfrastructureSubsidy,
        interest_1006: 0,
        total_infrastructure_receipts: lastYearBalance + infrastructureReceiptsPndsInfrastructureSubsidy
      }
    },
    /**
     * Calculate the operational monthly report balance.
     * @param state - The state object.
     * @returns OperationalMonthlyReportBalance
     */
    getOperationalMonthlyReportBalance (state): OperationalMonthlyReportBalance {
      const commulativeData = this.getOperationalMonthlyReportCommulative
      const operationalFundBalanceThisYear = commulativeData.total_operational_receipts - commulativeData.total_operational_expenditures
      const thisMonthData = this.getOperationalMonthlyReportsData
      const thisMonthCash = Number(thisMonthData?.data?.cash ?? 0)
      const thisMonthBank = Number(thisMonthData?.data?.bank ?? 0)
      const totalOperationalBalance = thisMonthCash + thisMonthBank
      return {
        operational_fund_balance_this_year: operationalFundBalanceThisYear,
        cash: thisMonthCash,
        bank: thisMonthBank,
        total_operational_balance: totalOperationalBalance,
        difference_balance: totalOperationalBalance - operationalFundBalanceThisYear
      }
    },
    /**
     * Calculate the infrastructure monthly report data.
     * @param state - The state object.
     */
    getInfrastructureReportData (state): InfrastructureReportData {
      const cummulative: Record<string, { materials_3000: number, labour_incentive_3001: number }> = {}
      const budget: Record<string, { materials_3000: number, labour_incentive_3001: number }> = {}
      const findFF4 = state.allocationData.find(data => data.year === Number(state.monthlyReportsActiveParams.year))
      let totalCummulative = 0
      let total = 0
      let totalBudget = 0

      if (typeof findFF4 !== 'undefined') {
        findFF4?.repeaterInfrastructureFund?.forEach(fund => {
          if (typeof fund.project !== 'undefined') {
            if (typeof budget[fund.project] === 'undefined') {
              budget[fund.project] = {
                materials_3000: 0,
                labour_incentive_3001: 0
              }
            }
            totalBudget += Number(fund?.materials ?? 0) + Number(fund?.labour_incentive_3001 ?? 0)
            budget[fund.project].materials_3000 += Number(fund?.materials ?? 0)
            budget[fund.project].labour_incentive_3001 += Number(fund?.labour_incentive_3001 ?? 0)
          }
        })
      }

      state.selectedFF12Submission[0]?.repeaterInfrastructureFund?.forEach(fund => {
        total += Number(fund?.materials_3000 ?? 0) + Number(fund?.labour_incentive_3001 ?? 0)
      })
      // Iterate through the data array
      state.ff12submissionsCommulative.forEach(item => {
        // Iterate through the repeaterInfrastructureFund array for each item
        item.repeaterInfrastructureFund?.forEach(fund => {
          if (typeof fund?.project !== 'undefined') {
            // If the project name doesn't exist in the 'total' object, initialize it
            if (typeof cummulative[fund.project] === 'undefined') {
              cummulative[fund.project] = {
                materials_3000: 0,
                labour_incentive_3001: 0
              }
            }

            totalCummulative += Number(fund?.materials_3000 ?? 0) + Number(fund?.labour_incentive_3001 ?? 0)

            // Add the values to the respective properties
            cummulative[fund.project].materials_3000 += Number(fund?.materials_3000 ?? 0)
            cummulative[fund.project].labour_incentive_3001 += Number(fund?.labour_incentive_3001 ?? 0)
          }
        })
      })

      return {
        cummulative,
        total,
        total_cummulative: totalCummulative,
        total_budget: totalBudget,
        budget
      }
    },
    /**
     * Calculate the infrastructure monthly report balance.
     * @param state - The state object.
     */
    getInfrastructureMonthlyReportBalance (state): InfrastructureMonthlyReportBalanceSchema {
      const commulativeData = this.getOperationalMonthlyReportCommulative
      const infrastructureData = this.getInfrastructureReportData
      let totalBalance = 0
      const infrastructureBalanceThisYear = Number(commulativeData.total_infrastructure_receipts ?? 0) - Number(infrastructureData.total_cummulative ?? 0)
      const balance: InfrastructureBalanceSchema = {}
      state.selectedFF12Submission[0]?.repeaterBalance?.forEach(fund => {
        const activity = fund.activity
        if (typeof activity !== 'undefined') {
          if (typeof balance[activity] === 'undefined') {
            balance[activity] = {
              cash: 0,
              bank: 0,
              cummulative_cash: 0,
              cummulative_bank: 0
            }
          }
          let commulativeCash = Number(fund.balance_cash ?? 0)
          let commulativeBank = Number(fund.balance_bank ?? 0)
          state.ff12submissionsCommulative.forEach(sub => {
            sub.repeaterBalance?.forEach(commulativeFund => {
              if (commulativeFund.activity === activity) {
                commulativeCash += Number(commulativeFund.balance_cash ?? 0)
                commulativeBank += Number(commulativeFund.balance_bank ?? 0)
              }
            })
          })
          balance[activity].cash += Number(fund.balance_cash ?? 0)
          balance[activity].bank += Number(fund.balance_bank ?? 0)
          balance[activity].cummulative_bank += Number(commulativeBank)
          balance[activity].cummulative_cash += Number(commulativeCash)
          totalBalance += (Number(fund.balance_cash ?? 0) + Number(fund.balance_bank ?? 0))
        }
      })

      return {
        fund_balance_this_year: infrastructureBalanceThisYear,
        balance,
        difference_balance: infrastructureBalanceThisYear - totalBalance,
        total_balance: totalBalance
      }
    },
    /**
     * get pom1 submission by year
     * @param year - Selected year
     * @returns Pom1SubmissionContext
     */
    getPom1SubmissionByYear (state): (year: number) => Pom1SubmissionContext | undefined {
      return (year: number) => {
        return state.pom1submissions.find(submission => Number(submission.year) === year)
      }
    }
  },
  actions: {
    /**
     * Initialize the store by fetching budget data and brought forward data
     */
    async initialize () {
      const appStore = useAppStore()
      appStore.$state.loading = true
      // Fetch and store POM1 data
      const data = (await this.fetchBudgetData()) as Pom1SubmissionContext[]
      await this.fetchFF12Data()
      this.pom1submissions = data
      appStore.$state.loading = false
    },

    /**
     * Check for existing submissions for a given suco ID and year
     * @param formType string
     * @param activeSubmissionId string
     * @param zSucoId number
     * @param year number
     * @param month number
     * @returns
     */
    async checkExistingBudgetSubmissionData (formType: string, activeSubmissionId: string, zSucoId: number, year: number, month?: number) {
      const appStore = useAppStore()
      appStore.$state.loading = true
      let filteredData = []
      if (formType === FORM_TYPES.CFM2FF4) {
        filteredData = (await this.fetchAllocationData(zSucoId, year)).filter(sub => sub.submission_id !== activeSubmissionId)
      } else {
        const data = (await this.fetchBudgetData(formType, false, { year })) as Array<{ suco_id: number | undefined, year: number | undefined, submission_id: string, month: number | undefined }>
        filteredData = (data ?? []).filter(dt => dt.suco_id === zSucoId && dt.year === year && dt.submission_id !== activeSubmissionId && dt.month === month)
      }
      appStore.$state.loading = false
      return filteredData
    },

    /**
     * Fetch budget data based on the form type and optional filtering
     * @param form string
     * @param filterData boolean
     * @param params Object
     * @returns
     */
    async fetchBudgetData (form?: string, filterDataByZsuco = true, params: Record<string, any> = {}) {
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = sukuProfileStore.$state.activezSukuId
      // Determine the API path based on the form type
      const path = form === FORM_TYPES.CFM12FF12 ? '/api/submissions/v2/cfm_12_ff_12' : '/api/submissions/v2/pom_1'
      if (filterDataByZsuco) {
        params.suco = zSucoId
      }
      const { data } = await client.GET(path, { params: { query: { status: SUBMISSIONS_STATUS_VALUE.verified, ...params } } })
      // Filter data based on the form type and suco ID if needed
      return filterDataByZsuco ? (data as Array<{ suco: number }>)?.filter((dt: { suco: number }) => dt.suco === zSucoId) : data
    },
    /**
     * Fetch and store FF12 submissions
     * if params is exist will update `ff12Submission` and `ff12SubmissionCommulative` state. those will be data source for monthly report
     * * @param params MonthlyReportsParamsContext
     */
    async fetchFF12Data (params?: MonthlyReportsParamsContext) {
      const appStore = useAppStore()
      appStore.$state.loading = true
      const data = (await this.fetchBudgetData(FORM_TYPES.CFM12FF12, true, params)) as FF12SubmissionContext[]
      const filteredDataByParams = typeof params === 'undefined'
        ? data
        : data.filter((item) => {
          return Object.entries(params).every(([key, value]) => {
            // If the property is not present in the item, consider it a match
            if (!Object.prototype.hasOwnProperty.call(item, key)) return true

            // If the property is present, check if it matches the filter value
            // eslint-disable-next-line eqeqeq
            return item[key as keyof MonthlyReportsParamsContext] == value
          })
        })
      // if params is exist create data source for the previous month submissions
      if (typeof params !== 'undefined') {
        const commulativeData = data.filter((item) => {
          return item.year === Number(params.year) && (item.month ?? 13) < Number(params.month) // filter by year and month
        }).sort((a: FF12SubmissionContext, b: FF12SubmissionContext) => Number(b.month) - Number(a.month))
        this.ff12submissionsCommulative = commulativeData
        this.selectedFF12Submission = filteredDataByParams
      } else {
        this.ff12submissionsCommulative = []
        this.ff12submissions = filteredDataByParams
      }
      appStore.$state.loading = false
    },

    /**
     * Fetch allocation data based on sucoId
     * @param sucoId number (optional)
     * @returns Promise<FF4SubmissionContext[]>
     */
    async fetchAllocationData (sucoId?: number, year?: number): Promise<FF4SubmissionContext[]> {
      const appStore = useAppStore()
      const sukuProfileStore = useSukuProfileStore()
      const zSucoId = typeof sucoId === 'undefined' ? sukuProfileStore.$state.activezSukuId : sucoId
      const params: Record<string, any> = { status: SUBMISSIONS_STATUS_VALUE.verified, suco: zSucoId }
      if (typeof year !== 'undefined') {
        params.year = year
      }
      appStore.$state.loading = true
      const { data } = await client.GET('/api/submissions/v2/cfm_2_ff_4', { params: { query: params } })
      const filteredData = (data as FF4SubmissionContext[])?.filter((dt) => dt.suco === zSucoId).sort((a: FF4SubmissionContext, b: FF4SubmissionContext) => Number(b.year) - Number(a.year))
      appStore.$state.loading = false
      this.allocationData = filteredData
      return filteredData
    },

    async updateBudgetStatus (formType: string, status: string, fields: Pom1SubmissionContext) {
      const req = await this.updatePom1Status(formType, status, fields)
      if (typeof req !== 'undefined') {
        if (status === SUKU_BUDGET_STATUS.active) {
          const othersPom1Submissions = [...this.pom1submissions].filter(sub => sub.submission_id !== fields.submission_id)
          for (const sub of othersPom1Submissions) {
            await this.updatePom1Status(formType, SUKU_BUDGET_STATUS.closed, sub)
          }
        }
        toast.success(useI18NStore().gettext('The budget status has been updated'))
      }
    },

    async updatePom1Status (formType: string, status: string, fields: Pom1SubmissionContext) {
      useAppStore().$state.loading = true
      const values = { ...fields }
      const key = fields.submission_id
      if (this.activeBudget === SUKU_BUDGET_TYPE.infrastructure) {
        values.pom1_infrastructure_status = status as never
      } else if (this.activeBudget === SUKU_BUDGET_TYPE.operational) {
        values.pom1_operational_status = status as never
      }

      const { data } = await client.PUT('/api/submissions/update', { body: { key, form_type: formType, fields: values as unknown as Record<string, never> } })
      if (typeof data !== 'undefined') {
        // update the existing submission data
        this.pom1submissions = this.pom1submissions.map((obj: Pom1SubmissionContext) => {
          if (obj.submission_id === key) {
            if (this.activeBudget === SUKU_BUDGET_TYPE.infrastructure) {
              obj.pom1_infrastructure_status = status
            } else if (this.activeBudget === SUKU_BUDGET_TYPE.operational) {
              obj.pom1_operational_status = status
            }
            return obj
          }
          return obj
        })
      } else {
        toast.error(useI18NStore().gettext('Something wrong !'))
      }
      useAppStore().$state.loading = false
      return data
    }
  }
})
