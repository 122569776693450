<script setup lang="ts">
import { reactive, ref } from 'vue'
import { FormKitNode } from '@formkit/core'
import { useI18NStore } from '../stores/i18n'
import { useAuthStore } from '../stores/auth'
import AuthLayout from '../layouts/AuthLayout.vue'
import { resetPasswordSchema } from '../_formkit/schema/auth.schema'
import { FormKitSchema } from '@formkit/vue'
import AlertBox from '../components/commons/AlertBox.vue'
import BasicButton from '../components/commons/BasicButton.vue'
import { ResetPasswordData } from '../_types/auth'

const props = defineProps({
  uidb64: { type: String, required: true },
  token: { type: String, required: true }
})

const i18Store = useI18NStore()
const authStore = useAuthStore()

const formKitSubmitAttrs = {
  suffixIcon: 'arrowRight'
}

const isSubmitted = ref<boolean>(false)

async function submit (submittedData: ResetPasswordData, node: FormKitNode | undefined) {
  const response = await authStore.resetPassword(submittedData, props.uidb64, props.token)
  if (response.success) {
    isSubmitted.value = true
  } else {
    if (node) {
      let simplifiedErrors = ''
      for (const key in response.errors) {
        const fieldErrors = response.errors[key]
        simplifiedErrors += fieldErrors
      }
      node.setErrors(simplifiedErrors)
    }
  }
}
const data = reactive({
  passwordFieldType: 'password',
  passwordFieldIcon: 'eyeClosed',
  confirmPasswordFieldType: 'password',
  confirmPasswordFieldIcon: 'eyeClosed',
  passwordFieldAttributes: {
    onSuffixIconClick () {
      data.passwordFieldType = data.passwordFieldType === 'textFloating' ? 'password' : 'textFloating'
      data.passwordFieldIcon = data.passwordFieldType === 'textFloating' ? 'eye' : 'eyeClosed'
    }
  },
  confirmPasswordFieldAttributes: {
    onSuffixIconClick () {
      data.confirmPasswordFieldType = data.confirmPasswordFieldType === 'textFloating' ? 'password' : 'textFloating'
      data.confirmPasswordFieldIcon = data.confirmPasswordFieldType === 'textFloating' ? 'eye' : 'eyeClosed'
    }
  },
  gettext: i18Store.gettext
})
</script>

<template>
  <auth-layout
    :has-border="false"
    content-size="lg"
  >
    <div class="flex items-center justify-center text-zinc-900 pt-16 lg:pt-[144px] pb-14">
      <div
        v-if="!isSubmitted"
        class="max-w-[440px]"
      >
        <h1 class="text-4.5xl font-black mb-2">
          {{ gettext('Create a new password') }}
        </h1>
        <p class="text-xl mb-10">
          {{ gettext('Enter a new password for your account. Ensure it’s secure using at least 8 digits including a mix of letters, numbers and symbols.') }}
        </p>
        <FormKit
          type="form"
          :submit-label="gettext('Set password')"
          :submit-attrs="formKitSubmitAttrs"
          novalidate
          message-class="text-red-500 text-sm"
          :incomplete-message="gettext('Sorry, please check that you have filled in the form correctly')"
          @submit="submit"
        >
          <FormKitSchema
            :schema="resetPasswordSchema"
            :data="data"
          />
        </FormKit>
      </div>
      <div
        v-else
        class="max-w-[440px]"
      >
        <h1 class="text-4.5xl font-black mb-2 lg:whitespace-nowrap">
          {{ gettext('Your password has been reset') }}
        </h1>
        <p class="text-xl mb-10">
          {{ gettext('You’re all set to login to Ida.') }}
        </p>
        <alert-box class="mt-9 md:max-w-[440px]">
          <div class="flex items-center md:pr-10">
            <div class="mr-5">
              <i class="text-2xl las la-check-circle"></i>
            </div>
            <div class="text-[15px]">
              <p class="font-bold mb-1">
                {{ gettext('Success!') }}
              </p>
              <p>
                {{ gettext('Your password has been successfully reset.') }}
              </p>
            </div>
          </div>
        </alert-box>
        <basic-button
          class="!w-full md:max-w-[440px] mt-7.5 justify-center"
          suffix-icon="la-arrow-right"
          :to="{ name: 'login' }"
        >
          {{ gettext('Return to login') }}
        </basic-button>
      </div>
    </div>
  </auth-layout>
</template>
