import { FormKitExtendableSchemaRoot, FormKitNodeType } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  textInput
} from '@formkit/inputs'
  /**
   * Input definition for a text.
   * @public
   */

interface FormKitSchemaType {
  type?: FormKitNodeType
  schema: FormKitExtendableSchemaRoot
}

export const floatingLabelTextInput: FormKitSchemaType = {
  schema: outer(
    wrapper(
      /*
         * Here we are commenting out the label input and moving it below the
         * text input.
         * label('$label'),
         */
      inner(
        icon('prefix', 'label'),
        prefix(),
        textInput(),
        label('$label'), // 👈 and now placed here
        suffix(),
        icon('suffix')
      )
    ),
    help('$help'),
    messages(message('$message.value'))
  )
}
