<script setup lang="ts">
import BaseLayout from '../../layouts/BaseLayout.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import PanelCard from '../../components/commons/PanelCard.vue'
import StickyWrapper from '../../components/commons/StickyWrapper.vue'
import LabelText from '../../components/commons/LabelText.vue'
import BasicCard from '../../components/commons/BasicCard.vue'
import CollapseBox from '../../components/commons/CollapseBox.vue'
import DropdownNav from '../../components/sukumanager/DropdownNav.vue'
import AlertBox from '../../components/commons/AlertBox.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, reactive, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { FormKit, FormKitSchema } from '@formkit/vue'
import { complaintSchemaIn } from '../../_formkit/schema/complaint.schema'
import { getNode } from '@formkit/core'
import { useSukuComplaintStore } from '../../stores/sukucomplaint'
import { useI18NStore } from '../../stores/i18n'
import { useLocationStore } from '../../stores/locations'
import { useAuthStore } from '../../stores/auth'
import { complaintStatus, complaintsCategories, complaintsMethod } from '../../_constants/options.constant'
import { OptionType } from '../../_types/components/forms/select-dropdown'
import { components } from '../../_api-services/openapi'
import { useModal } from '../../stores/modal'
import { format, parse, parseISO } from 'date-fns'
import { useGeoLocationStore } from '../../stores/geolocation'
import { useAppStore } from '../../stores/app'

type ComplaintSchemaIn = components['schemas']['ComplaintSchemaIn']
type ComplaintCommentSchemaIn = components['schemas']['ComplaintCommentSchemaIn']
type ComplaintVerify = components['schemas']['ComplaintVerify']

const sukuComplaintStore = useSukuComplaintStore()
const modal = useModal()
const toast = useToast()
const i18nstore = useI18NStore()
const locationStore = useLocationStore()
const geolocationStore = useGeoLocationStore()

await locationStore.initialize()

const activeGeoLocation = computed(() => geolocationStore.activeGeolocation)
if (sukuComplaintStore.$state.complaints.length < 1) {
  await sukuComplaintStore.initialize()
}

const complaint = computed(() => {
  const id = useRoute().params.complaintId as string
  return id !== undefined ? sukuComplaintStore.getComplaintById(Number(id)) : undefined
})

if (complaint.value === undefined) {
  toast.warning(useI18NStore().gettext('Complaint not found'))
  useRouter().push({
    name: 'sukuComplaints',
    params: {
      id: useRoute().params.id
    }
  })
} else {
  if (complaint.value.id !== undefined) {
    await sukuComplaintStore.fetchComments(complaint.value.id)
  }
}

const values = ref({
  ...complaint.value
})

const schemaData = reactive({
  gettext: i18nstore.gettext,
  getLocationByLevel: (level: number): OptionType[] => {
    const locations = locationStore.getByLevel(level).map((opt) => { return { label: opt.name, value: opt.path.at(-1) } })
    return locations as OptionType[]
  },
  getCurrentDate: () => {
    const currDate = new Date()
    return {
      day: currDate.getDate(),
      month: currDate.getMonth(),
      year: currDate.getFullYear()
    }
  },
  complaintsCategories: computed(() => complaintsCategories.map(it => ({ value: it.value, label: i18nstore.gettext(it.label) }))),
  complaintsMethod: computed(() => complaintsMethod.map(it => ({ value: it.value, label: i18nstore.gettext(it.label) }))),
  complaintStatus: computed(() => complaintStatus.map(it => ({ value: it.value, label: i18nstore.gettext(it.label) }))),
  caseOpenedOptions: computed(() => {
    return [
      {
        value: true,
        label: i18nstore.gettext('Case')
      },
      {
        value: false,
        label: i18nstore.gettext('Not a case')
      }
    ]
  })
})

const statusLabel = (value?: number) => {
  if (value === undefined) return '-'
  const activeStatus = complaintStatus.find(it => Number(it.value) === Number(value))?.label
  return activeStatus ?? '-'
}

const formatDate = (date?: string) => {
  if (date === undefined) return '-'
  return format(parse(date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
}

const saveComplaint = async (dataIn: ComplaintSchemaIn) => {
  if (dataIn.status !== complaint.value?.status) {
    modal.open('info', {
      label: 'Confirm',
      modalTitle: 'Are you sure?',
      modalCaption: 'Do you really want to solved this complaint ? This process cannot be undone',
      callback: async () => {
        await sukuComplaintStore.createComplaint(dataIn, false)
        modal.close()
      }
    })
  } else {
    await sukuComplaintStore.createComplaint(dataIn, false)
  }
}

const saveComment = async (dataIn: ComplaintCommentSchemaIn) => {
  const userId = useAuthStore().user?.id
  const complaintId = complaint.value?.id
  if (userId === undefined || complaintId === undefined) return
  dataIn.complaint_id = complaintId
  dataIn.user_id = userId
  const req = await sukuComplaintStore.createComplaintComment(dataIn, complaint.value?.status)
  if (req) {
    getNode('text_content')?.input('')
  }
}

</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/suku-manager/${activeGeoLocation?.properties?.id}/complaints/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
          <span class="after:content-[` `] after:inline-block after:absolute after:w-full after:h-[2px] after:-bottom-0 after:left-0 after:bg-emerald-600 relative inline-block">{{ activeGeoLocation?.properties?.name }}</span>
        </router-link>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
        <DropdownNav />
      </div>
    </template>
    <template #info>
      <span class="block mt-5 md:mt-0 px-2 text-sm rounded-2.5xl border border-zinc-400 bg-zinc-400/50 [&:not(:last-child)]:mr-2">
        <span class="px-3 inline-block border-r border-r-zinc-400 py-3.5">
          <i class="las la-map-marker-alt mr-1"></i>
          {{ gettext('Municipality') }} <strong>{{ activeGeoLocation?.properties.municipality_name }}</strong>
        </span>
        <span class="px-3 py-3.5 inline-block">
          {{ gettext('Administrative Post') }} <strong>{{ activeGeoLocation?.properties.adminpost_name }}</strong>
        </span>
      </span>
    </template>
    <div
      class="flex flex-col-reverse lg:flex-row flex-wrap px-5 py-6 lg:px-10 xl:px-22 md:py-8 "
    >
      <div class="w-full lg:w-3/5 2xl:w-2/3 lg:pr-4 xl:pr-[33px]">
        <basic-card class="px-4 pt-9 pb-9 lg:px-11 mb-7 !overflow-visible form-card">
          <collapse-box
            id="complaint-detail"
            active
          >
            <template #collapseToggle>
              <h3 class="text-lg md:text-1.5xl font-bold flex items-center text-left">
                <i
                  class="las la-comment-dots text-gray-400 mr-2.5"
                ></i> {{ gettext('Complaint details') }}
              </h3>
            </template>
            <template #collapseContent>
              <div class="!text-left mt-6">
                <FormKit
                  v-model="values"
                  type="form"
                  :disabled="complaint?.status === 0"
                  :actions="false"
                  @submit="saveComplaint"
                >
                  <alert-box
                    :theme="complaint?.status === 1 ? 'warning' : 'success'"
                    rounded="2.5xl"
                    class="mb-5"
                  >
                    <div class="flex items-center">
                      <i class="las la-info-circle text-2xl"></i>
                      <div class="pl-5">
                        <p class="text-2sm font-bold">
                          {{ gettext(complaint?.status === 1 ? 'Ongoing complaint' : 'Solved complaint') }}
                        </p>
                        <p
                          v-if="complaint?.status === 1"
                          class="text-2sm mt-1"
                        >
                          {{ interpolate(gettext('This is an ongoing complaint added by %(user)s on %(date)s'), {
                            user: complaint.user_name ?? '-',
                            date: formatDate(complaint.received)
                          }, true) }}
                        </p>
                        <p
                          v-else
                          class="text-2sm mt-1"
                        >
                          {{ interpolate(gettext('This has been solved on %(date)s'), {
                            date: complaint?.updated_at ? format(parseISO(complaint?.updated_at), 'dd/MM/yyyy'): '-'
                          }, true) }}
                        </p>
                      </div>
                    </div>
                  </alert-box>
                  <FormKitSchema
                    :schema="complaintSchemaIn()"
                    :data="schemaData"
                  />
                  <FormKit
                    id="status"
                    key="status"
                    type="hidden"
                    name="status"
                  />
                  <FormKit
                    id="id"
                    key="id"
                    type="hidden"
                    name="id"
                  />
                  <FormKit
                    type="submit"
                    input-class="w-max mt-7.5"
                    :disabled="complaint?.status === 0"
                  >
                    <i class="las la-check text-xl mr-3"></i>
                    {{ gettext('Save complaint') }}
                  </FormKit>
                </FormKit>
              </div>
            </template>
          </collapse-box>
        </basic-card>

        <basic-card class="px-4 pt-9 pb-9 lg:px-11 !overflow-visible form-card">
          <collapse-box
            id="comments"
            active
          >
            <template #collapseToggle>
              <h3 class="text-lg md:text-1.5xl font-bold flex items-center text-left">
                <i
                  class="las la-comments text-gray-400 mr-2.5"
                ></i> {{ gettext('Comments') }}
              </h3>
            </template>
            <template #collapseContent>
              <div class="text-left mt-6">
                <FormKit
                  type="form"
                  :actions="false"
                  @submit="saveComment"
                >
                  <div class="min-h-[166px]">
                    <FormKit
                      v-if="useAppStore().$state.loading === false"
                      id="text_content"
                      key="text_content"
                      type="textarea"
                      name="text_content"
                      validation="required"
                      :label="gettext('Add a comment')"
                    />
                  </div>
                  <FormKit
                    type="submit"
                    input-class="w-max mt-5"
                  >
                    <i class="las la-plus text-xl mr-3"></i>
                    {{ gettext('Add comment') }}
                  </FormKit>
                </FormKit>
                <ul
                  v-if="sukuComplaintStore.getComments.length > 0"
                  class="mt-9"
                >
                  <li
                    v-for="(comment, index) in sukuComplaintStore.getComments"
                    :key="index"
                    class="relative flex items-center w-full mb-8 last:mb-4"
                  >
                    <div class="relative top-[18px] text-2sm text-center font-semibold w-[65px]">
                      <span
                        class="mx-auto prefix z-20 min-w-[40px] w-10 h-10 md:w-[50px] md:min-w-[50px] md:h-[50px] flex items-center justify-center text-lg md:text-xl rounded-full border"
                        :class="{'border-orange-500 bg-orange-500/[.3]': comment.status === 1, 'bg-emerald-200/[.85] border-green-300': comment.status === 0}"
                      >
                        <i
                          class="las"
                          :class="comment?.status === 1 ? 'la-info-circle' : 'la-check'"
                        ></i>
                      </span>
                      <span class="mt-1 block">
                        {{ complaint !== undefined ? gettext(statusLabel(comment?.status)) : '-' }}
                      </span>
                    </div>
                    <div class="w-full pl-7">
                      <span class="md:flex justify-between items-end">
                        <h4 class="font-black text-2sm leading-none pr-2 md:pr-0 mb-2.5">{{ comment.title }}</h4>
                        <p class="text-[13px] text-gray-400 leading-none mb-1 italic">{{ comment.info }}</p>
                      </span>
                      <span class="block relative bg-white border border-zinc-400 py-2.5 md:py-4 px-4.5 md:px-6 rounded-[4px] mt-2 bubble-text min-h-[72px]">
                        <p class="text-xs md:text-sm">
                          {{ comment.comment }}
                        </p>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </collapse-box>
        </basic-card>
      </div>
      <div class="w-full lg:w-2/5 2xl:w-1/3 lg:pl-4 xl:pl-[33px] mb-5 lg:mb-0">
        <sticky-wrapper device="desktop">
          <panel-card class="shadow-none">
            <template #title>
              <h3 class="text-lg lg:text-xl font-bold flex items-center">
                <i class="las la-sms text-emerald-600 mr-3 relative"></i> {{ gettext('Complaint summary') }}
              </h3>
            </template>
            <p class="mb-2.5 text-2sm block font-semibold">
              {{ gettext('Status') }}
            </p>
            <label-text
              class="text-2sm font-bold flex items-center mb-5"
              :theme="complaint?.status === 1 ? 'warning' : 'success'"
            >
              <span>
                <i
                  class="las text-lg leading-none mr-3 relative top-[1px]"
                  :class="complaint?.status === 1 ? 'la-info-circle' : 'la-check'"
                ></i> {{ complaint !== undefined ? gettext(statusLabel(complaint?.status)) : '-' }}
              </span>
            </label-text>
          </panel-card>
        </sticky-wrapper>
      </div>
    </div>
  </base-layout>
</template>
