
export const addUserSchema = [
  {
    $formkit: 'text',
    name: 'username',
    label: '$gettext("Username")',
    required: true
  },
  {
    $formkit: 'text',
    name: 'first_name',
    label: '$gettext("First name")'
  },
  {
    $formkit: 'text',
    name: 'last_name',
    label: '$gettext("Last name")'
  },
  {
    $formkit: 'email',
    name: 'email',
    label: '$gettext("Email")'
  }
]
