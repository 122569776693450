import { Chart, ChartOptions, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.register(...registerables, ChartDataLabels)

const doughnutPlugins: ChartOptions<'doughnut'>['plugins'] = {
  legend: {
    display: false,
    position: 'bottom'
  },
  datalabels: {
    display: true,
    color: 'white',
    font: {
      weight: 'bold',
      family: 'Lato'
    }
  }
} as const

const DoughnutOptions: ChartOptions<'doughnut'> = {
  plugins: doughnutPlugins
} as const

const plugins: ChartOptions<'bar'>['plugins'] = {
  legend: { display: false },
  datalabels: {
    align: 'end',
    anchor: 'end',
    color: '#000',
    font: {
      weight: 'bold',
      family: 'Lato'
    }
  }
}

const BarOptions: ChartOptions<'bar'> = {
  layout: {
    padding: {
      top: 20
    }
  },
  plugins
}

const centerTextPlugin = ({ text = () => '?', textSize = '16px', yOffset = 0 }: { text?: string | (() => string), textSize?: string, yOffset?: number } = {}) => {
  /**
   * This is a 'beforeDraw' plugin to write the total
   * number of projects in the center of the doughnut chart
   */
  return function (chart: Chart<'doughnut'>) {
    const { ctx } = chart
    const { width, height } = chart.chartArea
    ctx.restore()
    ctx.font = `${textSize} sans-serif`
    ctx.textBaseline = 'middle'

    const text_ = typeof text === 'string' ? text : text()
    const textX = Math.round((width - ctx.measureText(text_).width) / 2)
    const textY = (height / 2) + yOffset

    ctx.fillText(text_, textX, textY)
  }
}

export { DoughnutOptions, BarOptions, doughnutPlugins, centerTextPlugin }
