import { ObjectWithNumberKey, ObjectKeyWithString } from '../_types/commons'

export const IS_MOBILE_MAX_SCREEN_WIDTH = 768

export const SUBMISSIONS_STATUS_NAME: ObjectKeyWithString = {
  newSubmission: 'New Submission',
  rejected: 'Rejected',
  verified: 'Verified',
  changesRequested: 'Reopened'
}

export const SUBMISSIONS_STATUS: ObjectWithNumberKey = {
  1: SUBMISSIONS_STATUS_NAME.newSubmission,
  2: SUBMISSIONS_STATUS_NAME.rejected,
  3: SUBMISSIONS_STATUS_NAME.verified,
  4: SUBMISSIONS_STATUS_NAME.changesRequested
}

export const SUBMISSION_STATUS_ARRAY = [
  {
    label: SUBMISSIONS_STATUS_NAME.newSubmission,
    value: SUBMISSIONS_STATUS_NAME.newSubmission
  },
  {
    label: SUBMISSIONS_STATUS_NAME.rejected,
    value: SUBMISSIONS_STATUS_NAME.rejected
  },
  {
    label: SUBMISSIONS_STATUS_NAME.verified,
    value: SUBMISSIONS_STATUS_NAME.verified
  },
  {
    label: SUBMISSIONS_STATUS_NAME.changesRequested,
    value: SUBMISSIONS_STATUS_NAME.changesRequested
  }
]

export const SUBMISSIONS_STATUS_VALUE: Record<string, number> = {
  newSubmission: 1,
  rejected: 2,
  verified: 3,
  changesRequested: 4
}

export const SUBMISSIONS_STATUS_SUCCESS_MESSAGE: ObjectWithNumberKey = {
  1: 'Submission form has been created',
  2: 'Submission form has been rejected',
  3: 'Submission form has been verified',
  4: 'Submission form has been reopened'
}

export const FORM_STATUS: Record<string, number> = {
  verified: 3,
  rejected: 2
}

export const DATA_COLLECTION_STATUS = {
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  PENDING_SUBMIT: 'Pending'
}

export const DATA_COLLECTION_STATUS_ARRAY = [
  {
    label: DATA_COLLECTION_STATUS.DRAFT,
    value: DATA_COLLECTION_STATUS.DRAFT
  },
  {
    label: DATA_COLLECTION_STATUS.PENDING_SUBMIT,
    value: DATA_COLLECTION_STATUS.PENDING_SUBMIT
  },
  {
    label: DATA_COLLECTION_STATUS.SUBMITTED,
    value: DATA_COLLECTION_STATUS.SUBMITTED
  }
]

export const FILE_EXTENSIONS = {
  pdf: 'application/pdf'
}

export const FORM_STATE = {
  new: 'new'
}

export const COMPANY_DETAILS = {
  phoneLabel: '+670 1234 9875',
  phone: '+67012349875',
  email: 'it@pnds.gov.tl'
}

export const FORM_TYPES: Record<string, string> = {
  SF11: 'SF_1_1',
  SF23: 'SF_2_3',
  SF13: 'SF_1_3',
  SF12: 'SF_1_2',
  SF41: 'SF_4_1',
  SF42: 'SF_4_2',
  TF611: 'TF_6_1_1',
  TF1321: 'TF_13_2_1',
  SF62: 'SF_6_2',
  CFM2FF4: 'CFM_2_FF_4',
  CFM12FF12: 'CFM_12_FF_12',
  POM1: 'POM_1',
  FF14: 'FF_14'
}

export const FORM_SCHEMA_STATUS_DRAFT = 'draft'
export const FORM_SCHEMA_STATUS_PUBLISHED = 'published'
