<script setup lang="ts">
import { FormKitConfig } from '@formkit/core'
import { computed, inject } from 'vue'
import SelectDropdown from '../../components/forms/SelectDropdown.vue'
import { useI18NStore } from '../../stores/i18n'
import { selectOptions } from '../../_helpers/common.helpers'

const i18nStore = useI18NStore()

const selectPlaceholder = computed(() => {
  return i18nStore.gettext('Select')
})
const config: FormKitConfig | undefined = inject(Symbol.for('FormKitConfig'))

const input = () => {
  if (typeof config !== 'undefined') {
    config.locale = i18nStore.$state.code
  }
}
</script>

<template>
  <SelectDropdown
    v-model="i18n.$state.code"
    name="language"
    class="min-w-[200px] leading-[24px]"
    :options="selectOptions"
    :placeholder="selectPlaceholder"
    @input="input"
  />
</template>
