<script setup lang="ts">
import BaseLayout from '../layouts/BaseLayout.vue'
import ContentSection from '../components/base/ContentSection.vue'
import { useIdaFormsStore, isNodeReturnType } from '../stores/idaforms'
import { computed } from 'vue'
import { FormKitSchemaFormKit } from '@formkit/core'

const store = useIdaFormsStore()
store.initialize()

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  node: {
    type: String,
    required: true
  }
})

const node_ = computed(() => {
  const node = store.getNodeByKey(props.node)
  if (isNodeReturnType(node)) return store.nodeAsFormkitSchema(node)
  throw new TypeError('An invalid node type was passed')
})
const childNodes = computed(() => {
  const node = store.getNodeByKey(props.node)
  const childNodes = (isNodeReturnType(node) ? store.childNodes(node) : []) ?? []
  return childNodes as FormKitSchemaFormKit[]
})

</script>

<template>
  <base-layout>
    <template #title>
      <div class="flex items-center mb-2 ">
        <router-link
          :to="`/`"
          class="text-2.75xl font-black inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left text-xl"></i>
          </icon-circle-box>
        </router-link>
        <h1 class="text-2.5xl font-black mb-10">
          {{ gettext('Ida Forms') }}
        </h1>
        <i
          class="las text-xl relative top-1 la-angle-right text-gray-400 mx-2"
        ></i>
      </div>
    </template>
    <content-section
      :has-border="false"
      class="lg:!pt-12"
    >
      {{ node_ }}
      <p>
        <strong>{{ node_?.node.title || node_?.node.key }}</strong>
      </p>
      <p>----</p>
      <ul
        v-for="cnode in childNodes"
        :key="cnode.key"
      >
        <router-link
          :to="{name: 'idanodeedit', params: {node: cnode.key}}"
        >
          {{ cnode.node.name }}
        </router-link>
      </ul>
      <p>-----</p>
      {{ node_.node }}
    </content-section>
  </base-layout>
</template>
